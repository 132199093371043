import React, { Component } from 'react'
import Card from '@mui/material/Card';
import LinkIcon from '@mui/icons-material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

class PaymentListItemCard extends Component {
  constructor(props){
    super(props)
    this.state = {
        order:props.order,
        data:props.data,
        EditOptions:[
            'Edit',
            'Delete',
        ],
        openEditMenu:false,
        editMenuOpen:null,
    }
  }
  convert_date = date => {
    return 
  }
  render() {
    const {order} = this.state;
    let link = ''
    if(order.hasOwnProperty('subscription')){
      link = order.subscription != null ? order.subscription.short_url : '';
    }

    let order_date = new Date(order.created_at);
    return (
      <Card sx={{ minWidth: '100%',maxHeight:'80px',p:0.5,border:'1px solid lightgrey',boxShadow:'none',borderRadius:'10px',mb:1}}>
        <Grid xs={12} container sx={{p:1}} >
          <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Typography sx={{fontSize:'16px'}} component="div">
              {order.subs_id} 
            </Typography>
          </Grid>
          <Grid xs={2.7} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Typography sx={{fontSize:'16px'}} component="div">
              $99
            </Typography>
          </Grid>
          <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Typography sx={{fontSize:'16px'}}>
              {order_date ? order_date.toDateString() : order.created_at}
            </Typography>
          </Grid>
          <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Typography sx={{fontSize:'16px'}} component="div">
                {order.status}
              </Typography>
          </Grid>
          <Grid sx={0.3}>
            { link === '' ? 
              (<div>-</div>) : 
              (<a href={link}><LinkIcon sx={{color:'grey',fontSize:'12px',width:"14px",height:'14px',ml:'4px',mt:'4px'}}/></a>)
            }
          </Grid>
        </Grid>
      </Card>
    )
  }
}

export default PaymentListItemCard;