import React, { Component } from 'react'
import {Box,InputAdornment,Button} from '@mui/material';
import Joi from "joi-browser";
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme } from '@mui/material/styles';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import "../../css/UploadInputCardModel.css";
import Dropzone from 'react-dropzone';
import "../../css/AllInputFields.css";
import Slider from '@mui/material/Slider';
import Info from '@mui/icons-material/Info';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Configuration, OpenAIApi } from "openai";
import config from '../services/config.json';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import ToolTips from './../Common/ToolTips';

class AllInputFields extends Component {
  constructor(props) {  
    super(props);
    this.dropzoneRef = React.createRef({});

    this.state = {
      openAiSelectedDialogData:{
        name:'',
        type:'',
      },
      loadingOpenAiInfo:false,
      openAiDialogOpen:false,
      openAiImageLimit:4,
      showPassword:false,
      fullScreenDialog:false,
      onEdit:false,
      editData:{},
      editFiles:{},
      data:{
        openAiGeneratedText:'',
        openAiGeneratedImage:[],
      },
      files:{},
      errors:{},
      filesType:{},
      passwordVisibility:{
        old_password:false,
        password:false,
        password_confirmation:false
      },
      globalObj : {
        dpi: 0,
        nc: 0,
        w: 0,
        h: 0,
        arr: []
      },
      theme:createTheme({
        palette:{
          primary:{
            main:'#808080',
            darker:'#808080'
          }
        },
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em'
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
          }
        }
      }),
      currentDropDownValue:-1,
      markerlessData:this.props.cardData,
      nameWithExt:'',
      nameWOExt:''
    };
  }

  openDialog2 = (name) => {
    if(this.dropzoneRef[name]) {
      if(this.dropzoneRef[name].current) {
        this.dropzoneRef.current.open();
      }
    }
  }

  GenerateImage = async () => {
    let openAiGeneratedText =  this.state.data.openAiGeneratedText;
    const openAiGeneratedImage = [];
    const data = {...this.state.data,openAiGeneratedImage}
    
    this.setState({loadingOpenAiInfo:true,data})

    const configuration = new Configuration({
        apiKey: config.openAIKey,
    });
    
    const openai = new OpenAIApi(configuration);
    const response = await openai.createImage({
        prompt: openAiGeneratedText,
        n: 4,
        size: "256x256",
        response_format: "b64_json",
    });

    console.log("my response is",response)
    
    if(response.status === 200)
    {
      const openAiGeneratedImage = response.data.data;
      const data = {...this.state.data,openAiGeneratedImage};
      this.setState({data,loadingOpenAiInfo:false});
    }
  }

  setCountryCode = (country_code) => {
    let data = {...this.state.data};
    data['country_code']=country_code;
    this.setState({data})
  }

  getCountryCode = () => {
    return this.state.data['country_code']
  }

  allowedFileType = (type) =>{
    let allowed_types = [];

    switch(type){
      case 'content':
        allowed_types = ['glb','zip','fbx','jpg','png','jpeg','mp4','m4v'];
        break;
      case 'glb':
        allowed_types = ['glb','gltf'];
        break;
      case 'only_png':
        allowed_types = ['png'];
        break;
      case 'only_glb':
          allowed_types = ['glb'];
          break;
      case 'body_track':
          allowed_types = ['glb','vrm'];
          break;
      case 'pattern':
        allowed_types = ['patt'];
        break;
      case 'image':
        allowed_types = ['png', 'jpg', 'jpeg'];
        break;
      case 'ads_background_image':
        allowed_types = ['png', 'jpg', 'jpeg','gif'];
        break;
      case 'video':
        allowed_types = ['mp4','m4v'];
        break;
      case 'audio':
        allowed_types = ['mp3'];
        break;
      case 'pdf':
        allowed_types = ['pdf'];
        break;
      case 'button':
        allowed_types = ['png', 'jpg', 'jpeg'];
        break;
      case 'zip':
        allowed_types = ['zip'];
        break;
      case 'meshFile':
        allowed_types = ['fbx', 'obj'];
        break;
      case 'app':
        allowed_types = ['fbx', 'obj', 'zip'];
        break;
      case 'arPortal':
        allowed_types = ['png', 'jpg', 'jpeg'];
        break;
    }
    return allowed_types;
  }

  validateFileProperty = (name,value,type,sizeLimit='') => {
    // Allowing file type
    let allowed_types = this.allowedFileType(type)
    if(sizeLimit!= ''){
      
      console.log(Math.round((value[0].size/1024))," compare two values" ,value[0].size,sizeLimit)

      if(Math.round(value[0].size/1024) >= sizeLimit ){
        return 'file size should be less than '+ Math.round(sizeLimit/1024) +'mb'
      }      
    }

    
    const currentFileExtension = value[0].name.split('.').pop();
    console.log(" check all values  ===> ",name,value,type,currentFileExtension);

    let fileAllowed = false;
    allowed_types.forEach((extension)=>{
      console.log("extensions are ",extension, " ==> ",currentFileExtension);
      if(extension === currentFileExtension){
        fileAllowed = true;
      }
    })

    if(fileAllowed){
      return null;
    }
    else{
      return "content file should be in " + allowed_types.join(', ') + " format";
    }
    return null;
  };

  SaveEditData(name,files) 
  {
    console.log("let's see on edit files");
    const editData = {...this.state.editData}
    if(name.includes('face_game')) {
      let k = name.split('_');
      editData['imagename' + k[k.length-2]] = files;
    }
    else {
      editData[name] = files; 
    }
    this.setState({editData});
  }

  SaveEditFiles(name,files) {
    const editFiles = {...this.state.editFiles}
    editFiles[name] = files;
    this.setState({editFiles});
  }

  onDrop = (name,files,type,sizeLimit,delete_library_assets = false) => {
    console.log("on drop is working",files);
    
    const errors = { ...this.state.errors };
    const errorMessage = this.validateFileProperty(name,files,type,sizeLimit);


    if (errorMessage) {
      errors[name] = errorMessage;
      this.setState({ errors });
    } else {
      delete errors[name];
      // let {nameWOExt,nameWithExt} = this.state;
      // if (name === 'marker_image') {
      //   nameWithExt = files[0].name;
        
      //   // const canvas = document.getElementById('hiddenCanvas');
      //   // nameWOExt = nameWithExt.substr(0, nameWithExt.lastIndexOf('.'));
      //   const extJpg = nameWithExt.substr(nameWithExt.lastIndexOf('.'));
      //   console.log('Marker Image Extension', extJpg);
      //   if (extJpg.toLowerCase() === '.png' || extJpg === '.PNG') {
      //     // globalObj.dpi = 72;
      //     // readImage(e);
      //   } else {
      //       console.log("Invalid image format!");
      //       return false;
      //       // imageLoader.value = null;
      //   }
      // }
      let data = {...this.state.data};
      let src = window.URL.createObjectURL(files[0]);
      let filesType = this.state.filesType;
  
      const upfile = {...this.state.files};
      upfile[name] = src;
      // data[name] = src;
      
      
      // filesType[name] = files[0].name.split('.').pop();
      // let str = document.getElementById(name);
      // console.log(str);

      // let element, name, arr;
      // element = str.nextSibling;
      // console.log(
      //   (element.innerHTML = str.value.Add File(/C:\\fakepath\\/i, ""))
      // );

      // name = "selected";
      // arr = element.className.split(" ");
      // if (arr.indexOf(name) === -1) {
      //   element.className += " " + name;
      // }
      if(delete_library_assets && name === 'content'){
        delete data['library_assets'];
      }

      if(name == 'audio'){
        const {use_content_audio} = this.state.data;
        if(use_content_audio == true){
          upfile['sound'] = upfile['audio'];
          this.setState({['sound']:files[0]})
        }
      }
      if(name === 'sound')
      {
        delete data['sound'];
      }

      this.setState({ [name]: files[0], errors, files:upfile,filesType:{...filesType,[name]:files[0].name.split('.').pop()} ,data});
      
      let audio = document.getElementById(name)
      if(audio && files[0].name.split('.').pop().toLowerCase()=='mp3'){
        audio.load();
      }
      if(this.state.onEdit) this.SaveEditFiles(name,files)

      if(name === 'marker_image') {
        this.setState({marker_image_processing: files});
        setTimeout(()=>{
          this.startMarkerProcessing();
        },500);
      }
    }
  };

  checkURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      
      if(!pattern.test(str)) return true;
      else return false;
  }

  validateProperty = ({ name, value }) => {
    if(name === 'website') {
      if(this.checkURL(value)) return 'URL is not valid';
    }
    return;
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;

    // if (name === "mobileNumber") {
    //   const regexMobile = /^\d{10}$/;

    //   if (value.trim() === "") return "Mobile Number is required";
    //   else if (value.length > 10)
    //     return "Mobile Number is greater than 10 digit";
    //   else if (!value.match(regexMobile)) return "Mobile Number is not right";
    // }

    // if (name === "otp") {
    //   const regexOTP = /^\d{4}$/;
    //   if (value.trim() === "") return "OTP is required";
    //   else if (value.length > 4) return "OTP length is greater than 4";
    //   else if (!value.match(regexOTP)) return "OTP is not right";
    // }
  };
  
  handleSwitchChange = (event) => {


    console.log('Handle Switch',event.target);
    const {name,checked} = event.target;
    
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(event);
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];
    
    const data = {...this.state.data};
    const files = {... this.state.files};
    const filesType = {...this.state.filesType}
    const editFiles = {...this.state.editFiles}
    console.log("my switch button value is",data[name])
    
    if(name === 'enable_placement'){
      if(typeof data[name] === 'undefined' || data[name]===undefined || data[name] === 'False'){
        data[name]= 'True';
      }
      else{
        data[name] = 'False';
      }
    }
    else if(name === 'get_name' || name === 'get_mobile' || name === 'get_feedback')
    {
      if(typeof data[name] === 'undefined' || data[name]===undefined || data[name] === false){
        data[name]= true;
        data['feedback'] = true;
      }
      else{
        data[name] = false;

        if(data['get_name']=== true || data['get_mobile']=== true ||  data['get_feedback']=== true){
          data[name] = true;
        }
        
        delete data['feedback'];
      }
    }
    else if(name === 'transparent_background'){
      if(typeof data[name] === 'undefined' || data[name]===undefined || data[name] === 'No')
      {
        data[name]= 'Yes';
        data['background_color'] = '#009933';
      }
      else
      {

        delete data[name];
        data['background_color']="";

      }

    }
    else if(name === 'background_ImageVsColor'){
      console.log('Handle Switch Button',name,data[name]);
      if(data[name]==='FALSE'){
        data['background_colour'] = '#FF0000';
        delete files['background_image'];
        delete filesType['background_image'];
        data[name] = 'TRUE';
      }
      else{
        data[name] = 'FALSE';
        delete data['background_colour'];
      }
      console.log('Handle Switch Button',name,data[name]);
    }
    else if(name === 'use_content_audio'){
      if(event.target.value === 'on' || event.target.value === 'off'){
        data[name] = checked ? 'on' : 'off';
      }
      else if(data[name]==true || data[name]==false){
        data[name] = checked ? true : false;
      }
      else{
        data[name] = checked? 'TRUE':'FALSE';
      }
      
      if(data[name] === 'FALSE' || data[name] === 'off' || data[name] === false)
      {
        delete files['sound'];
      }
      else 
      {
        if(typeof this.state['audio'] != 'undefined')
        {
          this.setState({['sound']:this.state['audio']});
          files['sound'] = files['audio'];        
          editFiles['sound'] = true;
        }
        else{
          files['sound'] = files['audio']; 
          this.setState({['sound']:files['audio']});
          editFiles['sound'] = true;
        }
      }
      console.log('my fiels are',files['sound']);
    }
    else{
      if(event.target.value === 'on' || event.target.value === 'off'){
        data[name] = checked ? 'on' : 'off';
  
        if(data[name] === 'off'){
          if('enable_geo_location'){
            data['latitude'] = '';
            data['longitude'] = '';
            data['distance'] = '';
          }
        }
      }
      else if(data[name]==true || data[name]==false){
        data[name] = checked ? true : false;
      }
      else{
        data[name] = checked? 'TRUE':'FALSE';
        
        console.log('trying to ')
  
        
        if(data[name] === 'FALSE')
        {
          if(name === 'enable_splash' )
          {
            console.log('trying to delete the splash image')
            delete data['splash_screen'];
            delete files['splash_screen'];
            delete this.state['splash_screen'];
            delete filesType['splash_screen'];
            delete editFiles['splash_screen'];
          }
          else if(name === 'redirect_to_website'){
            data['redirection_time'] = '';
            data['custom_field_text'] = '';
          }
          // else if(name === 'background_ImageVsColor'){
          //   delete data['background_colour'];
          // }
          else if(name === '')
          console.log("please delete the data related to switch button",name)
        }
        else{
          
          if(name === 'redirect_to_website')
          {
            data['redirection_time'] = 10;
          }
          // else if(name === 'background_ImageVsColor')
          // {
          //   delete files['background_image'];
          //   delete filesType['background_image'];
          //   data['background_colour'] = '#FF0000';
          // }
        }
      }
    }

    if(this.state.onEdit){
      this.SaveEditData(name,data[name]);
    }
    console.log('Handle Switch Button',name,data[name]);
    this.setState({ data, errors, files,filesType,editFiles });
  }

  toGrayscale(arr){
    let gray = [];
    for(let i = 0; i < arr.length; i+=3){
        let avg = (arr[i] + arr[i+1] + arr[i+2])/3;
        gray.push(parseInt(avg));
    }
    return gray;
  }

  getHistogram(arr){
    let hist = [256];
    for(let i = 0; i < arr.length; i++){
        hist[i] = 0;
    }
    for(let i = 0; i < arr.length; i++){
        hist[arr[i]]++;
    }
    return hist;
  }

  calculateQuality(){

    // const canvas = document.getElementById('marker_image_hiddenCanvas');
    // const marker_img = document.getElementById('marker_image')


    const {globalObj} = this.state;
    let gray = this.toGrayscale(globalObj.arr);
    let hist = this.getHistogram(gray);
    let ent = 0;
    let totSize = globalObj.w * globalObj.h;
    for(let i = 0; i < 255; i++){ 
        if(hist[i] > 0){
            let temp = (hist[i]/totSize)*(Math.log(hist[i]/totSize));
            ent += temp;
        }
    }
    
    let entropy = (-1 * ent).toFixed(2);
    let oldRange = (5.17 - 4.6);  
    let newRange = (5 - 0);  
    let level = (((entropy - 4.6) * newRange) / oldRange);
    
    if(level > 5){
        level = 5;
    }else if(level < 0){
        level = 0;
    }
    return {l:level.toFixed(2), e: entropy};
  }

  // changed according to designing standards
  SwitchButton(name,label,value,default_value,isRequired){
    const {data,errors} = this.state;
    console.log("my data name value in switch is",data[name])
    return(
      <Grid container style={{borderRadius:'10px',width:"100%",background:'white'}}>
         <Grid xs={6} style={{display:'flex',fontWeight:"500"}}>
          {label!='' && <Typography sx={{p:1,fontWeight:'500',ml:'10px'}}>
            {label}
            </Typography>
          }
          </Grid>
          <Grid xs={6} sx={{display:'flex',justifyContent:'flex-end',alignItems:'flex-end',pr:'10px'}}>
          <Switch
            defaultChecked = { (data[name]==='on' || data[name] == true || data[name]=='TRUE' || data[name]=='True' || data[name]=='Yes') ? true : false }
            value={ (data[name] ==='on' || data[name] == true || data[name]=='TRUE' || data[name]==='True' || data[name]==='Yes') ? data[name] : default_value}
            onChange={this.handleSwitchChange}
            name={name}
            style={{float:'right'}}
            id={name}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
      </Grid>
    )
  }

  geolocation = (label,name) =>{
    const {data,errors} = this.state;
    return(
      <div style={{background:'white',borderRadius:'10px',padding:'10px',marginBottom:'16px'}}>
        <Grid container>
            <Grid xs={10}>
              <Typography sx={{p:1,fontWeight:'bold'}}>
                {label}
              </Typography>
            </Grid>
            <Grid  xs={2} style={{display:'flex',justifyContent:'end'}}>
              <Switch
                onChange={this.handleSwitchChange}
                name={name}
              id={name}
                defaultChecked = {data[name]==='on'?true:false}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid> 
         {/* <div style={{display:'flex'}}>
          <Typography sx={{p:1}}>
            {label}
          </Typography>
          <Switch
              onChange={this.handleSwitchChange}
              name={name}
              id={name}
              defaultChecked = {data[name]==='on'?true:false}
              inputProps={{ 'aria-label': 'controlled' }}
          />
        </div> */}
        {data[name] === 'on' && (
          <Grid container spacing={2} sx={{p:2}}>
            <Grid item xs={12} sm={12} md={4}>
              {this.NumberRangeField('latitude','Latitude',-9999999999,99999999999,1,true)}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {this.NumberRangeField('longitude','Longitude',-9999999999,99999999999,1,true)}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {this.NumberRangeField('distance','Distance',-9999,99999,1,true)}
            </Grid>
          </Grid>
        )}
      </div>

    )
  }

  SliderWithSwitch = (switchName,name,label, min_value, max_value,step, isRequired,unit) =>{
    const {data,errors} = this.state;

    console.log('my slider valuus is',data[switchName])

    return(
      <div style={{background:'white',borderRadius:'10px',padding:'10px',marginBottom:'16px'}}>
         <div style={{display:'flex'}}>
          <Grid container>
            <Grid xs={10}>
              <Typography sx={{p:1,fontWeight:'bold'}}>
                {label}
              </Typography>
            </Grid>
            <Grid  xs={2} style={{display:'flex',justifyContent:'end'}}>
              {this.SwitchButton(switchName,'','FALSE','FALSE')}
            </Grid>
          </Grid>
        </div>
        { (data[switchName] === 'on'||data[switchName] === 'TRUE'||data[switchName] === true) && ( 
          this.Slider(name,'', min_value, max_value,step, isRequired,unit)
        )}
      </div>
    )
  }

  UploadInputFieldExcludeGLBSwitch = (switchName,label,fieldname,type,isRequired,defaultValue = 'FALSE') =>{
    const {data,errors} = this.state;
    console.log("switch value is set or not for splash screen",data[switchName])
    return(
      <div style={{background:'white',borderRadius:'10px',padding:'10px',marginBottom:'16px'}}>
        <Grid container>  
          <Grid xs={10}>
            <Typography sx={{p:1,fontWeight:'bold'}}>
              {label}
            </Typography>
          </Grid>
          <Grid  xs={2} style={{display:'flex',justifyContent:'end'}}>
          {this.SwitchButton(switchName,'',defaultValue,defaultValue)}
          </Grid>
        </Grid>
          {
            switchName == 'use_content_audio' 
            ?
              (data[switchName] === 'FALSE' || data[switchName] == false || data[switchName]=='off') && (
                this.UploadInputFieldExcludeGLB(fieldname,'',type,isRequired)
              )
            :
              (data[switchName] === 'TRUE' || data[switchName] == true || data[switchName]=='on') && (
                this.UploadInputFieldExcludeGLB(fieldname,'',type,isRequired)
              )
          }
      </div>

    )
  }

  handleSliderChange = (event) =>{

    console.log('Slider Change',event.target)
    const {value,name} = event.target;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(event);
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];

    const data = { ...this.state.data };
    if(name === 'redirection_time') data.custom_field_text = '';
    data[name] = value;
    this.setState({ data, errors });
    if(this.state.onEdit){
      this.SaveEditData(name,data[name]);
    }
  } 

  handleSelectChange = (e) => {
    // data[e.target.name] = e.target.value;
    const input = e.target;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    if(input.value != data[input.name] && input.name === 'detection_point' && input.value == 'head'){
      data['place_head'] = null
    }
    if(input.name === 'node_type')
    {  
      if(input.value === 'cc')
        data['type'] = 'content';
      else if(input.value === 'ss')
        data['type'] = 'splash_screen';
      else if(input.value === 'pi')
        data['type'] = 'panel_image';
      else if(input.value === 'cf')
        data['type'] = 'feedback'
    }
    data[input.name] = input.value;
    this.setState({ data, errors });
    console.log("Select Field",e.target.value)
    if(this.state.onEdit){
      this.SaveEditData(input.name,input.value);
    }
  };

  checkValue = () => {
    return;
  }
  
  handleChange = ({ currentTarget: input },minLength='',maxLength='') => {
    const errors = { ...this.state.errors };

    let errorMessage = this.validateProperty(input);

    if(minLength !== '' && maxLength !== ''){
      if(input.value.length >= maxLength){
        errorMessage = 'Character Length should not exceed '+maxLength+' Characters.'
      }
    }

  
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    if(!(input.value.length === maxLength)){
      data[input.name] = input.value;
    }
    this.setState({ data, errors });
    console.log('Handle Change',input.value);
    if(this.state.onEdit){
      this.SaveEditData(input.name,input.value);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("i am in all input field page")
    const errors = this.validate();
    console.log("my errors",errors)
    console.log("my errors should be like 0",errors);
    this.setState({ errors: errors || {} });
    console.log("my errors should be like 1",errors);
    if (errors && Object.keys(errors).length !== 0){
      console.log("my errors should be like 1 nothing will happend now because of return keyword here",errors)
      return
    };
      console.log("my errors should be like 2",errors);
    this.doSubmit();
  };

  handleDataType(id,type){
    let data = this.state.markerlessData;
    
    data = data.map((d)=>{
      if(d.id === id){
        d.dataType = type;
      } 
      return d;
    })
    this.setState({markerlessData:data})
    console.log("datatype is settled now =>",this.state.markerlessData);
  }

  setData(id,value){
    let data = this.state.markerlessData;
    
    data = data.map((d)=>{
      if(d.id === id){
        d.value = value;
      } 
      return d;
    })
    this.setState({markerlessData:data})
  }

  handleOpenAIDialogClickOpen = (name,type) => {
    const openAiSelectedDialogData = {
      name:name,
      type:type,
    }
    this.setState({openAiDialogOpen:true,openAiSelectedDialogData})
  };

  handleOpenAIDialogClose = () => {
    this.setState({openAiDialogOpen:false})
  };

  chooseImagefromOpenAI =  (name,type,imageURL,id) => {
    const allowed_types = this.allowedFileType(type);
    let fileAllowed = false;
    allowed_types.forEach((extension)=> {
      if(extension === 'png' || extension === 'jpeg' || extension === 'jpg'){
        fileAllowed = true;
      }
    })

    if(fileAllowed === false){
      const errors = {...this.state.error, [name]:'image is not allowed to upload'};
      this.setState({errors})
      return null;
    }
    let data = {...this.state.data};
    let files = {...this.state.files}
    let editFiles = {...this.state.editFiles};
    let filesType = {...this.state.filesType};
    filesType[name] = 'png';    
    // data[name] = imageURL;
    
    if(name === 'content' )
    {
      delete data['library_assets'];
    }
    files[name] = imageURL;
    editFiles[name] = imageURL;
    document.getElementById(id).classList.add('OpenAIImageClicked');

    setTimeout(()=>{
      this.setState({files,data,filesType,openAiDialogOpen:false,[name]:imageURL,editFiles,marker_image_processing: imageURL})
    },500);

    setTimeout(()=>{
      this.startMarkerProcessingChatGpt();
    },1000);
  }
  
  setImageName = (e) => {
    const data = {...this.state.data,openAiGeneratedText:e.target.value}; 
    if(e.target.value.length <200){
      this.setState({data})
    }
  };

  openAiDialogBox(name,type) {
    const {openAiDialogOpen,loadingOpenAiInfo} = this.state;
    const {handleOpenAIDialogClose} = this;
    const {openAiGeneratedImage,openAiGeneratedText} = this.state.data;

    return(
      <Dialog
        open={openAiDialogOpen}
        onClose={handleOpenAIDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id={name}
      >
        <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}}>
          <CloseOutlinedIcon style={{padding:'4px',marginTop:'6px',marginRight:'6px',cursor:'pointer'}} onClick={handleOpenAIDialogClose} />
        </div>
          
        <DialogTitle id="alert-dialog-title">
          {"Please define your image in less than 200 character."}
        </DialogTitle>
        <DialogContent style={{minWidth:'600px'}}>
          <DialogContentText id="alert-dialog-description">
            <div style={{width:'100%', marginBottom:'16px'}}>
              <OutlinedInput
                aria-describedby="outlined-weight-helper-text"
                onChange={(e)=>{
                  this.setImageName(e);
                  // const data = {...this.state.data,openAiGeneratedText:e.target.value}; e.target.value.length < 200?this.setState({data}):''; 
                }}
                sx={{height:"48px !important",width:"100%",mb:1,border:'1px light lightgrey'}}
              />
            </div>
            {
              loadingOpenAiInfo ? (
                <div>Loading...</div>
              ):
              (
                openAiGeneratedImage &&
                <Grid container>
                  {openAiGeneratedImage.map((src,index) => {
                    let id = "openAIImage"+index
                    return (
                      <Grid  xs={6} style={{padding:'10px',borderRadius:'10px',width:"100%",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"center"}}>
                        <img id={id} src={`data:image/png;base64,${src.b64_json}` } alt="" style={{margin:'auto',width:"250px",height:"250px",borderRadius:"10px",border:'1px solid grey',cursor:'pointer'}} 
                        onClick={()=>this.chooseImagefromOpenAI(name,type,`data:image/png;base64,${src.b64_json}`,id)}/>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleOpenAIDialogClose}>Disagree</Button> */}
          <Button disabled={ typeof openAiGeneratedText!== 'undefined' && openAiGeneratedText.length===0?true:false} onClick={()=>this.GenerateImage()} autoFocus>
          Create Images
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  UploadInputField(name,label,type,isRequired,sizeLimit=20480,helperText=null,info={status:false}) {
    const {errors} = this.state;
    const data = this.state.files;
    const assets = this.state;
    const {handleOpenAIDialogClickOpen} = this;
    const {openDialog2} = this;
    
    return(
      <>
        <div className="InputField" style={{marginBottom:'16px' }}>
          <Dropzone  name={name} onDrop={(files)=>{this.onDrop(name,files,type,sizeLimit,true)}} >
          {({getRootProps, getInputProps}) => {
              
            let InputTypeDefine = getInputProps();

            return(
              <section className="container">
                <Grid container sx={{margin:'auto'}} >
                  <Grid xs={10} sx={{mx:'auto'}}>
                    <Typography sx={{p:1,pl:3,fontWeight:'bold',fontSize:'16px'}}>
                      {label}
                    </Typography>
                  </Grid>
                  <Grid xs={2} sx={{mx:'auto'}}>
                    {info.status === true && (
                      <ToolTips
                        name={name}
                        scId={info.sid}
                        type={info.dp}
                        subtype={info.subtype}
                      />
                    )}
                  </Grid>
                </Grid>
                  
                <div {...getRootProps({className:'dropzone'}) }>
                  <Box className="UploadDataContainer" sx={{p:2,mx:'auto',mb:1,border:'2.5px dotted #682CEA',borderRadius:'10px',width:'96%',height:'70%',textAlign:'center',justifyContent:'center',display:'flex'}}>
                    <div className="UploadButtonBox">
                      {
                        
                        (this.state.filesType[name] || typeof data[name] !== 'undefined' || typeof assets[name] !== 'undefined') ?
                        (
                          <div style={{fontSize:'13px'}}>
                          {/* {
                            (this.state.fileType[name]==='png' && name === 'marker_image') && <canvas id="marker_image_canvas" style={{display:'none'}}>check canvas</canvas>
                          } */}
                          {
                            (this.state.filesType[name] === "png" || this.state.filesType[name] === "jpeg" || this.state.filesType[name] === "jpg")
                            &&
                            <>
                              <img id={data[name]} src={data[name]}   height='100px' width='100px' alt='voss'/> 
                            </>
                          }
                          { 
                            this.state.filesType[name] === "mp3"
                              && 
                            <audio
                              controls={true} id={name}  >
                              <source type="audio/mp3" src={data[name]} />
                            </audio>
                          }
                          {
                            this.state.filesType[name] === "glb"
                              && 
                            <model-viewer 
                              sx={{width:'20px',height:'20px'}}
                              src={data[name]===undefined?assets[name]:data[name]} alt="A 3D model" auto-rotate="" camera-controls="" 
                              id={data[name]===undefined?assets[name]:data[name]}
                              // background-color="#455A64"
                            ></model-viewer>
                          }{
                            this.state.filesType[name] === "zip"
                            &&
                            <div>
                                Zip Uploaded Successfully
                            </div>
                          }
                          {
                            this.state.filesType[name] === 'mp4'
                            &&
                            <div>
                              <video width="320" height="240" src={data[name]} controls>
                              </video>
                              </div>
                          }
                          {
                            this.state.filesType[name] === 'obj'
                            &&
                            <div>
                              Obj File Uploaded Successfully
                            </div>
                          }
                          </div>
                        ) 
                        : (<BackupOutlinedIcon sx={{color:'#682CEA',width:'30px'}}/>)
                      }
                      {
                        ( typeof this.state.filesType[name] === 'undefined') ?
                        (
                          <Box>
                            <Typography sx={{fontSize:'20px',color:'#682CEA'}}>
                              Drag  & Drop {data.dataType}
                            </Typography>
                            <Typography variant="subtitle2" sx={{fontSize:'10px',color:'black',b:0,p:0}}>or</Typography>

                          <Grid container>
                            <Grid >
                              <Typography style={{fontSize:'12px',color:'#682CEA',fontWeight:'600',cursor:'pointer',p:1,borderBottom:'1px solid #682CEA'}} onClick={()=>openDialog2(name)}>Select from your Device</Typography>
                            </Grid>
                          </Grid>
                          </Box>
                        ):
                        <Typography style={{fontSize:'12px',color:'#682CEA',fontWeight:'600',cursor:'pointer',borderBottom:'1px solid #682CEA'}} onClick={()=>openDialog2(name)}>Add File From Your Device</Typography>
                      }
                    </div>
                  </Box>
                    
                </div>
                {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
              </section>
          )}}
          </Dropzone>
          {helperText !== null
            ?
              (
                <div style={{backgroundColor:'#d7d9dc', paddingBottom:'12px', paddingTop:'12px', borderRadius:'10px'}}>
                  {helperText}
                </div>
              )
            : 
            (
              <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center',width:"85%",margin:'auto',mb:1}} >
                <Grid xs={12} sm={6} sx={{display:'flex',justifyContent:'center',alignItems:'center',mx:'auto'}}>
                  <Button 
                    id = {name}
                    sx={{width:'260px',borderRadius:'25px',border:'1px solid #682CEA',height:'43px',color:'#682CEA',textTransform:'capitalize'}} 
                    onClick={(e)=>this.handleFullScreenDialogOpen(name,type)}
                  >
                    {data[name] === undefined ? 'Add File From Your Gallery':'Add File From Your Gallery'}
                  </Button>
                </Grid>
                {(type === 'content' || type==='only_png' || type === 'image' || type === 'button' || type === 'arPortal') &&
                  (
                    <Grid xs={12} sm={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                          <Button 
                            id = "chat_gpt_open_dialogue_2"
                            sx={{width:'260px',borderRadius:'25px',border:'1px solid #682CEA',height:'43px',color:'#682CEA',textTransform:'capitalize'}} 
                            onClick={()=>handleOpenAIDialogClickOpen(name,type)}
                          >
                            Use Chat GPT To Create Image
                          </Button>
                    </Grid>
                  )
                }
              </Grid>
            )
          }

          {this.state.fullScreenDialog  && this.OpenDialogBox(name)}
        </div>
      </>
    )
  }

  // changed according to designing standards
  UploadInputFieldExcludeGLB(name,label,type,isRequired,sizeLimit=10240,isImageGenerationAllowed=true,info={status:false}){
    const {errors} = this.state;
    const {handleOpenAIDialogClickOpen} = this;
    const data = this.state.files;
    const {openDialog2} = this;
    return(
      <div className="InputField" style={{marginBottom:'16px'}}>
        
        <Dropzone  name={name} onDrop={(files)=>{this.onDrop(name,files,type,sizeLimit)}} >
        {({getRootProps, getInputProps}) => {
            
          let InputTypeDefine = getInputProps();

          return(
            <section className="container">
              <Grid container sx={{margin:'auto'}} >
                <Grid xs={10} sx={{mx:'auto'}}>
                  <Typography sx={{p:1,pl:3,fontWeight:'bold',fontSize:'16px'}}>
                    {label}
                  </Typography>
                </Grid>
                <Grid xs={2} sx={{mx:'auto'}}>
                  {info.status === true && (
                    <ToolTips
                      name={name}
                      scId={info.sid}
                      type={info.dp}
                      subtype={info.subtype}
                    />
                  )}
                </Grid>
              </Grid>
              <div >
                <Box {...getRootProps({className:'dropzone'}) } className="UploadDataContainer" sx={{p:2,mx:'auto',mb:2,border:'2.5px dotted #682CEA',borderRadius:'10px',width:'96%',height:'70%',textAlign:'center',justifyContent:'center',display:'flex'}}>
                  <div className="UploadButtonBox">
                    {
                      data[name] !== undefined ?
                      (
                        <div style={{fontSize:'13px'}}>
                        {
                          (this.state.filesType[name] === "png" || this.state.filesType[name] === "jpeg" || this.state.filesType[name] === "jpg")
                          &&
                          <>
                            <img id={data[name]} src={data[name]} height='100px' width='100px' alt='voss'/> 
                          </>
                        }
                        { 
                          this.state.filesType[name] === "mp3"
                            && 
                          <audio
                            controls={true}  id={name} >
                            <source type="audio/mp3" name={data[name]} src={data[name]} />
                          </audio>
                        }
                        {
                          this.state.filesType[name] === "zip"
                          &&
                          <div>
                              Zip Uploaded Successfully
                          </div>
                        }
                        {
                          this.state.filesType[name] === 'mp4'
                          &&
                          <div>
                            <video width="320" height="240" src={data[name]} controls>
                            </video>
                            </div>
                        }
                        {
                          this.state.filesType[name] === 'obj'
                          &&
                          <div>
                            Obj File Uploaded Successfully
                          </div>
                        }
                        </div>
                      ) : (<BackupOutlinedIcon sx={{color:'#682CEA',width:'30px'}}/>)
                    }



                    {
                      data[name] === undefined ?
                      (
                        <Box>
                          <Typography sx={{fontSize:'20px',color:'#682CEA'}}>
                            Drag  & Drop {data.dataType}
                          </Typography>
                          <Typography variant="subtitle2" sx={{fontSize:'10px',color:'black',b:0,p:0}}>or</Typography>
                          <Typography style={{fontSize:'12px',color:'#682CEA',fontWeight:'600',cursor:'pointer',p:1,borderBottom:'1px solid #682CEA'}}   onClick={()=>openDialog2(name)}>Select from your pc</Typography>
                        </Box>
                      ):
                      <>
                        <Typography style={{fontSize:'12px',color:'#682CEA',fontWeight:'600',cursor:'pointer',borderBottom:'1px solid #682CEA'}} onClick={()=>openDialog2(name)}>Add File From Your pc</Typography>
                      </>
                    }
                  </div>
                </Box>
                <div style = {{display:'flex',alignContent:'center',justifyContent:'center'}}>
                  {
                    (isImageGenerationAllowed) && (type === 'content' || type==='only_png' || type === 'image' || type === 'button' || type === 'arPortal' || type === 'ads_background_image')
                    && (
                      <>
                        <Button 
                          id = "chat_gpt_open_dialogue_1"
                          sx={{width:'265px',borderRadius:'25px',border:'1px solid #682CEA',height:'43px',color:'#682CEA',textTransform:'capitalize',my:1}} 
                          onClick={()=>handleOpenAIDialogClickOpen(name,type)}
                        >
                          Use Chat GPT to create Image
                        </Button>
                      </>
                    )
                  }
                </div>
              </div>
              {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
            </section>
        )}}
        </Dropzone>
      </div>
    )
  }

  // changed according to designing standards
  UploadInputFieldImageWithSwitch(name,label,type,isRequired,sizeLimit=10240,isImageGenerationAllowed=true,helperText){
    const {errors} = this.state;
    const {handleOpenAIDialogClickOpen} = this;
    const data = this.state.files;
    const {openDialog2} = this;
    return(
      <div style={{background:'white',borderRadius:'10px'}}>
        <Dropzone  name={name} onDrop={(files)=>{this.onDrop(name,files,type,sizeLimit)}} >
          {
            ({getRootProps, getInputProps}) => {
              let InputTypeDefine = getInputProps();
              return(
                <section className="container">
                  <aside>
                    <Typography sx={{p:1,pl:3,fontWeight:'bold',fontSize:'16px'}}>
                      {label}
                    </Typography>
                  </aside>
                  <div >
                    <Box {...getRootProps({className:'dropzone'}) } className="UploadDataContainer" sx={{p:2,mx:'auto',mb:2,border:'2.5px dotted #682CEA',borderRadius:'10px',width:'96%',height:'70%',textAlign:'center',justifyContent:'center',display:'flex'}}>
                      <div className="UploadButtonBox">
                        {
                          data[name] !== undefined ?
                          (
                            <div style={{fontSize:'13px'}}>
                            {
                              (this.state.filesType[name] === "png" || this.state.filesType[name] === "jpeg" || this.state.filesType[name] === "jpg")
                              &&
                              <>
                                <img id={data[name]} src={data[name]}   height='100px' width='100px' alt='voss'/> 
                              </>
                            }
                            { 
                              this.state.filesType[name] === "mp3"
                                && 
                              <audio
                                controls={true}  id={name} >
                                <source type="audio/mp3" name={data[name]} src={data[name]} />
                              </audio>
                            }
                            {
                              this.state.filesType[name] === "zip"
                              &&
                              <div>
                                  Zip Uploaded Successfully
                              </div>
                            }
                            {
                              this.state.filesType[name] === 'mp4'
                              &&
                              <div>
                                <video width="320" height="240" src={data[name]} controls>
                                </video>
                                </div>
                            }
                            {
                              this.state.filesType[name] === 'obj'
                              &&
                              <div>
                                Obj File Uploaded Successfully
                              </div>
                            }
                            </div>
                          ) : (<BackupOutlinedIcon sx={{color:'#682CEA',width:'30px'}}/>)
                        }

                        {
                          data[name] === undefined ?
                          (
                            <Box>
                              <Typography sx={{fontSize:'20px',color:'#682CEA'}}>
                                Drag  & Drop {data.dataType}
                              </Typography>
                              <Typography variant="subtitle2" sx={{fontSize:'10px',color:'black',b:0,p:0}}>or</Typography>
                              <Typography style={{fontSize:'12px',color:'#682CEA',fontWeight:'600',cursor:'pointer',p:1,borderBottom:'1px solid #682CEA'}}   onClick={()=>openDialog2(name)}>Select from your pc</Typography>
                            </Box>
                          ):
                          <>
                            <Typography style={{fontSize:'12px',color:'#682CEA',fontWeight:'600',cursor:'pointer',borderBottom:'1px solid #682CEA'}} onClick={()=>openDialog2(name)}>Add File From Your pc</Typography>
                          </>
                        }
                      </div>
                    </Box>
                    <div style = {{display:'flex',alignContent:'center',justifyContent:'center'}}>
                      {
                        isImageGenerationAllowed &&
                        (
                          <>
                            <Button 
                              id = "chat_gpt_open_dialogue_1"
                              sx={{width:'265px',borderRadius:'25px',border:'1px solid #682CEA',height:'43px',color:'#682CEA',textTransform:'capitalize',my:1}} 
                              onClick={()=>handleOpenAIDialogClickOpen(name,type)}
                            >
                              Use Chat GPT to create Image
                            </Button>
                          </>
                        )
                      }
                    </div>
                  </div>
                  {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
                </section>
              )
            }
          }
        </Dropzone>
        {helperText &&
          (<div style={{backgroundColor:'#d7d9dc', paddingBottom:'12px', paddingTop:'12px'}}>
            {helperText}
            {/* <Typography sx={{px:2, fontWeight:'regular', fontSize:'16px', textAlign:'justify'}}>Please use GLB or VRM (Virtual Reality Model) files only. To know how to make VRM file click on the link. <a href="https://vrm.dev/en/vrm/how_to_make_vrm/index.html" target="_blank">VRM Documentation</a></Typography> */}
          </div>)
        }
      </div>
    )
  }

  // changed according to designing standards
  SwitchButtonWithInput(name,label,value,default_value,isRequired){
    const {data,errors} = this.state;
    console.log("my data name value in switch is",data[name])
    return(
      <Grid container style={{width:"100%",background:'white'}}>
         <Grid xs={6} style={{display:'flex',fontWeight:"500"}}>
          {label!='' && <Typography sx={{p:1,fontWeight:'500',ml:'10px'}}>
            {label}
            </Typography>
          }
          </Grid>
          <Grid xs={6} sx={{display:'flex',justifyContent:'flex-end',alignItems:'flex-end',pr:'10px'}}>
          <Switch
            defaultChecked = { (data[name]==='on' || data[name] == true || data[name]=='TRUE' || data[name]=='True' || data[name]=='Yes') ? true : false }
            value={ (data[name] ==='on' || data[name] == true || data[name]=='TRUE' || data[name]==='True' || data[name]==='Yes') ? data[name] : default_value}
            onChange={this.handleSwitchChange}
            name={name}
            style={{float:'right'}}
            id={name}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
      </Grid>
    )
  }

  UploadInputField2(name,label){
    
    const {openDialog2} = this;
    return(
      <Box sx={{background:'skyblue',py:'16px',display:'flex',flexDirection:'column',alignItems:'center',jutifyContent:'center'}}>
        {label}
        <Dropzone ref={this.dropzoneRef} noClick noKeyboard>
          {({getRootProps, getInputProps, acceptedFiles}) => {
            return (
              <div className="container">
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here</p>
                  <button
                    type="button"
                    onClick={openDialog2}
                  >
                    Open File Dialog
                  </button>
                </div>
                <aside>
                  <h4>Files</h4>
                  <ul>
                    {acceptedFiles.map(file => (
                      <li key={file.path}>
                        {file.path} - {file.size} bytes
                      </li>
                    ))}
                  </ul>
                </aside>
              </div>
            );
          }}
        </Dropzone>
        </Box>
    )

  }

  DropDownInputField(name,label,options,helperText,isRequired) {
    const {data,errors} = this.state;
    return(
      <>
        <FormControl fullWidth className="InputField" sx={{mb:2}}>
          <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id={data[name]}
            name={name}
            value={data[name]}
            label={label}
            onChange={this.handleSelectChange}
            required = {isRequired}
          >
            {
              options.map(option=>{
                return(<MenuItem value={option.name} selected={data[name]===option.name} >{option.label}</MenuItem>)
              })
            }
          </Select>
        </FormControl>
        {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
      </>
    )
  }

  Rating(name,value,isRequired,ReadOnly){

    return(
      <Rating
        value={value}
        id = {name}
        name = {name}
        isRequired = {isRequired}
        readOnly = {ReadOnly}
        onChange={this.handleChange}
        size="large" 
        sx={{pb:2}}
      />
    )
  }

  changeVisiblity = (name) =>{

    let passwordVisibility = {...this.state.passwordVisibility}
    console.log("look this",passwordVisibility)


    if(passwordVisibility[name])
      passwordVisibility[name] = false
    else
      passwordVisibility[name] = true
    
    this.setState({passwordVisibility})
    setTimeout(()=>{
      console.log("my password visibility is",this.state.passwordVisibility)
    },200)
  }

  passwordInputField(name, type ,label, isRequired, isDisabled = false,){
    const { data, errors, onEdit } = this.state;
    let passwordVisibility = {...this.state.passwordVisibility}

    return(
      <div style={{width:'100%', marginBottom:'16px'}}>
        <FormControl sx={{width: '100%' }} variant="outlined">
          {/* <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel> */}
          <OutlinedInput
            sx={{height:"48px !important",width:"100%"}}
            id={name}
            name={name}
            placeholder={label}
            type={  passwordVisibility[name] ? 'text':'password'}
            onChange={this.handleChange}
            endAdornment={
              <InputAdornment position="end">
                  <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>this.changeVisiblity(name)}
                  edge="end"
                >
                  {passwordVisibility[name] ? <Visibility sx={{height:'18px'}} /> : <VisibilityOff sx={{height:'18px'}} />}
                </IconButton>
              </InputAdornment>
              }
            isRequired = {isRequired}
            isDisabled = {isDisabled}
          />
        </FormControl>
        {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
      </div>
    )
  }
  
  // changed according to designing standards
  renderInput(name, type = "text", label, isRequired, isDisabled = false) {
    const { data, errors, onEdit } = this.state;
    return(
      <div style={{width:'100%', marginBottom:'16px'}}>
          <OutlinedInput
              aria-describedby="outlined-weight-helper-text"
              name={name}
              id={name}
              placeholder={label}
              color="primary"
              className="InputField"
              required={isRequired}
              disabled={isDisabled}
              type={type}
              // error={errors[name]}
              value={data[name]}
              onChange={this.handleChange}
              fullWidth

              onBlur={name === 'exp_name' && !onEdit ? this.checkExperienceName : this.checkValue}
              sx={{height:"48px !important",width:"100%",mb:1,border:'1px light lightgrey'}}
          />
        {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
      </div>
    )
  }

  // changed according to designing standards
  renderInputGA4(name, type = "text", label, isRequired, isDisabled = false) {
    const { data, errors, onEdit } = this.state;
    return(
      <div style={{width:'100%', marginBottom:'16px', backgroundColor:'#d7d9dc', paddingBottom:'12px', borderRadius:'10px' }}>
        <OutlinedInput
            aria-describedby="outlined-weight-helper-text"
            name={name}
            id={name}
            placeholder={label}
            color="primary"
            className="InputField"
            required={isRequired}
            disabled={isDisabled}
            type={type}
            // error={errors[name]}
            value={data[name]}
            onChange={this.handleChange}
            fullWidth

            onBlur={name === 'exp_name' && !onEdit ? this.checkExperienceName : this.checkValue}
            sx={{height:"48px !important",width:"100%",mb:1,border:'1px light lightgrey'}}
        />

        <Typography sx={{px:2, fontWeight:'regular', fontSize:'16px', textAlign:'justify'}}>While Creating a GA4 Tag choose https & enter webar.vossle.com in the website URL. Upon creation, locate the "Measurement Id (i.e G-ML7ERLXXXX)" and type it here to track analytics of this AR experience.</Typography>
        {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
      </div>
    )
  }
  
  renderInputWithIcon(name, type = "text", label, isRequired, isDisabled = false) {
    const { data, errors, onEdit } = this.state;
    return(
      <div style={{width:'100%', marginBottom:'16px'}}>
        <OutlinedInput
          name={name}
          id={name}
          // label={label}
          color="primary"
          className="InputField"
          required={isRequired}
          disabled={isDisabled}
          placeholder={label}
          type={type}
          value={(data[name] != null && data[name] !== "null") ?data[name] :''}
          onChange={this.handleChange}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end"><EditIcon/></InputAdornment>,
          }}
        />
        {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
      </div>
    )
  }
  
  // changed according to designing standards
  MultiLineStringInputField(name, type = "text", label, isRequired,minLength,maxLength) {
    const {data,errors} = this.state;
    console.log("my name is",errors,name)
    return(
      <>
        <OutlinedInput
          name={name}
          id={name}
          className="InputField"
          placeholder={label}
          color="primary"
          required={isRequired}
          onChange={(e)=>{this.handleChange(e,minLength,maxLength)} }
          multiline      
          sx={{width:'100%',border:'green', mb:2}}
          value={data[name]}
        />
        {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
      </>
    )
  }
  
  // changed according to designing standards
  Slider(name, label, min_value, max_value,step, isRequired,unit){
    const {data,errors} = this.state
    return(
      <div  style={{marginBottom:'16px',borderRadius:'10px',border:'1px solid lightgrey',flexDirection:'column',justifyContent:'center',display:'flex',background:'white'}} >
          <div style={{padding:"1em"}}>
            <Typography style={{fontWeight:"600"}}>
                {label}
            </Typography>
            <Slider
              name={name}
              id={name}
              aria-label="Temperature"
              defaultValue={data[name]}
              onChange={this.handleSliderChange}
              required = {isRequired}
              step={step}
              marks
              min={min_value}
              max={max_value}
              color="primary"
            />
            <div style={{width:"100%",display:'flex',alignItems:'flex-end',justifyContent:"flex-end",color:'#682CEA',fontWeight:"700"}}>{data[name]??0} {unit}</div>
          </div>
          
          {
            errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>)
          }
        
      </div>
    )
  }
  
  // changed according to designing standards
  ColorPicker(name,label,isRequired, props){ 
    const {data,errors} = this.state;
    return(
      <>
        <OutlinedInput
          sx={{borderRadius:'50%',height:'100%',width:'100%',mb:2}}
          type="color"
          label={label}
          name={name}
          className="InputField"
          defaultValue={typeof data[name] =='undefined' || data[name] === "null" || data[name]===null ? (name === 'redirection_button_colour'?'#e0dede':'#FF0000') : data[name]}
          required = {isRequired}
          onChange={this.handleChange}
          // inputProps={{ max:data.max,min:data.min }}
          value={typeof data[name] =='undefined' || data[name] === "null" || data[name]===null ? (name === 'redirection_button_colour'?'#e0dede':'#FF0000') : data[name]}
        />
        {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>) }
      </>
    )
  }
  
  // changed according to designing standards
  NumberRangeField(name,label,minRange,maxRange,step,isKeyboardAllow){
    const {data,errors} = this.state;
    return(
      <div style={{marginBottom:'16px'}}>
        <OutlinedInput
          sx={{width:'100%',mb:2}}
          type="number"
          id="outlined-basic"
          name={name}
          // label={label}
          placeholder={label}
          variant="outlined"
          className="InputField"
          defaultValue={data[name]}
          onChange={this.handleChange}
          inputProps={{ 
            max:maxRange,
            min:minRange,
            step:step,
            onKeyDown: (event) => {
              if(!(event.key === "ArrowDown"|| event.key ==="ArrowUp")){
                if(isKeyboardAllow === false){
                  event.preventDefault()
                }
              }

            }
          }}
        />
        {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>)}
      </div>
    )
  }
  
  renderRadioButton(name,label,options,optionsLabels,isRequired){
    const {data,errors} = this.state;
    if(data[name] === undefined || typeof data[name] === 'undefined'){
      data[name] = 'null';
    }
    console.log("my texture value is",name,data[name],options[0])
    return(
      <div style={{padding:"16px",background:'white',borderRadius:'10px',marginBottom:'16px',border:'1px solid grey',display:'flex',justifyConten:'center',alignItems:'center'}}>
        <Typography sx={{display:'flex',ml:0.6}}>
          {label}
        </Typography>
        
        <RadioGroup
          row
          id = {name}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name={name}
          defaultValue = {(data[name]===null || data[name]==="null" )? options[0]:data[name]}
          // value = {data[name]}
          // defaultValue={data[name]===null ? options[0]:data[name]}
          // defaultValue = {data[name] !== null ? data[name]:options[0]}
          required = {isRequired}
          onChange={this.handleSliderChange}
        >
          {options.map((option ,index)=>{return(
          <FormControlLabel sx={{ml:1}} value={option} control={<Radio />} label={optionsLabels[index]} />)})}
        </RadioGroup>
        {errors[name] && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors[name]}</Typography>)}
      </div>
    )
  }
  
};

export default AllInputFields;