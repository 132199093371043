import React, { Component } from 'react'
import Card from '@mui/material/Card';
import CardImage2 from "../../assets/CardImage3.png";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import "../../css/workingProductivityCard.css";

export default class WorkingProductivityCard extends Component {

  constructor(props){
    super(props)
    this.state = {
      data : props.data
    }
    // this.style.backgroundImage = `url(${CardImage2})`;
    // backgroundImage: url({CardImage2})
  }

  componentDidMount(){
    // const card = document.getElementById('myCardForImage')
    // card.style.backgroundImage = `url(${CardImage2})`
    // card.style.backgroundWidth = `100% auto`;
    // card.style.backgroundSize = `100% auto`;
    // console.log(card)
    // console.log("my chart",ctx)
    // const myChart = new Chart(ctx,this.state.chartData);
  }


  render() {
    let data = this.props.data;
    return (

    <Card sx={{ maxWidth: '376px',maxHeight: '182px',boxShadow:'0' }}>
      <Box sx={{ position: 'relative',background:data.background }}>
        <CardMedia
          component="img"
          // height="200"
          width="100%"
          height="100%"
          image={data.image}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right:20,
            height:"100%",
            width: '100%',
            color: 'white',
            padding: '10px',
            
          }}
        >
          <Box style={{width:"100%",height:'100%',display:'flex',justifyContent:'right',alignItems:'center'}}>
            <Box  style={{width:"55%",textAlign:'center'}}>
              <Typography className='Subtitle1' sx={{fontSize:'15px',color:'black'}}>{data.title}</Typography>
              <Typography className="heading" sx={{fontSize:'32px',color:"#682CEA",fontWeight:'500'}} >{data.value}</Typography>
              <Typography className='Subtitle2' sx={{fontSize:'12px',color:'black'}}>{data.subtitle}</Typography>
            </Box>
          </Box>

        </Box>
      </Box>
    </Card>
    )
  }
}
