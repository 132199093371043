import http from './httpServices';
import config from "./config.json";
import axios from "axios";
const apiEndpoint = config.apiEndpoint;

export async function getAllCollectionCategories() {
  const all_collection =  await http.get(apiEndpoint+"collection_category");
  return all_collection.status ? all_collection:[];
}

export async function getCategories(){
  return await http.get(apiEndpoint + "collection_category");
}

export async function getCollectionById(id) {
  const collection =  await http.get(apiEndpoint+"collection/"+id);
  return await collection.status ? collection : [];
}

export async function getCategoriesExpBased(FormData) {
  return await http.post(apiEndpoint + "collection_category_exp_based",FormData);
}

export async function getCollectionByIdExpBased(FormData) {
  const collection =  await http.post(apiEndpoint+"collection_exp_based",FormData);
  return await collection.status ? collection : [];
}

export async function getExperienceByArId(id){
  const exp = await http.get(apiEndpoint+"ar/"+id+"/edit");
  return await exp.status ? exp : {}; 
}

