import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import PaymentListItemCard from '../Components/cards/PaymentListItemCard';
import { get_payment_details } from '../Components/services/paymentService';
import { Link, Navigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Loader from '../Components/Common/Loader';
import Paginations from './../Components/Common/paginations';
import { paginate } from './../Components/utils/paginate';
import Balloon from '../assets/baloon.png'

class Payments extends Component {
  constructor(props){
    super(props)
    this.state  = {
      orderDetails:[],
      sendToHomePage:false,
      loaderFlag:true,
      token_expired:false,
      text:'Loading Payment Details, Please wait ...',
      filters:[
        {id:1,name:'Ascending',value:'Ascending',action:'created_at'},
        {id:2,name:'Descending',value:'Descending',action:'created_at'},
        {id:3,name:'Success',value:'Success',action:'status'},
      ], 
      selectedFilter:{id:3,name:'Success',value:'Success',action:'status'},
      pageSize:10,
      currentPage:1,
    }
  }

  async componentDidMount() {
    try {
      const formData = new FormData();

      if(typeof this.props.user == 'undefined'){
        this.setState({sendToHomePage:true})
      }
      else{
        formData.append('user_id',  this.props.user);
        const {data} = await get_payment_details(formData);
        if(data.status === true) {
          const {data:orderDetails} = data;
          this.setState({ orderDetails,loaderFlag:false });
        } else if(data.status === 'Token is Expired') {
          this.setState({text:'User token expired, Redirecting to login page.'});
          setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
          },400)
        }
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  };

  handlePageChange = (value) => {
    this.setState({ currentPage: value });
  };

  render() {
    const { length: count } = this.state.orderDetails;
    const {
      pageSize,
      currentPage,
      selectedFilter,
      orderDetails,
      token_expired,
      loaderFlag,
      text,
      sendToHomePage
    } = this.state;
    
    // if (count === 0) return (<Loader text='Loading Experiences Data ...' />);
    if(sendToHomePage) return (<Navigate to='/' />);
    if(loaderFlag) return (<Loader text={text} />);
    if(token_expired) return (<Navigate to='/logout' />);

    const filtered = orderDetails;
    // selectedFilter && selectedFilter.id ? orderDetails.filter((fil)=>{ return selectedFilter.value === fil.status }) : orderDetails;
    const paginated = paginate(filtered, currentPage, pageSize);
    // const paginated = filtered;

    
    return (
      <div className="Page-Width-Vossle" style={{textAlign:'center'}}>
        <p style={{textAlign:'center',fontSize:'30px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',color:'#053630',marginTop:'16px'}}>
          Payment History
        </p>
        <p style={{textAlign:'center',marginBottom:'20px',fontFamily:'Noto Sans',fontSize:'13px',color:'#343434'}}>
          Step into endless possibilities 
        </p>
        { paginated.length === 0 ? 
          (
            <Grid container>
              <Grid xs={12} sx={{color:'red'}}>
                You are not Subscribed yet, please <Link to="/payment">Subscribe</Link> to enjoy experiences
              </Grid>
              <Grid xs={12}>
                <img src={Balloon} alt=""/>
              </Grid>
            </Grid>
          ) : 
          (
            <React.Fragment>
              <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                {/* <Grid xs={0.5}></Grid> */}
                <Grid xs={11}>
                  <Grid xs={12} container sx={{p:1,mb:1}} >
                      <Grid xs={3} sx={{display:'flex',justifyContent:'center',fontWeight:'500',alignItems:'center'}}>
                        Subscription Details
                      </Grid>
                      <Grid xs={2.7} sx={{fontWeight:'500',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        Amount
                      </Grid>
                      <Grid xs={3} sx={{fontWeight:'500',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        Date
                      </Grid>
                      <Grid xs={3} sx={{fontWeight:'500',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        Status
                      </Grid>
                      <Grid xs={0.3}></Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Grid xs={11}>
                  {
                    paginated.map(order => {
                      return(
                        <PaymentListItemCard order={order} />
                      )
                    })
                  } 
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={1}></Grid>
                <Grid xs={10}>
                  <Paginations
                    itemsCount={filtered.length}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                  />
                </Grid>
                <Grid xs={1}></Grid>
              </Grid>
            </React.Fragment>
          )
        }
      </div>
    )
  }
}

export default Payments;