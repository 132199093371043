import React from 'react';

export default [
  {
    id: 'dndnode_0',
    type: 'input',
    sourcePosition: 'right',
    data: { label: 'Splash Screen', temp_id:'ss' },
    position: { x: 20, y: 250 },
  }
  // {
  //   id: 'ewb-1',
  //   type: 'input',
  //   data: { label: 'Splash Screen', temp_id:'ss',  color: '#1A192B' },
  //   position: { x: 250, y: 0 },
  // },
  // { id: 'ewb-2', 
  // data: { label: 'Splash Screen', temp_id:'cc',  color: '#1A192B' },
  // position: { x: 250, y: 300 } },

  // {
  //   id: 'edge-1-2',
  //   source: 'ewb-1',
  //   target: 'ewb-2',
  //   type: 'buttonedge',
  // },
];