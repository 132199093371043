import React, { Component } from 'react';
import { Box } from '@mui/material';
import {Helmet} from "react-helmet";

class Marker extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isload:false,
        }
    }

    download = (buffer) => {
        console.log('Buffer',buffer);
        var blob = new Blob([buffer]);
        var aLink = window.document.createElement('a');
        aLink.download = 'targets.mind';
        aLink.href = window.URL.createObjectURL(blob);
        aLink.click();
        window.URL.revokeObjectURL(aLink.href);
    };

    showData = (data) => {
        console.log("data", data);
        for (let i = 0; i < data.trackingImageList.length; i++) {
          const image = data.trackingImageList[i];
          const points = data.trackingData[i].points.map((p) => {
            return {x: Math.round(p.x), y: Math.round(p.y)};
          });
          this.showImage(image, points);
        }

        for (let i = 0; i < data.imageList.length; i++) {
          const image = data.imageList[i];
          const kpmPoints = [...data.matchingData[i].maximaPoints, ...data.matchingData[i].minimaPoints];
          const points2 = [];
          for (let j = 0; j < kpmPoints.length; j++) {
            points2.push({x: Math.round(kpmPoints[j].x), y: Math.round(kpmPoints[j].y)});
          }
          this.showImage(image, points2);
        }
    }


    showImage = (targetImage, points) => {
        const container = document.getElementById("container");
        const canvas = document.createElement('canvas');
        container.appendChild(canvas);
        canvas.width  = targetImage.width;
        canvas.height = targetImage.height;
        canvas.style.width = canvas.width;
        const ctx = canvas.getContext('2d');
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = new Uint32Array(imageData.data.buffer);

        const alpha = (0xff << 24);
        for (let c = 0; c < targetImage.width; c++) {
          for (let r = 0; r < targetImage.height; r++) {
            const pix = targetImage.data[r * targetImage.width + c];
            data[r * canvas.width + c] = alpha | (pix << 16) | (pix << 8) | pix;
          }
        }

        var pix = (0xff << 24) | (0x00 << 16) | (0xff << 8) | 0x00; // green
        for (let i=0; i < points.length; ++i) {
          const x = points[i].x;
          const y = points[i].y;
          const offset = (x + y * canvas.width);
          data[offset] = pix;
          //for (var size = 1; size <= 3; size++) {
          for (var size = 1; size <= 6; size++) {
            data[offset-size] = pix;
            data[offset+size] = pix;
            data[offset-size*canvas.width] = pix;
            data[offset+size*canvas.width] = pix;
          }
        }
        ctx.putImageData(imageData, 0, 0);
    }

    loadImage = async (file) => {
        const img = new Image();

        return new Promise((resolve, reject) => {
          let img = new Image()
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = URL.createObjectURL(file);
          //img.src = src
        })
    }

    compileFiles = async (files) => {
        console.log('Start Compiling',files);
        try {
          const compiler = new window.MINDAR.IMAGE.Compiler();
          const images = [];
          for (let i = 0; i < files.length; i++) {
            images.push(await this.loadImage(files[i]));
          }
          let _start = new Date().getTime();
          console.log('Loaded Image',images);
          const dataList = await compiler.compileImageTargets(images, (progress) => {
            console.log('Progress',progress.toFixed(2));
            document.getElementById("progress").innerHTML = 'progress: ' + progress.toFixed(2) + "%";
          });
          console.log('exec time compile: ', new Date().getTime() - _start);
          for (let i = 0; i < dataList.length; i++) {
            this.showData(dataList[i]);
          }
          const exportedBuffer = await compiler.exportData();
          // document.getElementById("downloadButton").addEventListener("click", function() {
            this.download(exportedBuffer);
          // });
        } catch(ex) {
          console.log('File not loaded yet.');
        }
    }

    loadMindFile = async (file) => {
      try {
        const compiler = new window.MINDAR.IMAGE.Compiler();
        var reader = new FileReader();
        reader.onload = function() {
          const dataList = compiler.importData(this.result);
          for (let i = 0; i < dataList.length; i++) {
            this.showData(dataList[i]);
          }
        }
        reader.readAsArrayBuffer(file);
      } catch(ex) {
        console.log('File not loaded yet.');
      }
    }

    // const myDropzone = new Dropzone("#dropzone", { url: "#", autoProcessQueue: false, addRemoveLinks: true });
    // myDropzone.on("addedfile", function(file) {});
    
    startButton = () => {
        const myDropzone = document.getElementById('myfile');
        const files = myDropzone.files;
        if (files.length === 0) return;
        const ext = files[0].name.split('.').pop();
        console.log('File loading', files);
        console.log('File loading Ext', ext);
        if (ext === 'mind') {
            this.loadMindFile(files[0]); 
        } else {
            this.compileFiles(files);
        }
    };

    render() {
        console.log('Page Loading Start'); 
        return (
          <>
            <Helmet>
              <script src="https://assets.vossle.com/scripts/mindar-image.js"></script>
            </Helmet>
            <Box sx={{px:'40px',pt:'20px'}}>
                <div>
                    <p>Usage:</p>
                    <ol>
                        <li>drop target images (e.g. .png) into the drop zone. (can drop multiple)</li>
                        <li>click "Start". It could take a while (especially for large image)</li>
                        <li>When done, some debug images will shown, and you can visualize the feature points.</li>
                        <li>click "download" to get a targets.mind file, which is used in the AR webpage</li>
                    </ol>
                </div>
                <button id="startButton" onClick={this.startButton}>Start</button>
                <button id="downloadButton" onClick={this.download}>Download</button>
                <span id="progress"></span>
                <input type="file" id="myfile" name="myfile" />
                <div id="dropzone" class="dropzone"></div>

                {/* Change /upload-target to your upload address */}
                <div id="container">
                </div>
            </Box>
          </>
        );
    }
}
 
export default Marker;