import React, { Component } from 'react'
import { Button, Divider} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PaymentListItemCard from '../Components/cards/PaymentListItemCard';
import { Pagination, PaginationItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { check_payment_status, get_payment_details, upgrade_payment } from '../Components/services/paymentService';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { Link, Navigate } from 'react-router-dom';
import { toast } from "react-toastify";
import image from '../assets/background.png'
import Loader from '../Components/Common/Loader';
import Paginations from './../Components/Common/paginations';
import { paginate } from './../Components/utils/paginate';
import NotFound from '../Components/NotFound';
import Balloon from '../assets/baloon.png'
import withRouter from './../Components/services/withRouter';
import authService from '../Components/services/authService';
import { isBoolean } from 'lodash';
import GetMessage from './../Components/Common/getMessage';
import Info from '@mui/icons-material/Info';

class PaymentVerify extends Component {
  constructor(props){
    super(props)
    this.state  = {
      paymentDetails:{},
      sendToHomePage:false,
      token_expired:false,
      error:{},
      message:{},
      loaderFlag:true,
      text:'Fetching Payment Information, Please wait ...',
    }
  }

  async componentDidMount() {
    const error = {};
    try {
      if(this.state.loaderFlag) {
        const user = await authService.getCurrentUser();
        if(user === null && user === '') {
          console.log('Pay user :',user);
          this.setState({text:'User not found, Redirecting to login page.'});
          setTimeout(()=>{
              this.setState({sendToHomePage:true,loaderFlag:false});
          },1000);
        }
        console.log("my user is now",user)
        const payment_id = this.props.params.id;
        
        const data = new FormData();
        data.append("user_id", user);
        data.append("payment_type", 'commercial_plan');
        data.append("payment_id", payment_id);
        const { data:res } = await check_payment_status(data);
        if(isBoolean(res.status) && res.status) {
          console.log('Response',res);
          const {data:paymentDetails} = res;
          console.log("my payment details",paymentDetails)
          this.setState({ paymentDetails,loaderFlag:false });
        } else if(res.status === 'Token is Expired') {
          this.setState({ token_expired:true });
        }else {
          this.setState({ sendToHomePage:true });
        }
      }
    } catch (ex) {
      console.log("my error is",ex);
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
        const {errors:message} = ex.response.data;
        this.setState({ message });
      } else if (ex.response && ex.response.status === 404) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
      } else {
        toast.error("Unexpected Error");
      }
    }
    this.setState({error,loaderFlag:false});
  }

  render() {
    const {
      token_expired,
      loaderFlag,
      text,
      sendToHomePage,
      error,message
    } = this.state;

    let paymentDetails = {...this.state.paymentDetails};
    let {user,subscription} = paymentDetails;
   
    if(sendToHomePage) return (<Navigate to='/' />);
    if(loaderFlag) return (<Loader text={text} />);
    if(token_expired) return (<Navigate to='/logout' />);
    console.log('Payment Verify',this.state);
    return (
      <div className="Page-Width-Vossle" style={{textAlign:'center'}}>
        {/* <Grid container>
          <Grid xs={12} sx={{textAlign:'center',m:'auto',p:'auto'}}>
            {error.global && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info />{error.global}</Typography>)}
            <GetMessage message={message} />
            {JSON.stringify(paymentDetails)}
          </Grid>
        </Grid> */}
        
      {
        paymentDetails.status === 'Success'&&
        (
          <React.Fragment>
            <div style={{height:"200px",width:"100%",display:'flex',justifyContent:'center',alignItems:'center'}}>
              <div style={{height:"100%",width:"100%"}}>
                <img src={image} alt="congratulation" style={{maxWidth:"100vw",height:"200px"}}/>
              </div>
              <div style={{zIndex:2,position:'absolute'}}>
                <HowToRegIcon  sx={{height:"60px",width:"60px",color:"white"}}/>
                <Typography sx={{color:"gold",fontSize:"40px"}}>Congratulation</Typography>
                <Typography sx={{color:"white",fontSize:"20px"}}>You are subscribed now</Typography>
              </div>
            </div>
          
            <div style={{marginTop:"20px",fontSize:'30px',fontWeight:"500"}}>
              Payment
            </div>
            <div style={{background:'lightgrey',padding:"20px",marginTop:"20px"}} >
              <Grid container>
                      <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                          <Typography sx={{fontSize:'18px',fontWeight:'medium'}} component="div">
                              Payment Id
                          </Typography>
                      </Grid>
                      <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                          <Typography sx={{display:'flex-inline',fontSize:'18px',fontWeight:'medium'}}>
                              Description
                          </Typography>
                      </Grid>
                      <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                          <Typography sx={{fontWeight:'medium',fontSize:'18px'}} component="div">
                              Subscription Fee
                          </Typography>
                      </Grid>
                      <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                          <Typography sx={{fontWeight:'medium',fontSize:'18px'}} component="div">
                              Duration
                          </Typography>
                      </Grid>
              </Grid>
              <Grid container sx={{background:'white',mt:2,p:"10px 10px 10px 10px"}}>
                <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Typography sx={{fontSize:'18px'}} component="div">
                    {subscription.plan_id}
                    </Typography>
                </Grid>
                <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Typography sx={{display:'flex-inline',fontSize:'18px'}}>
                        Vossle Subscription Plan
                    </Typography>
                </Grid>
                <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Typography sx={{fontSize:'18px'}} component="div">
                        {subscription.currency}:{subscription.ammount}
                    </Typography>
                </Grid>
                <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Typography sx={{fontSize:'18px'}} component="div">
                        1 Month
                    </Typography>
                </Grid>
              </Grid>
            </div>
            <Grid container sx={{background:'lightgrey',p:1,mb:2}} >
              <Grid xs={12} sx={{display:"flex",justifyContent:'flex-end',flexDirection:'column',alignItems:'flex-end'}}>
                  
                  <p style={{color:'black'}}>
                    Invoice To : {user.name}, {user.email}
                    
                  </p>

                  <p  style={{color:'black'}}>
                    Date: {subscription.created_at && subscription.created_at.split('T')[0]}
                  </p>
                  
              </Grid>
            </Grid>
          </React.Fragment>
        )
       
      }

      {
       paymentDetails.status === 'Awaited' && (
        
          <div style={{display:'flex',minHeight:"300px",flexDirecion:'column',alignItems:'center',justifyContent:'center'}}>
            <Grid>
              <Grid xs={12} sx={{mb:2}}>
              <AccessAlarmsIcon style={{height:"90px",width:"90px",color:'#682cea'}} />
              </Grid>

              <Grid xs={12} sx={{fontSize:"30px",fontWeight:'500'}}>
                Waiting for your Payment, <a href='/payment'>Check our plans</a>
  
              </Grid>
            </Grid>
          </div>
        ) 
      }
      {
       paymentDetails.status === 'Failed' && (
        <div style={{display:'flex',minHeight:"300px",flexDirecion:'column',alignItems:'center',justifyContent:'center'}}>
          <Grid>
            <Grid xs={12} sx={{mb:2}}>
            <CancelIcon style={{height:"90px",width:"90px",color:'red'}} />
            </Grid>

            <Grid xs={12} sx={{fontSize:"30px",fontWeight:'500'}}>
            Payment Failed, <a href='/payment'>Please Try Again</a>
 
            </Grid>
          </Grid>
      </div>
        ) 
      }
      </div>
    )
  }
}

export default withRouter(PaymentVerify);