import React, { Component } from 'react';
import { isBoolean } from 'lodash';
import { toast } from "react-toastify";
import { Link, Navigate } from 'react-router-dom';
import baloon from '../assets/baloon.png';
import VossleLogo from '../assets/logo.png';
import Info from '@mui/icons-material/Info';
import { Typography,Button,Grid } from '@mui/material';
import { verify } from './../Components/services/userService';
import withRouter from './../Components/services/withRouter';
import CountdownCircle from './../Components/Common/countdownCircle';
import RenderButton from '../Components/InputFields/RenderButton';


class AuthenticateUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            token:'',
            countdown:true,
            verification:false,
            redirection:false,
            errors:{},
            message:'Email verification is in process.',
            componentMounted:false,
        }
    }

    async componentDidMount() {
        const {componentMounted} = this.state;
        if(!componentMounted) {
            const token = this.props.params.id;
            console.log('Token',token);
            this.setState({ componentMounted:true });
            if(!token) {
                toast.error('Token not found in url, redirecting to login page');
                this.setState({ redirection:true });
                return;
            } else {
                try {
                    const {data:res} = await verify(token);
                    console.log(res);
                    if (res.status && isBoolean(res.status)) {
                        toast.success("Email verification successfull, please login to continue.");
                        const {message} = res;
                        this.setState({ message,verification:true,countdown:false,token });
                    } else {
                        const message = 'Email verification not successfull, Please try again.';
                        this.setState({ message,countdown:false,token });
                        toast.error(res.message);
                    }
                } catch (ex) {
                    let message = ex.response;
                    let errors = {};
                    console.log('Response Ex : ',ex);
                    if (ex.response && ex.response.status === 400) {
                        message = ex.response.data.message;
                        toast.error(message);
                    } else if (ex.response && ex.response.status === 404) {
                        message = "Server Down, Under maintenance.";
                    } else {
                        message = "Unexpected Error";
                    }
        
                    toast.error(message);
                    errors.global = message;
                    this.setState({ errors,message,countdown:false });
                }
            }
        }
    }

    setRedirection = () => {
        this.setState({redirection:true});
    };

    updateCountdown = () => {
        console.log('update updateCountdown called');
        this.setState({countdown:false});
    };

    render() {
        const {errors,countdown,message,redirection,token,user} = this.state;
        console.log('Authenticate Props',this.props);
        console.log('Authenticate State ',this.state);
        
        if(redirection) return <Navigate to="/login" />;

        return (
            <React.Fragment>
               <div style={{background:'rgb(243 244 246 / var(--tw-bg-opacity))',width:"99vw",height:"99vh",margin:"auto",display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{maxWidth:"630px",maxHeight:"490px",borderRadius:"10px",border:'1px solid grey',padding:"16px"}}>
                        {/* <Grid container>
                            <Grid xs={12}>{errors.global && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors.global}</Typography>) }</Grid>
                        </Grid> */}
                        <div style={{position:'absolute',maxWidth:"100%",maxHeight:"100%"}}>
                            <img alt="logo" src={VossleLogo} width="100px" style={{position:'relative'}} />
                        </div>
                        <div style={{width:"100%",display:"flex",justifyContent:'center'}}>
                            <img width="180px" alt="confirmation_img" src={baloon}/>
                        </div>
                        <div style={{width:"100%",textAlign:'center'}}>
                            <Typography sx={{fontSize:"48px",fontWidth:"medium",color:'rgba(104,44,234,1)'}}>
                                Welcome to Vossle
                            </Typography>
                            <Grid container sx={{mb:2}}>
                                <Grid xs={2}></Grid>
                                <Grid xs={8} sx={{textAlign:'center'}}>
                                    <Typography variant="subtitle1" sx={{color:'grey',mb:2}}>
                                        {message && message}
                                    </Typography>
                                </Grid>
                                <Grid xs={2}></Grid>
                            </Grid>
                            <Link to='/login' sx={{textDecoration:'none'}}>
                                <RenderButton 
                                className="button3" 
                                name = 'submit_button'
                                type = "submit"
                                label = {'Back to Login'}
                                // label = {selectedExpType.id === 5 ? 'Next' : 'Submit'}
                                isDisabled = {false}
                                minWidth = {180}
                                />
                                {/* // style={{background:'#FF821C',border:"1px solid #FF821C",p:1,
                                // borderRadius:"50px",color:'black',width:"300px", color:'white','&:hover':{color:'#FF821C'}}}> */}
                                    {/* Back to Login */}
                                {/* </RenderButton> */}
                            </Link>
                        </div>
                    </div>
               </div>
            </React.Fragment>
        );
    }
}
export default withRouter(AuthenticateUser);