import React, { Component } from 'react'
// Required 
import Chart from 'chart.js/auto';
// Required End
import Box from '@mui/material/Box';
import { Line } from 'react-chartjs-2';


export default class AnalyticsChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:this.props.chartData,
            Duration:'',
            handleChange : (event) => {
                this.setState({Duration:event.target.value});
            }
        }
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.chartData });  
    }

    render() {
        const {data} = this.state;

        let labels = [];
        let ViewCount = [];
        let AvgSessionDuration = [];

        const analytics_data = data.analytics_data ? data.analytics_data : data;

        analytics_data && analytics_data.forEach((label) => {
            if(label.hasOwnProperty('country')) {
                let myLabel = label.analytics_date + '('+label.country+')' ;
                labels.push(myLabel);
            } else labels.push(label.analytics_date);

            ViewCount.push(label.view_count != null ? label.view_count:0 );

            if(Number(label.view_count) != null && Number(label.avg_time) != null && Number(label.view_count) !== 0 && Number(label.view_count) !== 0) {
                AvgSessionDuration.push(Math.round(Number(label.avg_time)/Number(label.view_count)) )
            } else {
                AvgSessionDuration.push(0)
            }
        });

        const ColorItems = [{
            name:'View Count',
            color:'rgb(255, 99, 132)'
          },{
            name:'Session Duration',
            color:'rgb(255, 99, 132)'
          }

        ];
        
        return(
            <Box style={{
                width:"100%",
                height:"100%",
            }}>
                <Line
                    minHeight={100}
                    data={{
                            labels: labels,
                            datasets: [
                                {
                                    label: 'Session Duration',
                                    data:AvgSessionDuration, 
                                    fill: true,
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'transparent',
                                    tension: 0.5
                                },
                                {
                                    label: 'View Count',
                                    data: ViewCount,
                                    fill: true,
                                    borderColor: 'rgb(53, 162, 235)',
                                    backgroundColor: 'transparent',
                                    tension: 0.5
                                }
                                
                            ]
                        }}
                    options={{
                        plugins: {
                            title: {
                            display: true,
                            text: ""
                            },
                            legend: {
                            display: true,
                            position: "bottom"
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false      
                                }
                            },
                            y: {
                                grid: {
                                    display: false      
                                }
                            }
                        }
                    }}
                />
            </Box>       
        )
    }
}