import React, { Component } from 'react';
import { isBoolean } from 'lodash';
import TabList from '@mui/lab/TabList';
import { toast } from "react-toastify";
import Slide from '@mui/material/Slide';
import TabPanel from '@mui/lab/TabPanel';
import Dialog from '@mui/material/Dialog';
import Info from '@mui/icons-material/Info';
import PhoneInput from "react-phone-input-2";
import TabContext from '@mui/lab/TabContext';
import 'react-phone-input-2/lib/material.css';
import { Navigate, Link } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button,Typography,Grid,InputAdornment,TextField,Tab,Box} from '@mui/material';


import vossleLogo from './../assets/logo.png';
import Loader from './../Components/Common/Loader';
import config from './../Components/services/config.json';
import GetMessage from './../Components/Common/getMessage';
import PersonalSettingImage from '../assets/personalSetting1.png';
import  SubscriptionCard from '../Components/cards/SubscriptionCard';
import AllInputFields from '../Components/InputFields/AllInputFields';
import { deactivateAccount } from './../Components/services/userService';
import authService, { getUserDetails, setUserDetails } from './../Components/services/authService';
import { contact_enterprise, upgrade_payment, verify_payment } from './../Components/services/paymentService';

class Settings extends  AllInputFields {
  constructor(props){
    super(props)
    this.state  = {
      open:false,
      menuOpen:false,
      tabValue:'1',
      payment_check:{},
      sendToHomePage:false,
      SubscriptionCardData:[
        {
          id:'basic_plan',
          name:'Basic Plan',
          subtitle:'Current plan',
          amount:'FREE',
          perMonth:'',
          selectedPlan:true,
          connect:'',
          submitButton:'Current Plan',
          limit:'Experiences Limits',
          features:[
            'World Tracking Experiences',
            'Image Tracking Experiences',
            'Tryons',
            'Games in AR',
            'Green Screen Chroma Key Videos',
            'Cloud Editor + Hosting',
            'Cloud Unlimited AR Experiences',
            'Analytics Dashboard',
            '100 Views',
          ]
        },
        {
          id:'commercial_plan',
          name:'Commercial Plan',
          subtitle:'Recurring amount',
          amount:'$99',
          selectedPlan:false,
          connect:'',
          perMonth:'/per month',
          submitButton:'Upgrade',
          limit:'Experiences Limits',
          features:[
            'World Tracking Experiences',
            'Image Tracking Experiences',
            'Tryons',
            'Games in AR',
            'Green Screen Chroma Key Videos',
            'Cloud Editor + Hosting',
            'Cloud Unlimited AR Experiences',
            'Analytics Dashboard',
            '1000 views'
          ]
        },
        {
          id:'enterprise_plan',
          name:'Enterprise',
          subtitle:'Upgraded Plan',
          amount:'Contact',
          selectedPlan:false,
          perMonth:'',
          connect:'Support Team',
          submitButton:'Contact Us',
          limit:'Unlimited Experiences',
          features:[
            'World Tracking Experiences',
            'Image Tracking Experiences',
            'Tryons',
            'Games in AR',
            'Green Screen Chroma Key Videos',
            'Cloud Editor + Hosting',
            'Cloud Unlimited AR Experiences',
            'Analytics Dashboard',
            'Views based on package'
          ]
        }
      ],
      user:{},
      loaderFlag:true,
      token_expired:false,
      text:'Loading User Data, Please wait ...',
      profile_pic:'',
      data:{},
      error:{},
      errors:{},
      files:{},
      message:{},
      upgrade:{},
      selectedPlan:'basic_plan',
      enterprise_contact:false,
      deactivateRes:{},
    }
  }

  Transition =  React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  handleClickOpen = () => {
    this.setState({open:true})
  };

  handleClose = () => {
    this.setState({open:false})
  };

  async componentDidMount() {
    const error = {};
    try {
      const user = await authService.getCurrentUser();
      if(user === null && user === '') {
        console.log('AR Exp user : '+user);
        this.setState({text:'User not found, Redirecting to login page.'});
        setTimeout(()=>{
            this.setState({sendToHomePage:true});
        },1000);
      }

      const formData = new FormData();
      formData.append("user_id", user);
      formData.append("payment_type", 'commercial_plan');
      if(this.state.loaderFlag) {
        const {data:res} = await getUserDetails();
        if(res.status === true) {
          const {data} = res;
          console.log('Response User', data);
          if(data.payment_status === "Success") {
            const selectedPlan = data.payment_status === "Success" ?'commercial_plan' : 'basic_plan';
            const payment_check = {status:true};
            this.setState({data,payment_check,selectedPlan,loaderFlag:false})
          } else {
            const {data:payment_check } = await upgrade_payment(formData);
            if(isBoolean(payment_check.status) && payment_check.status) {
              console.log('Response',payment_check);
              const selectedPlan = payment_check.message === 'already_available' ? 'commercial_plan' : 'basic_plan';
              this.setState({ data,payment_check,selectedPlan,loaderFlag:false });
            } else if(payment_check.status == 'Token is Expired') {
              let payment_check = {status:'user_token_expired'};
              this.setState({ payment_check,token_expired:true });
            } else {
              let payment_check = {status:'not_found'};
              this.setState({ data,payment_check });
            }
          }
        } else if(res.status === 'Token is Expired') {
          let payment_check = {status:'user_token_expired'};
          this.setState({payment_check, text:'User token expired, Redirecting to login page.'});
          setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
          },400)
        } else {
          let payment_check = {status:'not_found'};
          this.setState({ text:'Payment or User information not found.', payment_check });
        }
      }
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
      } else if (ex.response && ex.response.status === 404) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
      } else {
        toast.error("Unexpected Error");
      }
    }
    this.setState({error});
  };

  loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
  }

  handlePayment = async (pay_data) => {
    console.log('Called Payment', pay_data);
    const error = {};

    const {payment_check} = this.state;
    try {
      if(pay_data.id === 'commercial_plan' && payment_check.message === 'process_payment' && payment_check.data.pay_check === 'new') {
        const res = await this.loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!res) {
          return;
        }

        // Getting the order details back
        const { payment_check } = this.state;
        console.log('RPAY',payment_check);
        const options = {
            key: config.razorpayKey, // Enter the Key ID generated from the Dashboard
            subscription_id: payment_check.data.subscription.subs_id ?? null,
            name: "Vossle",
            description: "Purchase AR monthly Plan",
            image: vossleLogo,
            handler: async function (response) {
                const data = {
                  subscriptionId: response.razorpay_subscription_id,
                  razorpayPaymentId: response.razorpay_payment_id,
                  razorpaySignature: response.razorpay_signature,
                };
                console.log(data);
                const { data:upgrade } = await verify_payment(data);
                if(isBoolean(upgrade.status) && upgrade.status) {
                  console.log('Rz Verify Response',upgrade);
                  const selectedPlan = 'commercial_plan';
                  const message = 'Payment Success enjoy the services.';
                  // this.setState({ upgrade,selectedPlan,message });
                  window.location = '/payment/verify/'+response.razorpay_payment_id;            

                } else if(upgrade.status == 'Token is Expired') {
                  let payment_check = {status:'user_token_expired'};
                  error.global='User token is expired, Please check.';
                  this.setState({ payment_check });
                } else {
                  error.global='Not able to validate the payment.';
                }
                // const result = await axios.post("http://localhost:5000/payment/success", data);
                console.log(data);
            },
            // prefill: {
            //     name: "Soumya Dey",
            //     email: "SoumyaDey@example.com",
            //     contact: "9999999999",
            // },
            // notes: {
            //     address: "Queppelin Office",
            // },
            theme: {
                color: "#F37254",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        
        paymentObject.on('payment.failed', async function (response) {
          console.log('Rzp Error Response',response);
          console.log('Rzp Error Response',response.error);
          // if(response.error.reason === 'payment_failed') {}
          const {metadata} = response.error;
          const data = {
            subscriptionId: payment_check.data.subscription.subs_id,
            razorpayPaymentId: metadata.payment_id,
          };
          console.log(data);
          try {
            const { data:upgrade } = await verify_payment(data);
            console.log('Rz Verify Error Response',upgrade);
            if(isBoolean(upgrade.status) && upgrade.status) {
              window.location = '/payment/verify/'+metadata.payment_id;
              // this.setState({ upgrade,selectedPlan,message });
            } else if(upgrade.status == 'Token is Expired') {
              let payment_check = {status:'user_token_expired'};
              error.global='User token is expired, Please check.';
              this.setState({ payment_check });
            } else {
              window.location = '/payment/verify/'+metadata.payment_id;
              error.global='Not able to validate the payment.';
            }
          } catch (err) {
            console.log('Ex Error Payment Failed',err);
            if (err.response && err.response.status === 400) {
              toast.error(err.message);
              if(err.response.data.message === "payment_failed") window.location = '/payment/verify/'+metadata.payment_id;
            } else if (err.response && err.response.status === 404) {
              toast.error(err.message);
            } else {
              toast.error("Unexpected Error");
            }
          }
          // const result = await axios.post("http://localhost:5000/payment/success", data);
          console.log(data);
        });
        // const data = new FormData();
        // data.append("user_id", this.props.user);
        // data.append("payment_type", pay_data.id);
        
        // const { data:response } = await upgrade_payment(data);
        // console.log('Response',response);
        // this.setState({ response });
      } else if(pay_data.id == 'enterprise_plan') {
        
        const { data:upgrade } = await contact_enterprise(this.props.user);
        console.log('Response ',upgrade);
        
        if(isBoolean(upgrade.status) && upgrade.status) {
          this.setState({ upgrade,contact_enterprise:true });
        } else if(upgrade.status == 'Token is Expired') {
          let upgrade = {status:'user_token_expired'};
          this.setState({ upgrade });
        } else {
          let upgrade = {status:'not_found'};
          this.setState({ upgrade });
        }
      } else {
        console.log('Unknown Request');
        error.global='Unknown Request';
      }
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.message);
        // if(err.response.data.message === "payment_failed") window.location = '/payment/verify/'+metadata.payment_id;
        error.global = ex.response.data.message ?? ex.message;
      } else if (ex.response && ex.response.status === 404) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
      } else {
        toast.error("Unexpected Error");
      }
    }
    this.setState({error});
  };

  validate() {
    const { data,files } = this.state;
    const errors = {};
    
    if(data.name === '') errors.name = 'Name is required';
    if(data.email === '') errors.email = 'e-mail is required';
    if(data.country_code === '') errors.country_code = 'Country Code is required';
    if(data.mobile === '') errors.mobile = 'Mobile Number is required';
    if(data.address === '') errors.address = 'Address is required';
    
    if( 
      (data.hasOwnProperty('old_password') && data.old_password !== '' ) || 
      (data.hasOwnProperty('password') && data.password !== '') || 
      ( data.hasOwnProperty('password_confirmation') && data.password_confirmation !== '') 
    ){
      console.log("my data is to check in setting page 1",data,data.old_password,data.password,data.password_confirmation)
      if(!(data.hasOwnProperty('old_password') && data.old_password !== '' )) {
        errors.old_password = 'Old Password is required';
      }
      if(!(data.hasOwnProperty('password') && data.password !== '')) {
        errors.password = 'New Password is required';
      }
      if(!( data.hasOwnProperty('password_confirmation') && data.password_confirmation !== '')) {
        errors.password_confirmation = 'Confirmation Password is required';
      }
    }
    console.log("my data is to check in setting page",data,data.old_password,data.password,data.password_confirmation)
    return Object.keys(errors).length === 0 ? null : errors;
  }

  doSubmit = async () => {
    console.log('Data saving started');
    document.getElementById("submit_button").disabled = true;
    document.getElementById("submit_button").innerHTML = "Saving Data...";
    
    const {data:formobject,files} = this.state;
    
    const errors = {};
    let message = {};

    const data = new FormData();
    data.append("user_id", this.props.user);
    if(formobject.password && formobject.password_confirmation && formobject.old_password) data.append('verifier',true);
    {Object.keys(formobject).map((name) => {
      data.append(name, formobject[name]);
    })}

    {Object.keys(files).map((key) => {
      data.append(key, this.state[key]);
    })}

    for (const value of data.values()) {
      console.log('Form Values',value);
    }
    // return;
    try {
      const res = await setUserDetails(data);
      if (res.data.status === true) {
        document.getElementById("submit_button").innerHTML = "Saved :-)";
        document.getElementById("submit_button").disabled = false;
        toast.success("User Data Saved.");
        const userData = res.data.data;
        if(userData.id) this.setState({data:userData});
      } else if (res.data.status === false) {
        document.getElementById("submit_button").innerHTML = "Not Saved";
        document.getElementById("submit_button").disabled = false;
        errors.global = "Data not saved, Please try again" + res.data.message;
        toast.error("Data not saved, Please try again" + res.data.message);
      } else {
        toast.error("Data not saved, Please try again" + res.status);
        errors.global = "Data not saved, Please try again" + res.data.message;
        document.getElementById("submit_button").innerHTML = "Not Saved";
        document.getElementById("submit_button").disabled = false;
      }
    } catch (ex) {
      console.log('Error in Response',ex);
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data.message);
        const {errors:response} = ex.response.data;
        message=response;
        errors.global = ex.response.data.message && ex.response.data.message;
      } else if (ex.response && ex.response.status === 404) {
        errors.global = "Unexpected Error occured Please try again.";
        toast.error("Unexpected Error occured Please try again.");
      } else {
        errors.global = "Unexpected Error other than 404 Please try again.";
        toast.error("Unexpected Error other than 404 Please try again.");
      }
      // document.getElementById("submit_button").innerHTML = "Back with Error";
      document.getElementById("submit_button").disabled = false;
    }
    this.setState({ errors,message });
  };

  handleTabChange = (event, newValue) => {
    this.setState({tabValue:newValue})
  };

  handleDeactivateAccount = async () => {
    this.setState({loaderFlag:true,text:'Deactivating User, Please wait...'});
    const error = {};
    let message = {};
    try {
      const {data} = this.state;
      if(typeof data.id === 'undefined' && !data.id) {
        this.setState({text:'User not found, Redirecting to login page.'});
        setTimeout(()=>{
            this.setState({sendToHomePage:true});
        },1000);
      }
      
      const formData = new FormData();
      formData.append("user_id", data.id);
      formData.append("email", data.email);

      const {data:res} = await deactivateAccount(formData);
      if(res.status === true && isBoolean(res.status) && res.status) {
        const {data:deactivateRes} = res;
        console.log('Deactivated User', deactivateRes);
        if(deactivateRes.id) {
          this.setState({ deactivateRes, text:'User Deactivated, Redirecting to login page...'});
          setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false,open:false});
          }, 1000);
        }
      } 
      else if(res.status === 'Token is Expired') {
        this.setState({ text: 'User Token is Expired, Redirecting to login page...' });
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },800);
      } 
      else {
        this.setState({ text:'Not able to deactivate user at this moment.' });
      }
      
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
        const {errors:response} = ex.response.data;
        message=response;
      } else if (ex.response && ex.response.status === 404) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
      } else {
        toast.error("Unexpected Error");
      }
    }
    this.setState({error,message});
  };

  personalSetting = () =>{
    const {data,files} = this.state;
    const {handleClickOpen , handleClose, } = this;
    return (
      <Grid container style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <Grid xl={8} lg={8}  md={10} sm={11} xs={11} >
        <p style={{fontSize:'20px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',color:'#053630'}}>
            User Details
          </p>
          <p style={{marginBottom:'20px',fontFamily:'Noto Sans',fontSize:'13px',color:'#343434'}}>
          Update your personal details here
          </p>
          <Grid container >
            <Grid xs={4} sx={{borderRadius:'50%',display:"flex",alignItems:"center",justifyContent:"center"}}>
              <img width="120px" height="120px" alt="profile pic" style={{borderRadius:"50%",border:'1px solid lightgrey'}} src={files.profile_pic ? files.profile_pic:(data.profile_pic?config.assetsEndpoint+ data.profile_pic: PersonalSettingImage ) } />
            </Grid>
            <Grid xs={8} >
                {this.UploadInputFieldExcludeGLB('profile_pic','Profile Pic','image',true,1024,false)}
            </Grid>
            <Grid xs={12}>
              {/* <div style={{width:"100%",display:'flex',alignItems:'center',justifyContent:'center', flexDirection:'column'}}> */}
              {this.renderInputWithIcon("name", "text", "User Name", true)}
              {this.renderInputWithIcon("email", "email", "User Email", true, true)}
              {this.renderInputWithIcon("country_code", "text", "Country Code", true,true)}
              {this.renderInputWithIcon("mobile", "text", "Mobile Number", true)}
              {this.renderInputWithIcon("address", "text", "Address", true)}
                <Typography sx={{fontSize:'16px',py:1,mt:'10px',fontWeight:'00'}}>
                {/* <p style={{fontSize:'20px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',color:'#053630'}}> */}
                  Update Password 
                {/* </p> */}
                </Typography>
                {/* <Typography sx={{color:'grey',pl:2,pt:0,pb:1,fontSize:'14px'}}>
                  To change your password, verify your current password, then create a new password that you don't use elsewhere
                </Typography> */}
              {this.passwordInputField("old_password", "text", "Current Password", true,false)}
              {this.passwordInputField("password", "text", "New Password", true,false)}
              {this.passwordInputField("password_confirmation", "text", "Confirm Password", true,false)}
            </Grid>
            {/* <Grid container sx={{background:'green'}}>
              <Grid xs={6}>
                <Typography sx={{fontSize:'16px',pl:2,pt:1,fontWeight:'600'}}>
                  Delete My Account 
                </Typography>
                <Typography  variant="subtitle2" sx={{color:'grey',pl:2,pt:0,fontSize:'14px'}}>
                  Do you want to Downgrade instead ? <Link to='/payment'>Manage Subscription</Link>
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                <Button className="button1" sx={{height:"48px !important",background:'red !important',fontSize:'16px'}} onClick={this.handleDeactivateAccount}>Delete Account</Button>
              </Grid>
            </Grid> */}

            <Grid container sx={{mt:1}}>
              <Grid xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Button className="button1" id="submit_button" type="submit" sx={{height:"48px !important",fontSize:'16px'}}>Save Changes</Button>
              </Grid>
              
              {/* <Grid  xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Button id="cancel" className="button2" sx={{height:"48px !important",fontSize:'16px'}} type="button" onClick={()=>{console.log("it is available because it was present in design so ignore it")}}>Cancel</Button>
              </Grid> */}

              <Grid item xs={4} sx={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                <Button onClick={handleClickOpen} className="button1" sx={{height:"48px !important",background:'grey !important','&:hover':{background:'red'},fontSize:'16px'}} >Delete Account</Button>
              </Grid>
            </Grid>
                
                
            <div>
              <Dialog
                open={this.state.open}
                TransitionComponent={this.Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{"Delete Account"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Do you want to delete your Account ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDeactivateAccount}>Yes</Button>
                  <Button onClick={handleClose}>No</Button>
                  <Button component={Link} to='/payment' onClick={handleClose}>Manage Subscription</Button>
                </DialogActions>
              </Dialog>
            </div>


          </Grid>
        </Grid>
      </Grid>
    )
  };
  
  workspaceSetting = () =>{

    const {SubscriptionCardData,payment_check,error,selectedPlan,upgrade,token_expired} = this.state;
    
    console.log("my subscription is",SubscriptionCardData)
    if(token_expired) return (<Navigate to='/logout' />);
    
    if(!payment_check.status) 
      return (<Loader text='Loading Payment Information ...' />);
    else if(payment_check.status == 'user_token_expired') 
      return (<Loader text='User token expired, Please login again...' />);
    

    return (
      <div style={{margin:'auto',width:'85%'}}>
      {/* <Typography variant="h5" sx={{}}> */}
        
      {/* </Typography>
      <Typography variant='subtitle2' sx={{color:'grey',mb:4}}>
      </Typography> */}
      <Grid container>
      <Grid xs={12} sx={{px:2}}>
        <p style={{fontSize:'20px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',color:'#053630'}}>
          Plan & Billings
        </p>
        <p style={{marginBottom:'20px',fontFamily:'Noto Sans',fontSize:'13px',color:'#343434'}}>
          Update your plan here
        </p>
        
      </Grid>  
      </Grid>
      <div>

        <div style={{fontSize:'18px',fontWeight:'550'}}>
          <Grid container >
              {error.global && (<Grid lg={12} md={12} sm={12} sx={{padding:1}}><Typography variant='h4' sx={{color:'red',mb:4}}>{error.global}</Typography></Grid>)}
              {upgrade.message && (<Grid lg={12} md={12} sm={12} sx={{padding:1}}><Typography variant='h4' sx={{color:'green',mb:4}}>{upgrade.message}</Typography></Grid>)}
              {
                (
                  <SubscriptionCard SubscriptionCardData={SubscriptionCardData} onPay={this.handlePayment} payCheckData={payment_check} selectedPlan={selectedPlan} upgrade={upgrade}/>                 
                )
              }
          </Grid>
        </div>
      </div>
    </div>
    )
  };

  // getMessage = (message) => {
  //   console.log('Get Message',message);
  //   if(Object.keys(message).length > 0) {
  //     return Object.values(message).map((data) => {
  //       if(data) {
  //         return data.map((value) => {
  //           return (
  //             <Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info />{value}</Typography>
  //           );
  //         })
  //       }
  //     })
  //   }
  // };
  
  render() {
    const {tabValue,loaderFlag,token_expired,text,errors,message,sendToHomePage} = this.state;

    console.log('Props from setting page',this.props.user);

    if(sendToHomePage) return (<Navigate to='/' />);
    if(loaderFlag) return (<Loader text={text} />);
    if(token_expired) return (<Navigate to='/logout' />);
    // console.log("my country data is ",window.intlTelInputGlobals.getCountryData())

    return (
      <div className="Page-Width-Vossle">
        <Box sx={{ width: '100%', typography: 'body1',marginTop:"26px"  }}>
          <TabContext value={tabValue}>
            <Box sx={{borderBottom: 1, borderColor: 'divider',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <TabList onChange={this.handleTabChange} aria-label="lab API tabs example" sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Tab  label="Personal Setting" sx={{fontSize:'18px',textTransform:'capitalize',width:'300px',fontWeight:'500',borderRadius:"10px 10px 0px 0px",color:'white !important',background:(tabValue ==='1' ?'#682CEA':'lightgrey'),b:''}} value="1" />
                <Tab disabled sx={{width:"100px"}} > </Tab>
                {/* <Tab disabled > </Tab>
                <Tab disabled > </Tab> */}
                <Tab label="Workspace Setting" sx={{fontSize:'18px',textTransform:'capitalize',width:'300px',fontWeight:'500',color:'white !important',background:(tabValue === '2' ?'#682CEA':'lightgrey'),borderRadius:"10px 10px 0px 0px"}} value="2" />
              </TabList>


            </Box>
            <TabPanel value="1">
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={this.handleSubmit}
              >
                <Grid xs={12} sx={{textAlign:'center',m:'auto',p:'auto'}}>{errors.global && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors.global}</Typography>) }</Grid>
                <Grid xs={12} sx={{textAlign:'center',m:'auto',p:'auto'}}>
                  {/* {this.getMessage(message)} */}
                  <GetMessage message={message} />
                </Grid>
                {this.personalSetting()}
              </Box>
            </TabPanel>
            <TabPanel value="2">
              {this.workspaceSetting()}
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    )
  }

}

export default Settings;