import React from 'react'
import Grid from '@mui/material/Grid';
import { Doughnut } from 'react-chartjs-2';
import Button from '@mui/material/Button';

const AdvertisementSepration = (props) => {
    const Duration = props.Duration;
    const setFilter = props.setFilter;
    const DropDownOpen = props.DropDownOpen;
    const DropDownOption = props.DropDownOption;
    const selectedFilter = props.selectedFilter;
    const AdData = props.data.length === 0 ? [] : props.data;
    const ITEM_HEIGHT= 38;

    const data = {
        labels: ['AR Exp.','3d Exp.'],
        datasets: [
        {       
            data: [AdData.camera_yes_count, AdData.camera_no_count],
            backgroundColor: [
            '#37BE70',
            '#3762C7' 
            ],
            borderColor: [
            '#37BE70',
            '#3762C7'        
            ],
            borderWidth: 1,
        },
        ],
    };

    const plugins = [{
        beforeDraw: function(chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
                ctx.restore();
                var fontSize = '20px';
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                var text = 'Ad Separation',
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
                ctx.fillText(text, textX, textY);
                ctx.save();
        } 
    }]


    const option = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 70,
        legend: {
        display: false
        },
        tooltips: {
            callbacks: {
            label: function(tooltipItem) {
                    return tooltipItem.yLabel;
            }
            }
        }
    }

    return (
        <> 
            <Grid container sx={{mt:3,mb:4,display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Grid xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Button
                        id="fade-button"
                        className={selectedFilter.action === 'gender'?"selectfilterDesign":"filterDesign"}
                        sx={{width:"90%",borderRadius:"50px",color:(selectedFilter.action === 'gender'?'white':'#454747')}}
                        // onClick={()=>{setFilter({id:1,name:'Gender',value:'gender',action:'gender'})}}
                    >
                        AR Exp. Count : {AdData.camera_yes_count}
                    </Button>
                </Grid>
                <Grid xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Button
                        className={selectedFilter.action === 'age'?"selectfilterDesign":"filterDesign"}
                        id="fade-button"
                        sx={{width:"90%",borderRadius:"50px",color:(selectedFilter.action === 'age'?'white':'#454747')}}
                        // onClick = {()=>{setFilter({id:2,name:'Age',value:'age',action:'age'})}}    
                    >
                        3d Exp. Count : {AdData.camera_no_count}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'240px',width:"240px"}}>
                        <Doughnut
                            options={option}
                            data={data}
                            plugins={plugins}
                            // {...props}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
 
export default AdvertisementSepration;
