import React from "react";
const File = ({ name, label, isFocus, error, ...rest }) => {
  return (
    <div>
      <input
        {...rest}
        name={name}
        id={name}
        autoFocus={isFocus}
        className="form-control"
        type="file"
        //   id="customFile"
        //   name="filename"
      />
      <label className="custom-file-label" htmlFor={name}>
        {label}
      </label>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default File;
