import React, { Component } from 'react';
import { Configuration, OpenAIApi } from "openai";
import config from '../Components/services/config.json';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AllInputFields from '../Components/InputFields/AllInputFields';
import RenderButton from './../Components/InputFields/RenderButton';
import { Grid } from '@mui/material';

class OpenAITest extends AllInputFields {
    constructor(props) {
        super(props)
        this.state = {
            data:{aitext:''},
            errors:{global:''},
        };
    }

    async componentDidMount() {
        const configuration = new Configuration({
            apiKey: config.openAIKey,
        });
        const openai = new OpenAIApi(configuration);
        const response = await openai.createImage({
            prompt: "dolphin",
            n: 4,
            size: "256x256",
            response_format: "b64_json",
        });
        const data = response.data.data;
        this.setState({img:data});
    }

    validate = () => {
        return null;
    };

    doSubmit = async () => {
        const configuration = new Configuration({
            organization: config.organisationId,
            apiKey: config.openAIKey,
        });
        const openai = new OpenAIApi(configuration);
        const response = await openai.createImage({
            prompt: this.state.data.aitext,
            n: 4,
            size: "256x256",
            response_format: "b64_json",
        });
        const data = response.data.data;
        this.setState({generated:data});
    };

    render() { 
        const {img,generated} = this.state;
        return (
            <Box className="Page-Width-Vossle" >
                <Typography variant="h5">
                    Text : Dolphin
                </Typography>
                {img && (
                    img.map((src) => <img src={'data:image/png;base64,'+src.b64_json} />)
                )}


                <Grid container sx={{background:'#F1F3F5', borderRadius:'10px', mt:3, p:1}}>
                    <Typography variant="h5">
                        Generate New
                    </Typography>
                    <Grid xs={12}>
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            sx={{
                            m:4,
                            py:4,
                            px:10,
                            }}
                            onSubmit={this.handleSubmit}
                        >
                            {this.MultiLineStringInputField('aitext', 'text', 'Generate PNG Image using text', true, 4, 1000)}
                            <RenderButton 
                                name = 'get_image'
                                type = "submit"
                                label = 'Submit'
                                minWidth = {180}
                            />
                        </Box>

                        {generated && (
                            generated.map((src) => <img src={'data:image/png;base64,'+src.b64_json} />)
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    }
}
 
export default OpenAITest;