import React from "react";
import "./floating-form.css";
const SelectBox = ({ name, label, values, error, ...rest }) => {
  const getOption = (values) => {
    let options = [];
    options.push(<option value="" key="optional">{label}</option>);
    for (const val of values) {
      options.push(<option value={val} key={val}>{val.charAt(0).toUpperCase() + val.slice(1)}</option>);
    }
    return options;
  };
  return (
    <div className="form-label-group">
      <select name={name} id={name} className="form-control" {...rest}>
        {getOption(values)}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default SelectBox;