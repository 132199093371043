import React, { Component } from 'react';

import { isBoolean } from 'lodash';
import { toast } from "react-toastify";
import Info from '@mui/icons-material/Info';
import { Link,Navigate} from 'react-router-dom';
import { Typography,Button,Grid } from '@mui/material';

import baloon from '../assets/baloon.png';
import VossleLogo from '../assets/logo.png';
import withRouter from './../Components/services/withRouter';
import { resendEmail } from './../Components/services/userService';
import CountdownCircle from './../Components/Common/countdownCircle';

class Confirmation extends Component {
    constructor(props){
        super(props);
        this.state = {
            user:{...this.props.location.state.user},
            countdown:true,
            errors:{},
            redirect:false,
            message:{},
        }
    }

    emailFunction = async () => {
        const {user} = this.state;
        try {
            const data = new FormData();
            data.append("user_id", user.id);
            data.append("email", user.email);
            const {data:res} = await resendEmail(data);
            if (res.status && isBoolean(res.status)) {
              toast.success(res.message);
              const {data:user} = res;
              this.setState({ user,redirect:true });
            } else toast.error(res.message);
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
              toast.error(ex.response.data.message);
              const errors = { ...this.state.errors };
              errors.global = ex.response.data.message;
              this.setState({ errors });
            } else if (ex.response && ex.response.status === 404) {
              toast.error("Server Down, Under maintenance.");
            } else {
              toast.error("Unexpected Error");
            }
        }
    };

    sendEmail = () => {
        const {countdown} = this.state;
        if(countdown) {
            let errors = {global:'Can\'t send email now, wait period is on'};
            this.setState({errors});
            toast.error(errors.global);
        } else {
            this.emailFunction();
        }
    };

    updateCountdown = () => {
        this.setState({countdown:false});
    };

    render() {
        const {errors,countdown,redirect} = this.state;
        
        if(redirect) {
            <Navigate to="/login" />
        }
        return (
            <React.Fragment>
               <div style={{background:'rgb(243 244 246 / var(--tw-bg-opacity))',width:"99vw",height:"99vh",margin:"auto",display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{maxWidth:"630px",maxHeight:"490px",borderRadius:"10px",border:'1px solid grey',padding:"16px"}}>
                        <Grid container>
                            <Grid xs={12}>{errors.global && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors.global}</Typography>) }</Grid>
                        </Grid>
                        <div style={{position:'absolute',maxWidth:"100%",maxHeight:"100%"}}>
                            <img alt="logo" src={VossleLogo} width="100px" style={{position:'relative'}} />
                        </div>
                        <div style={{width:"100%",display:"flex",justifyContent:'center'}}>
                            <img width="180px" alt="confirmation_img" src={baloon}/>
                        </div>
                        <div style={{width:"100%",textAlign:'center'}}>
                            <Typography sx={{fontSize:"48px",fontWidth:"medium",color:'rgba(104,44,234,1)'}}>
                                Welcome To Vossle
                            </Typography>
                            <Grid container sx={{mb:2}}>
                                <Grid xs={2}></Grid>
                                <Grid xs={8}>
                                    <Typography variant="subtitle1" sx={{color:'grey',mb:2}}>
                                        We have sent a verification mail to your registered email id, please verify your account.
                                    </Typography>
                                </Grid>
                                <Grid xs={2}></Grid>
                            </Grid>
                            
                            <Button className="button3" type="button" onClick={this.sendEmail} sx={{background:'#FF821C',border:"1px solid #FF821C",p:1,borderRadius:"50px",color:'black',width:"300px"}}>
                                <Grid container sx={{display:"flex",justifyContent:'center',alignItems:'center',px:1}}>
                                    <Grid xs={1}></Grid>
                                    <Grid xs={8.5}>
                                        <Typography className="buttonColor" sx={{ml:1,color:'white'}}>                                               
                                            {"R".toUpperCase() + "esend Link"}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={1.5}  style={{borderRadius:"50px"}}>
                                        <CountdownCircle timeChange={this.updateCountdown} />
                                    </Grid>
                                    <Grid xs={1}></Grid>
                                </Grid>
                            </Button>
                            <br/>
                            <Link to='/sign-up' sx={{mt:1}}>or create a new account</Link>
                        </div>
                    </div>
               </div>
            </React.Fragment>
        );
    }
}
export default withRouter(Confirmation);