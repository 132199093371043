import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { orange } from '@mui/material/colors';

const RenderButton = ({name, type, label, isDisabled, minWidth, ...rest}) => {
    const ColorButton = styled(Button)(({ theme }) => ({
        // color: theme.palette.getContrastText(orange['A400']),
        color: 'white',
        backgroundColor: orange['A400'],
        height: '50px',
        width: `${minWidth}px`,
        boxShadow: 'none',
        borderRadius: '25px',
        '&:hover': {
          backgroundColor: orange['A700'],
          boxShadow: 'none',
        },
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
        textTransform: 'capitalize',
        fontFamily: 'Noto Sans'
    }));
    
    return (
        <ColorButton
            {...rest}
            variant="contained"
            name={name}
            id={name}
            disabled={isDisabled}
            type={type}
        >
            {label}
        </ColorButton>
    );
}
 
export default RenderButton;
