import React from 'react';
import { Typography } from '@mui/material';
import Info from '@mui/icons-material/Info';

const GetMessage = (props) => {
    const {message} = props;
    console.log('Get Message',message);
    
    return (
        Object.keys(message).length === 0 ? null : (Object.values(message).map((data) => (
            data.map((value) => (
                <Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info />{value}</Typography>
            ))
        )))
    );
}
 
export default GetMessage;