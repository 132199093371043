import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import "../../css/VideoCard.css"


export default class VideoCard extends Component {

  constructor(props){
    super(props)
    this.state= {
      id:this.props.CardData.id,
      Poster:'',
      category:this.props.CardData.category,
      thumbnail:this.props.CardData.thumbnail,
      Url:this.props.CardData.assets,
      HeadingText:this.props.CardData.title,
      ParagraphText:this.props.CardData.description,
      SendtoPage:'',
      currentRunningVideo:'',
      currentSelectedCategory:'',
      currentSelectedId:'',
      avatar:'',
      playAndStopVideo:this.props.playAndStopVideo
    }
  }

  handleVideo = (category,id,Url) =>{
    if(category === 'Blogs') return false;
    let videoId = document.getElementById(category+id+'_video');
    let thumbnail = document.getElementById(category+id+'_thumbnail');
    let icon = document.getElementById(category+id+'_icon');
    videoId.src  = videoId.src + '&autoplay=1&muted=1'

    if(thumbnail.style.visibility !== 'hidden'){
      thumbnail.style.visibility = 'hidden';
      icon.style.visibility = 'hidden';
    }
    else{
      thumbnail.style.visibility = '';
      icon.style.visibility = '';  
    }
  }

  
  handleDragStart = (e) => e.preventDefault();

  render() {
    const {id,category,HeadingText,ParagraphText,Url,thumbnail} = this.state;
    const {handleVideo} = this;
    let videoKey = Url.split('/').pop();
    
    return (
        <Card  key={id} sx={{height:'400px',maxWidth:"290px",borderRadius:'10px',border:'1px solid lightgrey'}} onDragStart={this.handleDragStart}  >
          <CardActionArea sx={{height:"165px",maxWidth:"290px",background:'lightgrey'}}  className="video-wrapper">
          <Box sx={{height:'165px',width:'100%'}} >
            <Box sx={{width:'100%',position:'absolute',zIndex:2}} className="video-container" id={category+id+"_icon"} onClick={()=>handleVideo(category,id,Url)}>
              <img id={category+id+"_thumbnail"} src={thumbnail} alt='video thumbnail' style={{zIndex:2}}  height="165px" />
              {category !== 'Blogs' && (<div style={{zIndex:3,position:'absolute',width:'100%',display:'flex',justifyContent:'center'}}>
                  <div  style={{display:'inline',background:'blue',padding:'10px',borderRadius:'50%'}} >
                    <PlayArrowIcon sx={{height:'40px',width:'40px',color:'white',display:'flex',justifyContent:'center',alignItems:"center"}}/>
                  </div>
              </div>)}
            </Box>
            <Box sx={{width:'100%'}}className="video-container" id="video-container">
              <iframe width="290px" height="165px" 
                      id={category+id+"_video"}
                      loading="eager"
                      src = {'https://www.youtube.com/embed/'+videoKey+'?&enablejsapi=1&showinfo=0&controls=0&modestbranding=0&playlist='+videoKey+'&rel=0'}
                      title="YouTube video player" frameborder="0" 
                      allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              ></iframe>
            </Box>
          </Box> 
          </CardActionArea>
          <div style={{maxWidth:"323px"}}>
            <CardContent sx={{height:"180px"}} >
              <Typography style={{textTransform:"capitalize",fontWeight:'medium',fontSize:"21px"}} component="div">
                {HeadingText.length>22?HeadingText.substring(0,20)+'...':HeadingText}
              </Typography>
              <Typography variant="body2" sx={{textTransform:"capitalize",fontSize:'15px',mt:1}} color="text.secondary">    
                {ParagraphText.length > 130?ParagraphText.substring(0,130)+'...':ParagraphText}
              </Typography>
            </CardContent>
            {Url && (
              <CardActions sx={{justifyContent:'center'}}>
                <a target="_blank" rel="noreferrer" href={Url} style={{   textTransform:'capitalize',textDecoration:'none'}} >
                  <Button  size="small" color="primary" to={Url}>
                    View More
                  </Button>
                </a>
              </CardActions>
            )}
          </div>
        </Card>
    )
  }
}


