import React, { Component, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateExperienceCard from '../../Components/cards/CreateExperienceCard';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import background from '../../assets/background.png';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {Navigate} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search'
import "../../css/CreateExperiences.css";
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import config from '../../Components/services/config.json'
import S3 from 'aws-sdk/clients/s3';
import withRouter from '../../Components/services/withRouter';
class Step4 extends Component {

  constructor(props){
   super(props)
   this.state = {
    markerImageStatus:'',
    urlCopied:false,
    resetState:false,
    iframeUrlCopied:false,
    iframeLink:'',
    expType:this.props.selectedExpType,
    cardData:props.cardData,
    qrcode: '',
    placement:props.placement,
    url: props.cardData.url,
    marker_image: props.cardData.marker_image,
    showIframeLink:false,
    IframeUniqueText:[
      {
        id:2,
        uniqueHash:'markerless/'
      },
      {
        id:12,
        uniqueHash:'lipstick-tryon/'
      },
      {
        id:14,
        uniqueHash:'Tryons/faceTryon/'
      },
      {
        id:15,
        uniqueHash:'ring-try/ring-/watch/'
      },{
        id:13,
        uniqueHash:'tryon-watch/hand/watch/'
      }, 
      {
        id:26,
        uniqueHash:'mehndi-tryon/'
      },
      {
        id:23,
        uniqueHash:'Tryons/faceads/'
      },
      {
        id:24,
        uniqueHash:'world-mapping/'
      },
      {
        id:25,
        uniqueHash:'Model-ads/'
      }
    ],
   }
  }

  blobToBase64 = (blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((rs, rj) => {
      reader.onloadend = () => {
        rs(reader.result)
      }
      reader.onerror = rj
    })
  }

  async componentDidMount(){

    const r = await fetch(this.props.cardData.qrcode)
    const blob = await r.blob()

    const base64 = await this.blobToBase64(blob)
    this.setState({qrcode:base64})
  }

  downloadMarkerbasedImage =  (url, name,e) => {
    e.preventDefault();
    if(url !== '')
    {
      fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch((e) => {console.log("my error is",e);this.setState({markerImageStatus:'Unable to download markerbased Image'})});
    }
  }

  downloadQRImage = async () =>{
    const logoImage = document.querySelector('.logo_card');
    if (!logoImage) return;
    let {qrcode,url} = this.state; 

    if(qrcode)
    {
      const canvas = await html2canvas(logoImage);
      const dataURL = canvas.toDataURL('image/png');
      console.log("my qrcode and url is",qrcode,url);
      downloadjs(dataURL, 'download.png', 'image/png');
    }
  } 

  getIFrameUniqueHash = (id) =>{
    const {IframeUniqueText} = this.state;
    const placement = this.props.placement;
    let finalUrl = '';
    IframeUniqueText.forEach(e=>{
      console.log(e.id," ==> ",id)
      if(e.id == id)
      {        
          finalUrl = e.uniqueHash;
      } 
    })
    return finalUrl;
  }

  downloadMarkerImage = async (event,imageSrc) =>{

    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    if(imageBlog){

      const imageURL = URL.createObjectURL(imageBlog)
      console.log("my image URL is",imageURL)
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image file name here'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  render() {
    let exp = this.props.selectedExpType;
    let expSubcategory = this.props.selectedExpTypeSubcategory;
    let iframeLink = '';
    let {showIframeLink,iframeUrlCopied} = this.state;
    console.log("my step4 page is running now",exp,expSubcategory);
    const {url,qrcode,cardData,urlCopied,marker_image,resetState} = this.state;
    console.log('step4 Data Final',this.state);
    console.log("qrCode is",qrcode)
    console.log("my props data is",this.props)
    console.log("my sub categoryy is",expSubcategory)
    if(Object.getOwnPropertyNames(expSubcategory).length !== 0)
    {
      let uniqueHash = this.getIFrameUniqueHash(expSubcategory.id)
      console.log("my unique hash is cateogry of",expSubcategory.id,"===>",uniqueHash)
      if(uniqueHash !== '')
      {
        iframeLink = `<iframe id="vossleIframe" allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;" src=${config.webarEndpoint}${uniqueHash}?id=${url.split('/').pop()} style="height: 700px; width: 360px"></iframe>`  
      }
    }
    else
    {
      let uniqueHash = this.getIFrameUniqueHash(exp.id)  
      console.log("my unique hash is cateogry of",exp.id,"===>",uniqueHash)   
      if(uniqueHash !== '')
      {
          iframeLink = `<iframe id="vossleIframe" allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;" src=${config.webarEndpoint}${uniqueHash}?id=${url.split('/').pop()} style="height: 700px; width: 360px"></iframe>`
      }
    }

    // if(exp.exp_type === 'markerless')
    // {
    //   iframeLink = `<iframe id="vossleIframe" allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;" src=${config.webarEndpoint}markerless/?id=${url.split('/').pop()} style="height: 700px; width: 360px"></iframe>`
    // }
    // else if(expSubcategory.exp_type === 'facedetection' || exp.exp_type === 'tryon' ) 
    // {
    //   iframeLink = `<iframe id="vossleIframe" allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;" src=${config.webarEndpoint}T/123f/?id=${url.split('/').pop()} style="height: 700px; width: 360px"></iframe>`
    // }
    // else if(exp.exp_type === 'ads'){
    //   iframeLink = `<iframe id="vossleIframe" allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;" src=${config.webarEndpoint}T/123f/?id=${url.split('/').pop()} style="height: 700px; width: 360px"></iframe>`
    // }

    
    if(resetState) return (<Navigate to = {this.props.location.pathname === '/create'?'/ar/create':'/create'}  />);
    

    return (
      <div style={{width:"100%",marginTop:"20px"}}>
        <Card   sx={{ height:'100%',width: 420,mx:'auto' }}>
          <CardActionArea sx={{width:"100%",height:"100%",background:'#F1F3F5'}}>
            <CardActions sx={{position:'absolute',width:"100%",height:'150px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <Typography sx={{fontSize:"40px",color:'gold'}}>Congratulations!</Typography>
              <Typography sx={{color:'white'}}>Scan QR Code or visit link to explore</Typography>
            </CardActions>
            <CardMedia
              component="img"
              height="150px"
              image={background}
              alt="green iguana"
            />
            
            {
              marker_image &&(
            <Grid container>
              <Grid xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center',mt:1}}>
                <img src={marker_image} alt="" style={{border:"2px solid #682CEA",borderRadius:'10px',height:'180px',width:'180px'}}/>
              </Grid>
              <Grid xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center',mt:1}}>
                      <span onClick={(e)=> { this.downloadMarkerImage(e,marker_image) }}
                      style={{padding:'10px',color:'#1c7ed6',textDecoration:'underline'}} 
                      >
                  click to download image
                </span>
                <br/>
                {/* <span style={{color:'#1c7ed6',textDecoration:'underline'}} > 
                  {this.state.markerImageStatus}
                </span> */}
              </Grid>
              {/* <button onClick={(e)=>this.downloadMarkerImage(e,marker_image)} >Download marker Image</button> */}
            </Grid>
              )
            }
            <div ref={this.qrcode}></div>
            <CardContent sx={{margin:'auto',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',mb:1}}>
              <div className="logo_card" style={{padding:"10px 10px 1px 10px",display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                  <img src={qrcode} alt="vossle qrcode" style={{border:"2px solid #682CEA",borderRadius:'10px',height:'180px',width:'180px'}}/>
                  <div style={{width:"80%"}}>
                    <Divider sx={{mb:1,mt:1}}>or</Divider>
                  </div>
                <Typography onClick={()=>{navigator.clipboard.writeText(url);this.setState({urlCopied:true})}} className="url-wrapper" variant="body2" sx={{display:'flex',jutifyContent:'center',alignItems:'center'}} color="text.secondary" >
                  <a  style={{padding:'10px',color:'#1c7ed6',textDecoration:'underline'}}>{url}</a> 
                  <ContentCopyIcon className="url-icon" sx={{color:'grey'}} /> 
                </Typography>
              </div>
              <div style={{color:'green',marginBottom:'8px'}}>{urlCopied && <div>Url Copied</div>}
              </div>
              <Grid container>
                <Grid xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <Button sx={{mx:'auto',height:'30px',mb:0.5,width:"300px !important"}} onClick={()=>{this.downloadQRImage()}} className="button1">Download QR Code & Link</Button>
                </Grid>
                {
                  (exp.exp_type === 'markerless' || exp.exp_type === 'tryon' || exp.exp_type === 'ads' || exp.exp_type=== 'facedetection') &&
                  <>
                    <Grid xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                      <Button sx={{mx:'auto',background:"#6E6E6E !important",mb:0.5,height:'40px !important',width:"300px !important"}}  onClick={()=>{this.setState({showIframeLink:true})}}  className="button1">Get iframe Link</Button>
                    </Grid>
                    <div style={{display:(this.state.showIframeLink === true?'':'none') }}>
                      
                      <Typography variant="body2" sx={{display:'flex',jutifyContent:'center',alignItems:'center',textAlign:'center'}} 
                          color="text.secondary" >
                        {iframeLink}
                        <div id="copyIcon">
                          <ContentCopyIcon  sx={{color:'grey'}} onClick={()=>{navigator.clipboard.writeText(iframeLink);this.setState({iframeUrlCopied:true})}}/> 
                        </div>
                      </Typography>
                      {iframeUrlCopied && <Grid container><Grid xs={12} sx={{display:'flex',color:'green',alignItems:'center',justifyContent:'center',mt:1,mb:1}}>iframe Url Copied</Grid></Grid>}
                    </div>
                  </>
                }
              </Grid>
              
              
              <span style={{padding:'10px',color:'#1c7ed6',textDecoration:'underline'}} 
                onClick={()=>this.setState({resetState:true})}
              >Go back to previous page</span>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    )
  }
}

export default withRouter(Step4);