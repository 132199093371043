import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { toast } from "react-toastify";
import Key1 from './../assets/Key1.png';
import Key2 from './../assets/Key2.png';
import Key3 from './../assets/Key3.png';
import KeyCard from '../Components/cards/KeyCard';
import { getUserDetails, generateKey } from './../Components/services/authService';
import Loader from './../Components/Common/Loader';
import { Navigate } from 'react-router-dom';

class Keys extends Component {
  constructor(props){
    super(props)
    this.state={
      data:[{
        id:'shopify_key',
        keyType:'Shopify Key',
        key:'Generate Key',
        image:Key1
      },{
        id:'wordpress_key',
        keyType:'Wordpress Key',
        key:'Generate Key',
        image:Key2
      },{
        id:'magento_key',
        keyType:'Magento Key',
        key:'Generate Key',
        image:Key3
      }],
      user:{},
      text:'Loading User API Key...',
      loaderFlag:true,
      token_expired:false,
    }
  }

  async componentDidMount(){
    try {
      const {data} = await getUserDetails();
      if(data.status === true) {
        const {data:user} = data;
        console.log('Response User');
        this.setState({ user,loaderFlag:false });
      } else if(data.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400)
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }

  generateKey = async (type) => {
    console.log('Called generate key',type);
    try {
      let form = new FormData();
      form.append('type',type);
      form.append('user_id',this.props.user);
      const {data:res} = await generateKey(form);
      // const res = data;
      console.log('generated Key', res);
      if(res.status == true) {
        const {data:user} = res;
        console.log('Response User',user);
        this.setState({ user,loaderFlag:false });
      } else if(res.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400)
      } else {
        console.log('Else :',res);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  };

  render() {
    const {data,user,text,loaderFlag,token_expired} = this.state;
    
    if(loaderFlag) return (<Loader text={text} />);
    if(token_expired) return (<Navigate to='/logout' />);
    
    return (
      <Box className="Page-Width-Vossle">
        <Grid container spacing={1}>
          <KeyCard data={data} user={user} generateKey={this.generateKey} />
          {/* {
            data.map((data) => {
              return(
                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                  <KeyCard data={data} user={user} generateKey={this.generateKey} />
                </Grid>
              )
            })
          } */}
      </Grid>
    </Box>
    )
  }
}

export default Keys;