import React, { Component } from 'react'
import {Grid,Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import Image from '../assets/mouse.png'

class NotFound extends Component {
  render() {
    return (
        <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',color:'red',fontSize:'25px',fontWeight:'medium',mt:3,mb:3}}>Page Not Found</Grid>
            <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={Image} alt=""/></Grid>
        </Grid>
    )
  }
}

export default NotFound;