import React, { Component } from 'react';
import {Configuration, OpenAIApi } from 'openai';
import config from '../Components/services/config.json';
import { Button } from '@mui/material';
export default class Testing extends Component {

  constructor(props){
    super(props)
    this.state = {
      myImage:'',
    }
  }

  componentDidMount() {
    console.log(config.openAIKey)
  }
  
  GenerateImage = async () =>{
    console.log("hello rj from generate image function")
    try{
      const configuration = new Configuration({
        apiKey: config.openAIKey,
      });
      console.log("my configuration is",configuration)
      const openai = new OpenAIApi(configuration)
      console.log("let's see our openai model ",openai)
      const response = await openai.createImage({
        prompt:'cute baby dog',
        n:3,
        size:'1024x1024'
      })
      const imageUrl = response.data.data[0].url;

      this.setState({myImage:imageUrl})
      console.log("my response of the data is",response,imageUrl)
    }
    catch(e){
      console.log("my error is",e)
    }
  }

  createImage = () =>{
    this.GenerateImage();
  }

  render() {   
    const myImage = this.state;
    return (
      <div className="Page-Width-Vossle" style={{fontSize:"200px",width:"100%"}} >
        {
          <div style={{width:"530px",height:"530px",border:"4px solid green",borderRadius:"10px",margin:'10px auto'}}>
          {
            myImage !== '' 
            ? 
            <img src={myImage} alt=""></img>:
            <div>No Image for now</div>
          }
          </div>
        }
        <div style={{width:"100%",margin:"auto",display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Button onClick={()=>this.createImage()}>Click to Generate image</Button>
        </div>
      </div>   
    );
  }
}
