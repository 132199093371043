import React, {Component} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
class StepperModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps : [ 'Basic Info', 'Setup', 'Finish'],
            activeStep: this.props.activeStep,
        }
    };

    render() {
        const {steps, activeStep} = this.state;
        return (
            <React.Fragment>
                <Box sx={{ flexGrow: 1, pt:2, backgroundColor:'#e1e1e1', height: '89px' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={3} sx={{pt:2}}>
                            <Typography paragraph sx={{pl:2, color:'#939494', fontSize:'18px', display:'inline'}}>AR Experience <ArrowForwardIosIcon sx={{color:'#313131'}} /></Typography><Typography paragraph sx={{fontSize:'18px', color:'#31313', fontSize:'18px', display:'inline'}}>WorkFlow</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Stepper activeStep={activeStep === '' ? 0 : 1} alternativeLabel sx={{width:'100%'}}>
                                {steps.map((label) => (
                                <Step key={label} sx={{ marginTop:'0px' }}>
                                    <StepLabel sx={{ marginTop:'0px' }}>{label}</StepLabel>
                                </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={2} sx={{textAlign:'right', pr:2}}>
                            <CloseIcon/>
                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }
}

export default StepperModule;