import React, { Component, useRef } from 'react';
import { toast } from "react-toastify";
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import background from '../assets/background.png';
import partyPopper from '../assets/partyPopper.png';

import "../css/CreateExperiences.css";
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import config from '../Components/services/config.json'
import {getNode} from '../Components/services/arService';
import withRouter from '../Components/services/withRouter';
import Loader from './../Components/Common/Loader';

class QrCodePage extends Component {

  constructor(props){
   super(props)
    this.state ={
      exp_id:-1,
      loaderFlag:true,
      qrcode:'',
      iframeLink:'',
      marker_image:'',
      iframeUrlCopied:false,
      markerImageStatus:'',
      url:'',
      exp:'',
      exp_type:'',
      urlCopied:false,
      IframeUniqueText:[
        { id:2, uniqueHash:'markerless/'},
        { id:12, uniqueHash:'lipstick-tryon/'},
        { id:14, uniqueHash:'Tryons/faceTryon/' },
        { id:15, uniqueHash:'ring-try/ring-/watch/' },
        { id:13, uniqueHash:'tryon-watch/hand/watch/' },
        { id:26, uniqueHash:'mehndi-tryon/' },
        { id:23, uniqueHash:'Tryons/faceads/' },
        { id:24, uniqueHash:'world-mapping/' },
        { id:25, uniqueHash:'Model-ads/' }
      ]
    }
  }
  
  // 3d Ads ==> /Model-ads/
  // world mappinng ==> /world-mapping/
  // face filter ==> /T/faceads/
  // palm tryon => /mehndi-tryon/
  // wrist detection  ==> /T/123f/
  // ring tryon    ==> /ring-try/ring-/watch/
  // face Detection ==> /T/123f/
  // lipstic tryon ==> /lipstick-tryon/
  // markerless ==> markerless/

  blobToBase64 = (blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((rs, rj) => {
      reader.onloadend = () => {
        rs(reader.result)
      }
      reader.onerror = rj
    })
  }

  async componentDidMount(){
    try{
        let ch = this.props.location.pathname.split('/').pop();
        const {data} = await getNode(ch);
        console.log("Experience Status", data.status);
        console.log("Experience Data", data.data);
        if(data.status) {
          let {data:exp} = data;
          
          const r = await fetch(config.assetsEndpoint + exp.qr_code)
          const blob = await r.blob()
          const base64 = await this.blobToBase64(blob)
          
          let id = exp.sub_exp_type != null ? exp.sub_exp_type.id : exp.exp_type.id;
          console.log("my new exp Type is ",id)
          
          let marker_image = '';
          if (exp.exp_type.exp_type === 'markerbased') {
            marker_image = this.getMarkerImage(exp.assets);
            console.log("my marker_image is",marker_image);
            if(marker_image !== '') this.setState({marker_image,qrcode:base64, url:config.webarEndpoint + exp.slug_exp_name , exp_type:exp.exp_type.exp_type, exp_id:id, loaderFlag:false,exp});
          } else {
            this.setState({qrcode:base64, url:config.webarEndpoint + exp.slug_exp_name , exp_type:exp.exp_type.exp_type, exp_id:id, loaderFlag:false,exp,marker_image})
          }
        }
        
    }
    catch(ex) {
      let message = {};
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
        message = ex.response.data.errors;
        this.setState({ message });
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }

  downloadMarkerImage = async (event,imageSrc) =>{

    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    if(imageBlog){

      const imageURL = URL.createObjectURL(imageBlog)
      console.log("my image URL is",imageURL)
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image file name here'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  downloadQRImage = async () =>{
    const logoImage = document.querySelector('.logo_card');
    if (!logoImage) return;
    let {qrcode,url} = this.state; 

    if(qrcode)
    {
      const canvas = await html2canvas(logoImage);
      const dataURL = canvas.toDataURL('image/png');
      console.log("my qrcode and url is",qrcode,url);
      downloadjs(dataURL, 'download.png', 'image/png');
    }
  } 

  getIFrameUniqueHash = (id) =>{
    const {IframeUniqueText} = this.state;
    const placement = this.props.placement;
    let finalUrl = '';
    IframeUniqueText.forEach(e=>{
      console.log(e.id," ==> ",id)
      if(e.id == id)
      {        
          finalUrl = e.uniqueHash;
      } 
    })
    return finalUrl;
  }

  getMarkerImage  = (assets) => {
    let str=""
    assets.forEach(ast => {
      console.log("my ast is",ast)
      if(ast.type === 'marker_image'){
        str = config.assetsEndpoint + ast.assets;
      }
    });
    return str;
  };

  render() 
  {
    const {qrcode,url,urlCopied,exp_type,iframeUrlCopied,exp,exp_id,loaderFlag,marker_image}  = this.state;
    console.log("===>",exp)
    let iframeLink = '';
    console.warn("my exp id is",exp_id);
    const uniqueHash = this.getIFrameUniqueHash(exp_id);

    if(uniqueHash !== '')
    {
        iframeLink = `<iframe id="vossleIframe" allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;" src=${config.webarEndpoint}${uniqueHash}?id=${url.split('/').pop()} style="height: 700px; width: 360px"></iframe>`
    }
    
    if (loaderFlag) return (<Loader text='Loading Experiences Data ...' />);

    console.log("my marker_image is now",marker_image)

    return (
      <div style={{width:"100%",marginTop:"20px"}}>
        <Card   sx={{ height:'100%',width: 420,mx:'auto' }}>
          <CardActionArea sx={{width:"100%",height:"100%",background:'#F1F3F5'}}>
            <CardActions sx={{position:'absolute',width:"100%",height:'150px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <img src={partyPopper} alt="" height="55px"  style={{marginTop:"15px",marginBottom:'20px'}}/>
              <Typography sx={{color:'gold',fontSize:"20px",marginBottom:"10px",fontWeight:"400"}}>Scan QR Code or visit link to explore</Typography>
            </CardActions>
            <div>
                <CardMedia
                component="img"
                height="150px"
                image={background}
                alt="green iguana"
                />
            </div>

            { url !== '' &&
            <CardContent sx={{margin:'auto',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',mt:1,mb:1}}>
              
              {
                (marker_image !== '') &&(   
                  
                  <Grid container>
                    <Grid xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center',mt:1}}>
                      <img src={marker_image} alt="" style={{border:"2px solid #682CEA",borderRadius:'10px',height:'180px',width:'180px',padding:'4px'}}/>
                    </Grid>
                    <Grid xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center',mt:1}}>
                      <span onClick={(e)=> { this.downloadMarkerImage(e,marker_image) }}
                      style={{padding:'10px',color:'#1c7ed6',textDecoration:'underline'}} 
                      >
                        click to download image
                      </span>
                      <br/>
                      {/* <span style={{color:'#1c7ed6',textDecoration:'underline'}} > 
                        {this.state.markerImageStatus}
                      </span> */}
                    </Grid>
                  </Grid>
                
                )
              }
              <div className="logo_card" style={{padding:"10px 10px 1px 10px",display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                  <img src={qrcode} alt="vossle qrcode" style={{border:"2px solid #682CEA",borderRadius:'10px',height:'180px',width:'180px'}}/>
                  <div style={{width:"80%"}}>
                    <Divider sx={{mb:1,mt:1}}>or</Divider>
                  </div>
                <Typography onClick={()=>{navigator.clipboard.writeText(url);this.setState({urlCopied:true})}} className="url-wrapper" variant="body2" sx={{display:'flex',jutifyContent:'center',alignItems:'center'}} color="text.secondary" >
                  <a  style={{padding:'10px',color:'#1c7ed6',textDecoration:'underline'}}>{url}</a> 
                  <ContentCopyIcon className="url-icon" sx={{color:'grey'}} /> 
                </Typography>
              </div>
               <div style={{color:'green',marginBottom:'8px'}}>{urlCopied && <div>Url Copied</div>}
              </div>
              <Grid container>
                <Grid xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <Button sx={{mx:'auto',height:'30px',mb:0.5,width:"300px !important"}} onClick={()=>{this.downloadQRImage()}} className="button1">Download QR Code & Link</Button>
                </Grid>
                {
                  (iframeLink !== '') && (exp_type === 'markerless' || exp_type === 'tryon' || exp_type === 'ads' || exp_type=== 'facedetection') &&
                  <>
                    <Grid xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                      <Button sx={{mx:'auto',background:"#6E6E6E !important",mb:0.5,height:'40px !important',width:"300px !important"}}  onClick={()=>{this.setState({showIframeLink:true})}}  className="button1">Get iframe Link</Button>
                    </Grid>
                    <div style={{display:(this.state.showIframeLink === true?'':'none') }}>
                      
                      <Typography variant="body2" sx={{display:'flex',jutifyContent:'center',alignItems:'center',textAlign:'center'}} 
                          color="text.secondary" >
                        {iframeLink}
                        <div id="copyIcon">
                          <ContentCopyIcon  sx={{color:'grey'}} onClick={()=>{navigator.clipboard.writeText(iframeLink);this.setState({iframeUrlCopied:true})}}/> 
                        </div>
                      </Typography>
                      {iframeUrlCopied && <Grid container><Grid xs={12} sx={{display:'flex',color:'green',alignItems:'center',justifyContent:'center',mt:1,mb:1}}>iframe Url Copied</Grid></Grid>}
                    </div>
                  </>
                }
              </Grid>
              
              </CardContent>
            }
          </CardActionArea>
        </Card>
      </div>
    )
  }
}

export default withRouter(QrCodePage);