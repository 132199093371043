import http from "./httpServices";
import config from "./config.json";
const apiEndpoint = config.apiEndpoint;

export function get_paid_plans() {
  return http.get(apiEndpoint+'get-paid-plans');
}

export function upgrade_payment(FormData) {
  return http.post(apiEndpoint+'upgrade_payment',FormData);
}

export function resubscribe(FormData) {
  return http.post(apiEndpoint+'re-subscribe',FormData);
}

export function verify_payment(payData) {
  const data = new FormData();
  payData.subscriptionId && data.append("subscriptionId", payData.subscriptionId);
  payData.razorpayPaymentId && data.append("razorpayPaymentId", payData.razorpayPaymentId);
  payData.razorpaySignature && data.append("razorpaySignature", payData.razorpaySignature);
  return http.post(apiEndpoint+'verify_payment',data);
}

export function check_payment_status(data) {
  return http.post(apiEndpoint+'check_payment_status',data);
}

export function contact_enterprise(user_id) {
  return http.get(apiEndpoint+'contact_enterprise/'+user_id);
}

export function get_payment_details(formData) {
  return http.post(apiEndpoint+'get-payment-details',formData);
}
