import React, { Component } from 'react'
import {socialLogIn} from '../Components/services/userService'
import withRouter from '../Components/services/withRouter'
import { toast } from "react-toastify";
import {Navigate} from "react-router-dom";
import { isBoolean } from 'lodash';
import Loader from '../Components/Common/Loader';
class GoogleAuth extends Component {

    constructor(props){
        super(props)
        this.state = {
            redirect:false
        }
    }

    async componentDidMount(){
        if(this.props.location){
            console.log("this",this)
            const {search} = this.props.location;
            const {provider} = this.props;
            if( (search !== '' || search !== null ) && provider !== ''){
                try
                {
                    const {data:LogIn} = await socialLogIn(provider,search);
                    console.log("my social login data is ",provider,search)
                    if(LogIn.status && isBoolean(LogIn.status))
                    {
                        localStorage.setItem('token',LogIn.data)
                        window.location = '/';
                    }
                    else{
                        this.setState({redirect:true})
                    }
                }
                catch (ex) {
                    console.log("server busy please check the processing",ex)
                    if (ex.response && ex.response.status === 400) {
                      toast.error("Server Busy");
                    } else if (ex.response && ex.response.status === 404) {
                      toast.error("Server Down, Under maintenance.");
                    } else {
                      toast.error("Unexpected Error");
                      console.log("my error is",ex)
                    }
                  }
            }
        }
    }
    render() {
        const {redirect} = this.state;
        console.log("my page print this here ",this)
        
        if(redirect){
           return(<Navigate to="/login" />)
        }
        return (
            <Loader text="Loading Information ...." />
        )
    }
}


export default withRouter(GoogleAuth);