import React, { Component } from 'react';

import { isBoolean } from 'lodash';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import { toast } from "react-toastify";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Navigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { Pagination, PaginationItem, Typography } from '@mui/material';

import NotFound from '../Components/NotFound';
import Loader from './../Components/Common/Loader';
import { paginate } from "../Components/utils/paginate";
import Pagination from './../Components/Common/paginations';
import {deleteArExp} from '../Components/services/arService';
import authService from '../Components/services/authService';
import TableDataCard from '../Components/cards/tableDataCard';
import { getExperiencesAll,deleteARExperience } from '../Components/services/arService';

class ArExperiences extends Component {
  constructor(props) {
    super(props)
    this.state = {
      experiences:[],
      allExperiences:[],
      searchByName:'',
      paginationPages:0,
      loaderFlag:true,
      ExperienceOpen:false,
      ExperienceOption:null,
      orderOption:null,
      ByDateOption:null,
      ExperienceOpenByOrder:false,
      ExperienceOptionByOrder:null,
      totalCollectionData:[],
      collectionData:[],
      currentFilter:'All',
      pageSize: 10,
      currentPage: 1,
      selectedFilter: {id:1,name:'All',value:'Active',action:'all'},
      setSlug:"",
      filters:[
        {id:1,name:'All',value:'Active',action:'all'},
        {id:2,name:'Live',value:'Active',action:'live'},
        {id:3,name:'Delete',value:'Delete',action:'status'},
        {id:4,name:'Recent',value:'Recent',action:'recent'},
        {id:5,name:'Pending',value:'Deactive',action:'status'},
        {id:6,name:'Ascending',value:'Ascending',action:'created_at'},
        {id:7,name:'Descending',value:'Descending',action:'created_at'},
        {id:8,name:'markerless',value:'Recent',action:'exp_type'},
        {id:9,name:'markerbased',value:'Deactive',action:'exp_type'},
        {id:10,name:'facedetection',value:'Ascending',action:'exp_type'},
        {id:11,name:'games',value:'games',action:'exp_type'},
        {id:12,name:'workflow',value:'workflow',action:'exp_type'},
        {id:13,name:'advertisement',value:'advertisement',action:'exp_type'},
      ],
      message:{},
      editRedirect:false,
      requestId:'',
      token_expired:false,
      loaderFlag:true,
    }
  };


  setNewSlug = (slug) =>{
    this.setState({setSlug:slug})
  }

  async componentDidMount() {
    try {
      const user = await authService.getCurrentUser();
      if(user === null && user === '') {
        this.setState({text:'User not found, Redirecting to login page.'});
        setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
        },1000);
      }
      
      const {data:res} = await getExperiencesAll(user);
      if(res.status && isBoolean(res.status)) {
        const {data:experiences} = res;
        this.setState({ experiences, allExperiences:experiences, user, loaderFlag:false });
      } else if(res.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
        },1000);
      }
    } catch (ex) {
      let message = {};
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
        message = ex.response.data.errors;
        this.setState({ message });
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  };

  handlePageChange = (value) => {
    this.setState({ currentPage: value });
  };

  handleSortByOrderOpen = (event) =>{
    this.setState({ExperienceOpenByOrder:true,ExperienceOptionByOrder:event.currentTarget})
  };
  
  stringCapitalLetter = (name)=>{
    return name[0].toUpperCase() + name.substring(1,name.length);
  }
  searchArByName=(e)=>{
    this.setState({searchByName:e.target.value,currentPage:1})
  }

  handleSortByOrderClose =  (selectedFilter) =>{
    if(selectedFilter === undefined || typeof selectedFilter === 'string'){
      return ;
    }
 
    this.setState({currentFilter:selectedFilter.name})
    this.setState({selectedFilter,ExperienceOpenByOrder:false,ExperienceOptionByOrder:null})
  };

  handleSortByExpOpen = (event) =>{
    this.setState({ExperienceOpen:true,ExperienceOption:event.currentTarget})
  };

  handleSortByExpClose = (selectedFilter)=>{
    if(selectedFilter === undefined || typeof selectedFilter === 'string'){
      this.setState({ExperienceOpen:false})
      return ;
    }
    if(selectedFilter.name === 'facedetection'){
      this.setState({currentFilter:'Tryon'})
    }
    else{
      this.setState({currentFilter:selectedFilter.name})
    }
    this.setState({selectedFilter,ExperienceOpen:false,ExperienceOption:null,currentPage:1})
  };

  handleOperation = async (operation,experience) => {
    if(operation === 'Edit') this.setState({editRedirect:true,requestId:experience.id});
    else if(operation === 'Delete') {
      try {
        const {user,allExperiences} = this.state;
        if(user === null && user === '') {
          this.setState({text:'User not found, Redirecting to login page.'});
          setTimeout(()=>{
              this.setState({token_expired:true,loaderFlag:false});
          },1000);
        }
        
        const data = new FormData();
        data.append('user_id',user);
        data.append('ar_id',experience.id);
        const {data:res} = await deleteARExperience(data);
        if(res.status && isBoolean(res.status)) {
          const {data:del_id} = res;

          const experiences = allExperiences.filter((ex)=>{return (ex.id !== experience.id)});
          this.setState({ experiences ,allExperiences:experiences ,loaderFlag:false,closePopup:true});
          toast.success("Experience Deleted Successfully");
        } else if(res.status === 'Token is Expired') {
          this.setState({text:'User token expired, Redirecting to login page.'});
          setTimeout(()=>{
              this.setState({token_expired:true,loaderFlag:false});
          },1000);
        }
      } catch (ex) {
        let message = {};
        if (ex.response && ex.response.status === 400) {
          toast.error("Server Busy");
          message = ex.response.data.errors;
          this.setState({ message });
        } else if (ex.response && ex.response.status === 404) {
          toast.error("Server Down, Under maintenance.");
        } else {
          toast.error("Unexpected Error");
        }
      }
    }
  };

  render() {
    const { length: count } = this.state.experiences;
    const {
      pageSize,
      currentPage,
      selectedFilter,
      experiences:allExperiences,
      editRedirect,
      requestId,
      setSlug,
      token_expired,
      loaderFlag,
      searchByName
    } = this.state;

    const {searchArByName} = this;

    if(token_expired) return (<Navigate to='/logout' />);
    if (editRedirect) return (<Navigate to={'/edit/'+requestId} />);
    if (loaderFlag) return (<Loader text='Loading Experiences Data ...' />);
    

    // const myexp = [...allExperiences];
    // myexp.reverse();
    
    let filtered = selectedFilter && selectedFilter.id ? (
      (selectedFilter.id === 1 || selectedFilter.id === 4 || selectedFilter.id === 7 )
      ? allExperiences 
      : (
        selectedFilter.id === 6 ? allExperiences.reverse() : (
          (selectedFilter.id === 2 || selectedFilter.id === 5) ? 
          (
            allExperiences.filter((exp)=>{
              return selectedFilter.value === exp.status
            })
          ): allExperiences.filter(exp=>{
            if(selectedFilter.name === 'advertisement'){
              return exp.exp_type.exp_type === 'ads'
            }
            return exp.exp_type.exp_type === selectedFilter.name
          })
        )
      )
    ) : allExperiences;
    
    filtered = filtered.filter(d => {
      return d.exp_name.toLowerCase().includes(searchByName.toLowerCase())
    })

    const experiences = paginate(filtered, currentPage, pageSize);    
    const {length:exp_count} = experiences;
    
    return (
        <div className="Page-Width-Vossle">
          <p style={{textAlign:'center',fontSize:'30px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',color:'#053630',marginTop:'16px'}}>
            All Experiences 
          </p>
          <p style={{textAlign:'center',marginBottom:'20px',fontFamily:'Noto Sans',fontSize:'13px',color:'#343434'}}>
            Step into endless possibilities 
          </p>

          <Paper
              component="form"
              sx={{mx:'auto',mb:3, p: '2px 4px', display: 'flex', alignItems: 'center', width: 500,background:'#F1F3F5' ,border:'1px solid lightgrey',borderRadius:'10px',boxShadow:'none'}}
            >
              <IconButton type="button" sx={{ p: '10px',boxShadow:'0px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1}}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange = {searchArByName}
              />
          </Paper>

          {allExperiences.length !==0 ?(
            <React.Fragment>
              <Grid container sx={{display:'flex',alignItems:'center',justifyContent:'center',fontFamily:'Noto Sans', mb:6}}>  
                <Grid xs={1}></Grid>
                <Grid xs={1.5} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <Button
                        className={this.state.selectedFilter.action==="exp_type"?"selectfilterDesign":"filterDesign"}
                        id="fade-button"
                        aria-controls={this.state.ExperienceOpen ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={this.state.ExperienceOpen ? 'true' : undefined}
                        onClick={this.handleSortByExpOpen}
                        sx={{width:"120px",color:'#454747',borderRadius:"50px"}}
                    >
                      <Grid container>
                        <Grid xs={10} className="alignCenter" sx={{justifyContent:'flex-end'}} >
                          Experience
                        </Grid>
                        <Grid xs={2} className="alignCenter">
                        {this.state.ExperienceOpen ? <ArrowDropDownIcon />:<ArrowRightIcon />}
                        </Grid>
                      </Grid>
                    </Button>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                        'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={this.state.ExperienceOption}
                        open={this.state.ExperienceOpen}
                        onClose={()=>this.handleSortByExpClose(this.state.selectedFilter)}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={()=>this.handleSortByExpClose({id:8,name:'markerless',value:'Recent',action:'exp_type'})}>Markerless</MenuItem>
                        <MenuItem onClick={()=>this.handleSortByExpClose({id:9,name:'markerbased',value:'Deactive',action:'exp_type'})}>Markerbased</MenuItem>
                        <MenuItem onClick={()=>this.handleSortByExpClose({id:11,name:'games',value:'games',action:'exp_type'})}>Games</MenuItem>
                        <MenuItem onClick={()=>this.handleSortByExpClose({id:10,name:'facedetection',value:'Ascending',action:'exp_type'})}>Tryon</MenuItem>
                        <MenuItem onClick={()=>this.handleSortByExpClose({id:12,name:'workflow',value:'workflow',action:'exp_type'} )}>Workflow</MenuItem>
                        <MenuItem onClick={()=>this.handleSortByExpClose({id:13,name:'advertisement',value:'advertisement',action:'exp_type'})}>Advertisement</MenuItem>                        
                    </Menu>
                </Grid>

                <Grid xs={1.4} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <Button
                          className={this.state.selectedFilter.action==="all"?"selectfilterDesign":"filterDesign"}
                          id="fade-button"
                          aria-haspopup="true"
                          sx={{width:"110px",borderRadius:"50px",color:'#454747'}}   
                          onClick={()=>this.handleSortByExpClose({id:1,name:'All',value:'Active',action:'all'})}                                     
                        >
                      All
                    </Button>
                </Grid>
                
                <Grid xs={1.4} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <Button

                      id="fade-button"
                      aria-haspopup="true"
                      sx={{width:"110px",borderRadius:"50px",color:'#454747'}}
                      // className={this.state.arExperienceFilterType==="created_at"?"selectfilterDesign":"filterDesign"}
                      className={this.state.selectedFilter.action==="live"?"selectfilterDesign":"filterDesign"}
                          
                      onClick={()=>this.handleSortByExpClose({id:2,name:'Live',value:'Active',action:'live'})}
                  >
                    Live
                  </Button>
                </Grid>

                <Grid xs={1.4} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <Button
                    id="fade-button"
                    aria-haspopup="true"
                    sx={{width:"110px",borderRadius:"50px",color:'#454747'}}
                    // className={this.state.arExperienceFilterType==="created_at"?"selectfilterDesign":"filterDesign"}
                    className={this.state.selectedFilter.action==="recent"?"selectfilterDesign":"filterDesign"}
                      
                    // sx={{height:'30px',border:'0.1px solid #682CEA',color:(this.state.arExperienceFilterType==='creat'?'white':'#682CEA') ,background:(this.state.arExperienceFilterType==='created_at'?'#682CEA':'#F1F3F5'),borderRadius:'6px'}} 
                    onClick={()=>this.handleSortByExpClose({id:4,name:'Recent',value:'Recent',action:'recent'})}
                  >
                  Recent
                  </Button>
                </Grid>

                <Grid xs={1.4} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <div style={{color:'#1C7ED6',textDecoration:'underline',cursor:'pointer'}} onClick={()=>this.handleSortByExpClose({id:1,name:'All',value:'Active',action:'all'})}>
                    Clear Filter
                  </div>
                </Grid>
                
                <Grid xs={1}></Grid>
              </Grid>

              <Grid container xs={12} md={10} sx={{margin:'auto',fontSize:'24px',marginTop:"20px",mx:'auto'}}>
              {this.stringCapitalLetter(this.state.currentFilter)}
              </Grid>
              <Grid container >
                <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',margin:'auto'}}>
                  <TableDataCard 
                    data={experiences} 
                    {...this.props}
                    handleOperation={this.handleOperation}
                    setSlug={setSlug}
                    setNewSlug={this.setNewSlug}
                  />
                </Grid>
                <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}> 
                  <Pagination
                    itemsCount={filtered.length}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          ):(
            <NotFound text="No Experience Found"/>
          )
        }

        </div>
    )
  }

};

export default ArExperiences;