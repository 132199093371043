import React, { Component } from 'react'
import AllInputFields from '../Components/InputFields/AllInputFields'
import Box from '@mui/material/Box';


export default class ExperienceForm extends Component {
  render() {
    return (
      <Box className="Page-Width-Vossle">
        Hello from experience form
        <Box sx={{height:'300px',width:'1202px',margin:'auto'}}>
          <AllInputFields />
        </Box>
      </Box>
    )
  }
}
