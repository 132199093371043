import React from "react";
import _ from "lodash";
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Pagination, PaginationItem } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const Paginations = (props) => {
  const { itemsCount, pageSize, currentPage, onPageChange } = props;
  
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);
  
  const handleChange = (event, value) => {
    onPageChange(value);
  };

  return (
    <Stack spacing={2} sx={{display:'flex',alignItems:'flex-end'}}>
        <Pagination
          color='primary' variant="outlined" shape="rounded"
          count={pages.length}
          page={currentPage}
          renderItem={(experiences) => (
              <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...experiences}
              />
          )}
          onChange={handleChange}
        />
    </Stack>
  );
};


Paginations.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};


export default Paginations;
