import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {CardMedia,Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import logo from '../../assets/logo.png'
import { Button, CardActionArea, CardActions } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import StarRateIcon from '@mui/icons-material/StarRate';
import mouseImage from './../../assets/mouse.png';
const SubscriptionCard = (props) => {
  const {SubscriptionCardData,selectedPlan,payCheckData,upgrade,onPay} = props;

  console.log("myy subscription data is",SubscriptionCardData)

  return (
    <React.Fragment>
      {
        SubscriptionCardData && SubscriptionCardData.length === 0 ?
        (
          <>
            <Grid xs={12} sx={{fontSize:"26px",fontWeight:"medium",display:'flex',justifyContent:'center',alignItems:'center'}}>
                Oops, Subscription Plan not Found ..
            </Grid>
            <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img src={mouseImage} alt="Subscription Plan not found"/>
            </Grid>
          </>
        ) : (
            SubscriptionCardData.map((SubscriptionData)=> (
              <Grid lg={4} md={12} sm={12} sx={{padding:1}}>
                <Box  style={{p:1,minHeight:'489px'}}>
                  <Card sx={{boxShadow:'none',border:'1px solid lightgrey',Width: "100%",height: '575px' }}>
                    <CardActionArea style={{height:'100%'}}>
                      <CardContent >
                        <Grid container >
                          <Grid container xs={12}>
                              <Grid xs={11}>
                              <Typography sx={{fontSize:'16px',fontWeight:'560'}} >
                                {SubscriptionData.name}
                              </Typography>
                              </Grid>
                              <Grid xs={1}>
                                {SubscriptionData.id === selectedPlan && <StarRateIcon sx={{color:'#FFFF00'}} />}
                              </Grid>
                          
                          </Grid>
                          <Grid xs={12}>
                          <Typography sx={{fontSize:'14px'}}>    
                            {SubscriptionData.subtitle}
                          </Typography>
                          </Grid>
                          <Grid xs={12} sx={{height:'130px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                              <span>
                                <span style={{fontWeight:'540',color:'#682CEA',fontSize:'54px'}}>{SubscriptionData.amount}</span>
                                <span style={{color:'grey'}}>{SubscriptionData.perMonth}</span>
                              </span>
                              <span style={{color:'grey'}}>
                                {SubscriptionData.connect}
                              </span>
                          </Grid>
                        </Grid>
                        <Typography>{SubscriptionData.limit}</Typography>
                        <List
                          sx={{ width: '100%', maxWidth: 360, 
                          bgcolor: 'white' }}
                          aria-label="contacts"
                        >
                          {
                            SubscriptionData.features.map((featureName)=>{
                              return(
                                <ListItem sx={{padding:0,mb:'2px'}}>
                                  {/* <ListItemIcon> */}
                                    <CheckIcon fontSize="small"  />
                                  {/* </ListItemIcon> */}
                                  <Typography sx={{fontSize:'14px',ml:'4px',color:'grey'}}>{featureName}</Typography>
                                  {/* <ListItemText primary={featureName} sx={{fontSize:'10px !important',color:'grey'}}/> */}
                              </ListItem>
                              
                              )
                            })
                          }
                        
                        </List>
                      </CardContent>
                      <CardActions sx={{justifyContent:'center'}}>
                        { SubscriptionData.id === selectedPlan && (<Typography variant="h4">Current Plan</Typography>) }
                        { (upgrade.data === 'mail_sended' && SubscriptionData.id === 'enterprise_plan') && (<Typography variant="h4">Contacted</Typography>) }
                        { (SubscriptionData.id != selectedPlan && upgrade.data != 'mail_sended') && (
                          (selectedPlan !== 'basic_plan' && SubscriptionData.id === 'basic_plan')  ? <Typography variant="h4">Free Plan</Typography> : (<Button disabled={SubscriptionData.selectedPlan} onClick={() => onPay(SubscriptionData)} className="button1"  size="small" color="primary">
                            {SubscriptionData.submitButton}
                          </Button>)
                        )}
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Box>
              </Grid>
            ))
            )
      }
    </React.Fragment>
  );
}
 
export default SubscriptionCard;

// export default class SubscriptionCard extends Component {

//   constructor(props){
//     super(props)
//     // console.log("my wikiCard props",props.CardData);
//     this.state= {
//       SubscriptionData:props.SubscriptionData,
//       payCheckData:props.payCheckData,
//       selectedPlan:props.selectedPlan,
//       upgrade:props.upgrade,
//     }
//   }

//   render() {
//     const {SubscriptionData,payCheckData,selectedPlan,upgrade} = this.state;
//     const {onPay} = this.props;
//     return (
//       <Box  style={{p:1,minHeight:'489px'}}>
//         <Card sx={{boxShadow:'1px 1px 5px 0px',Width: "100%",height: '575px' }}>
//           <CardActionArea style={{height:'100%'}}>
//             <CardContent >
//               <Grid container >
//                 <Grid container xs={12}>
//                     <Grid xs={11}>
//                     <Typography sx={{fontSize:'16px',fontWeight:'560'}} >
//                       {SubscriptionData.name}
//                     </Typography>
//                     </Grid>
//                     <Grid xs={1}>
//                       {SubscriptionData.id === selectedPlan && <StarRateIcon sx={{color:'#FFFF00'}} />}
//                     </Grid>
                
//                 </Grid>
//                 <Grid xs={12}>
//                 <Typography sx={{fontSize:'14px'}}>    
//                   {SubscriptionData.subtitle}
//                 </Typography>
//                 </Grid>
//                 <Grid xs={12} sx={{height:'130px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
//                     <span>
//                       <span style={{fontWeight:'540',color:'#682CEA',fontSize:'54px'}}>{SubscriptionData.amount}</span>
//                       <span style={{color:'grey'}}>{SubscriptionData.perMonth}</span>
//                     </span>
//                     <span style={{color:'grey'}}>
//                       {SubscriptionData.connect}
//                     </span>
//                 </Grid>
//               </Grid>
//               <Typography>{SubscriptionData.limit}</Typography>
//               <List
//                 sx={{ width: '100%', maxWidth: 360, 
//                 bgcolor: 'white' }}
//                 aria-label="contacts"
//               >
//                 {
//                   SubscriptionData.features.map((featureName)=>{
//                     return(
//                       <ListItem sx={{padding:0,mb:'4px'}}>
//                         {/* <ListItemIcon> */}
//                           <CheckIcon fontSize="small"  />
//                         {/* </ListItemIcon> */}
//                         <Typography sx={{fontSize:'14px',ml:'4px',color:'grey'}}>{featureName}</Typography>
//                         {/* <ListItemText primary={featureName} sx={{fontSize:'10px !important',color:'grey'}}/> */}
//                     </ListItem>
                    
//                     )
//                   })
//                 }
              
//               </List>
 
//             </CardContent>
//             <CardActions sx={{justifyContent:'center',mt:4}}>
//               { SubscriptionData.id === selectedPlan && (<Typography variant="h4">Current Plan</Typography>) }
//               { (upgrade.data === 'mail_sended' && SubscriptionData.id === 'enterprise_plan') && (<Typography variant="h4">Contacted</Typography>) }
//               { (SubscriptionData.id != selectedPlan && upgrade.data != 'mail_sended') && <Button disabled={SubscriptionData.selectedPlan} onClick={() => onPay(SubscriptionData)} className="button1"  size="small" color="primary">
//                   {selectedPlan !== 'basic_plan' ? SubscriptionData.submitButton : 'Downgrade'}
//                 </Button>
//               }
//             </CardActions>
//           </CardActionArea>
//         </Card>
//       </Box>
//     )
//   }
// }
