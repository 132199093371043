import React, { Component } from 'react'
import { Typography,Grid} from '@mui/material';
import SubscriptionCard from '../Components/cards/SubscriptionCard';
import { toast } from 'react-toastify';
import Loader from '../Components/Common/Loader';
import { isBoolean } from 'lodash';
import vossleLogo from './../assets/logo.png';
import config from "./../Components/services/config.json";
import { Navigate } from 'react-router-dom';
import authService ,{getUserDetails} from '../Components/services/authService';
import { get_paid_plans, contact_enterprise, upgrade_payment, verify_payment } from './../Components/services/paymentService';

class Payment extends Component {
  constructor(props){
    super(props)
    this.state  = {
      tabValue:'1',
      SubscriptionCardData:[
        {
          id:'basic_plan',
          name:'Basic Plan',
          subtitle:'Current plan',
          amount:'FREE',
          perMonth:'',
          selectedPlan:true,
          connect:'',
          submitButton:'Current Plan',
          limit:'Experiences Limits',
          features:[
            'World Tracking Experiences',
            'Image Tracking Experiences',
            'Tryons',
            'Games in AR',
            'Green Screen Chroma Key Videos',
            'Cloud Editor + Hosting',
            'Cloud Unlimited AR Experiences',
            'Analytics Dashboard',
            '100 Views',
          ]
        },
        {
          id:'commercial_plan',
          name:'Commercial Plan',
          subtitle:'Recurring amount',
          amount:'$99',
          selectedPlan:false,
          connect:'',
          perMonth:'/per month',
          submitButton:'Upgrade',
          limit:'Experiences Limits',
          features:[
            'World Tracking Experiences',
            'Image Tracking Experiences',
            'Tryons',
            'Games in AR',
            'Green Screen Chroma Key Videos',
            'Cloud Editor + Hosting',
            'Cloud Unlimited AR Experiences',
            'Analytics Dashboard',
            '10000 views'
          ]
        },
        {
          id:'enterprise_plan',
          name:'Enterprise',
          subtitle:'Upgraded Plan',
          amount:'Contact',
          selectedPlan:false,
          perMonth:'',
          connect:'Support Team',
          submitButton:'Contact Us',
          limit:'Unlimited Experiences',
          features:[
            'World Tracking Experiences',
            'Image Tracking Experiences',
            'Tryons',
            'Games in AR',
            'Green Screen Chroma Key Videos',
            'Cloud Editor + Hosting',
            'Cloud Unlimited AR Experiences',
            'Analytics Dashboard',
            'Views based on package'
          ]
        }
      ],
      message:'',
      error:{},
      upgrade:{},
      payment_check:{},
      selectedPlan:'basic_plan',
      enterprise_contact:false,
      loaderFlag:true,
      token_expired:false,
      resData:{},
      user:''
    }
  }

  async componentDidMount() {
    const error = {};
    try {
      if(this.state.loaderFlag) {
        const user = await authService.getCurrentUser();
        if(user === null && user === '') {
          this.setState({text:'User not found, Redirecting to login page.'});
          setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
          },1000);
        }
        
        if(this.state.loaderFlag) {
          const {data:res} = await getUserDetails();
          if(res.status === true) {
            let {data:resData} = res;
            if(resData.payment_status === "Success") {
              const selectedPlan = resData.payment_status === "Success" ?'commercial_plan' : 'basic_plan';
              const payment_check = {status:true};
              this.setState({user,resData,payment_check,selectedPlan,loaderFlag:false});
            } else {
              const selectedPlan = 'basic_plan';
              const payment_check = {status:false};
              this.setState({user,resData,payment_check,selectedPlan,loaderFlag:false});
            }

            const {data:resPlans} = await get_paid_plans();
            if(isBoolean(resPlans.status) && resPlans.status) {
              const {data:plans} =  resPlans;
              let planAmount =  plans.find(plan => plan.type === 'paidAmount');
              let paidViews =  plans.find(plan => plan.type === 'paidViews');

              if(planAmount.hasOwnProperty('value')) {
                const cardData = this.state.SubscriptionCardData;
                cardData['1']['amount'] = `$${planAmount.value}`;
                cardData['1']['features'] = [
                  'World Tracking Experiences',
                  'Image Tracking Experiences',
                  'Tryons',
                  'Games in AR',
                  'Green Screen Chroma Key Videos',
                  'Cloud Editor + Hosting',
                  'Cloud Unlimited AR Experiences',
                  'Analytics Dashboard',
                  `${paidViews.value}`
                ];
                
                this.setState({SubscriptionCardData:cardData});
              }
            }
          } else if(res.status === 'Token is Expired') {
            let payment_check = {status:'user_token_expired'};
            this.setState({payment_check, text:'User token expired, Redirecting to login page.'});
            setTimeout(()=>{
              this.setState({token_expired:true,loaderFlag:false});
            },400)
          } else {
            let payment_check = {status:'not_found'};
            this.setState({ text:'Payment or User information not found.', payment_check });
          }
        } 
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
      } else if (ex.response && ex.response.status === 404) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
      } else {
        toast.error("Unexpected Error");
      }
    }
    this.setState({error});
  }

  handlePayment = async (pay_data) => {
    this.setState({loaderFlag:true});
    const error = {};
    try {

      const data = new FormData();
      data.append("user_id", this.state.user);
      data.append("payment_type", 'commercial_plan');

      const {data:payment_check } = await upgrade_payment(data);
      if(isBoolean(payment_check.status) && payment_check.status) {
        const selectedPlan = payment_check.message === 'already_available' ? 'commercial_plan' : 'basic_plan';
        
        if(pay_data.id === 'commercial_plan' && payment_check.message === 'process_payment' && payment_check.data.pay_check === 'new') {
          const res = await this.loadScript("https://checkout.razorpay.com/v1/checkout.js");
  
          if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
          }
  
          // Getting the order details back
          const options = {
            key: config.razorpayKey, // Enter the Key ID generated from the Dashboard
            subscription_id: payment_check.data.subscription.subs_id ?? null,
            name: "Vossle",
            description: "Purchase AR monthly Plan",
            image: vossleLogo,
            handler: async function (response) {
              const data = {
                subscriptionId: response.razorpay_subscription_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpaySignature: response.razorpay_signature,
              };
              const { data:upgrade } = await verify_payment(data);
              if(isBoolean(upgrade.status) && upgrade.status) {
                window.location = '/payment/verify/'+response.razorpay_payment_id;
              } else if(upgrade.status === 'Token is Expired') {
                let payment_check = {status:'user_token_expired'};
                error.global='User token is expired, Please check.';
                this.setState({ payment_check });
              } else {
                error.global='Not able to validate the payment.';
              }
            },
            // prefill: {
            //     name: "Soumya Dey",
            //     email: "SoumyaDey@example.com",
            //     contact: "9999999999",
            // },
            // notes: {
            //     address: "Queppelin Office",
            // },
            theme: {
              color: "#F37254",
            },
          }

          this.setState({payment_check,selectedPlan,loaderFlag:false});
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
          paymentObject.on('payment.failed', async function (response) {
            const {metadata} = response.error;
            const data = {
              subscriptionId: payment_check.data.subscription.subs_id,
              razorpayPaymentId: metadata.payment_id,
            };

            try {
              const { data:upgrade } = await verify_payment(data);
              if(isBoolean(upgrade.status) && upgrade.status) {
                window.location = '/payment/verify/'+metadata.payment_id;
              } else if(upgrade.status === 'Token is Expired') {
                let payment_check = {status:'user_token_expired'};
                error.global='User token is expired, Please check.';
                this.setState({ payment_check });
              } else {
                window.location = '/payment/verify/'+metadata.payment_id;
                error.global='Not able to validate the payment.';
              }
            } catch (err) {
              if (err.response && err.response.status === 400) {
                toast.error(err.message);
                if(err.response.data.message === "payment_failed") window.location = '/payment/verify/'+metadata.payment_id;
              } else if (err.response && err.response.status === 404) {
                toast.error(err.message);
              } else {
                toast.error("Unexpected Error");
              }
            }
          });
        } else if(pay_data.id == 'enterprise_plan') {
          const { data:upgrade } = await contact_enterprise(this.props.user);
          
          if(isBoolean(upgrade.status) && upgrade.status) {
            this.setState({ upgrade,contact_enterprise:true,loaderFlag:false });
          } else if(upgrade.status === 'Token is Expired') {
            let upgrade = {status:'user_token_expired'};
            this.setState({ upgrade, loaderFlag:false });
          } else {
            let upgrade = {status:'not_found'};
            this.setState({ upgrade, loaderFlag:false });
          }
        } else {
          error.global='Unknown Request';
        }
      } else if(payment_check.status === 'Token is Expired') {
        let payment_check = {status:'user_token_expired'};
        this.setState({ payment_check,token_expired:true });
      } else {
        let payment_check = {status:'not_found'};
        this.setState({ payment_check, loaderFlag:false });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
      } else if (ex.response && ex.response.status === 404) {
        toast.error(ex.message);
        error.global = ex.response.data.message ?? ex.message;
      } else {
        toast.error("Unexpected Error");
      }
    }
    this.setState({error});
  };

  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
          resolve(true);
      };
      script.onerror = () => {
          resolve(false);
      };
      document.body.appendChild(script);
    });
  }


  render() {
    const {SubscriptionCardData,payment_check,error,selectedPlan,upgrade,token_expired,message,loaderFlag} = this.state;
    
    if(token_expired) return (<Navigate to='/logout' />);
    
    if(loaderFlag) return (<Loader text='Loading Payment Information ...' />);
    else if(payment_check.status === 'user_token_expired') return (<Loader text='User token expired, Please login again...' />);
    
    return (
      <div className="Page-Width-Vossle">
        <div style={{margin:'auto',width:'80%'}}>
          <Grid container>
            <Grid xs={12} sx={{px:2}}>
              <p style={{fontSize:'20px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',color:'#053630'}}>
                Plan & Billings
              </p>
              <p style={{marginBottom:'20px',fontFamily:'Noto Sans',fontSize:'13px',color:'#343434'}}>
                Update your plan here
              </p>          
            </Grid>  
          </Grid>
          <div>
            <div style={{fontSize:'18px',fontWeight:'550'}}>
              <Grid container >
                  {error.hasOwnProperty('global') && (<Grid lg={12} md={12} sm={12} sx={{padding:1}}><Typography variant='h4' sx={{color:'red',mb:4}}>{error.global}</Typography></Grid>)}
                  {upgrade.hasOwnProperty('message') && (<Grid lg={12} md={12} sm={12} sx={{padding:1}}><Typography variant='h4' sx={{color:'green',mb:4}}>{upgrade.message}</Typography></Grid>)}
                  {message && (<Grid lg={12} md={12} sm={12} sx={{padding:1}}><Typography variant='h4' sx={{color:'green',mb:4}}>{message}</Typography></Grid>)}
                  <SubscriptionCard 
                    SubscriptionCardData={SubscriptionCardData}
                    onPay={this.handlePayment}
                    payCheckData={payment_check}
                    selectedPlan={selectedPlan} upgrade={upgrade}
                  />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Payment;