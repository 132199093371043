import * as React from 'react';

import dayjs from 'dayjs';
import { Grid, Typography } from '@mui/material';
import RenderButton from './InputFields/RenderButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DateRangeCalendars = (props) => {
    const {onSelect} = props;
    const [startDate, setFromDate] = React.useState(dayjs( new Date()).subtract(1, 'month') );
    const [endDate, setToDate] = React.useState(dayjs( new Date()) );

    const handleDates = () => {
        let d1 = Date.parse(startDate);
        let d2 = Date.parse(endDate);

        if(d1 > d2) console.log('wrong date');
        else {
            const bothDates = {
                firstDate : dayjs(startDate).format('YYYY-MM-DD'),
                secondDate : dayjs(endDate).format('YYYY-MM-DD'),
            };
            onSelect(bothDates);
        }
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid xs={6}>
                    <Typography style={{padding:"4px 0px 10px 0px",fontSize:'16px',fontWeight:"500", textAlign:'center'}}>
                        Start Date
                    </Typography>
                    <StaticDatePicker displayStaticWrapperAs="desktop" label='Select Start Date' orientation="portrait" value={startDate} onChange={(newValue) => setFromDate(newValue)} disableFuture={true} />
                </Grid>
                <Grid xs={6}>
                    <Typography style={{padding:"4px 0px 10px 0px",fontWeight:"500", textAlign:'center'}}>
                        End Date
                    </Typography>
                    <StaticDatePicker displayStaticWrapperAs="desktop" label='Select End Date' orientation="portrait" value={endDate} onChange={(newValue) => setFromDate(newValue)} disableFuture={true} />
                </Grid>
            </LocalizationProvider>
            <Grid xs={12} sx={{textAlign:'center'}}>
                <RenderButton
                    name = 'search_button'
                    type = "button"
                    label = 'Search'
                    isDisabled = {false}
                    minWidth = {300}
                    onClick={()=>handleDates()}
                />
            </Grid>
        </>
    );
}

export default DateRangeCalendars;