import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Step2Card from '../../Components/cards/Step2Card';
import "../../css/CreateExperiences.css"


const Step2 = (props) => {

  const {data,parentId,handleState} = props;

  return (
    <Box sx={{display:'flex',justifyContent:'center'}} >
        <Grid container spacing={1} sx={{marginTop:'10px',display:'flex',mx:'auto'}}>
        {
          data.map((card)=>{
            return( 
              <Grid xs={12} sm={6} md={4} lg={3} xl={3} sx={{mt:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <Step2Card data={card} 
                    changeStepState ={handleState}
                  // changeStepState={(selectedSubcategory)=>{this.changeStepState(selectedSubcategory);console.log("step2 stepper")}}
                   />
              </Grid>
            )
          }) 
        }
      </Grid>
    </Box>
  )
}
 
export default Step2;


// export default class Step2 extends Component {
  
//   constructor(props){
//    super(props)
//    this.state={
//     data:props.data,
//     parentId:props.parentId,
//     handleState:props.handleState,
//    }
//    console.log("==========================================> my current data is",props.data)
//   }

//   changeStepState = (selectedSubcategoryData) =>{
//     this.state.handleState(selectedSubcategoryData)
//   }

//   render() {
//     const data = this.state.data
//     console.log("in step2 page ",data)
//     return (
//       <Box sx={{display:'flex',justifyContent:'center'}} >
//           <Grid container spacing={1} sx={{marginTop:'10px',display:'flex',mx:'auto'}}>
//           {
//             data.map((card)=>{
//               return( 
//                 <Grid xs={12} sm={6} md={4} lg={3} xl={2} sx={{mt:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
//                     <Step2Card data={card} changeStepState={(selectedSubcategory)=>{this.changeStepState(selectedSubcategory);console.log("step2 stepper")}} />
//                 </Grid>
//               )
//             }) 
//           }
//         </Grid>
//       </Box>
//     )
//   }
// }
