import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const RenderSocialButton = ({name, type, label, isDisabled, minWidth, logo, ...rest}) => {
    const ColorButton = styled(Button)(({ theme }) => ({
        // color: theme.palette.getContrastText(),
        // color: 'white',
        // backgroundColor: orange['A400'],
        height: '50px',
        minWidth: `${minWidth}px`,
        boxShadow: 'none',
        borderRadius: '25px',
        '&:hover': {
        //   backgroundColor: orange['A700'],
          boxShadow: 'none',
        },
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
        textTransform: 'capitalize',
        fontFamily: 'Noto Sans',
        border: '1px solid lightgrey',
    }));
    
    return (
        <ColorButton
            {...rest}
            name={name}
            id={name}
            disabled={isDisabled}
            type={type}
        >
            <Grid xs={3} sx={{display:'flex', justifyContent:'flex-end'}}>
                <img style={{mr:2,height:"26px",}} src={logo} alt={name} />
            </Grid>
            <Grid xs={1}>
            </Grid>
            <Grid xs={8} sx={{display:'flex', justifyContent:'flex-start'}}>
                {label}
            </Grid>
        </ColorButton>
    );
}
 
export default RenderSocialButton;