import React from 'react';
import Box from '@mui/material/Box';
import { Route, redirect } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';

import '../../css/protectedRoutes.css';
import auth from '../services/authService';
import DrawerAndNavbar from './../nav-bar/DrawerAndNavbar';


const ProtectedRoutes = (props) => {
    const [drawerOpen,setDrawerOpen] = React.useState(true);

    const setDrawerValue = (value) => {
        setDrawerOpen(value);
    };

    return (
        auth.getCurrentUser() ? (
            <React.Fragment>
                <DrawerAndNavbar props={props}  setDrawerValue = {setDrawerValue} />
                <Box className={drawerOpen === true ? "drawerOpen" : "drawerClose"} sx={{flexGrow: 1, height:'calc(100vh-77px)', marginTop:'50px'}}>
                    <Outlet context={drawerOpen} />
                </Box>
            </React.Fragment>
        ) : <Navigate to='/login' />
    );
}
 
export default ProtectedRoutes;
