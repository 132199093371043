import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import config from '../services/config.json';

export default class Step2Card extends Component {
    constructor(props){
        super(props)
        this.state = {
            data:props.data,
            currentStep:props.currentStep,
            cardStyles : {
                background: "#f00 !important",
                '&:hover': {
                    background: "#f331 !important",
                }
            }
        }
    }

    handleStepState=()=>{
      console.log("Stepper working")
      this.props.changeStepState(this.props.data);
    }

    render() {
        const {data} = this.state;
        console.log("from step2 card data",data)
        return (
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}} onClick={this.handleStepState}>
            <Card className={this.state.cardStyles} elevation={0} sx={{
                background:'#F1F3F5',
                width: '214px',height: '237px', display:'flex', cursor:'pointer',
                '&:hover': {
                          boxShadow:6,
                          background:'#682CEA'
                        }}}>
                <Box sx={{
                    position:'absolute',
                    display:'flex',
                    justifyContent:"center",
                    my:'auto',
                    mx:'auto',
                    alignItems:'center',
                    width: '214px',
                    height: '237px'
                }}>
                  <CardMedia
                    component="img"
                    sx={{
                     height:'auto',
                     width:"auto",
                     maxWidth:"200px",
                     maxHeight:"200px"
                    }}
                    image={config.assetsEndpoint + data.count_color}
                  />
                </Box>
                <Box sx={{fontSize:'18px',display:'flex',alignItems:'end',fontWeight:'bold',zIndex:10,width:'100%',justifyContent:'center',pb:3}}>
                  {data.exp_name}
                </Box>
            </Card>
          </Box>
        )
      }
}
