import React, { Component } from 'react';
import auth from "../Components/services/authService";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { register } from '../Components/services/userService';
import Grid from '@mui/material/Grid';
import {Navigate} from "react-router-dom";
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import { Typography } from '@mui/material';
import {Link} from 'react-router-dom';
import vossleLogo from '../assets/logo.png';
import googleLogo from '../assets/googleLogo.png';
import facebookLogo from '../assets/facebookLogo.png';
import AllInputFields from './../Components/InputFields/AllInputFields';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'
import '../css/SignUp.css';
import config from '../Components/services/config.json';
import Info from '@mui/icons-material/Info';
import { isBoolean, isArray } from 'lodash';
import GetMessage from './../Components/Common/getMessage';
import {getSocialLink} from '../Components/services/userService';


class SignUp extends AllInputFields {
  constructor(props) {
    super(props)
    // this.qrcode = React.createRef();
    this.state = {
      data: {name:"",country_code:"+91",mobile:"",email:"",password:"",password_confirmation:"",role_id:2},
      errors:{},
      redirectToReferrer:false,
      user:{},
      message:{},
      socialLinks:{
      },
    }
  }

  validate = () => {
    const { data } = this.state;
    const errors = {};
    
    if(data.name === '') errors.name = 'User Name is required';
    if(data.mobile === '') errors.mobile = 'Mobile Number is required';
    else {
      const {mobile:value} = data;
      const regexMobile = /^\d{8}$/;

      if (value.trim() === "") errors.mobile = "Mobile Number is required";
      else if (value.length > 10) errors.mobile = "Mobile Number is greater than 10 digit";
      else if (value.length < 8) errors.mobile = "Mobile Number is less than 8 digit";
      // else if (!value.match(regexMobile)) errors.mobile = "Mobile Number is not right";
    }

    if(data.email === '') errors.email = 'Email is required';
    if(data.password === '') errors.password = 'Password is required';
    if(data.password_confirmation === '') errors.password_confirmation = 'Password Confirmation is required';

    if(data.password !== data.password_confirmation) errors.global =  'Password and confirm password did not matched.';  
    return Object.keys(errors).length === 0 ? null : errors;
  };

  async componentDidMount(){
    try{
      const {data:social} = await getSocialLink();
      if(social.status === true)
      {
          let socialLinks = {};
          const {data:links} = social;
          socialLinks.google = links.google;
          socialLinks.facebook = links.facebook;
          this.setState({socialLinks})
      }
      else console.log('Facing error in response data.');
    }
    catch(ex){
      if (ex.response && ex.response.status === 400) {
          toast.error("Server Busy");
          let message = ex.response.data.errors;
          this.setState({ message });
      } else if (ex.response && ex.response.status === 404) {
          toast.error("Server Down, Under maintenance.");
      } else {
          toast.error("Unexpected Error");
      }
    }
  }
  
  handleInputTel = (country_code) => {
    const data = { ...this.state.data };
    data['country_code'] = country_code;
    this.setState({ data });
  };


  handleSocialLogin = (user) => {
    console.log("my social login result is",user);
  };
  
  handleSocialLoginFailure = (err) => {
    console.error("my social login failure is",err);
  };


  doSubmit = async () => {
    document.getElementById("submit_button").disabled = true;
    document.getElementById("submit_button").innerHTML = "Processing...";
    try {
      const {data:res} = await register(this.state.data);
      if (res.status && isBoolean(res.status)) {
        toast.success("Verification mail sent, Please check your email.");
        const {data:user} = res;
        this.setState({ user });
        setTimeout(() => {
          this.setState({ redirectToReferrer: true });
        }, 2000);
      } else {
        toast.error(res.message);
        document.getElementById("submit_button").disabled = false;
      }
    } 
    catch (ex) 
    {
      let errors = {};
      let message = {};
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data.message);
        errors.global = ex.response.data.message && ex.response.data.message;
        message = ex.response.data.errors;
      } else if (ex.response && ex.response.status === 404) {
        errors.global = 'Server down, Under maintenance';
        toast.error("Server Down, Under maintenance.");
      } else {
        errors.global = 'Unexpected Error';
        toast.error("Unexpected Error");
      }
      this.setState({ errors,message });
      document.getElementById("submit_button").disabled = false;
      document.getElementById("submit_button").innerHTML = "Failed, Try Again";
    }
  };

  onLoginStart = () => {
    console.log("login starting");
  };
  
  render() {
    const { redirectToReferrer,errors,user,message,socialLinks } = this.state;
    
    if(auth.getCurrentUser()) {
      return <Navigate to="/" />;
    }

    if (redirectToReferrer === true) {
      return this.props.navigate('/confirmation',{state:{user:user}});
    }
    
    return (
      <Box className="Page-Width-Vossle">
        <Grid container spacing={0}>
          <Grid xl={4} lg={4} md={4} sm={12} xs={12} sx={{m:"auto",mt:4,textAlign:'center'}}>
            <Typography color='#682cea' sx={{display:'flex',justifyContent:'center',alignItems:'center',fontWeight:"600", fontSize: '30px',fontFamily: `Poppins, sans-serif`}} >
                <p>Welcome to</p>
                <span style={{display:'flex',justifyContent:'center',margin:"2px"}}><img  src={vossleLogo} height='36px' alt='vossle-logo'/> </span> 
            </Typography>  
            <Typography sx={{mb:'40px',fontSize:'14px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                Faster and easier AR platform
            </Typography>                       
            <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                <Grid xs={12}  sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                  {
                    socialLinks.google
                        &&
                    <Button container variant='content' sx={{display:'flex',justifyCotntent:'flex-start',mb:1,height:"45px ",textAlign:'left',minWidth:"340px",border:"1px solid lightgrey",borderRadius:'50px'}} onClick={()=>window.location=socialLinks.google } >
                        <Grid xs={3} sx={{display:'flex',justifyContent:'flex-end'}}>
                        <img style={{mr:2,height:"26px",}} src={googleLogo} alt="Google sign" />
                        </Grid>
                        <Grid xs={1}>

                        </Grid>
                        <Grid xs={8} sx={{display:'flex',justifyContent:'flex-start'}}>
                            <Typography sx={{fontSize:'16px',textTransform:'capitalize',fontFamily:'Noto Sans'}}>Login with Google</Typography>
                        </Grid>

                    </Button>
                  }
                </Grid>
                <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    { 
                      socialLinks.facebook 
                          &&
                      <Button sx={{border:"1px solid #0d6efd",background:'#0d6efd !important',color:"white",fontFamily:'notosans !important',mb:1,height:"45px !important",minWidth:"340px",borderRadius:'50px'}} onClick={()=>window.location=socialLinks.facebook} >
                          
                          <Grid xs={3} sx={{display:'flex',justifyContent:'flex-end'}}>
                              <img style={{mr:2,height:"26px"}} src={facebookLogo} alt="Google sign" />
                          </Grid>
                          <Grid xs={1}>

                          </Grid>
                          <Grid xs={8} sx={{display:'flex',justifyContent:'flex-start'}}>
                              <Typography sx={{fontSize:'16px',textTransform:'capitalize',fontFamily:'Noto Sans'}}>Login with Facebook</Typography>
                          </Grid>

                          
                      </Button>
                    }
                </Grid>
            </Grid>
            
            <Divider sx={{maxWidth:'300px',mx:'auto',mb:'20px'}} >or </Divider>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={this.handleSubmit}
              sx={{maxWidth:"340px",mx:'auto'}}
            >
              {errors.global &&(<Grid xs={12}><Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors.global}</Typography></Grid>)}
              <Grid xs={12} sx={{textAlign:'center',m:'auto',p:'auto'}}>
                <GetMessage message={message} />
              </Grid>
              {this.renderInput("name", "text", "Name", true)}
              <Grid container>
                <Grid sx={{display:'flex',minHeight:"48px",width:"340px"}}>
                  <PhoneInput
                    country={"in"}
                    name="country_code"
                    id="country_code"
                    containerStyle={{
                      border: "1px solid grey",
                      borderRadius:'10px',
                      width:"65px",marginRight:'5px',
                      height:"48px"
                    }}
                    
                    value={this.state.data.country_code}
                    onChange={country_code => this.handleInputTel(country_code)}
                  />
                  <div style={{width:"384px"}}>
                    {this.renderInput("mobile", "text", "Mobile Number", true)}
                  </div>
                </Grid>
              </Grid>
              {this.renderInput("email", "email", "Email", true)}
              {this.passwordInputField("password", "password", "Password", true)}
              {this.passwordInputField("password_confirmation", "password", "Password Confirmation", true)}
              <Button 
                container variant='content' sx={{mx:'auto',mb:1,color:'white',textTransform:'capitalize',fontSize:'16px',
                height:"48px ",minWidth:"340px",border:"1px solid #ff821c",background:'#ff821c',fontFamily:'Noto Sans',borderRadius:'50px','&:hover': {background:'#ff821c'} }} type="submit" id="submit_button" >
                Submit
              </Button>
            </Box>

            <Typography sx={{fontSize:'14px',color:"#454547",m:1}}>
              Already a member?
            <Link  to="/login" style={{color:'#ff821c',margin:"0px 4px 0px 4px"}}>Login</Link>            
            </Typography>
                            
            <Typography variant="subtitle2" sx={{color:'#B9B9B8',mx:'auto',textAlign:'center',fontSize:'12px',maxWidth:"350px"}}>
                Creating an account means you're okay with our <a href="https://vossle.com/terms-and-conditions/" target="_blank" style={{color:"#3987FC"}}> Terms of Service, Private Policy </a> and our default <a href="https://vossle.com/privacy-policy/" target="_blank" style={{color:"#3987FC"}}>  Notification Settings <a href="https://vossle.com/privacy-policy/" target="_blank"></a> </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }
};

export default SignUp;
