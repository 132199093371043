import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import config from '../services/config.json';
import { isNull } from 'lodash';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const getFullUrl = assets => {
  return config.assetsEndpoint + assets.assets;
};

const Tips = [
  {
    id : 13,
    types:[],
    blender_files: [
      {point:'',assets:'blender_files/Vossle_Wrist_Tryon.blend'},
    ],
    property:'content',
    title : 'Watch Tryon',
    pointers : [
      {statement : 'Download and setup the provided blender file.'},
      {statement : 'The placeholders are displayed in red and the occluder in white.'},
      {statement : 'Import your own mesh and then scale, rotate and translate it to match the desired position.'},
      {statement : 'Once sure of the positioning, select your product and go to Object>>Apply>>All Transforms.'},
      {statement : 'Select your product (no need to export the base mesh) and go to File>>Export>>Gltf2.0.'},
      {statement : 'Make sure to match your export settings to this pointers below.'},
      {statement : 'Export Settings:',
        substatements : [
          'Format - glTF Binary (.glb)',
          '✅Remember Export Settings ',
          '✅Include>>Selected Objects',
          '✅Transform>>+Y Up',
          '✅Mesh>>UVs',
          '✅Mesh>>Normals',
          '✅Mesh>>Vertex Colors',
          '✅PBR Extensions>>Export Original PBR Specular',
          '✅Animation>>Limit Playback Range',
          '✅Animation>>Always Sample Animations',
          '❌Animation>>Group by NLA Track (Make sure to check off, very important)',
        ],
      },
      {statement : 'Import your content/GLB file on Vossle.'},
      {statement : 'Give a name to your experience.'},
      {statement : 'Fill in other details (if applicable).'},
      {statement : 'Submit.'},
    ],
  },
  {
    id : 14,
    types:['head','neck','eye','ear'],
    blender_files: [
      {point:'place_head',assets:'blender_files/Vossle_Face_tryon_on_head.blend'},
      {point:'above_head',assets:'blender_files/Vossle_Face_tryon_above_head.blend'},
      {point:'neck',assets:'blender_files/Vossle_Face_tryon_neck.blend'},
      {point:'eye',assets:'blender_files/Vossle_Face_tryon_eye.blend'},
      {point:'ear',assets:'blender_files/Vossle_Face_tryon_ear.blend'},
    ],
    property:'content',
    title : 'Face Detection',
    pointers : [
      {statement : 'Download and setup the provided blender file.'},
      {statement : 'Add your product to the blender scene and place/adjust according to the base mesh provided.'},
      {statement : 'Once sure of the positioning, select your product and go to Object>>Apply>>All Transforms.'},
      {statement : 'Select your product (no need to export the base mesh) and go to File>>Export>>Gltf2.0.'},
      {statement : 'Make sure to match your export settings to this pointers below.'},
      {statement : 'Export Settings:',
        substatements : [
          'Format - glTF Binary (.glb)',
          '✅Remember Export Settings ',
          '✅Include>>Selected Objects',
          '✅Transform>>+Y Up',
          '✅Mesh>>UVs',
          '✅Mesh>>Normals',
          '✅Mesh>>Vertex Colors',
          '✅PBR Extensions>>Export Original PBR Specular',
          '✅Animation>>Limit Playback Range',
          '✅Animation>>Always Sample Animations',
          '❌Animation>>Group by NLA Track (Make sure to check off, very important)',
        ],
      },
      {statement : 'Import your content/GLB file on Vossle.'},
      {statement : 'Give a name to your experience.'},
      {statement : 'Fill in other details (if applicable).'},
      {statement : 'Submit.'},
    ],
  },
  {
    id : 23,
    types:['head','neck','eye','ear'],
    blender_files: [
      {point:'place_head',assets:'blender_files/Vossle_Face_tryon_on_head.blend'},
      {point:'above_head',assets:'blender_files/Vossle_Face_tryon_above_head.blend'},
      {point:'neck',assets:'blender_files/Vossle_Face_tryon_neck.blend'},
      {point:'eye',assets:'blender_files/Vossle_Face_tryon_eye.blend'},
      {point:'ear',assets:'blender_files/Vossle_Face_tryon_ear.blend'},
    ],
    property:'content',
    title : 'Face Detection',
    pointers : [
      {statement : 'Download and setup the provided blender file.'},
      {statement : 'Add your product to the blender scene and place/adjust according to the base mesh provided.'},
      {statement : 'Once sure of the positioning, select your product and go to Object>>Apply>>All Transforms.'},
      {statement : 'Select your product (no need to export the base mesh) and go to File>>Export>>Gltf2.0.'},
      {statement : 'Make sure to match your export settings to this pointers below.'},
      {statement : 'Export Settings:',
        substatements : [
          'Format - glTF Binary (.glb)',
          '✅Remember Export Settings ',
          '✅Include>>Selected Objects',
          '✅Transform>>+Y Up',
          '✅Mesh>>UVs',
          '✅Mesh>>Normals',
          '✅Mesh>>Vertex Colors',
          '✅PBR Extensions>>Export Original PBR Specular',
          '✅Animation>>Limit Playback Range',
          '✅Animation>>Always Sample Animations',
          '❌Animation>>Group by NLA Track (Make sure to check off, very important)',
        ],
      },
      {statement : 'Import your content/GLB file on Vossle.'},
      {statement : 'Give a name to your experience.'},
      {statement : 'Fill in other details (if applicable).'},
      {statement : 'Submit.'},
    ],
  },
  {
    id : 15,
    types:[],
    property:'content',
    title : 'Ring Tryon',
    blender_files: [
      {point:'',assets:'blender_files/Vossle_Wrist_Tryon.blend'},
    ],
    pointers : [
      {statement : 'Download and setup the provided blender file.'},
      {statement : 'The placeholders are displayed in red and the occluder in white.'},
      {statement : 'Import your own mesh and then scale, rotate and translate it to match the desired position.'},
      {statement : 'Once sure of the positioning, select your product and go to Object>>Apply>>All Transforms.'},
      {statement : 'Select your product (no need to export the base mesh) and go to File>>Export>>Gltf2.0.'},
      {statement : 'Make sure to match your export settings to this pointers below.'},
      {statement : 'Export Settings:',
        substatements : [
          'Format - glTF Binary (.glb)',
          '✅Remember Export Settings ',
          '✅Include>>Selected Objects',
          '✅Transform>>+Y Up',
          '✅Mesh>>UVs',
          '✅Mesh>>Normals',
          '✅Mesh>>Vertex Colors',
          '✅PBR Extensions>>Export Original PBR Specular',
          '✅Animation>>Limit Playback Range',
          '✅Animation>>Always Sample Animations',
          '❌Animation>>Group by NLA Track (Make sure to check off, very important)',
        ],
      },
      {statement : 'Import your content/GLB file on Vossle.'},
      {statement : 'Give a name to your experience.'},
      {statement : 'Fill in other details (if applicable).'},
      {statement : 'Submit.'},
    ],
  },
  {
    id : 26,
    types:[],
    property:'content',
    title : 'Palm Tryon',
    pointers : [
      {statement : 'The Supported formats for the content file are - .png, .jpg, .jpeg and make sure the image has no borders to ensure the right placement.'},
      {statement : 'The size limit for the content file is 5mb make sure the image is below the prescribed limit for a better experience.'},
    ],
  },
  {
    id : 27,
    types:[],
    property:'content',
    title : 'Shoe Tryon',
    blender_files: [
      {point:'',assets:'blender_files/Vossle_Shoe_Tryon.blend'},
    ],
    pointers : [
      {statement : 'Download and setup the provided blender file.'},
      {statement : 'Add your product to the blender scene and place / adjust according to the base mesh provided.'},
      {statement : 'Once sure of the positioning, select your product and go to Object>>Apply>>All Transforms.'},
      {statement : 'Select your product (no need to export the base mesh) and go to File>>Export>>Gltf2.0.'},
      {statement : 'Make sure to match your export settings to this pointers below.'},
      {statement : 'Export Settings:',
        substatements : [
          'Format - glTF Binary (.glb)',
          '✅Remember Export Settings ',
          '✅Include>>Selected Objects',
          '✅Transform>>+Y Up',
          '✅Mesh>>UVs',
          '✅Mesh>>Normals',
          '✅Mesh>>Vertex Colors',
          '✅PBR Extensions>>Export Original PBR Specular',
          '✅Animation>>Limit Playback Range',
          '✅Animation>>Always Sample Animations',
          '❌Animation>>Group by NLA Track (Make sure to check off, very important)',
        ],
      },
      {statement : 'Import your content/GLB file on Vossle.'},
      {statement : 'Give a name to your experience.'},
      {statement : 'Fill in other details (if applicable).'},
      {statement : 'Submit.'},
    ],
  },
  {
    id : 14,
    types:['live_face_morph'],
    property:'content',
    title : 'Face Detection',
    pointers : [
      {statement : 'Download the provided Blender file for the face try-on project. Open Blender and load the file.'},
      {statement : 'Add and Position Head Model - Import the head model you want to use for the try-on. Adjust the position of the imported model to match the base head model in the provided Blender file.'},
      {statement : 'Apply All Transforms - Select your imported head model and navigate to "Object" in the menu. Choose "Apply" and then "All Transforms" to apply any transformations and ensure the model\'s transformation is finalized.'},
      {statement : 'Create Shape Keys - Shape keys, also known as blend shapes, allow you to morph the geometry of a 3D model. Create the required shape keys for your head model. The provided base model has 52 default shape keys,but you can add as many shape keys as needed to achieve your desired effects.'},
      {statement : 'Export as GLTF 2.0:'},
      {statement : 'Once you have set up your shape keys, select your model (not the base model) in Blender. Navigate to "File," then choose "Export," and finally, select "GLTF 2.0" as the export format.'},
      {statement : 'Adjust Export Settings - Configure your GLTF 2.0 export settings to match these recommended settings:',
        substatements : [
          'Format - glTF Binary (.glb)',
          '✅Remember Export Settings ',
          '✅Include>>Selected Objects',
          '✅Transform>>+Y Up',
          '✅Mesh>>UVs',
          '✅Mesh>>Normals',
          '✅Mesh>>Vertex Colors',
          '✅PBR Extensions>>Export Original PBR Specular',
          '✅Animation>>Limit Playback Range',
          '✅Animation>>Always Sample Animations',
          '❌Animation>>Group by NLA Track (Make sure to check off, very important)',
        ],
      },
      {statement : 'Give a name to your experience.'},
      {statement : 'Fill in other details (if applicable).'},
      {statement : 'Submit.'},
    ],
  },
  {
    id : 23,
    types:['live_face_morph'],
    property:'content',
    title : 'Face Detection',
    pointers : [
      {statement : 'Download the provided Blender file for the face try-on project. Open Blender and load the file.'},
      {statement : 'Add and Position Head Model - Import the head model you want to use for the try-on. Adjust the position of the imported model to match the base head model in the provided Blender file.'},
      {statement : 'Apply All Transforms - Select your imported head model and navigate to "Object" in the menu. Choose "Apply" and then "All Transforms" to apply any transformations and ensure the model\'s transformation is finalized.'},
      {statement : 'Create Shape Keys - Shape keys, also known as blend shapes, allow you to morph the geometry of a 3D model. Create the required shape keys for your head model. The provided base model has 52 default shape keys,but you can add as many shape keys as needed to achieve your desired effects.'},
      {statement : 'Export as GLTF 2.0:'},
      {statement : 'Once you have set up your shape keys, select your model (not the base model) in Blender. Navigate to "File," then choose "Export," and finally, select "GLTF 2.0" as the export format.'},
      {statement : 'Adjust Export Settings - Configure your GLTF 2.0 export settings to match these recommended settings:',
        substatements : [
          'Format - glTF Binary (.glb)',
          '✅Remember Export Settings ',
          '✅Include>>Selected Objects',
          '✅Transform>>+Y Up',
          '✅Mesh>>UVs',
          '✅Mesh>>Normals',
          '✅Mesh>>Vertex Colors',
          '✅PBR Extensions>>Export Original PBR Specular',
          '✅Animation>>Limit Playback Range',
          '✅Animation>>Always Sample Animations',
          '❌Animation>>Group by NLA Track (Make sure to check off, very important)',
        ],
      },
      {statement : 'Give a name to your experience.'},
      {statement : 'Fill in other details (if applicable).'},
      {statement : 'Submit.'},
    ],
  },
];

const ToolTips = (props) => {
  const {scId,type,subtype} = props;
  console.log('Detection Type',type,subtype);

  // const tool_tips = Tips.find(tip => tip.id === scId && tip.type.length > 0 ? tip.types.find(type => type === type));
  const tool_tips = Tips.find(tip => tip.id === scId && (tip.types.length > 0 ? tip.types.includes(type) : true));
  console.log('ToolTips',tool_tips);
  
  const blender_files = () => {
    if(typeof tool_tips === 'undefined') return false;
    if(!tool_tips.hasOwnProperty('blender_files')) return false;
    const {blender_files} = tool_tips;
    console.log('Start Blend',blender_files);
    
    var newtype = '';
    if((scId === 14 || scId === 23) && type === 'head') {
      newtype = typeof subtype === 'undefined' || isNull(subtype) ? "place_head" : subtype;
    } else newtype = type;
    console.log('New Point Type', newtype);
    const asset = blender_files.find(file => file.point ? file.point === newtype : true);
    console.log('Blender Files',asset)
    return asset;
  }
  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const blender_file_url = blender_files();
  console.log('Blender File URL',blender_file_url);

  return (
    <>
      {tool_tips ? (
          <div style={{textAlign:'right', paddingRight:'15px'}}>
            {blender_file_url && (<a href={getFullUrl(blender_file_url)} target="_blank" rel="noreferrer">
            <IconButton
              aria-label="close"
              // onClick={getFullUrl(blender_file_url)}
              sx={{
                // position: 'absolute',
                // right: 8,
                // top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloudDownloadIcon />
            </IconButton></a>)}
            <IconButton
              aria-label="close"
              onClick={handleClickOpen}
              sx={{
                // position: 'absolute',
                // right: 8,
                // top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <InfoIcon />
            </IconButton>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                {tool_tips.hasOwnProperty('title') && tool_tips.title}
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <Box sx={{mx:3,my:1}}>
                  <ol type="1">
                  {
                    tool_tips.hasOwnProperty('pointers') && tool_tips.pointers.map(
                      (tool_tip) => (
                        <li>
                          <Typography gutterBottom>
                            {tool_tip.statement}
                          </Typography>
                          {tool_tip.substatements && 
                            (
                              <ol type="a">
                                {tool_tip.substatements.map(substatement => (<li style={{marginLeft:'20px'}}>{substatement}</li>))}
                              </ol>
                            )
                          }
                        </li>
                      )
                    )
                  }
                  </ol>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  {/* <CloseIcon /> */}
                  Close
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </div>
        ) : (
          <></>
        )
      }
    </>
  );
};
 
export default ToolTips;