import http from "./httpServices";
import config from "./config.json";
import jwtDecode from "jwt-decode";

const apiEndpoint = config.apiEndpoint;
const tokenKey = "token";

http.setJwt(getJWT());

export async function login(FormData) {
  const { data: resp } = await http.post(apiEndpoint + "login", FormData);
  const { token: jwt } = resp;
  // console.log(jwt);
  localStorage.setItem(tokenKey, jwt);
}

export function logout(){
  localStorage.removeItem(tokenKey);
}

export function getUserDetails() {
  return http.get(apiEndpoint+"user");
}

export function setUserDetails(formData) {
  return http.post(apiEndpoint+"update-user-profile",formData);
}

export function generateKey(formdata) {
  return http.post(apiEndpoint+"generate-api-key",formdata);
}

export function getCurrentUser(){
  try {
    const jwt = localStorage.getItem(tokenKey);
    const { sub } = jwtDecode(jwt);
    // const user_clone = {
    //   iat: iat,
    //   exp: exp,
    //   nbf: nbf,
    //   jti: jti,
    //   sub: sub,
    //   prv: prv,
    // };
    // const {data} = await http.get(apiEndpoint+"user");
    // console.log(data.user);
    // if(data.user.id){
    //   let user =  {
    //     id : data.user.id,
    //     name : data.user.name,
    //     mobile : data.user.mobile,
    //   };
    // }
    if(sub){
      return sub;
    }else {
      return null;
    }
  } catch (ex) {
    return null;
  }
}

// export async function getUserDetails(){
//   try {
//     const jwt = localStorage.getItem(tokenKey);
//     console.log('Jwt : ',jwt);
   
//     const {data} = await http.get(apiEndpoint+"user");
//     console.log("=>",data.user);
//     if(data.user.id){
//       let user =  {
//         id : data.user.id,
//         name : data.user.name,
//         mobile : data.user.mobile,
//         email: data.user.email,
//         wordpress_key:data.user.wordpress_key,
//         shopify_key:data.user.shopify_key,
//         magento_key:data.user.magento_key
//       };
//       return user;
//     }
//     return {'error':'not_found'}
//   } catch (ex) {
//     return null;
//   }
// }

export function getJWT(){
  const jwtToken = localStorage.getItem(tokenKey);
  // console.log(jwtToken);
  return 'Bearer '+jwtToken;
}



export default {
  login, logout, getCurrentUser, getJWT
}
