import React, { Component } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { Modal, Box, Button, Grid } from '@mui/material';
import { createTheme,ThemeProvider } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { isBoolean } from 'lodash';
import Step4 from './createExperience/Step4';
import AllInputFields from '../Components/InputFields/AllInputFields';
import ExperiencesStepper from '../Components/Stepper/ExperiencesStepper';
import {getCategories, getCollectionById} from '../../src/Components/services/collectionService';
import { getCategoriesExpBased, getCollectionByIdExpBased} from '../../src/Components/services/collectionService';
import { Navigate } from 'react-router-dom';
// CSS and Images
import "../css/CreateExperiences.css";
import VossleLogo from '../assets/logo.png';
// New Added Services
import { checkExpName, editExperience ,getExperienceSubCategory} from './../Components/services/arService';
import { getExperienceByArId } from './../Components/services/collectionService';

import { toast } from "react-toastify";
import config from './../Components/services/config.json';
import Info from '@mui/icons-material/Info';
// import CreateWorkFlow from '../Components/WorkFlow/CreateWorkFlow';
import withRouter from './../Components/services/withRouter';
import Loader from './../Components/Common/Loader';
import UploadGlbDialog from './../Components/Common/UploadGlbDialog';
import RenderButton from './../Components/InputFields/RenderButton';
import Checkbox from '@mui/material/Checkbox';

class EditExperiences extends AllInputFields {
    
  constructor(props){
    super(props)
    this.state = {

      openAiSelectedDialogData:{
        name:'',
        type:'',
      },
      loadingOpenAiInfo:false,
      openAiDialogOpen:false,
      isButtonDisabled:true,
      phase:1,
      token_expired:false,
      UploadGlbCurrentPage:1,
      isChildLoading:false,
      selectedGLB:{},
      selectedArExptype:{},
      lipstick_with_textures:[''],
      selectFileFromGallery:'',
      selectFileFromGalleryType:'',
      allExperiences:[],
      fullScreenDialog:false,
      openDialog:false,
      data:{
        use_content_audio:false,
        openAiGeneratedText:'',
        openAiGeneratedImage:'',
        exp_name:'',
        slug_exp_name:'',
        label:'',
        website:'',
        s:1,
        detection_point:'',
        redirect_to_website:false,
        redirection_time:10,
        ga_code_exp:'',
        enable_geo_location:false,
        latitude:'',
        longitude:'',
        distance:'',
        text_to_display:'',
        bullet_frq:0.5,
        game_duration:30,
        feedbackForm_game:'',
        image_speed:0.5,
        // background_colour:'#FF0000',
        background_ImageVsColor:'FALSE'
      },
      editData:{},
      editFiles:{},
      loaderFlag:false,
      errors:{global:''},
      isQRCode:false,
      files:{},
      qrcode:'',
      ArrayUploadData:[{ImageName:'imagename',ImageType:'text',FileName:'face_game',FileType:'image'}],
      SingleArrayUploadFileData:[{FileName:'content',FileType:'glb'}],
      currentStepper:{'currentStep':0,'totalSteps':3},
      allCategory: [],
      theme:createTheme({
        palette:{
          primary:{
            main:'#682CEA',
            white:'#FFFFFF',
            darker:'#682CEA'
          }
        },
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em'
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
          }
        }
      }),
      currentStep:3,
      expTypes:[],
      selectedExpType:{},
      selectedExpTypeSubcategory:{},
      step2Data:[],
      Ads:
      [
          {
            count_color: "v2-assets/lipsticktryon.png",
            created_at: null,
            exp_name :"Face Filter",
            exp_type : "face_filter",
            id : 17,
            parent_id : 16,
            status : "Active",
            tab_color : null,
            updated_at : null
          },
          {
            count_color: "v2-assets/lipsticktryon.png",
            created_at: null,
            exp_name :"World Mapping",
            exp_type : "world_mapping",
            id : 18,
            parent_id : 16,
            status : "Active",
            tab_color : null,
            updated_at : null
          },
          {
            count_color: "v2-assets/lipsticktryon.png",
            created_at: null,
            exp_name :"3D Ads",
            exp_type : "3d_ads",
            id : 19,
            parent_id : 16,
            status : "Active",
            tab_color : null,
            updated_at : null
          }
      ],
      step4Data:{
        'url':'',
        'qrcode':'',
        'marker_image':''
      },
      setShow:false,
      downloadProgress:0,
      showUrl:false,
      showUrlMessage:'',
      globalObj : {
        dpi: 0,
        nc: 0,
        w: 0,
        h: 0,
        arr: []
      },
      nameWithExt:'',
      nameWOExt:'',
      arExp:{},
      onEdit:false,
    }
  }

  async componentDidMount() {
    try{
      let id = this.props.params.id;
      const {data:exp_data} =  await getExperienceByArId(id);
      if(exp_data.status && isBoolean(exp_data.status))
      {  
        const {data} = exp_data;  
        const filesType = {...this.state.filesType};
        const files = {...this.state.Files}
        const uploadData = [];
        let i=1;
        let use_content_audio = false;
        data['enable_splash'] ='FALSE';

        let lipstick_with_textures = [];

        data.assets.forEach(asset=>{
          
          if(asset.type==='content' && asset.transparent_background === 'Yes'){
            data['transparent_background'] = 'Yes';
            data['background_color'] = this.rgbToHex(asset.background_color);
            if(data['background_color'].includes('.')){
              data['background_color'] = data['background_color'].split('.')[0];
            }
            console.log("my color in rgb form is",data['background_color']);
          }
          if( (asset.light_range == null || asset.light_range === "1" || asset.light_range === "0") && (asset.type.includes('lipstick_colour')))
          {
            data[asset.type] = asset.assets;
            let textureName = 'texture_' + asset.type.split('_').pop();
            lipstick_with_textures.push('');
            console.log("lighting range ",asset.light_range)
            data[textureName] = (asset.light_range==='1'?'matte':'glossy')
            // console.log("my textutre is",data['texture_1'])
          }
          if(asset.type === "splash_screen"){
            data['enable_splash'] = 'TRUE';
          }     
          if(asset.type === "overlay_image"){
            data['repeat_overlay'] = asset.action;
          }     
          if(asset.type  === 'target_audio'){
            files['sound'] = config.assetsEndpoint+asset.assets
            filesType['sound'] = 'mp3'
          }
        
          if(!files[asset.type])
          {
            // this.setState({[asset.type]:config.assetsEndpoint + asset.assets})
            files[asset.type] = config.assetsEndpoint + asset.assets;
            filesType[asset.type] = asset.assets.split('.').pop().toLowerCase();

            // data['background_ImageVsColor'] = 'FALSE'
            console.log("my asset type is",asset.type)
            if(asset.type === 'background_colour')
            {
                data['background_colour'] = asset.assets;
                data['background_ImageVsColor'] = 'TRUE'; 
                console.log("my value is set of not",asset.type,data)
            }
            else if(asset.type === 'background_image'){
              data['background_ImageVsColor'] = 'FALSE'; 
            }
            
            if(asset.action && asset.type != 'background_colour'){
              data[asset.type + "_name"] = asset.action; 
            }

            // if(asset.type === 'lipstick_colour_0'){
            //   data['lipstick_colour_0'] = asset.assets
            //   data['texture_1'] = asset.light_range === '1' ?'matte':'glossy'; 
            // }

           
            if(asset.type == 'face_game_'+ i){
              console.log("please check execution of face-game",asset.type)
              uploadData.push({ImageName:'face_game_'+i+"_name",ImageType:'text',FileName:asset.type,FileType:'image'})
              i++;
            }
            if(asset.type === 'content_'+ i) { 
              console.log("please check execution of face-game",asset.type)
              uploadData.push({FileName:asset.type,FileType:'glb'})
              i++;
            }
            console.log("==> face-game",asset.type)
          }
          else
          {
            let newName = asset.type;
            if(uploadData.length === 0) 
            {
              if(asset.action && asset.action !==""){
                uploadData.push({ImageName:newName+"_name",ImageType:'text',FileName:newName,FileType:'image'})
                filesType[newName] = asset.assets.split('.').pop();
              }
              else{
                uploadData.push({FileName:newName,FileType:'image'})
              }
            }

            let j=2;
            while(files[newName]) 
            {
              newName = asset.type+""+j;
              j++;
            }

            if(asset.action && asset.action!=="") {
              uploadData.push({ImageName:newName+"_name" ,ImageType:'text',FileName:newName,FileType:'image'})
              data["fileName"+j] = asset.action;
              filesType[newName] = asset.assets.split('.').pop().toLowerCase();
            }
            else
            {
              uploadData.push({FileName:newName,FileType:'image'})
            }
            this.setState({[newName]:config.assetsEndpoint + asset.assets})
            files[newName]= config.assetsEndpoint + asset.assets;
          }
          console.log("my type should be declare here",this.state)
          filesType[asset.type] = asset.assets.split('.').pop().toLowerCase();
          console.log("my assets are",asset.assets.split('.').pop())
        })
        
        let enable_geo_location = data.latitude === "" || data.latitude==='null'?'off':'on';
        if(data.exp_type.id === 6) data.exp_type.id = 4;

        const selectedDetectionPoint= (data.exp_type.id === 3 || data.exp_type.id === 4) ? this.getSubcategoryIdBySelectionPoint(data.detection_point) : '';
        console.log("my final data for testing is",data);
        try
        {
          const {step4Data} = this.state;
          step4Data['url'] = config.webarEndpoint+data.slug_exp_name;
          step4Data['qrcode'] = config.assetsEndpoint+data.qr_code; 

          data['redirect_to_website'] = 'FALSE';
          if(data.custom_field_text !== null && data.custom_field_text !== 'null' && data.custom_field_text !== '' )
          {
            data['redirect_to_website']='TRUE'
          }

          if(data.detection_point === 'face_game'){
            this.setState({ArrayUploadData:uploadData})        
          }
          else if(data.detection_point === "treasure_hunt"){
            this.setState({SingleArrayUploadFileData:uploadData})
          }

          if(data.exp_type.id === 3 || data.exp_type.id === 4 || data.exp_type.id === 16)
          {
            const { data:res } = await getExperienceSubCategory(data.exp_type.id);

            if(res.status && isBoolean(res.status))
            {
                let {data:subCategories} = res;
                let selectedSubCat = [];
                if(data.exp_type.id === 16) {
                  selectedSubCat = subCategories.filter((sub)=>{
                    console.log("check id",data.exp_subtype_id,sub.id)
                    return data.exp_subtype_id == sub.id
                  })
                } else if(data.exp_type.id === 4 && data.sub_exp_type.id === 28) {
                  selectedSubCat = subCategories.filter((sub)=>{
                    console.log("check id",data.exp_subtype_id,sub.id)
                    return data.exp_subtype_id == sub.id
                  })
                } else {
                  selectedSubCat = subCategories.filter((sub)=>{
                    return selectedDetectionPoint === sub.exp_type
                  })
                }
                
                console.log("my subcategory is:::::::::",selectedDetectionPoint,selectedSubCat[0])
                
                let currentStepper = this.state.currentStepper;
                currentStepper['currentStep']++;
                currentStepper['totalSteps']++;  
                
                this.setState({
                  files,
                  data:{...data,redirection_time:data.custom_field_text,enable_geo_location,use_content_audio},
                  selectedExpType:{...data.exp_type},
                  selectedExpTypeSubcategory : selectedSubCat[0],
                  filesType:{...filesType},
                  loaderFlag:true,
                  lipstick_with_textures,                  
                  step4Data,
                  currentStepper:{'currentStep':2,'totalSteps':4},
                  onEdit:true 
                })
              
            }
            else if(res.status === 'Token is Expired') {
              this.setState({text:'User token expired, Redirecting to login page.'});
              setTimeout(()=>{
                this.setState({token_expired:true,loaderFlag:false});
              },400)
            }
          }
          else{
            this.setState({
              files,
              data:{...data,redirection_time:data.custom_field_text,enable_geo_location,use_content_audio},
              selectedExpType:{...data.exp_type},
              filesType:{...filesType},
              loaderFlag:true,
              step4Data,
              currentStepper:{'currentStep':1,'totalSteps':3}, 
              onEdit:true 
            })
            // save state here for markerless and markerbase
          }
        }
        catch(ex) {
          let message = {};
          if (ex.response && ex.response.status === 400) {
            toast.error("Server Busy");
            message = ex.response.data.errors;
            this.setState({ message });
          } else if (ex.response && ex.response.status === 404) {
            toast.error("Server Down, Under maintenance.");
          } else {
            toast.error("Unexpected Error");
          }
        }

      }
      else if(exp_data.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
        },1000);
      }
    }
    catch(ex) {
      let message = {};
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
        message = ex.response.data.errors;
        this.setState({ message });
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }
  
  handleFullScreenDialogOpen = async (name,type) =>{
    
    const {selectedExpType,selectedExpTypeSubcategory} = this.state;

    const data = new FormData();
    data.append("exp_type_id", selectedExpType.id);
    if(selectedExpTypeSubcategory.hasOwnProperty('id')) data.append("sub_exp_type_id", selectedExpTypeSubcategory.id);
      
    const {data:allCategory} = await getCategoriesExpBased(data);
    if(allCategory.status && isBoolean(allCategory.status))
    {
      console.log("my total catetgories are", allCategory);
      console.log("create experience page")
      this.setState({ fullScreenDialog:true,UploadGlbCurrentPage:1,selectFileFromGallery:name,selectFileFromGalleryType:type,allCategory:allCategory.data, loaderFlag:true})
  
    }
    else if(allCategory.status === 'Token is Expired') {
      this.setState({text:'User token expired, Redirecting to login page.'});
      setTimeout(()=>{
        this.setState({token_expired:true,loaderFlag:true});
      },400)
    }



  }

  handleFullScreenDialogClose = () => {
    console.log("changing dialog to stop",this.state.fullScreenDialog)
    this.setState({fullScreenDialog:false,phase:1})
  }

  handleState = (data) =>{
    let currentStepper = this.state.currentStepper;
    if(data.parent_id){
      console.log("handle subcategory from handleState",data)
      this.setState({selectedExpTypeSubcategory:data})
      this.setState({currentStep:3})
      currentStepper['currentStep']++;
      this.setState({currentStepper})
    }
    else{
      console.log("handle category from handleState")
      if(data.exp_type === 'games' || data.exp_type === 'tryon') {
        this.setState({currentStep:2});
        currentStepper['currentStep']++;
        currentStepper['totalSteps']++;   
        this.setState({currentStepper})   
        // this.setState({currentStepper:{'currentStep':2,'totalSteps':4}})
      }
      else {
        this.setState({currentStep:3});
        currentStepper['currentStep']++;
        this.setState({currentStepper})
      }
      this.setState({selectedExpType:data});
    }
  }

  randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  }

  OpenDialogBox = (name) =>{
    
    const fullScreenDialog = this.state.fullScreenDialog;
    const handleFullScreenDialogClose = this.handleFullScreenDialogClose;
    console.log("my image name is",name)
    return(
      <div>
        <UploadGlbDialog handlePageChange={this.handlePageChange} currentPage={this.state.UploadGlbCurrentPage} allCategory = {this.state.allCategory} isloading={this.state.isChildLoading} id={name} fieldname={name} selectedItems={this.selectedItems} phase={this.state.phase} fullScreenDialog={this.state.fullScreenDialog} handleFullScreenDialogClose={this.handleFullScreenDialogClose}  selectedCategory={this.selectedCategory} selectedItem={this.state.selectedItem} selectedCategoryCollection={this.state.selectedCollection}/>
      </div>
    )
  }

  hexToRgbNew = (hex) => {
    
    if(hex.includes('#')){
      hex = hex.split("#")[1];
    }
    
    var arrBuff = new ArrayBuffer(4);
    var vw = new DataView(arrBuff);
    vw.setUint32(0,parseInt(hex, 16),false);
    var arrByte = new Uint8Array(arrBuff);
  
    let r = arrByte[1]/255;
    let g = arrByte[2]/255;
    let b = arrByte[3]/255;
   
    return Math.round(r * 10) / 10 + " " + Math.round(g * 10) / 10 + " " + Math.round(b * 10) / 10; 
  }

  markerbaseUploadInputField = () =>{
    const {marker_image} = this.state.files;
    console.log('Marker Image', marker_image);
    return(
      <div style={{background:'white',border:'1px solid grey',borderRadius:'10px',marginBottom:"16px"}}>
        <Typography sx={{p:1,pl:3,fontWeight:'bold',fontSize:'16px'}}>
          Marker Image
        </Typography>
        <Grid container>
          <Grid item xs={12} sx={{textAlign:'center'}}>
            {/* {this.UploadInputFieldExcludeGLB('marker_image','','only_png',true)} */}
            <img id='marker_image' src={marker_image} alt='Marker Image' style={{border:"2px solid #682CEA",borderRadius:'10px',height:'180px',width:'180px'}} />
          </Grid>
        </Grid>
        <div style={{textAlign:"center",marginBottom:'10px'}}>
          You can download this marker image from the list of AR Experiences.
        </div>
      </div>
    )
  }

  handlePageChange = (value) => {
    console.log('Page Change',value);
    this.setState({ UploadGlbCurrentPage: value });
  };

  // selectedCategory  = async (d) =>{

  //   // console.log("hello ")
  //   const {errors} = this.state;
  //   try{
  //     const {selectedExpType,selectedExpTypeSubcategory} = this.state;

  //     const formData = new FormData();
  //     formData.append("exp_type_id", selectedExpType.id);
  //     if(selectedExpTypeSubcategory.hasOwnProperty('id')) formData.append("sub_exp_type_id", selectedExpTypeSubcategory.id);
  //     formData.append("category_id", d.id);

  //     let {data:res} = await getCollectionByIdExpBased(formData);
  //     if(res.status && isBoolean(res.status))
  //     {

  //       const {data:selectedCollection}  = res;
  //       console.log("selected cateogry is =>",selectedCollection)
  //       this.setState({phase:2,isChildLoading:false,selectedItem:d,selectedCollection, UploadGlbCurrentPage:1});
  //     }
  //     else if(res.status === 'Token is Expired') {
  //       this.setState({text:'User token expired, Redirecting to login page.'});
  //       setTimeout(()=>{
  //         this.setState({token_expired:true,loaderFlag:false});
  //       },400)
  //     }
  //   }
  //   catch (err) {
  //     console.log('Error in Response',err);
  //     if (err.response && err.response.status === 404) {
  //       errors.global = "Unexpected Error occured Please try again.";
  //       toast.error("Unexpected Error occured Please try again.");
  //     } else {
  //       errors.global = "Unexpected Error other than 404 Please try again.";
  //       toast.error("Unexpected Error other than 404 Please try again.");
  //     }
  //   }
  // }

  selectedCategory  = async (data) => {
    try
    {
      this.setState({phase:2,isChildLoading:true,selectedItem:data,UploadGlbCurrentPage:1});

      const {selectedExpType,selectedExpTypeSubcategory} = this.state;

      const formData = new FormData();
      formData.append("exp_type_id", selectedExpType.id);
      if(selectedExpTypeSubcategory.hasOwnProperty('id')) formData.append("sub_exp_type_id", selectedExpTypeSubcategory.id);
      formData.append("category_id", data.id);

      let {data:selectedCollection} = await getCollectionByIdExpBased(formData);
      if(selectedCollection.status && isBoolean(selectedCollection.status) )
      {
      // console.log("my selected Collection data is",selectedCollection)
        this.setState({selectedCollection:selectedCollection.data})
        setTimeout(()=>{
          this.setState({isChildLoading:false})
        },200)
      }
      else if(selectedCollection.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400)
      }
    }
    catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }

  selectedItems = (data,type) =>{
    let {selectFileFromGalleryType,selectFileFromGallery} = this.state;
    const fileAddress = config.assetsEndpoint+data.assets;
    type = selectFileFromGalleryType;
    console.log("please find my data",data," with type",selectFileFromGalleryType,selectFileFromGallery)
    let files  =  {...this.state.files};
    let editFiles = {...this.state.editFiles}
    let curfile = this.state.selectFileFromGallery;
    let newData = {...this.state.data};
    this.setState({[this.state.selectFileFromGallery]:fileAddress})
    const stateData = {...this.state.data};
    
    if(type === 'content' || curfile === 'content')
    {
      console.log("step1")
      files[this.state.selectFileFromGallery] = fileAddress;
      newData['library_assets'] = data.id;
      delete editFiles[this.state.selectFileFromGallery];
      this.setState({data:{...stateData,library_assets:data.id,files}})
    }
    else if(type === 'only_glb')
    {
      // console.log("step2")
      files[this.state.selectFileFromGallery] = fileAddress;
      newData['library_assets_tossing'] = data.id;
      delete editFiles[this.state.selectFileFromGallery];
      delete files[this.state.selecteFileFromGallery];
    }
    else
    {
      console.log("step3")
      console.log("my single Array",this.state.filesType)
      let newName = 'imagefile'+this.state.selectFileFromGallery.split('_')[1];
      console.log("my new file name is",newName)
      newData[newName] = data.id
      files[this.state.selectFileFromGallery] = fileAddress;    
      delete editFiles[this.state.selectFileFromGallery];
      console.log("step4")
    }

    // const files = {...this.state.files}
    const filesType = {...this.state.filesType}
    filesType[this.state.selectFileFromGallery] = 'glb';
    // files[type] = fileAddress;
    // this.sestState({[data.FileName]:fileAddress})
    // this.setState({SingleArrayUploadFileData})
    // files[type] = data;
    this.setState({fullScreenDialog:false,filesType,files,phase:1,data:newData,editFiles,['imagefile'+this.state.selectFileFromGallery.split('_')[1]]:fileAddress,errors:{}})
  }

  AddOneMoreTexture = () =>{
    let {data} = this.state;
    let lipstick_with_textures= [...this.state.lipstick_with_textures];
    const length = lipstick_with_textures.length+1; 
    
    lipstick_with_textures.push('')
    data['lipstick_colour_'+ length] = '#FF0000';
    data['texture_'+ length] = 'glossy';
    this.setState({lipstick_with_textures});

  }

  RemoveOneTexture = () =>{
    let {data} = this.state;
    let lipstick_with_textures= [...this.state.lipstick_with_textures];
    const length = lipstick_with_textures.length; 
    delete data['lipstick_colour_'+ length];
    delete data['texture_'+ length];
    lipstick_with_textures.pop()
    this.setState({lipstick_with_textures,data});
  }

  ColorWithLabel = (name,label,isRequired) =>{
    return(
      <div style={{width:"100%",marginBottom:"20px",background:'white',padding:'20px',borderRadius:'10px'}}>
        <Typography sx={{fontWeight:'bold',mb:1}}>{label}</Typography>
        {this.ColorPicker(name,label,isRequired)}
      </div>
    )
  }

  ColorWithTexture = () =>{
    const {lipstick_with_textures} = this.state;
    const {RemoveOneTexture, AddOneMoreTexture} = this;
    return(
      <div style={{width:"100%",marginBottom:"20px"}}>
        {
          lipstick_with_textures.map((ele,index)=>{
            return(
              <>
                {this.ColorPicker('lipstick_colour_'+(index+1),'Choose Color',true)}
                {/* {this.renderRadioButton('texture_'+(index+1),'Texture',['glossy','matte'],['Glossy','Matte'],true)} */}
              </>
            )
          })
        }
        <div style={{marginBottom:'60px'}}>
          <div style={{display:'inline',justifyContent:'center',alignItems:'center',float:'right'}}>
            {
              lipstick_with_textures.length !== 1 &&
              <Button className="alignCenter" sx={{cursor:'pointer'}} onClick={RemoveOneTexture} >Remove</Button>
            }

            {
              lipstick_with_textures.length !== 5 
                &&
              <Button className="alignCenter" sx={{cursor:'pointer'}} onClick={AddOneMoreTexture}>Add</Button>
            }
          </div>
        </div>

      </div>
    )
  }

  addOneMoreUploadField = (Image=false,file=false,data,limit)=>{

    if(Image && file){
      let UploadData = [...this.state.ArrayUploadData];

      console.log("total vaues are ",UploadData)
     
      console.log("called but not updated",limit,UploadData.length,this.state.ArrayUploadData);
      if(limit > UploadData.length) { 
        
        UploadData.push(data);
        console.log("data is pushed",UploadData)
        this.setState({ArrayUploadData:UploadData})
        setTimeout(()=>{
          console.log("called1",this.state.ArrayUploadData.length,this.state.ArrayUploadData);
        },200)
      }
    }
    else if(file){
      let SingleArrayUploadFileData = [...this.state.SingleArrayUploadFileData];
      data.FileName = data.FileName + (SingleArrayUploadFileData.length+1);
      if(limit > SingleArrayUploadFileData.length) { 
        SingleArrayUploadFileData.push(data)
        this.setState({SingleArrayUploadFileData})
      }
    }
  };
  
  getSubcategoryIdBySelectionPoint =  (detectionpoint) => {
    let local_detectionpoint = detectionpoint;
    // if(detectionpoint === 'toss'){
    //   return this.state.step2Data[0];
    // }
    // else if(detectionpoint ==="dodge_collect"){
    //   return this.state.step2Data[1];
    // }
    // else if(detectionpoint === "shooter"){
    //   return this.state.step2Data[2];
    // }
    // else if(detectionpoint === 'face_game'){
    //   return this.state.step2Data[3];
    // }
    // else if(detectionpoint === "treasure_hunt"){
    //   return this.state.step2Data[4];
    // }
    if(detectionpoint === "lips" || detectionpoint === "lipstick_tryon"){
      // return this.state.step2Data[5];
      local_detectionpoint = 'lipstick_tryon';
    }
    else if(detectionpoint === "wrist" || detectionpoint === "watch_tryon"){
      // return this.state.step2Data[6];
      local_detectionpoint = 'watch_tryon';
    }
    else if(detectionpoint === "eye" || detectionpoint === 'ear' || detectionpoint === 'neck' || detectionpoint === 'head' || detectionpoint === 'live_face_morph'){
      // return this.state.step2Data[7];
      local_detectionpoint = 'facedetection';
    }    
    else if(detectionpoint === "finger" || detectionpoint === "ring_tryon"){
      // return this.state.step2Data[8];
      local_detectionpoint = 'ring_tryon';
    }
    // Filter goes Here 
    return local_detectionpoint;
  }

  fieldUploaded(Image=false,file=false) {
    const UploadData = (file && Image) ? this.state.ArrayUploadData : this.state.SingleArrayUploadFileData;
    console.log("FieldUploaded =>",UploadData);
    if(file && Image) {
      return(
        UploadData.map((data,index)=>{
          return(<>
            {Image && this.renderInput(data.ImageName,data.ImageType,'Image Name ' + (index+1),true)}
            {file && this.UploadInputFieldExcludeGLB(data.FileName,'Upload Image ' + (index+1),data.FileType,false)}
          </>)
        })
      );    
    } else {
      return(
        UploadData.map((data,index) => {
          return(<>
            {file && this.UploadInputField(data.FileName,'Hidden Object ' + (index+1),data.FileType,false)}
          </>)
        })
      );  
    }
  }
  
  step3 = () => {
    const {selectedExpType, selectedExpTypeSubcategory, currentStep,files,data,filesType,use_content_audio} = this.state;
    console.log('Selected Exp Type id',selectedExpType.id);
    return(
      <React.Fragment>
        {/* for Common Markerbased and Markerless */}

        {
          (selectedExpType.id === 1 || selectedExpType.id === 2 
            || (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24)
             ) && (
            <React.Fragment>
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {this.UploadInputField('content','Content File','content',false)}
              { filesType && filesType['content'] && filesType['content']!=='mp4' && this.UploadInputFieldExcludeGLB('audio','Audio File','audio',true)} 
              
              <div>
                {
                   selectedExpType.id === 2  &&  filesType && filesType['content'] && filesType['content']=== 'mp4'  &&
                  <div style={{marginBottom:'10px'}}>
                    {(this.SwitchButton('transparent_background','Transparent Background','Yes','No',false))}
                  </div>
                }
                {
                  filesType && filesType['content'] && filesType['content'] === 'mp4' && data['transparent_background']  === 'Yes' && selectedExpType.id ===2 &&
                  <div style={{background:'white',padding:'10px',borderRadius:'10px',marginBottom:'10px'}}>
                    <Typography sx={{fontWeight:'500',mb:'10px'}}>
                        Background Colour
                    </Typography>
                    { this.ColorPicker('background_color','Choose Color',true) }
                  </div>
                }
              </div>
              {this.Slider("light_range", "Light Setting Range", 0, 5, 1, true)}
 
              <div style={{marginBottom:'10px'}}>
                 { (selectedExpType.id === 2 || (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24) ) &&  filesType && filesType['content'] &&  this.SwitchButton('enable_placement','Enable Placement',"False","True",false)}
                </div>
              {selectedExpType.id === 1 && this.markerbaseUploadInputField()}
            </React.Fragment>
          )
        }
        
        {/* for Common Markerbased and Markerless  */}
        {
          ((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24) || (selectedExpType.id === 1 || selectedExpType.id === 2) ) ? 
          (
            <React.Fragment>
              {selectedExpType.id !== 16 && this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,'s')}
              {/* {this.Slider("redirection_time", "Redirection Time", 10, 60, 5, true)} */}
              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('label','text','Label',true)}
              {this.renderInput('website','text','Website Link',true)}
            </React.Fragment>
          ) : null
        }

        {
          selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 7 && (
            <React.Fragment>
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {this.UploadInputField('content','Target Model','only_glb',true,6144)}
              {this.UploadInputField('tossing_model','Tossing Model','only_glb',true,1024)}
              { (files.tossing_model || this.state.data['library_assets_tossing']) && this.UploadInputFieldExcludeGLB('tossing_image_modal','Tossing Model Image','image',true)}
              {this.UploadInputFieldExcludeGLB('audio','Background Audio','audio',true)}
              {this.UploadInputFieldExcludeGLB('sound','Target Audio','audio',true)}

              {this.Slider("light_range", "Light Setting Range", 0, 5, 1, true)}
            
              {/* {this.Slider("redirection_time", "Redirection Time", 10, 60, 5, true)} */}
              {this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,'s')}
            
              {this.MultiLineStringInputField("text_to_display","text","Game Instructions", true,50,100)}
              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('website','text','Website Link',true)}
            </React.Fragment>
          )
        }
        {
          selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 8 && (
            <React.Fragment>
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              
              {this.UploadInputField('content','Main Model','only_glb',true)}
              {this.UploadInputField('tossing_model','Collider Model','only_glb',true,1024)}
              {this.UploadInputFieldExcludeGLB('audio','Background Audio','audio',true)}
              {/* {this.Slider("redirection_time", "Redirection Time", 10, 60, 5, true)} */}
              {this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,'s')}
            
              {this.MultiLineStringInputField("text_to_display","text","Game Instructions", true,50,100)}
              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('website','text','Website Link',true)}
            </React.Fragment>
          )
        }

        {
          selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 9 && (
            <React.Fragment>
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {this.UploadInputField('content','Target Model','only_glb',true)}
              {this.UploadInputField('tossing_model','Bullet Model','only_glb',true,1024)}
              {this.NumberRangeField('bullet_frq','Bullet Frequency', 0.5, 1, 0.1, false)}
              {this.NumberRangeField('game_duration','Game Duration', 30, 60, 1, false)}
              {this.DropDownInputField('feedbackForm_game','Feedback Form',[{name:'visible',label:'Visible'},{name:'hidden',label:'Hidden'}],"",true)}
           </React.Fragment>
          )
        }

        {
          selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 10 && (
            <React.Fragment>
              {this.DropDownInputField('face_game_type','Face Game Type',[{name:'angry_pokemon',label:'Angry Pokemon'},{name:'favourite_food',label:'Favourite Food'},{name:'tarot_reading',label:'Tarot Reading'}],"",true)}
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {this.fieldUploaded(true,true)}
              <div style={{display:'flex',width:'100%',justifyContent:'end'}}>
                <Typography sx={{color:'red',fontSize:'13px',marginLeft:'10px'}}>
                  All 11 images are required
                </Typography>
              </div>
              <Button sx={{mb:2,float:'right'}} variant="outlined" startIcon={<AddIcon />} onClick={()=>this.addOneMoreUploadField(true,true,{ImageName:'imagename',ImageType:'text',FileName:'imagefile',FileType:'image'},11)}>
                Add next Image
              </Button>
              {this.NumberRangeField('image_speed','Randomize Speed', 0.5, 1, 0.1, false)}
            </React.Fragment>
          )
        }
        {
          selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 11 && (
            <React.Fragment>
              {this.fieldUploaded(false,true)}
              <div style={{width:'100%',display:'flex',justifyContent:'end'}}>
                <Typography sx={{color:'red',fontSize:'13px'}}>
                  All 10 GLB's are required
                </Typography>
              </div>
              <div style={{width:'100%',display:'flex',justifyContent:'end'}}>
                <Button sx={{mb:2}} variant="outlined" startIcon={<AddIcon />} onClick={()=>this.addOneMoreUploadField(false,true,{FileName:'imagefile',FileType:'glb'},10)}>
                  Add Element
                </Button>
              </div>
              {this.UploadInputFieldExcludeGLB('audio','Background Audio','audio',true)}
              {this.state.audio && (this.SwitchButton('audio_option','Audio Setting','on','off',false))}
              {this.NumberRangeField('game_duration','Game Duration', 30, 60, 1, false)}
           </React.Fragment>
          )
        }
        {
          selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 12 && (
            <React.Fragment>
              <div style={{marginLeft:"6px",marginBottom:"6px",fontSize:"14px",fontWeight:"500",background:'white',paddingLeft:"10px",paddingRight:"10px",borderRadius:"10px"}}>
                <Typography style={{fontWeight:"600",marginBottom:"10px",paddingTop:"15px"}}>
                  Choose Lipstick Colours
                </Typography>
                {this.ColorWithTexture()}
              </div>
              {/* {this.Slider("redirection_time", "Redirection Time", 10, 60, 5, true)} */}
              {this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,'s')}
            
              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('website',"text", 'Website Link', true)}
            </React.Fragment>
          )
        }
        
        {
          ((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23) || (selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 14)) && (
            <>
              {(selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 14 || selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23) ? 
                this.DropDownInputField('detection_point','Face Detection Point',[{name:'eye',label:'Eye'},{name:'ear',label:'Ears'},{name:'neck',label:'Neck'},{name:'head',label:'Head'},{name:'live_face_morph',label:'Live Face Morph'}],"",true) : 
                this.DropDownInputField('detection_point','Face Detection Point',[{name:'eye',label:'Eye'},{name:'ear',label:'Ears'},{name:'neck',label:'Neck'},{name:'head',label:'Head'}],"",true) 
              }
              {this.state.data.detection_point && this.state.data.detection_point === "head"
                && this.renderRadioButton('place_head','',['place_head','above_head'],['place on head','place above head'],true)
              }
            </>
          )
        }

        {
          ((selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 28)) && (
            <>
              {this.DropDownInputField('detection_point','Body Detection Point',[{name:'upper_body',label:'Upper Body'},{name:'lower_body',label:'Lower Body'}],"",true)}
            </>
          )
        }

        {
          ((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23) || (selectedExpType.id === 4 && (selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 26  || selectedExpTypeSubcategory.id === 27 || selectedExpTypeSubcategory.id === 28 ) )) && 
          (
            <React.Fragment>
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}

              {selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 26 && (this.UploadInputFieldExcludeGLB('content','Content File','image',true,10240,true,{status:true,sid:selectedExpTypeSubcategory.id}))}
              {selectedExpTypeSubcategory.id === 28 
                ? (this.UploadInputField('content','Content File','body_track',true,20480,this.getHelperText('body_track')))
                :
                (
                  (selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 27)
                  ?
                  (this.UploadInputField('content','Content File','only_glb',true,20480,null,{status:true,sid:selectedExpTypeSubcategory.id}))
                  :
                  (selectedExpTypeSubcategory.id !== 26 && (this.UploadInputField('content','Content File','content',true)))
                )
              }
              <div>
                  {(selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 27 || selectedExpTypeSubcategory.id === 28 ) 
                    && filesType && filesType['content'] && 
                    this.UploadInputFieldExcludeGLB('audio','Audio File','audio',true)
                  }
              </div>
              


              {/* {this.Slider("redirection_time", "Redirection Time", 10, 60, 5, true)} */}
              {selectedExpType.id !== 16 && this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,'s')}
            
              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('website',"text", 'Website Link', true)}              
            </React.Fragment>
          )
        }
        
        {
          selectedExpType.id === 5 && (
            <React.Fragment >
              {this.MultiLineStringInputField("text_to_display","text","Add Description Here", true)}
              {this.UploadInputFieldExcludeGLB('global_audio_file','Global Audio','audio',true)}
              {this.state.audio && (this.SwitchButton('audio_option','Audio Setting','on','off',false))}
              {/* {this.SwitchButton('screen_gesture','Enable Screen Gesture','True','False',false)}
              {this.SwitchButton('multisessions','Enable Multisessions','True','False',false)} */}
              <div style={{marginBottom:"16px"}}>
              {this.SwitchButton('screen_gesture','Enable Screen Gesture','True','False',false)}
              </div>
              <div style={{marginBottom:"16px"}}>
              {this.SwitchButton('multisessions','Enable Multisessions','True','False',false)}
              </div>
            </React.Fragment>
          )
        }

        {
          (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 25 ) && (
            <>
               {this.UploadInputField('content','Content File','only_glb',true)}
              {this.UploadInputFieldExcludeGLB('audio','Audio File','audio',true)}
              { this.SwitchButton('background_ImageVsColor','Switch To Choose Colour as Background','True','FALSE',false)}
              {
                data['background_ImageVsColor'] === 'FALSE'?
                  ( this.UploadInputFieldExcludeGLB('background_image','Background Image','ads_background_image',false))
                :
                  ( this.ColorPicker('background_colour','Choose Color',true))
              }
              {this.renderInput('website',"text", 'Website Link', true)}
            </>
          )
        }
        {
          (selectedExpType.id === 16 && (selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 24 || selectedExpTypeSubcategory.id === 25) )  && (
            <React.Fragment>
              {this.ColorWithLabel('redirection_button_colour','Redirection Button Colour',false)}
            </React.Fragment>
          )
        }

        {
          (selectedExpType.id === 1 || selectedExpType.id === 2 ||
            (selectedExpType.id === 3 && (selectedExpTypeSubcategory.id === 7 || selectedExpTypeSubcategory.id === 8)) ||
            (selectedExpType.id === 16 && (selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 24))||
            (selectedExpType.id === 4 && (selectedExpTypeSubcategory.id === 12 || selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 26))
          )
          && 
          (
            <div>
              {this.geolocation('Enable Geo Location','enable_geo_location')}
            </div>
          )
        }

        {(selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id == 24) && (
          <div style={{marginBottom:'16px',borderRadius:'10px',background:'white',border:'1px solid grey'}}>
            {this.UploadInputFieldImageWithSwitch('overlay_image','Overlay Image','ads_background_image',false,3072,true,this.getHelperText('overlay_image'))}
            {this.SwitchButton('repeat_overlay','Switch off to play overlay image only once.','True','FALSE',false)}
          </div>
        )}

        {
          (selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id == 24) &&(

            <div>

               { this.UploadInputField('tossing_model','Fallback Content Model','only_glb',false) }
               { filesType && filesType['tossing_model'] && this.UploadInputFieldExcludeGLBSwitch('use_content_audio','FallBack Audio ( Use Same Audio of Content File )','sound','audio','off','TRUE')}

               { this.SwitchButton('background_ImageVsColor','Switch To Choose Colour as Background','True','FALSE',false)}
               {
                  data['background_ImageVsColor'] === 'FALSE'?
                    ( this.UploadInputFieldExcludeGLB('background_image','Background Image','ads_background_image',false))
                  :
                    ( this.ColorPicker('background_colour','Choose Color',true))
               }
            </div>
          )
        }

      </React.Fragment>
    )
  };

  workflow = () => {
    const {arExp} = this.state;
    return (
      <React.Fragment>
        {/* <CreateWorkFlow arData={arExp} /> */}
        <Typography variant="h5">Coming Soon...</Typography>
      </React.Fragment>
    )
  };
  
  step4 = () => {
    const {step4Data,selectedExpType,selectedExpTypeSubcategory,data} = this.state;
    console.log('Final QR Data',step4Data,data);
    let enable_placement = 'False';
    if(this.state.data.hasOwnProperty('enable_placement')){
      enable_placement = this.state.data['enable_placement'];
    }
    if(this.state.files.marker_image){
      step4Data['marker_image'] = this.state.files.marker_image;
    }
    
    return(
      <React.Fragment>
        <Step4 cardData={step4Data} selectedExpType = {selectedExpType} selectedExpTypeSubcategory = {selectedExpTypeSubcategory} placement={enable_placement} />
      </React.Fragment>
    )
  };
  
  validate = () => {
    // return null;
    const options = { abortEarly: false };
    // const { error } = Joi.validate(this.state.data, this.schema, options);
    // if (!error) return null;

    // const errors = {};
    // for (let item of error.details) errors[item.path[0]] = item.message;
    // console.log(errors);
    // return errors;



    const { data,selectedExpType,selectedExpTypeSubcategory,qrcode,files,ArrayUploadData,SingleArrayUploadFileData,editFiles } = this.state;
    const errors = {};
    
    if(data.exp_name === '') errors.exp_name = 'Exp Name is required';

    if(data.website && data.website !== '') {
      console.log('Website Link checking',data.website);
      if(this.checkURL(data.website)) errors.website = 'URL is not valid.';
    }

    if(selectedExpType.id === 1 || selectedExpType.id === 2 || (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24)) 
    {
      if(typeof files.content == 'undefined' || files.content === ''){        
        if(this.state.data['library_assets'] === '' || typeof this.state.data['library_assets'] === 'undefined')
          errors.content = 'Content File is required';
      }
      if(selectedExpType.id === 1 && (typeof files.marker_image === 'undefined' || files.marker_image === '')) errors.marker_image = 'Marker Image File is required';
    }


    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 7) {
      if(typeof files.content == 'undefined' || files.content === ''){ 
        if(this.state.data['library_assets'] === '' || typeof this.state.data['library_assets'] === 'undefined')
        errors.content = 'Target Model is required';
      }
      if(typeof files.tossing_model == 'undefined' || files.tossing_model === '') 
      {
        if(this.state.data['library_assets_tossing'] === '' || typeof this.state.data['library_assets_tossing'] === 'undefined')
          errors.tossing_model = 'Tossing Model is required';
      }
      if(typeof files.tossing_image_modal == 'undefined' || files.tossing_image_modal === '') errors.tossing_image_modal = 'Tossing Model Image is required';
    }

    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 8) {
      if(typeof files.content == 'undefined' || files.content === '') errors.content = 'Main Model is required';
      if(typeof files.tossing_model == 'undefined' || files.tossing_model === '') errors.tossing_model = 'Collider Model is required';
    }

    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 9) {
      if(typeof files.content == 'undefined' || files.content === '') errors.content = 'Target Model is required';
      if(typeof files.tossing_model == 'undefined' || files.tossing_model === '') errors.tossing_model = 'Bullet Model is required';
      if(typeof data['feedbackForm_game']=='undefined' || data['feedbackForm_game']===''){errors.feedbackForm_game = 'Feedback Form is required';}
    }

    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 10) {
      if(typeof data.face_game_type == 'undefined' || data.face_game_type === '') errors.face_game_type = 'Face Game Type is required';
      
      if(ArrayUploadData.length < 11) errors.global = 'All 11 image files are required';
      else {
        ArrayUploadData.map((check,index) => {
          if(typeof data[check.ImageName] == 'undefined' || data[check.ImageName] === '') errors[check.ImageName] = `Image Name ${index+1} are required`;
          if(typeof files[check.FileName] == 'undefined' || files[check.FileName] === '') errors[check.FileName] = `Image File ${index+1} are required`;
        });
      }
    }

    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 11) {
      if(typeof data.game_duration == 'undefined' || data.game_duration === '') errors.game_duration = 'Game Duration is required.';
      
      if(SingleArrayUploadFileData.length < 10) errors.global = 'All 10 hidden objects are required.';
      else {
        SingleArrayUploadFileData.map((check,index) => {
          if(typeof files[check.FileName] == 'undefined' || files[check.FileName] === '') errors[check.FileName] = `Hidden Object ${index+1} is required.`;
        });
      }
    }

    if(selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 12) {
      // if(typeof data.lipstick_colour_0 == 'undefined' || data.lipstick_colour_0 === '') errors.lipstick_colour_0 = 'Lipstick Color is required.';
      // if(typeof data.texture_1 == 'undefined' || data.texture_1 === '') errors.texture_1 = 'Lipstick Texture is required.';
      
      // if(SingleArrayUploadFileData.length < 10) errors.global = 'All 10 hidden objects are required.';
      // else {
      //   SingleArrayUploadFileData.map((check,index) => {
      //     if(typeof files[check.FileName] == 'undefined' || files[check.FileName] === '') errors[check.FileName] = `Hidden Object ${index+1} is required.`;
      //   });
      // }
    }

    
    if((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23)||(selectedExpType.id === 4 && (selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 26 )))
    {
      if(((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23)||(selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 14)) && (typeof data.detection_point == 'undefined' || data.detection_point === '')) errors.detection_point = 'Detection Point is required.';
      if(typeof files.content == 'undefined' || files.content === '') errors.content = 'Content is required.';
    }
    
    if(selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 24){
      if(data.background_ImageVsColor === 'TRUE'){
        if(data.background_colour === '' || typeof data.background_colour ==='undefined' ) 
          errors.background_colour = 'background color is required'
      }
      else{
        if(files.background_image === '' || typeof files.background_image ==='undefined' ) 
          errors.background_image = 'background Image is required'
      }
    }

    if(data.enable_geo_location === 'on') {
      console.log('Please add Latitude, Longitude and Distance');
      if(data.latitude === '') errors.latitude = 'Latitude is required';
      if(data.longitude === '') errors.longitude = 'Longitude is required';
      if(data.distance === '') errors.distance = 'Distance is required';
    }

    if(selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 24){
      if(typeof files['tossing_model'] == 'undefined' || files['tossing_model'] == '' || files['tossing_model'] == null){
        if(typeof this.state.data['library_assets'] === 'undefined')
          errors.tossing_model = 'fallback content model is required';
      }
    }

    console.log("my selected expType is of validation",selectedExpType.id,"    ",selectedExpTypeSubcategory.id)
    if((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23)||(selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 14)){
     
      if(data['detection_point'] == 'head')
      {
        if(typeof data['place_head'] == 'undefined' || data['place_head']=='' && data['place_head']===null){
          errors.place_head = 'detection head point is required';
        }
      }
    }


    if(data.enable_splash === "TRUE"){
      if(typeof files.splash_screen == 'undefined' || files.splash_screen === '') 
      {
        if(typeof editFiles.splash_screen === 'undefined' || editFiles.splash_screen === '')
        {
          errors.splash_screen = 'Splash Screen is required';
        }
      }
    }

   
    if(selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 25 ) {
      if(typeof files.content == 'undefined' || files.content === '') errors.content = 'Content is required.';
      
      if(data.background_ImageVsColor == 'TRUE'){
        if(data.background_colour === '' || typeof data.background_colour ==='undefined' ) 
          errors.background_colour = 'background color is required'
      }
      else{
        if(files.background_image === '' || typeof files.background_image ==='undefined' ) 
          errors.background_image = 'background Image is required'
      }
    }
    // const regexMobile = /^\d{10}$/;
    // const regexOTP = /^\d{4}$/;

    // if (data.mobileNumber.trim() === "")
    //   errors.mobileNumber = "Mobile Number is required";
    // else if (data.mobileNumber.length > 10)
    //   errors.mobileNumber = "Mobile Number is greater than 10 digit";
    // else if (!data.mobileNumber.match(regexMobile))
    //   errors.mobileNumber = "Mobile Number is not right";

    // if (data.otp.trim() === "") errors.otp = "OTP is required";
    // else if (data.otp.length > 4)
    //   errors.otp = "OTP length is greater than 4";
    // else if (!data.otp.match(regexOTP)) errors.otp = "OTP is not right";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  
  rgbToHex = (rgb) => {
    if(rgb.includes(' ')){
      let [r,g,b] = rgb.split(' ');
      r*= 255;
      g*= 255;
      b*= 255;
      
      console.log("my color in rgb form is",r,g,b);
      return "#" + this.componentToHex(Math.round(r)) + this.componentToHex(Math.round(g)) + this.componentToHex(Math.round(b));
    }
    return '#FF0000';
  }

  doSubmit = async () => {
    
    console.log('Data Editing started');
    this.handleShow();
    document.getElementById("submit_button").disabled = true;
    document.getElementById("submit_button").innerHTML = "Saving Data...";
    // const {selectedExpType,selectedExpTypeSubcategory,data:formobject,splash_screen,content,marker_image,global_audio_file,qrcode,files} = this.state;
    const {selectedExpType,selectedExpTypeSubcategory,qrcode,data:formobject,editFiles:files,files:originalFiles} = this.state;
    const errors = {};

    
    const data = new FormData();
    data.append("user_id", this.props.user);
    data.append("ar_id", formobject.id);
    data.append("exp_type", selectedExpType.id);
    if(selectedExpType.id === 3) data.append("sub_game_category", selectedExpTypeSubcategory.exp_type);
    selectedExpTypeSubcategory.exp_type && data.append("sub_category", selectedExpTypeSubcategory.exp_type);

    const {use_content_audio} = formobject;
    delete formobject['use_content_audio'];

    if(use_content_audio === true){
      formobject['sound'] = 'on';
      delete files['sound'];
    }

    {Object.keys(formobject).map((name) => {
      if(name === 'background_color')
      {
        data.append(name,this.hexToRgbNew(formobject['background_color'])); 
      }
      else
      {
        data.append(name, formobject[name]);
      }
    })}
    // Files
    data.append("qrCodeLogo", qrcode);
    {Object.keys(files).map((key) => {
      console.log(key," =====>",this.state[key])
      
        if(key == 'sound' && (typeof originalFiles['sound'] == 'undefined' || originalFiles['sound'] == null || originalFiles['sound'] == ''))
        {
          data.append('sound', 'off');
        }
        else{

          data.append(key, this.state[key]);
        }
    })}

    for (const value of data.values()) {
      console.log('Form Values',value);
    }
    
    try {
      const config = {
        onUploadProgress: (progressEvent) => {
          var downloadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log('Download Progress',downloadProgress);
          this.setState({downloadProgress});
        }
      }
      this.setState({isButtonDisabled:false})
      // return;
      const res = await editExperience(data,config);
      console.log(res);
      if (res.data.status === "success") {
        document.getElementById("submit_button").innerHTML = "Saved :-)";
        toast.success("AR Experience Edited.");
        const arExp = Object.keys(res.data).length !== 0 ? {...res.data.data} : {};

        console.log("my current stepper is",this.state.currentStepper)
        let currentStep = this.state.currentStepper['currentStep'];
        let totalSteps = this.state.currentStepper['totalSteps'];
        currentStep++;
        let newStepper = 
        this.setState({currentStepper:{'currentStep':currentStep,'totalSteps':totalSteps}})
        

        selectedExpType.id === 5 ? this.setState({arExp,currentStep:3.5}) : this.setState({arExp,currentStep:4});
      
      } else if (res.data.status === "failed") {
        document.getElementById("submit_button").innerHTML = "Not Edited";
        document.getElementById("submit_button").disabled = false;
        errors.global = "Data not edited, Please try again" + res.data.message;
        toast.error("Data not edited, Please try again" + res.data.message);
        this.setState({isButtonDisabled:true})
      } else {
        toast.error("Data not edited, Please try again" + res.status);
        errors.global = "Data not edited, Please try again" + res.data.message;
        document.getElementById("submit_button").innerHTML = "Not edited";
        document.getElementById("submit_button").disabled = false;
        this.setState({isButtonDisabled:true})
      }
    } catch (err) {
      this.setState({isButtonDisabled:true})
      console.log('Error in Response',err);
      if (err.response && err.response.status === 404) {
        errors.global = "Unexpected Error occured Please try again.";
        toast.error("Unexpected Error occured Please try again.");
      } else {
        errors.global = "Unexpected Error other than 404 Please try again.";
        toast.error("Unexpected Error other than 404 Please try again.");
      }
      // document.getElementById("submit_button").innerHTML = "Back with Error";
      // document.getElementById("submit_button").disabled = false;
    }
    this.setState({errors});
    this.handleClose();
  };
  
  handleClose = () => this.setState({setShow:false});
  handleShow = () => this.setState({setShow:true});

  getHelperText = type => {
    if(type === null) return null;
    
    let response = null;
    
    if(type === 'body_track') response = (<Typography sx={{px:2, fontWeight:'regular', fontSize:'16px', textAlign:'justify'}}>Please use GLB or VRM (Virtual Reality Model) files only. To know how to make VRM file click on the link. <a href="https://vrm.dev/en/vrm/how_to_make_vrm/index.html" target="_blank">VRM Documentation</a></Typography>);
    else if(type === 'overlay_image') response = (<Typography sx={{px:2, fontWeight:'regular', fontSize:'16px', textAlign:'justify'}}>Image size should be less than 3MB.</Typography>);
    return response;
  };
  
  render() {
    const {currentStep,currentStepper,isButtonDisabled,selectedExpType,token_expired,selectedExpTypeSubcategory,isQRCode,expTypes,theme,data,setShow,downloadProgress,showUrl,showUrlMessage,errors, openAiDialogOpen, openAiSelectedDialogData} = this.state;
    console.log('Final State',this.state);
    console.log('Final Errors',errors);

    if(!this.state.loaderFlag) return (<Loader text='Loading Experience Data ...' />);
    const {name,type} = openAiSelectedDialogData;
    if(token_expired) return (<Navigate to='/logout' />);
    
    return (
      <React.Fragment>
        <Modal show={setShow} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>
            <Modal.Title>Progress ({downloadProgress} %)</Modal.Title>
            <div className="progress mt-2" id="progress_bar" >
              <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={downloadProgress} aria-valuemin="0" aria-valuemax="100" style={{width: ' '+downloadProgress+'%'}}></div>
            </div>
          </Modal.Body>
        </Modal>

        <Box className="Page-Width-Vossle" style={{margin:"40px",paddingTop:"20px"}}>
          <ExperiencesStepper style={{marginTop:"40px"}} CurrentStepper={currentStepper} />
          {currentStep===3 && (
            <React.Fragment>
              {/* {`Details for step ${currentStep} Selected Experiecnce Type ${selectedExpType.exp_name} Sub Type ${selectedExpTypeSubcategory.exp_name}`} */}
              {errors.qrcode}
              {/* {isQRCode && config.webarEndpoint+data.slug_exp_name} */}
              
              {        
                openAiDialogOpen && (type === 'content' || type==='only_png' || type === 'image' || type === 'button' || type === 'arPortal')
                      && 
                this.openAiDialogBox(name,type)
              }
              <Grid sx={{background:'#F1F3F5',borderRadius:'10px'}}>
                <Grid xs={2}></Grid>
                <Grid xs={8}>
                  <ThemeProvider theme={theme}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        my:4,
                        mx:2,
                        py:4,
                      }}
                      onSubmit={this.handleSubmit}
                    >
                      <Grid xs={12}>{errors.global && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors.global}</Typography>) }</Grid>
                      {this.renderInput("exp_name", "text", "Experience Name", true, true)}
                      {showUrl && (<Typography paragraph sx={{pl:1, color:'green'}}>{showUrlMessage}</Typography>)}
                      {this.step3()}
                      {isQRCode && (
                        <div id="qrcode-logo" style={{display:'none'}}>
                          <QRCodeCanvas 
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level='H'
                            size={256}
                            value={config.webarEndpoint+data.slug_exp_name}
                            id="qrcode-bar"
                            includeMargin={true}
                            imageSettings={{src:VossleLogo,height: 55,width: 100, excavate:true}}
                          />
                        </div>
                      )}
                      <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
                        <RenderButton 
                          name = 'submit_button'
                          type = "submit"
                          label = {selectedExpType.id === 5 ? 'Next' : 'Submit'}
                          isDisabled = {isButtonDisabled ? false : true}
                          minWidth = {180}
                        />
                        {/* <Button className="button1" id="submit_button" type="submit">{selectedExpType.id === 5 ? 'Next' : 'Submit'}</Button> */}
                      </div>
                    </Box>
                  </ThemeProvider>
                </Grid>
                <Grid xs={2}></Grid>
              </Grid>
            </React.Fragment>)
          }
          {currentStep===3.5 && this.workflow()}
          {currentStep===4 && this.step4()}
        </Box>
      </React.Fragment>
    )
  }

}

export default withRouter(EditExperiences);