import _ from "lodash";
export function paginate(items, page, pageSize) {
    // console.log('Pagination Started',items);
    // console.log('Pagination Length',items.length);
    // var pg = page || 1,
    //     pgSize = pageSize || 100,
    //     offset = (pg - 1) * pgSize,
    //     pagedItems = _.drop(items, offset).slice(0, pgSize);
    //     console.log('Offset',offset);
    // return pagedItems;
    // return {
    //     page: pg,
    //     pageSize: pgSize,
    //     total: items.length,
    //     total_pages: Math.ceil(items.length / pgSize),
    //     data: pagedItems
    // };

    const startIndex = (page - 1) * pageSize;
    return _(items).slice(startIndex).take(pageSize).value();

    //   _.slice(items, startIndex);
    //   _.take()
}
