import React, { Component } from 'react'

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import {CopyAllOutlined} from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import Dns from '@mui/icons-material/Dns';
// import Public from '@mui/icons-material/Public';
// import People from '@mui/icons-material/People';
// import PermMedia from '@mui/icons-material/PermMedia';
// import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { IconButton, Typography } from '@mui/material';
// import ListItem from '@mui/material/ListItem';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

class LeftDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerWidth : 220,
            data: this.props.data,
            nodes: this.props.nodes ? true : false,
            open:true,
            stopen:true,
            ttopen:true,
        }
    }
    
    onDragStart = (event, nodeType) => {
        console.log('OnDrag start');
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };
    
    render() { 
        const {drawerWidth, open, stopen, ttopen, data, nodes} = this.state;
        return (
            <Box sx={{ display: 'flex',maxWidth:107 }}>
                <Box
                sx={{
                    bgcolor: '#464646',
                    // pb: stopen ? 2 : 0,
                    borderRadius:'10px',
                }}
                >
                    {nodes && stopen && data.map((item) => (
                        <div 
                            onDragStart={(event) => this.onDragStart(event, item.dragid)}
                            draggable
                            className={item.class}
                            key = {item.label}
                            style={{marginBottom:'2px',borderRadius:'10px',border:"7px solid #464646",display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                            <div style={{background:'#e1e1e1',marginTop:'10px',marginBottom:'2px',padding:'8px',borderRadius:"10px",height:"100%",display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                                <IconButton sx={{height:'40px',width:'40px'}}>
                                {item.icon}
                                </IconButton>
                            </div>
                            <Typography sx={{fontSize:"10px",color:'white'}} >
                                {item.label}
                            </Typography>
                            {/* <ListItemButton
                                key={item.label}
                                sx={{ py: 0, minHeight: 36, maxHeight: 36, maxWidth: 176, color: '#313131', backgroundColor: '#e1e1e1 !important', marginLeft: '25px', marginTop:'10px', borderLeft: `4px solid ${item.bordercolor}`, borderRadius: '5px' }}
                                onDragStart={(event) => this.onDragStart(event, item.dragid)}
                                draggable
                                className={item.class}
                            >
                                
                                <ListItemIcon sx={{ color: '#313131', height: '18px', width : '18px' }}>
                                    {item.icon}
                                </ListItemIcon>
                                <Divider/>
                                <ListItemText
                                    primary={item.label}
                                    primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                                />
                            </ListItemButton> */}
                        </div>
                    ))}
                    <div 
                        key='newNode'
                        className='dndnode'
                        onDragStart={(event) => this.onDragStart(event, 'default new')}
                        draggable
                        style={{marginBottom:'2px',borderRadius:'10px',border:"7px solid #464646",display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}
                    >
                        <div style={{background:'#e1e1e1',marginBottom:'4px',padding:'8px',borderRadius:"10px",height:"100%",display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                            <IconButton sx={{height:'40px',width:'40px'}}>
                                <CopyAllOutlined />
                            </IconButton>
                        </div>
                        <Typography sx={{fontSize:"12px",color:'white'}} >
                            Add Blank
                        </Typography>
                    </div>
                    {/* <ListItemButton
                        key='newNode'
                        sx={{ py: 0, minHeight: 36, maxHeight: 36, maxWidth: 176, color: '#313131', backgroundColor: '#e1e1e1 !important', marginLeft: '25px', marginTop:'10px', borderLeft: '4px solid #1a3b51', borderRadius: '5px' }}
                        onDragStart={(event) => this.onDragStart(event, 'default new')}
                        draggable
                        className='dndnode'
                    >
                        <ListItemIcon sx={{ color: '#313131', height: '18px', width : '18px' }}>
                            <CopyAllOutlined />
                        </ListItemIcon>
                        <ListItemText
                            primary='Add Blank'
                            primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                        />
                    </ListItemButton> */}
                </Box>
            </Box>
        );
    }
}
 
export default LeftDrawer;