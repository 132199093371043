import React, { Component } from 'react'
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Link,Navigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TokenIcon from '@mui/icons-material/Token';
import PieChartIcon from '@mui/icons-material/PieChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyIcon from '@mui/icons-material/Key';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import InsightsIcon from '@mui/icons-material/Insights';
import InfoIcon from '@mui/icons-material/Info';
import Collapse from '@mui/material/Collapse';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import * as Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import dayjs from 'dayjs';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import vossleLogo from '../../assets/logo.png';
import avatar1 from '../../assets/avatar1.png';
import avatar2 from '../../assets/avatar2.png';
import * as QRCode from 'easyqrcodejs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import '../../css/DrawerAndNavbar.css'
import allExperienceIcon from '../../assets/allExperienceIcon.png';
import face_detection_icon from '../../assets/face_detection_icon.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import game_icon from '../../assets/game_icon.png';
import markerbased_icon from '../../assets/markerbased_icon.png';
import markerless_icon from '../../assets/markerless_icon.png';
import workflow_icon from '../../assets/userflow.png';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import { ArrowRight } from '@mui/icons-material';
import {getNotification, markUserNotifications} from '../services/userService';
import {getUserDetails} from '../services/authService';
import withRouter from './../services/withRouter';
import { isArray } from 'lodash';
import { toast } from "react-toastify";
import { isBoolean } from 'lodash';
import Loader from './../Common/Loader';
import config from '../services/config.json';


class DrawerAndNavbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user:{},
      freeTrialDaysLeft:30,
      subscribed:false,
      currentPage:props.props.pageName,
      previousPageId:'',
      theme:createTheme({
        palette:{
          primary:{
            main:'#808080',
            darker:'#808080'
          }
        }
        ,
        '@global': {
          '*::-webkit-scrollbar': {
            width: '4px'
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
          }
        }
      }),
      open : true,
      // anchorElUser:null,
      // anchorElNav:'',
      drawerWidth : 217,
      direction:'ltr',
      profileAnchorEl:null,
      profileOpen:false,
      arExperienceCollapse:false,
      DrawerHeader : styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      })),
      notificationAncorEl:null,
      notificationOpen:false,
      notifications:[],
      AppBar : styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
      })(({ theme, open }) => ({
        
      })),
      Item : styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      })),
      selectedItemId:-1,
      headers:[
        {
          id:1,
          name:'Home',
          to:'/'
        },
        {
          id:2,
          name:'Dashboard',
          to:'/dashboard'
        },
        {
          id:3,
          name:'AR Experiences',
          to:'/ar'
        },
        {
          id:4,
          name:'Analytics',
          to:'/analytics'
        },{
          id:5,
          name:'Payments',
          to:'/payments'
        },{
          id:6,
          name:'Get API Key',
          to:'/keys'
        },{
          id:7,
          name:'Collections',
          to:'/category'
        },
        {
          id:8,
          name:'Create AR',
          to:'/create'
        },
        {
          id:9,
          name:'Payment Plans', 
          to:'/payment'
        },
        {
          id:10,
          name:'Settings',
          to:'/settings'
        },
        {
          id:11,
          name:'Logout',
          to:'/logout'
        },
        {
          id:12,
          name:'Edit AR',
          to:'/edit'
        },
        {
          id:13,
          name:'Payment',
          to:'/payment'
        }
      ],
      DrawerTopItems:[
        {
          id:1,
          name:'Home',
          icon:<HomeIcon sx={{size:"20px",height:"20px",width:"20px"}} />,
          accordian:false,
          to:'/'
        },{
          id:2,
          name:'Dashboard',
          icon:<DashboardIcon sx={{size:"20px",height:"20px",width:"20px"}} />,
          accordian:false,
          to:'/dashboard'
        },
        {
          id:3,
          name:'AR Experiences',
          icon:<TokenIcon sx={{size:"20px",height:"20px",width:"20px"}}/>,
          accordian:false,
          to:'/ar'
        },
        {
          id:4,
          name:'Analytics',
          icon:<PieChartIcon sx={{size:"20px",height:"20px",width:"20px"}}/>,
          accordian:false,
          to:'/analytics'
        },{
          id:5,
          name:'Payments',
          icon:<AccountBalanceWalletIcon sx={{size:"20px",height:"20px",width:"20px"}}/>,
          accordian:false,
          to:'/payments'
        },{
          id:6,
          name:'Get API Key',
          icon:<KeyIcon  sx={{size:"20px",height:"20px",width:"20px"}}/>,
          accordian:false,
          to:'/keys'
        },{
          id:7,
          name:'Collections',
          icon:<ThreeDRotationIcon sx={{size:"20px",height:"24px",width:"24px"}}/>,
          accordian:false,
          to:'/category'
        }
      ],
      DrawerBottomItems:[{
          id:10,
          name:'Settings',
          icon:<SettingsIcon sx={{size:"20px",height:"20px",width:"20px"}} />,
          accordian:false,
          to:'/settings'
        }
      ],
      token_expired:false,
      loaderFlag:true,
      sendToHomePage:false,
    }
  }

  async componentDidMount() {
    try{
      const {data:res} = await getUserDetails();
      if(res.status === true && isBoolean(res.status)) {
        const {data:user} = res;
        
        let {created_at,payment_status} = user;
        let subscribed = false;
        
        if(payment_status ==="Success" )
        {
          subscribed = true;
        }
        let diff = -1;
        if(created_at != null)
        {
          let start_date = created_at.split('T')[0];
          const end_date = dayjs(new Date()).format('YYYY-MM-DD');
          diff =  Math.floor(
            (Date.parse(end_date.replace(/-/g,'\/')) - Date.parse(start_date.replace(/-/g,'\/'))) / 86400000
          );
        }
        const formData = new FormData();
        formData.append("user_id", user.id);

        const {data:res2} = await getNotification(formData);
        if(res2.status === true && isBoolean(res2.status)) {
          const {data:notifications} = res2;
          this.setState({subscribed,notifications,user,loaderFlag:false,freeTrialDaysLeft:( diff ===-1?-1:30-diff)})
          let path = this.props.location.pathname;
          const {headers} = this.state;
          
          let selectedHeader = headers.filter(item => {
            if(item.to === path) { return item; }
            if(path==null) { return []; }
          });

          if(isArray(selectedHeader)  && selectedHeader.length>0) {
            this.handleSelectPage(selectedHeader[0].id);
          }
        }

        if(user.views === 0) {
          let DrawerTopItems = this.state.DrawerTopItems;
          let newTopItems = DrawerTopItems.map((DrawerTopItem) => {
            if(DrawerTopItem.to === '/payments' || DrawerTopItem.to === '/payment') {
              return ({...DrawerTopItem});
            } else {
              return ({
                id:DrawerTopItem.id,
                name:DrawerTopItem.name,
                icon:DrawerTopItem.icon,
                accordian:DrawerTopItem.accordian,
                to: '/'
              });
            }
          });
          this.setState({DrawerTopItems:newTopItems});
        }
      } else if(res.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400)
      } else {
        this.setState({ text:'User information not found.' });
      }


    }
    catch(ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }

  profileHandleClose = () => {
    this.setState({profileOpen:false})
    this.setState({profileAnchorEl:null})
  }
  
  profileHandleClick = (event) => {
    this.setState({profileOpen:true})
    this.setState({profileAnchorEl:event.currentTarget})
  }

  handleDrawerOpen = () => {
    this.setState({direction:'ltr'})
    this.props.setDrawerValue(true)
    this.setState({ open : true })
  }

  handleDrawerClose = () => {
    this.setState({direction:'rtl'})
    this.props.setDrawerValue(false)
    this.setState({open:false});
  }

  handleNotifications = async () => {
    try {
      const {user} = this.state;
      if(typeof user.id === 'undefined' && !user.id) {
        this.setState({text:'User not found, Redirecting to home page.'});
        setTimeout(()=>{
            this.setState({token_expired:true});
        },100);
      }
      
      const formData = new FormData();
      formData.append("user_id", user.id);

      const {data:res} = await markUserNotifications(formData);
      
      if(res.status && isBoolean(res.status)) {
        const {data:notifications} = res;
        this.setState({ notifications });
      } else if(res.status === 'Token is Expired') {
        setTimeout(()=>{
          this.setState({token_expired:true});
        },800);
      } else {
        toast.error('Not able to mark notifications as read');
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.message);
      } else if (ex.response && ex.response.status === 404) {
        toast.error(ex.message);
      } else {
        toast.error("Unexpected Error");
      }
    }
  };

  notificationClick = (event) =>{
    this.setState({notificationAncorEl:event.currentTarget,notificationOpen:true});
  }

  notificationClose = () =>{
    this.setState({notificationAncorEl:null,notificationOpen:false})
  }

  handleARExperienceClick = (id) => {
      this.handleSelectPage(id)
      if(this.state.arExperienceCollapse===true)
        this.setState({arExperienceCollapse:false})
      else
        this.setState({arExperienceCollapse:true})
  };

  handleSelectPage = (id) => {
    let {previousPageId} = this.state; 
    if(previousPageId !== '') {
      let oldItem = document.getElementById("selected_nav_item");
      if(oldItem != null) {
        oldItem.id = "drawer_item_"+ previousPageId
      }
    }
    let item1 = document.getElementById("drawer_item_"+id);
    if(item1 != null) {
      item1.id = "selected_nav_item";
    }
    this.setState({previousPageId:id})
  };
    
  openedMixin = (theme) => ({
    width: this.state.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  getPageHeading = location => {
    const {headers} = this.state;
    const header = headers.filter(item => {
      if(item.to === location.pathname) {
        return item.name; 
      } else {
        const head_array = item.to.split('/');
        let lopnm = location.pathname;
        const location_array = lopnm.split('/');
        if((isArray(head_array) && head_array.length > 1) && (isArray(location_array) && location_array.length > 1)) {
          if(head_array[1] === location_array[1]) return item.name;
        }
      }
      return false;
    });
    return isArray(header) && header.length > 0 ? header[0].name : location.pathname;
  };

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  render(){
    const {previousPageId,freeTrialDaysLeft,token_expired,DrawerBottomItems,subscribed,DrawerTopItems,notificationAncorEl,notificationOpen,direction,DrawerHeader,AppBar,Item,notifications,user,sendToHomePage} = this.state;
    const {notificationClick,notificationClose,handleDrawerOpen,handleDrawerClose,profileHandleClose,profileHandleClick} = this;

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
      ({ theme, open }) => ({
        width: this.state.drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...this.openedMixin(theme),
          '& .MuiDrawer-paper': this.openedMixin(theme),
        }),
        ...(!open && {
          ...this.closedMixin(theme),
          '& .MuiDrawer-paper': this.closedMixin(theme),
        }),
      }),
    )


    // if(loaderFlag) return (<Loader text={text} />);
    if(sendToHomePage) return (<Navigate to='/' />);
    if(token_expired) return (<Navigate to='/logout' />);

    let DrawerTopUpdatedItems;
    
    if(user.hasOwnProperty('tour'))
    {
      DrawerTopUpdatedItems = DrawerTopItems.filter((item)=>(item.id!==2));
    }
    else
    {
      DrawerTopUpdatedItems = DrawerTopItems;
    }

    return (
      <>
        <AppBar style={{ background:'white',fontFamily:'Noto Sans, Regular',width:`calc(100% - 60px`,height:'55px'}} position="fixed" open={this.state.open}>
          <Toolbar sx={{display:'flex', m:'0 !important',p:'0 !important',height:'100%'}}>
            <Grid spacing={1} container style={{height:'100%'}}>
              
              <Grid xs={5} sm={9} md={7} lg={5} xl={5} sx={{display:'flex',alignitems:'center'}} >
                <span style={{margin:"auto 35px",color:'black',fontSize:'24px'}}>
                  {/* {this.getPageHeading(this.props.location)} */}
                  {/* <div style={{width:"100%",marginLeft:'15px',marginTop:'5px'}}> */}
                    <Link onClick={()=>{window.location.href = config.currentWrokingWebsite}}>
                    <img src={vossleLogo} alt="Cinque Terre" height="30px" ></img>
                    </Link>
                  {/* </div> */}
                </span>
              </Grid>
              <Grid xs={0} sm={0} md={0} lg={3} xl={3} sx={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
              {
                (subscribed === false) && (
                  <React.Fragment>
                    <Button className="extra-large-screen-only" sx={{height:"30px",background:(freeTrialDaysLeft > 0 ?'#37BE70': '#FEE8EA'),border:(freeTrialDaysLeft > 0 ?'1px solid grey':''),color:'white',minWidth:(freeTrialDaysLeft > 0 ?"100%":"160px"),'&:hover':{background:'#37BE70 !important'},borderRadius:'20px',my:'auto',fontSize:'14px' }}> 
                      {
                        freeTrialDaysLeft > 0 ?
                          (<p style={{fontSize:'14px',color:'white',textTransform: 'capitalize' }}>
                          Free Trial Started | {freeTrialDaysLeft} days left
                          </p>):(
                            <p style={{width:"160px",fontSize:'14px',color:(freeTrialDaysLeft > 0 ? 'white':'#F62D4F'),textTransform: 'capitalize'}} >
                              Free Trial Ended
                            </p>
                            )
                      }
                    </Button>
                </React.Fragment>
                )
              }
              </Grid>
                <Grid   xs={0} sm={0} md={2} lg={2} xl={2} sx={{display:'flex',alignItems:'center'}}>    
                    <Button className='large-screen-only' 
                      sx={{mx:'auto',fontFamily:"Noto Sans",color:"#343434",height:"30px",border:'1px solid #B1B1B1',minWidth:"80%",borderRadius:'20px',textTransform: 'capitalize'}}
                      onClick={()=>{this.props.props.navigate("/payment")}}
                    >
                      { (subscribed === false) ? (
                        <React.Fragment>
                          <Grid xs={1} sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}}>
                           <BoltRoundedIcon className="blinking" sx={{maxWidth:"100%",height:'19px'}} />
                          </Grid>
                          <Grid xs={11} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>                        
                            Subscribe Now
                          </Grid>
                      </React.Fragment>):(
                      <div style= {{display:'flex',alignItems:'center',justifyContent:'flex-start'}} >Subscribed</div>
                      )}
                    </Button>
              </Grid>
              <Grid container xs={7} sm={3}  md={3} lg={2} xl={2}  sx={{height:"100%"}}>
                {user.views > 0 && (
                  <div style={{width:"30%",display:'flex',justifyContent:'flex-end',alignItems:'center',mt:'8px'}}  >
                    {this.props.location.pathname !== '/create' && (
                      <IconButton
                          size="large"
                          aria-label="show 17 new notifications"
                          color="inherit"
                          component={Link} 
                          to = '/create'
                          sx={{p:0,m:0,b:0}}
                        >
                          <AddCircleRoundedIcon className="medium-screen-only" sx={{height:"36px",width:"36px",color:'#FF821C'}}/>
                        </IconButton>
                    )}
                  </div>
                )}
                
                <div style={{width:"30%",display:'flex',justifyContent:'flex-end',alignItems:'center',mt:'8px'}}   >
                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"
                    >
                      <ThemeProvider theme={this.state.theme}>
                        <Badge badgeContent={notifications === 'data_not_found' ? '0' : notifications.length}  color="primary" style={{color:'#682CEA'}} >
                          
                          <NotificationsIcon 
                            onClick={notificationClick} size='large' 
                            aria-controls={notificationOpen ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={notificationOpen ? 'true' : undefined}
                            style={{color:'grey',fontSize:'32px',height:"25px",width:"25px"}}
                          />
                        </Badge>
                      </ThemeProvider>
                    </IconButton>
                    <Menu
                        anchorEl={notificationAncorEl}
                        id="account-menu"
                        open={notificationOpen}
                        onClose={notificationClose}
                        onClick={notificationClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        <div style={{minWidth:"300px",padding:'10px'}}>
                          <Grid container>
                            
                            <Grid xs={7}>
                            <Typography  sx={{fontSize:'20px',fontWeight:'500',ml:1}}>
                              Notification
                            </Typography>
                            </Grid>
                            <Grid xs={5} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                              <Button style={{textDecoration:'none',fontSize:'12px'}} onClick={this.handleNotifications}>
                                <Typography sx={{textTransform:'capitalize',fontSize:'12px'}}>mark all as read</Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid xs={12} sx={{mt:1,color:'grey',fontSize:'16px',ml:2}}>Today</Grid>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                          {notifications !== 'data_not_found' && (
                            notifications.map((notification)=> {
                              return (
                                <>
                                  <ListItem alignItems="flex-start">
                                  <ListItemAvatar  sx={{}} >
                                    <Avatar alt="Remy Sharp" sx={{height:'70px', width:'70px'}} />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Grid container>
                                        <Grid xs={9}>
                                        <Typography sx={{fontSize:'14px',fontWeight:'600'}}>Vossle</Typography>
                                        </Grid>
                                        <Grid xs={3} sx={{fontSize:'10px',color:'lightgrey'}}>
                                          {notification.created_at}
                                        </Grid>
                                      </Grid>
                                    }
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                        >
                                        </Typography>
                                        {" — "+notification.message+"…"}
                                      </React.Fragment>
                                    }
                                  />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </>
                              )
                            }))
                          }
                        </List>
                        
                      </Menu>
              
                </div>

                <div style={{width:"30%",display:'flex',justifyContent:'flex-end',alignItems:'center',mt:'8px'}} xs={2} sm={2} md={2} lg={4} >
                    <Tooltip title="Account settings">
                    <IconButton
                      onClick={profileHandleClick}
                      size="small"
                      
                      aria-controls={this.state.profileOpen ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={this.state.profileOpen ? 'true' : undefined}
                    >
                      <Avatar sx={{ width: 32, height: 32,border:'1px solid lightgrey' }} src= {user.profile_pic ? config.assetsEndpoint+user.profile_pic:''} >{user.name && user.name[0].toUpperCase()}</Avatar>
                      
                      {this.state.profileOpen ? 
                      <ArrowDropDownIcon sx={{width:'16px',height:'16px',color:'grey'}} />
                      : 
                      <ArrowRightIcon sx={{color:'grey',width:'16px',height:'16px'}} />
                      }
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={this.state.profileAnchorEl}
                    id="account-menu"
                    open={this.state.profileOpen}
                    onClose={profileHandleClose}
                    onClick={profileHandleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 138,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem disabled sx={{color:'#682CEA',fontWeight:'600',pb:0}}>
                      {user.name && this.capitalizeFirstLetter(user.name)}
                    </MenuItem>
                    <MenuItem disabled sx={{fontWeight:'400',pt:0}}>
                      {user.email && user.email}
                    </MenuItem>
                    <Divider />
                    <MenuItem>

                    <Link to="settings" style={{textDecoration:'none',width:'100%',color:'black'}}>
                      <div style={{width:'100%',display:'flex',alignItems:'center'}}>
                        <span style={{width:'100%'}}>Settings</span> 
                        <SettingsIcon  fontSize="small" />
                      </div>
                    </Link>
                    </MenuItem>
                    <MenuItem >
                      <Link to="/logout" style={{textDecoration:'none',width:'100%',color:'black'}}>
                        <div style={{width:'100%',display:'flex',alignItems:'center',underline:'none'}}>
                          <span style={{width:'100%'}}>Logout</span>   
                          <LogoutIcon fontSize="small" />
                        </div>                         
                      </Link>
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>

            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer className='Drawer' variant="permanent" open={this.state.open} >
          <div 
            style={{background:'#F1F3F5',minHeight:'100vh'}}>
            <DrawerHeader style={{maxHeight:'8vh'}}>
             {
                direction === 'rtl' ? (
                  <IconButton onClick={handleDrawerOpen} style={{width:"100%",marginLeft:'55px',marginTop:'0px'}}>
                  <MenuIcon style={{height:"24px",width:'24px',color:'grey'}} /> 
                </IconButton>
                ):(
                  <div style={{display:'flex',width:"100%",marginLeft:'15px',marginTop:'0px'}}>
                    <div style={{display:'flex',alignItems:'center',marginLeft:'10px',justifyContent:'flex-start',width:"80%"}}>
                    
                      <Link onClick={()=>{window.location.href = config.currentWrokingWebsite}}>
                        <img src={vossleLogo} alt="Cinque Terre" height="30px" ></img>
                      </Link>
                       {/* <img src={vossleLogo} alt="Vossle logo" height="30px" /> */}
                    </div>
                    <div style={{display:'flex',alignitems:'flex-start',justifyContent:'flex-end',width:"20%"}}>
                    <IconButton onClick={handleDrawerClose} > 
                      <MenuOpenIcon style={{height:"24px",width:'24px',color:'grey'}} />
                    </IconButton>
                    </div>
                </div>
                  // <div >
                  //   {/* <img src={vossleLogo} alt="Cinque Terre" height="30px" /> */}
                  // </div>
                )
              }
              {
                direction === 'rtl'?
                (
                  <div></div>
                  )
                :
                  (<div></div>
                  )
                    
              }
              
            </DrawerHeader>
            <List sx={{fontFamily:'Noto Sans',minHeight:'88vh'}}>
              {DrawerTopUpdatedItems.map((item, index) => (
                <ListItem key={index}  
                  sx={{color:'black',p:0,b:0,mb:'1px'}} component={Link} to={item.to} 
                >
                  {
                    item.accordian === false
                    ?(
                    <div style={{paddingLeft:"10px",paddingRight:"10px",width:"100%"}}>
                      <ListItemButton
                        sx={{
                          height:'44px',
                          justifyContent: this.state.open ? 'initial' : 'center',
                          px: 2.5,
                          marginLeft:'10px solid #F1F3F5',
                          marginRight:'10px solid #F1F3F5',
                          borderRadius:"10px 10px 0px 0px",
                          // color:
                          color:(previousPageId === item.id?"#682CEA":'#4e5d71')
                        }}
                        className = "drawer-items"
                        onClick = {()=>this.handleSelectPage(item.id)}
                        id = {"drawer_item_"+item.id }
                      >
                        <ListItemIcon
                          className="drawer_child_class"
                          sx={{
                            minWidth: 0,
                            mr: this.state.open ? 3 : 'auto',
                            justifyContent: 'center',
                            color:(previousPageId === item.id?"#682CEA":"")
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText className="drawer_child_text" primary={item.name} sx={{ color:(previousPageId === item.id?"#682CEA":'#4e5d71'), opacity: this.state.open ? 1 : 0 , '&:hover':{color:'white'} }} />
                      </ListItemButton>
                    </div>
                    
                    ):
                    (
                    <div style={{width:'100%'}}>
                        <ListItemButton
                            aria-controls="panel1a-content"
                            sx={{
                              justifyContent: this.state.open ? 'initial' : 'initial',
                              Py: 0,
                              border:'0px !important',
                              margin:'0px !important',
                              borderLeft:'3px solid #F1F3F5',
                              borderRight:'3px solid #F1F3F5',
                              borderRadius:"10px 10px 0px 0px",
                              color:'#4e5d71'
                            }}
                                /* Old browsers */
                            className = "drawer-items"
                            id = {item.id}
                            onClick={()=>{this.state.handleARExperienceClick(item.id)}}>
                          <ListItemIcon
                              className="drawer_child_class"
                              sx={{
                                minWidth: 0,
                                mr: this.state.open ? 3 : 'auto',
                                justifyContent: 'center',
                                padding:'0 !important',
                              }}
                            >
                              {item.icon}
                            </ListItemIcon>
                      

                            <ListItemText 
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              primary={item.name} sx={{p:0, opacity: this.state.open ? 1 : 0 ,display:'flex'}} 
                            />
                            
                            {/* {this.state.open && (this.state.arExperienceCollapse ? <ExpandLess /> : <ExpandMore />)} */}
                        </ListItemButton>
                        <Collapse sx={{background:'white'}} in={this.state.arExperienceCollapse} timeout="auto" unmountOnExit>
                          <List component="div" >
                            {
                              item.children.map((childItem)=>{
                                return(
                                  <ListItemButton sx={{ display:'flex',justifyContent:'flex-start',alignItems:'flex-start',alignItems:'left' }} component={Link} to={childItem.to} >
                                    <img width="25px" src={childItem.icon} alt="icons"/>
                                    <ListItemText sx={{ml:3}} primary={childItem.name} />
                                  </ListItemButton>
                                )
                              })
                            }
                            {/* <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText primary="Starred" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText primary="Starred" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText primary="Starred" />
                            </ListItemButton> */}
                          </List>
                        </Collapse>
                    </div>
                    )
                  }
                </ListItem>
              ))}

              {DrawerBottomItems.map((Item, index) => (              
                <ListItem key={index}  
                  sx={{color:'black',p:0,b:0,mb:'1px'}} component={Link} to={Item.to} 
                >
                  {
                    <div style={{paddingLeft:"10px",paddingRight:"10px",width:"100%"}}>
                      <ListItemButton
                        sx={{
                          height:'44px',
                          justifyContent: this.state.open ? 'initial' : 'center',
                          px: 2.5,
                          marginLeft:'10px solid #F1F3F5',
                          marginRight:'10px solid #F1F3F5',
                          borderRadius:"10px 10px 0px 0px",
                          color:'#4e5d71'
                        }}
                        className = "drawer-items"
                        onClick = {()=>this.handleSelectPage(Item.id)}
                        id = {"drawer_item_"+Item.id }
                      >
                        <ListItemIcon
                          className="drawer_child_class"
                          sx={{
                            minWidth: 0,
                            mr: this.state.open ? 3 : 'auto',
                            justifyContent: 'center',
                            color:(previousPageId === Item.id?"#682CEA":'#4e5d71')
                          }}
                        >
                          {Item.icon}
                        </ListItemIcon>
                        <ListItemText className="drawer_child_text" primary={Item.name} sx={{  color:(previousPageId === Item.id?"#682CEA":'#4e5d71'), opacity: this.state.open ? 1 : 0 ,'&:hover':{color:'white'}}} />
                      </ListItemButton>
                    </div>
                    
                  }
                </ListItem>
              ))}
            </List>  
          </div>
        </Drawer>
      </>
    );
  }

}

export default withRouter(DrawerAndNavbar);