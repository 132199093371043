import React, { Component } from 'react'
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { toast } from "react-toastify";
import { Grid,Box } from '@mui/material';
import { isBoolean } from 'lodash';
import Button from '@mui/material/Button';
import { Navigate } from 'react-router-dom';
import mounseImage from '../assets/mouse.png';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Loader from '../Components/Common/Loader';
import ArGLBCard from '../Components/cards/ArGLBCard';
import { paginate } from './../Components/utils/paginate';
import withRouter from '../Components/services/withRouter';
import Paginations from './../Components/Common/paginations';
import { getCollectionById } from '../Components/services/collectionService';


class Collection extends Component {
  constructor(props)
  { 
    super(props)
    this.state = {
      onMount:{
        status:true,
        collection_content_file:'',
        id:'',
      },
      navigateToCategory:false,
      ExperienceOpen:false,
      ExperienceOption:null,
      totalCollectionData:[],
      collectionData:[],
      currentFilter:'All',
      selectedCategory:{name:''},
      filters:[
        {id:1,name:'Markerless',value:'Recent',action:'exp_type'},
        {id:2,name:'Markerbased',value:'Deactive',action:'exp_type'},
        {id:3,name:'Games',value:'games',action:'exp_type'},
        {id:4,name:'Tryons',value:'Ascending',action:'exp_type'},
        {id:5,name:'Workflow',value:'workflow',action:'exp_type'},
        {id:6,name:'All',value:'all',action:'exp_type'},
        {id:7,name:'Trending',value:'all',action:'exp_type'},
        {id:8,name:'ads',value:'advertisement',action:'exp_type'}
      ],
      selectedFilter:{id:6,name:'All',value:'all',action:'all'},
      pageSize:6,
      currentPage:1,
      token_expired:false,
      loaderFlag:true,
      text:'Loading Data...',
    }
  }

  handleExperienceOpen = (event) =>{
    this.setState({ExperienceOpen:true})
    this.setState({ExperienceOption:event.currentTarget})
  }

  stringCapitalLetter = (name)=>{
    return name[0].toUpperCase() + name.substring(1,name.length);
  }

  handleExperienceClose = (filter)=> {
    this.setState({currentFilter:filter.name,ExperienceOpen:false,ExperienceOption:null,selectedFilter:filter})
  };
  
  async componentDidMount(){

    try{
      const param = this.props.params.id;
      if(Number(param)){
        const {data:res} = await getCollectionById(param);
        if(res.status === true && isBoolean(res.status)) {
          const {data:collectionData} = res;
          this.setState({collectionData,totalCollectionData:collectionData,loaderFlag:false});
        } else if(res.status === 'Token is Expired') {
          this.setState({text:'User token expired, Redirecting to login page.'});
          setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
          },400)
        } else {
          this.setState({ text:'Payment or User information not found.', });
        }
      }
      else console.log("Id should be a number please show some error here");

      if(this.props.location.state){
        this.setState({selectedCategory:this.props.location.state.selectedCategory})
      }

    }
    catch(ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  };

  selectedCollectionItem = (Item) => {
    this.props.navigate(
      "create",
      {
        state:{
          onMount:{status:true,collection_content_file:Item.assets,id:Item.id}
        }
      }
    )
  };

  handlePageChange = (value) => {
    this.setState({ currentPage: value });
  };

  render() {
    const {handleExperienceOpen,handleExperienceClose} = this;
    
    const { length: count } = this.state.collectionData;
    const {
      pageSize,
      currentPage,
      selectedFilter,
      collectionData:allData,
      token_expired,
      loaderFlag,
      text,
      ExperienceOpen,
      ExperienceOption,
      selectedCategory,
      navigateToCategory
    } = this.state;
    
    if(loaderFlag) return (<Loader text={text} />);
    if(token_expired) return (<Navigate to='/logout' />);

    let filterid = selectedFilter.id ;
    if(selectedFilter.id === 2 || selectedFilter.id === 1 || selectedFilter.id === 5 || selectedFilter.id === 8){
      filterid = 2;
    }
    
    const filtered = selectedFilter && (selectedFilter.id === 6 || selectedFilter.id === 7 ) ? (allData) : (
      ( allData.filter(fil => { return (filterid === fil.exp_type_id)}))
    );
    const collectionData = paginate(filtered, currentPage, pageSize);
    
    if(navigateToCategory){
      return (<Navigate to="/category" />)
    }

    return (
      <Box  className="Page-Width-Vossle">
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',marginBottom:'40px'}}>
          <p style={{textAlign:'center',fontSize:'30px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',color:'#053630',marginTop:'16px'}}>
            {selectedCategory.name}
          </p>
          <p style={{textAlign:'center',fontFamily:'Noto Sans',fontSize:'13px',color:'#343434'}}>
            Step into endless possibilities 
          </p>
        </div>
        
        <Grid container sx={{mb:6,display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Grid xs={2}></Grid>
          
          <Grid xs={2} sx={{display:'flex',justifyContent:'center'}}>
            <Button
                className={
                  (
                    this.state.currentFilter==="Markerless" || this.state.currentFilter==="Markerbased" ||
                    this.state.currentFilter==="Tryon" || this.state.currentFilter==="Workflow" || 
                    this.state.currentFilter==="Games"
                  )
                ?"selectfilterDesign":"filterDesign"}
                sx={{width:"120px",borderRadius:"50px",color:'#454747'}}
                id="fade-button"
                aria-controls={this.state.ExperienceOpen ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.ExperienceOpen ? 'true' : undefined}
                onClick={handleExperienceOpen}
                // onClose={()=>handleExperienceClose(this.state.currentFilter)}
                // sx={{height:'30px',border:'0.1px solid #682CEA',color:(this.state.arExperienceFilterType==='created_at'?'white':'#682CEA') ,background:(this.state.arExperienceFilterType==='created_at'?'#682CEA':'#F1F3F5'),borderRadius:'6px'}} 
                endIcon={this.state.ExperienceOpen ? <ArrowDropDownIcon />:<ArrowRightIcon />}
            >
                Experience
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                'aria-labelledby': 'fade-button',
                }}
                anchorEl={ExperienceOption}
                open={ExperienceOpen}
                onClose={()=>handleExperienceClose(this.state.selectedFilter)}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={()=>handleExperienceClose({id:1,name:'Markerless',value:'Recent',action:'exp_type'})}>Markerless</MenuItem>
                <MenuItem onClick={()=>handleExperienceClose( {id:2,name:'Markerbased',value:'Deactive',action:'exp_type'})}>Markerbased</MenuItem>
                <MenuItem onClick={()=>handleExperienceClose({id:3,name:'Games',value:'games',action:'exp_type'})}>Games</MenuItem>
                <MenuItem onClick={()=>handleExperienceClose({id:4,name:'Tryons',value:'Ascending',action:'exp_type'})}>Tryon</MenuItem>
                <MenuItem onClick={()=>handleExperienceClose({id:5,name:'Workflow',value:'workflow',action:'exp_type'})}>Workflow</MenuItem>
                <MenuItem onClick={()=>handleExperienceClose({id:8,name:'advertisement',value:'advertisement',action:'exp_type'})}>Advertisement</MenuItem>
            </Menu>
          </Grid>

          <Grid xs={2} sx={{display:'flex',justifyContent:'center'}}>
            <Button
                  id="fade-button"
                  aria-haspopup="true"
                  sx={{width:"120px",borderRadius:"50px",color:'#454747'}}
                  // sx={{height:'30px',border:'0.1px solid #682CEA',color:'white' ,background:'#682CEA',borderRadius:'6px'}} 
                  className={this.state.currentFilter==="All"?"selectfilterDesign":"filterDesign"}
                  onClick={()=>handleExperienceClose({id:6,name:'All',value:'all',action:'all'})}
              >
              All
            </Button>
          </Grid>

          <Grid xs={2} sx={{display:'flex',justifyContent:'center'}}>
            <Button
                id="fade-button"
                aria-haspopup="true"
                sx={{width:"120px",borderRadius:"50px",color:'#454747'}}
                // sx={{height:'30px',border:'0.1px solid #682CEA',color:(this.state.arExperienceFilterType==='created_at'?'white':'#682CEA') ,background:(this.state.arExperienceFilterType==='created_at'?'#682CEA':'#F1F3F5'),borderRadius:'6px'}} 
                onClick={()=>handleExperienceClose({id:6,name:'Trending',value:'all',action:'all'})}
                className={this.state.currentFilter==="Trending"?"selectfilterDesign":"filterDesign"}
            >
              Trending
            </Button>
          </Grid>

          <Grid xs={2}>
            <a
                style={{color:'#1C7ED6',textDecoration:'underline',cursor:'pointer'}} 
                onClick={()=>handleExperienceClose({id:6,name:'All',value:'all',action:'all'})}
            >
                Clear Filter
            </a>
          </Grid>
              
          <Grid xs={2}></Grid>
        </Grid>

        <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Grid xs={11} sx={{mb:2,mt:1,ml:'10px'}}>
            <div style={{display:'flex'}}><ArrowBackIcon style={{marginRight:"16px",fontSize:'20px',cursor:'pointer'}} onClick={()=>{this.setState({navigateToCategory:true})}}/>{this.stringCapitalLetter(this.state.currentFilter)}</div>
          </Grid>
        </Grid>

        <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          { collectionData.length > 0 
            ?
              <ArGLBCard selectedItems={this.selectedCollectionItem} collection={collectionData} style={{background:'red'}} />
            : 
            <Grid container>
              <Grid xs={12} sx={{fontSize:"26px",fontWeight:"medium",display:'flex',justifyContent:'center',alignItems:'center'}}>
                Oops,  No Glb Found ..
              </Grid>
              <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img src={mounseImage} />
              </Grid>
            </Grid>
          }
          <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Paginations
              itemsCount={filtered.length}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              currentPage={currentPage}
            />
          </Grid>
        </Grid>
      </Box> 
    )
  }

}


export default withRouter(Collection);