import React, { Component } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { Modal, Box, Button, Grid } from '@mui/material';
import { createTheme,ThemeProvider } from '@mui/material/styles';
import { Navigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Step1 from './createExperience/Step1';
import Step2 from './createExperience/Step2';
import Step4 from './createExperience/Step4';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Typography from '@mui/material/Typography';
import AllInputFields from '../Components/InputFields/AllInputFields';
import ExperiencesStepper from '../Components/Stepper/ExperiencesStepper';
import * as QRCode from 'easyqrcodejs';
import UploadGlbDialog from '../Components/Common/UploadGlbDialog';
import { getCategoriesExpBased, getCollectionByIdExpBased} from '../../src/Components/services/collectionService';
import withRouter from '../Components/services/withRouter';
// CSS and Images
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import "../css/CreateExperiences.css";
import VossleLogo from '../assets/logo.png';

// New Added Services
import { getExperienceType, createExperience,getExperienceSubCategory } from './../Components/services/arService';
import { checkExpName } from '../Components/services/workFlowService';
import { toast } from "react-toastify";
import config from './../Components/services/config.json';
import Info from '@mui/icons-material/Info';
import CreateWorkFlow from '../Components/WorkFlow/CreateWorkFlow';
import Loader from './../Components/Common/Loader';
import RenderButton from '../Components/InputFields/RenderButton';
import { isBoolean } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// For Marker
import {Helmet} from "react-helmet";

import AWS from 'aws-sdk';

const InitialState = {
  openAiSelectedDialogData:{
    name:'',
    type:'',
  },
  loadingOpenAiInfo:false,
  resetState:false,
  isButtonDisabled:true,
  subCategoryLoader:true,
  isChildLoading:false,
  selectedItem:{},
  openAiDialogOpen:false,
  slugName:'',
  lipstick_with_textures:[''],
  token_expired:false,
  onMountStep2:{status:'false'},
  phase:1,
  selectedGLB:{},
  searchExpTypeByString:'',
  selectedCategoryItems:{},
  selectFileFromGallery:'',
  selectFileFromGalleryType:'',
  allCategory:[],
  fullScreenDialog:false,
  openDialog:false,
  UploadGlbCurrentPage:1,
  data:{
    repeat_overlay:true,
    use_content_audio:false,
    openAiGeneratedText:'',
    openAiGeneratedImage:'',
    exp_name:'',
    slug_exp_name:'',
    label:'',
    website:'',
    light_range:1,
    detection_point:'',
    redirect_to_website:'False',
    background_ImageVsColor:'FALSE',
    redirection_time:null,
    ga_code_exp:'',
    enable_geo_location:'off',
    latitude:'',
    longitude:'',
    distance:'',
    enable_placement:"False",
    text_to_display:'',
    bullet_frq:0.5,
    game_duration:30,
    feedbackForm_game:'',
    image_speed:0.5,
    lipstick_colour_1:'#FF0000',
    texture_1:'glossy'
  },
  files:{},
  filesType:{},
  errors:{global:''},
  loaderFlag:true,
  qrcode:'',
  markerBuffer:'',
  isQRCode:false,
  ArrayUploadData:[{ImageName:'imagename',ImageType:'text',FileName:'content',FileType:'image'}],
  SingleArrayUploadFileData:[{FileName:'content',FileType:'glb'}],
  currentStepper:{'currentStep':0,'totalSteps':3},
  theme:createTheme({
    palette:{
      primary:{
        main:'#682CEA',
        white:'#FFFFFF',
        darker:'#682CEA'
      }
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.4em'
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey'
      }
    }
  }),
  currentStep:1,
  expTypes:[],
  selectedExpType:{},
  selectedCollection:[],
  selectedExpTypeSubcategory:{},
  step2Data:[],
  step4Data:{
    'url':'',
    'qrcode':'',
    'marker_image':''
  },
  setShow:false,
  downloadProgress:0,
  showUrl:false,
  showUrlMessage:'',
  nameWithExt:'',
  nameWOExt:'',
  arExp:{},
  markerProgress:0,
}

let experiences = [];

class ArCreateExperiences extends AllInputFields {
  
  constructor(props) {
    super(props)
    // this.qrcode = React.createRef();
    this.state = InitialState;
  }

  handleFullScreenDialogOpen = async (name,type) =>{
    try{
      const {selectedExpType,selectedExpTypeSubcategory} = this.state;

      const data = new FormData();
      data.append("exp_type_id", selectedExpType.id);
      if(selectedExpTypeSubcategory.hasOwnProperty('id')) data.append("sub_exp_type_id", selectedExpTypeSubcategory.id);
      
      const {data:allCategory} = await getCategoriesExpBased(data);
      if(allCategory.status && isBoolean(allCategory.status)) {
        // console.log("my total catetgories are", allCategory);
        // console.log("create experience page")
        this.setState({fullScreenDialog:true,UploadGlbCurrentPage:1,selectFileFromGallery:name,selectFileFromGalleryType:type,allCategory:allCategory.data, loaderFlag:false})  
      } else if(allCategory.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:true});
        },400)
      }    
    }
    catch(ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  
  }

  ColorWithLabel = (name,label,isRequired) =>{
    return(
      <div style={{width:"100%",marginBottom:"20px",background:'white',padding:'20px',borderRadius:'10px'}}>
        <Typography sx={{fontWeight:'bold',mb:1}}>{label}</Typography>
        {this.ColorPicker(name,label,isRequired)}
      </div>
    )
  }

  async componentDidMount() {
    try {
      const { data:expTypes } = await getExperienceType();
      // console.log("my experiences data is",expTypes)
      if(expTypes.status === true && isBoolean(expTypes.status))
      {
        experiences = expTypes.data;
        this.setState({expTypes:expTypes.data, loaderFlag:false, currentStepper:{'currentStep':0,'totalSteps':3}});
      }
      else if(expTypes.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }

  selectedCategory  = async (data) => {
    try
    {
      this.setState({phase:2,isChildLoading:true,selectedItem:data,UploadGlbCurrentPage:1});

      const {selectedExpType,selectedExpTypeSubcategory} = this.state;

      const formData = new FormData();
      formData.append("exp_type_id", selectedExpType.id);
      if(selectedExpTypeSubcategory.hasOwnProperty('id')) formData.append("sub_exp_type_id", selectedExpTypeSubcategory.id);
      formData.append("category_id", data.id);

      let {data:selectedCollection} = await getCollectionByIdExpBased(formData);
      if(selectedCollection.status && isBoolean(selectedCollection.status) )
      {
      // console.log("my selected Collection data is",selectedCollection)
        this.setState({selectedCollection:selectedCollection.data})
        setTimeout(()=>{
          this.setState({isChildLoading:false})
        },200)
      }
      else if(selectedCollection.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400)
      }
    }
    catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }

  handleFullScreenDialogClose = () => {
    this.setState({fullScreenDialog:false,phase:1})
  }

  handleState = (data) => {
    let currentStepper = this.state.currentStepper;
    if(data.parent_id)
    {
      this.setState({selectedExpTypeSubcategory:data})
      this.setState({currentStep:3})
      currentStepper['currentStep']++;
      this.setState({currentStepper})
    }
    else
    {
    // console.log("handle category from handleState",data.exp_type)
      
      // 3d Ads to Ads change here
      if(data.exp_type === 'workflow'){
       currentStepper['totalSteps'] = 4;
       this.setState({currentStepper})
      }
      if(data.exp_type === 'games' || data.exp_type === 'tryon' || data.exp_type === 'ads') 
      {
        this.getSubCategory(data);
      }
      else 
      {
        this.setState({currentStep:3});
        currentStepper['currentStep']++;
        this.setState({currentStepper})
      }
      this.setState({selectedExpType:data});
    }
  }
  
  selectedItems = (data,type) => {
    
    const files = {...this.state.files}
    const filesType = {...this.state.filesType}
    const fileAddress = config.assetsEndpoint+data.assets;
    let newData = {...this.state.data};
    let SingleArrayUploadFileData = [...this.state.SingleArrayUploadFileData]

    type = this.state.selectFileFromGalleryType;
    let curfile = this.state.selectFileFromGallery;
    const stateData = {...this.state.data};
    if(type === 'content' || curfile === 'content')
    {
      files[this.state.selectFileFromGallery] = fileAddress;
      newData['library_assets'] = data.id;
      this.setState({data:{...stateData,library_assets:data.id,files}})
    }
    else if(type === 'only_glb'){
      files[this.state.selectFileFromGallery] = fileAddress;
      newData['library_assets_tossing'] = data.id;
      delete files[this.state.selecteFileFromGallery];
    }
    else{
      this.setState({[this.state.selectFileFromGallery]:fileAddress})
      newData[this.state.selectFileFromGallery] = data.id;
      if(! (files[this.state.selectFileFromGallery] === 'undefined')){
        delete files[this.state.selectFileFromGallery];
      }
    }
    filesType[this.state.selectFileFromGallery] = 'glb';
    this.setState({fullScreenDialog:false,filesType,files,phase:1,SingleArrayUploadFileData,data:newData,errors:{}})
  }

  handlePageChange = (value) => {
    this.setState({ UploadGlbCurrentPage: value });
  };

  OpenDialogBox = (name) =>{
    
    const fullScreenDialog = this.state.fullScreenDialog;
    const handleFullScreenDialogClose = this.handleFullScreenDialogClose;
    return(
      <div>
        <UploadGlbDialog 
          handlePageChange={this.handlePageChange} 
          currentPage = {this.state.UploadGlbCurrentPage} 
          isloading={this.state.isChildLoading} 
          id={name} 
          fieldname={name} 
          selectedItems={this.selectedItems} 
          selectedCategory={this.selectedCategory} 
          phase={this.state.phase} 
          fullScreenDialog={this.state.fullScreenDialog} 
          handleFullScreenDialogClose={this.handleFullScreenDialogClose} 
          allCategory = {this.state.allCategory} 
          selectedItem={this.state.selectedItem} 
          selectedCategoryCollection={this.state.selectedCollection}
        />
      </div>
    )
  }

  randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  }

  hexToRgbNew = (hex) => {
    
    if(hex.includes('#')){
      hex = hex.split("#")[1];
    }
    
    var arrBuff = new ArrayBuffer(4);
    var vw = new DataView(arrBuff);
    vw.setUint32(0,parseInt(hex, 16),false);
    var arrByte = new Uint8Array(arrBuff);
  
    let r = arrByte[1]/255;
    let g = arrByte[2]/255;
    let b = arrByte[3]/255;
   
    return Math.round(r * 10) / 10 + " " + Math.round(g * 10) / 10 + " " + Math.round(b * 10) / 10; 
  }

  markerbaseUploadInputField = () => {
    // const rand = this.randomNumber(1,5);
    const {marker_image} = this.state;
    const step4Data = {...this.state};

    if(!(typeof marker_image === 'undefined')){
      step4Data['marker_image'] = marker_image
    }

    return(
      <div style={{background:'white',border:'1px solid grey',borderRadius:'10px',marginBottom:"16px"}}>
        <Typography sx={{p:1,pl:3,fontWeight:'bold',fontSize:'16px'}}>
          Marker Image
        </Typography>
        {/* <span id="progress"></span> */}
        <div style={{padding:'0px 20px 0px 20px'}}>
          {this.UploadInputFieldExcludeGLB('marker_image','','image',true)}
        </div>
        <div style={{textAlign:"center",marginBottom:'10px'}}>
          You can download this marker image from the list of AR Experiences.
        </div>
        {/* <div id="container"></div> */}
      </div>
    )
  };

  startMarkerProcessing = () => {
    const {marker_image_processing:files} = this.state;
    console.log('File loading', files);
    if (files.length === 0) return;
    const ext = files[0].name.split('.').pop();
    console.log('File loading Ext', ext);
    if (ext === 'mind') {
        this.loadMindFile(files[0]);
    } else {
        this.compileFiles(files);
    }
  };

  uploadBuffer = (buffer) => {
    console.log('Buffer',buffer);
    try {
      var blob = new Blob([buffer]);
    
      /* Writing custom queries for aws s3 image */
      AWS.config.update({
        region: 'ap-south-1',
        accessKeyId: "AKIAWKZVOJY2LEAKNWC5",
        secretAccessKey: "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"
      });
      
      const fileName = 'shopify/assets/PatternFile/'+Date.now()+'_targets.mind';
      
      let s3 = new AWS.S3();

      const params = {
        Bucket: config.bucketName, // pass your bucket name
        Key: fileName,
        ACL: 'public-read',
        Body: blob
      };

      const result = s3.putObject(params, function(err,res) {
        if(err) {
          console.log('S3 Error',err); throw err;
        } else {
          console.log(`File uploaded successfully at ${res.location}`)
        }
      });
      console.log('S3 Result',result);
      
      const data = { ...this.state.data };
      data['markerBuffer'] = fileName;
      this.setState({data});
    } catch(ex) {
      console.log('Error',ex);
    }
    
    // var aLink = window.document.createElement('a');
    // aLink.download = 'targets.mind';
    // aLink.href = window.URL.createObjectURL(blob);
    // aLink.click();
    // window.URL.revokeObjectURL(aLink.href);
  };

  showData = (data) => {
      console.log("data", data);
      for (let i = 0; i < data.trackingImageList.length; i++) {
        const image = data.trackingImageList[i];
        const points = data.trackingData[i].points.map((p) => {
          return {x: Math.round(p.x), y: Math.round(p.y)};
        });
        this.showImage(image, points);
      }

      for (let i = 0; i < data.imageList.length; i++) {
        const image = data.imageList[i];
        const kpmPoints = [...data.matchingData[i].maximaPoints, ...data.matchingData[i].minimaPoints];
        const points2 = [];
        for (let j = 0; j < kpmPoints.length; j++) {
          points2.push({x: Math.round(kpmPoints[j].x), y: Math.round(kpmPoints[j].y)});
        }
        this.showImage(image, points2);
      }
  }


  showImage = (targetImage, points) => {
      const container = document.getElementById("container");
      const canvas = document.createElement('canvas');
      container.appendChild(canvas);
      canvas.width  = targetImage.width;
      canvas.height = targetImage.height;
      canvas.style.width = canvas.width;
      const ctx = canvas.getContext('2d');
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = new Uint32Array(imageData.data.buffer);

      const alpha = (0xff << 24);
      for (let c = 0; c < targetImage.width; c++) {
        for (let r = 0; r < targetImage.height; r++) {
          const pix = targetImage.data[r * targetImage.width + c];
          data[r * canvas.width + c] = alpha | (pix << 16) | (pix << 8) | pix;
        }
      }

      var pix = (0xff << 24) | (0x00 << 16) | (0xff << 8) | 0x00; // green
      for (let i=0; i < points.length; ++i) {
        const x = points[i].x;
        const y = points[i].y;
        const offset = (x + y * canvas.width);
        data[offset] = pix;
        //for (var size = 1; size <= 3; size++) {
        for (var size = 1; size <= 6; size++) {
          data[offset-size] = pix;
          data[offset+size] = pix;
          data[offset-size*canvas.width] = pix;
          data[offset+size*canvas.width] = pix;
        }
      }
      ctx.putImageData(imageData, 0, 0);
  }

  loadImage = async (file) => {
      const img = new Image();

      return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
        //img.src = src
      })
  }

  compileFiles = async (files) => {
      console.log('Start Compiling',files);
      try {
        const compiler = new window.MINDAR.IMAGE.Compiler();
        const images = [];
        for (let i = 0; i < files.length; i++) {
          images.push(await this.loadImage(files[i]));
        }
        let _start = new Date().getTime();
        console.log('Loaded Image',images);
        const dataList = await compiler.compileImageTargets(images, (progress) => {
          console.log('Progress',progress.toFixed(2));
          // document.getElementById("progress").innerHTML = 'progress: ' + progress.toFixed(2) + "%";
          this.setState({markerProgress:progress.toFixed(2)});
        });
        console.log('exec time compile: ', new Date().getTime() - _start);
        // for (let i = 0; i < dataList.length; i++) {
        //   this.showData(dataList[i]);
        // }
        const exportedBuffer = await compiler.exportData();
        // this.setState({markerBuffer:exportedBuffer});
        // document.getElementById("downloadButton").addEventListener("click", function() {
        //   this.uploadBuffer(exportedBuffer);
        // });
        this.uploadBuffer(exportedBuffer);
      } catch(ex) {
        console.log('File not loaded yet.');
      }
  }

  loadMindFile = async (file) => {
    try {
      const compiler = new window.MINDAR.IMAGE.Compiler();
      var reader = new FileReader();
      reader.onload = function() {
        const dataList = compiler.importData(this.result);
        for (let i = 0; i < dataList.length; i++) {
          this.showData(dataList[i]);
        }
      }
      reader.readAsArrayBuffer(file);
    } catch(ex) {
      console.log('File not loaded yet.');
    }
  }

  AddOneMoreTexture = () =>{
    let {data} = this.state;
    let lipstick_with_textures= [...this.state.lipstick_with_textures];
    const length = lipstick_with_textures.length+1; 
    
    lipstick_with_textures.push('')
    data['lipstick_colour_'+ length] = '#FF0000';
    data['texture_'+ length] = 'glossy';
    this.setState({lipstick_with_textures});

  }

  RemoveOneTexture = () =>{
    let {data} = this.state;
    let lipstick_with_textures= [...this.state.lipstick_with_textures];
    const length = lipstick_with_textures.length; 
    delete data['lipstick_colour_'+ length];
    delete data['texture_'+ length];
    lipstick_with_textures.pop()
    this.setState({lipstick_with_textures,data});
  }

  ColorWithTexture = () =>{
    const {lipstick_with_textures} = this.state;
    const {RemoveOneTexture, AddOneMoreTexture} = this;
    return(
      <div style={{width:"100%",marginBottom:"20px"}}>
        {
          lipstick_with_textures.map((ele,index)=>{
            return(
              <>
                {this.ColorPicker('lipstick_colour_'+(index+1),'Choose Color',true)}
              </>
            )
          })
        }
        <div style={{marginBottom:'60px'}}>
          <div style={{display:'inline',justifyContent:'center',alignItems:'center',float:'right'}}>
            {
              lipstick_with_textures.length !== 1 &&
              <Button className="alignCenter" sx={{cursor:'pointer'}} onClick={RemoveOneTexture} >Remove</Button>
            }
            {
              lipstick_with_textures.length !== 5 &&
              <Button className="alignCenter" sx={{cursor:'pointer'}} onClick={AddOneMoreTexture}>Add</Button>
            }
          </div>
        </div>

      </div>
    )
  }

  addOneMoreUploadField = (Image=false,file=false,data,limit)=>{
    if(Image && file){
      let ArrayUploadData = [...this.state.ArrayUploadData];
      if(Image){
        data.ImageName = data.ImageName +(ArrayUploadData.length+1);
      }
      if(file){
        data.FileName = data.FileName +(ArrayUploadData.length+1);
      }

      if(limit > ArrayUploadData.length) { 
        ArrayUploadData.push(data);
        this.setState({ArrayUploadData})
      }
    }
    else if(file){
      let SingleArrayUploadFileData = [...this.state.SingleArrayUploadFileData];
      data.FileName = data.FileName + (SingleArrayUploadFileData.length+1);
      if(limit > SingleArrayUploadFileData.length) { 
        SingleArrayUploadFileData.push(data)
        this.setState({SingleArrayUploadFileData})
      }
    }
  };
  
  fieldUploaded(Image=false,file=false) {
    const UploadData = (file && Image) ? this.state.ArrayUploadData : this.state.SingleArrayUploadFileData;
    if(file && Image) {
      return(
        UploadData.map((data,index)=>{
          return(<>
            {Image && this.renderInput(data.ImageName,data.ImageType,'Image Name ' + (index+1),true)}
            {file && this.UploadInputFieldExcludeGLB(data.FileName,'Upload Image ' + (index+1),data.FileType,false)}
          </>)
        })
      );    
    } else {
      return(
        UploadData.map((data,index) => {
          return(<>
            {file && this.UploadInputField(data.FileName,'Hidden Object ' + (index+1),data.FileType,false)}
          </>)
        })
      );  
    }
  }
  
  step1 = () => {
    const {expTypes,filesType} = this.state;
    let newExpType = [];

    if(this.props.location.state !== null)
    {
      let data = {...this.state.data};
      let filesType = {...this.filesType};
      
      data.library_assets = this.props.location.state.onMount.id;
      filesType['content'] = 'glb';
      this.setState({data,content:config.assetsEndpoint + this.props.location.state.onMount.collection_content_file,filesType})
      expTypes.forEach((exp)=> {
          if(exp.id ===1 || exp.id === 2)
          {
            newExpType.push(exp);
          }
      })
    }
    else{
      newExpType = [...expTypes]
    }

    let filteredData = newExpType.filter((exp)=>{
    // console.log("my exp name is",exp)
    })
    
    let {searchExpTypeByString} = this.state;
    return (
      <Box className="Page-Width-Vossle">
        <Step1 cardData={newExpType}  onStateChange={this.handleState} filterString={searchExpTypeByString} />
      </Box>
    )
  };

  getSubCategory = async (selectedExpType) =>{
    try
    {  
      
      const {subCategoryLoader,step2Data:step2Info} = this.state;
      if(step2Info.length === 0)
      {
        const { data:res } = await getExperienceSubCategory(selectedExpType.id);
        if(res.status && isBoolean(res.status)){
          
            let {data:step2Data} = res
            let currentStepper = this.state.currentStepper;
            currentStepper['currentStep']++;
            currentStepper['totalSteps']++;  
          // console.log("my Step2 Data is",step2Data)

            // if(selectedExpType.exp_type === '3d_ads'){
            //   step2Data = this.state.Ads;
            // }
            this.setState({step2Data,currentStep:2,currentStepper})
        }
        else if(res.status === 'Token is Expired') {
          this.setState({text:'User token expired, Redirecting to login page.'});
          setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
          },400)
        }
      }
    }
    catch(ex) {
      let message = {};
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
        message = ex.response.data.errors;
        this.setState({ message });
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
    
  }
  
  step2 = () => {
    const {selectedExpType,subCategoryLoader,step2Data} = this.state;
  

    if(step2Data.length > 0 ){
      return(
        <Box className="Page-Width-Vossle">
          <Step2 
            sx={{p:5,borderRadius:'10px'}}
            parentId={selectedExpType.id} 
            data={step2Data}
            handleState={this.handleState}
          />
        </Box>
      )
    }
    else{
      return <Loader text="loading data .." />
    }
  };

  step3 = () => {
    const {selectedExpType, selectedExpTypeSubcategory, currentStep,files,filesType,data} = this.state;
    // console.log('Selected Exp Type id',selectedExpType.id)
   
    return(
      <Box style={{width:"100%"}}>
        {/* for Common Markerbased and Markerless */}

        {     
          (selectedExpType.id === 1 || selectedExpType.id === 2 || (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24)) && (
            <React.Fragment>
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              {this.UploadInputField('content','Content File','content',false)}
              
              { filesType && filesType['content'] && filesType['content']!=='mp4' && this.UploadInputFieldExcludeGLB('audio','Audio File','audio',true)}

                <div style={{marginBottom:'10px'}}>
                 { (selectedExpType.id === 2 || (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24) ) &&  filesType && filesType['content'] &&  this.SwitchButton('enable_placement','Enable Placement',"False","True",false)}
                </div>
              {/* {this.SliderWithSwitch("light_range_enable","light_range", "Light Setting Range", 0, 3, 1, true)} */}
              <div>
                {
                   selectedExpType.id === 2  &&  filesType && filesType['content'] && filesType['content']=== 'mp4'  &&
                  <div style={{marginBottom:'10px'}}>
                    {(this.SwitchButton('transparent_background','Transparent Background','Yes','No',false))}
                  </div>
                }
                {
                  filesType && filesType['content'] && filesType['content'] === 'mp4' && data['transparent_background']  === 'Yes' && selectedExpType.id ===2 &&
                  <div style={{background:'white',padding:'10px',borderRadius:'10px',marginBottom:'10px'}}>
                    <Typography sx={{fontWeight:'500',mb:'10px'}}>
                        Background Colour
                    </Typography>
                    { this.ColorPicker('background_color','Choose Color',true) }
                  </div>
                }
              </div>
              {/* {this.Slider("light_range", "Light Setting Range", 0, 5, 1, true)} */}

              {(selectedExpType.id === 1 && this.markerbaseUploadInputField())}
            </React.Fragment>
          )
        }
        
        {
          (selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 7) && (
            <React.Fragment>
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              {this.UploadInputField('content','Target Model','only_glb',true,6144)}
              {this.UploadInputField('tossing_model','Tossing Model','only_glb',false,1024)}
              { (files.tossing_model || this.state.data['library_assets_tossing']) && this.UploadInputFieldExcludeGLB('tossing_image_modal','Tossing Model Image','image',true)}
              {this.UploadInputFieldExcludeGLB('audio','Background Audio','audio',true)}
              {this.UploadInputFieldExcludeGLB('sound','Target Audio','audio',true)}
              
              {/* {this.SliderWithSwitch("light_range_enable","light_range", "Light Setting Range", 0, 100,25, true)} */}
              {this.Slider("light_range", "Light Setting Range", 0,5,1, true,'s')}
              
              {this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,"s")}
              {/* {this.Slider("redirection_time", "Redirection Time", 10, 60,5, true)} */}
              {this.MultiLineStringInputField("text_to_display","text","Game Instructions", true,50,100)}
              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('website','text','Website Link',true)}
            </React.Fragment>
          )
        }

        {
          selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 8 && (
            <React.Fragment>
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              
              {this.UploadInputField('content','Main Model','only_glb',true)}
              {this.UploadInputField('tossing_model','Collider Model','only_glb',true,1024)}
              {this.UploadInputFieldExcludeGLB('audio','Background Audio','audio',true)}
              
              {this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,"s")}
              {/* {this.Slider("redirection_time", "Redirection Time", 10, 60,5, true)} */}
              {this.MultiLineStringInputField("text_to_display","text","Game Instructions", true,50,100)}
              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('website','text','Website Link',true)}
            </React.Fragment>
          )
        }

        {
          selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 9 && (
            <React.Fragment>
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              {this.UploadInputField('content','Target Model','only_glb',true)}
              {this.UploadInputField('tossing_model','Bullet Model','only_glb',true,1024)}
              {this.NumberRangeField('bullet_frq','Bullet Frequency',0.5,1,0.1,false)}
              {this.NumberRangeField('game_duration','Game Duration',30,60,1,false)}
              {this.DropDownInputField('feedbackForm_game','Feedback Form',[{name:'visible',label:'Visible'},{name:'hidden',label:'Hidden'}],"",true)}
           </React.Fragment>
          )
        }

        {
          selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 10 && (
            <React.Fragment>
              {this.DropDownInputField('face_game_type','Face Game Type',[{name:'angry_pokemon',label:'Angry Pokemon'},{name:'favourite_food',label:'Favorite Food'},{name:'tarot_reading',label:'Tarot Reading'}],"",true)}
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {/* {this.UploadInputFieldExcludeGLB('splash_screen','Splash Image','image',false)} */}
              {this.fieldUploaded(true,true)}
              <div style={{display:'flex',width:'100%',justifyContent:'end'}}>
                <Typography sx={{color:'red',fontSize:'13px',marginLeft:'10px'}}>
                  All 11 images are required
                </Typography>
              </div>
              <Button sx={{mb:2,float:'right'}} variant="outlined" startIcon={<AddIcon />} onClick={()=>this.addOneMoreUploadField(true,true,{ImageName:'imagename',ImageType:'text',FileName:'imagefile',FileType:'image'},11)}>
                Add next Image
              </Button>
              {this.NumberRangeField('image_speed','Randomize Speed', 0.5, 1, 0.1, false)}
            </React.Fragment>
          )
        }

        {
          selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 11 && (
            <React.Fragment>
              {this.fieldUploaded(false,true)}
              <div style={{width:'100%',display:'flex',justifyContent:'end'}}>
                <Typography sx={{color:'red',fontSize:'13px'}}>
                  All 10 GLB's are required
                </Typography>
              </div>
              <div style={{width:'100%',display:'flex',justifyContent:'end'}}>
                <Button sx={{mb:2}} variant="outlined" startIcon={<AddIcon />} onClick={()=>this.addOneMoreUploadField(false,true,{FileName:'imagefile',FileType:'glb'},10)}>
                  Add Element
                </Button>
              </div>
              {this.UploadInputFieldExcludeGLB('audio','Background Audio','audio',true)}
              {this.state.audio && (this.SwitchButton('audio_option','Audio Setting','on','off',false))}
              {this.NumberRangeField('game_duration','Game Duration', 30, 60, 1, false)}
           </React.Fragment>
          )
        }
        
        {
          selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 12 && (
            <React.Fragment>
              <div style={{marginLeft:"6px",marginBottom:"6px",fontSize:"14px",fontWeight:"500",background:'white',paddingLeft:"10px",paddingRight:"10px",borderRadius:"10px"}}>
                <Typography style={{fontWeight:"600",marginBottom:"10px",paddingTop:"15px"}}>
                  Choose Lipstick Colours
                </Typography>
                {this.ColorWithTexture()}
              </div>
              {this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,"s")}
              {/* {this.Slider("redirection_time", "Redirection Time", 10, 60, 5, true)} */}
              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('website',"text", 'Website Link', true)}
            </React.Fragment>
          )
        }
        
        {
          ((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23)||(selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 14)) && (
            <>
              {(selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 14 || selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23) ? 
                this.DropDownInputField('detection_point','Face Detection Point',[{name:'eye',label:'Eye'},{name:'ear',label:'Ears'},{name:'neck',label:'Neck'},{name:'head',label:'Head'},{name:'live_face_morph',label:'Live Face Morph'}],"",true) : 
                this.DropDownInputField('detection_point','Face Detection Point',[{name:'eye',label:'Eye'},{name:'ear',label:'Ears'},{name:'neck',label:'Neck'},{name:'head',label:'Head'}],"",true) 
              }
              {this.state.data.detection_point && this.state.data.detection_point === "head"
                && this.renderRadioButton('place_head','',['place_head','above_head'],['place on head','place above head'],true)
              }
            </>
          )
        }

        {
          ((selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 28)) && (
            <>
              {this.DropDownInputField('detection_point','Body Detection Point',[{name:'upper_body',label:'Upper Body'},{name:'lower_body',label:'Lower Body'}],"",true)}
            </>
          )
        }

        {
          (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 25 ) && (
            <>
              {this.UploadInputField('content','Content File','only_glb',true)}
              {this.UploadInputFieldExcludeGLB('audio','Audio File','audio',true)}
              {this.SwitchButton('background_ImageVsColor','Switch To Choose Colour as Background','True','FALSE',false)}
              {data['background_ImageVsColor'] === 'FALSE'
                ?
                  (this.UploadInputFieldExcludeGLB('background_image','Background Image','ads_background_image',false))
                :
                  (this.ColorPicker('background_colour','Choose Color',true))
              }
              {this.renderInput('website',"text", 'Website Link', true)}
            </>
          )
        }

        {
          ((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23) || (selectedExpType.id === 4 && (selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 26 || selectedExpTypeSubcategory.id === 27 || selectedExpTypeSubcategory.id === 28))) && (
            <React.Fragment>
              {this.UploadInputFieldExcludeGLBSwitch('enable_splash','Splash Image','splash_screen','image',false)}
              {selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 26 && (this.UploadInputFieldExcludeGLB('content','Content File','image',true,10240,true,{status:true,sid:selectedExpTypeSubcategory.id,dp:data.detection_point,subtype:data.hasOwnProperty('place_head') && data.place_head}))}
              {selectedExpTypeSubcategory.id === 28 
                ? (this.UploadInputField('content','Content File','body_track',true,20480,this.getHelperText('body_track')))
                :
                (
                  (selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 27)
                  ?
                  (this.UploadInputField('content','Content File','only_glb',true,20480,null,{status:true,sid:selectedExpTypeSubcategory.id,dp:data.detection_point,subtype:data.hasOwnProperty('place_head') && data.place_head}))
                  :
                  (selectedExpTypeSubcategory.id !== 26 && (this.UploadInputField('content','Content File','content',true)))
                )
              }

              <div>
                  {(selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 27 || selectedExpTypeSubcategory.id === 28 )
                    && filesType && filesType['content'] && 
                    this.UploadInputFieldExcludeGLB('audio','Audio File','audio',true)
                  }
              </div>
              
            </React.Fragment>
          )
        }

        {(selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id == 24) && (
          <div style={{marginBottom:'16px',borderRadius:'10px',background:'white',border:'1px solid grey'}}>
            {this.UploadInputFieldImageWithSwitch('overlay_image','Overlay Image','ads_background_image',false,3072,true,this.getHelperText('overlay_image'))}
            {this.SwitchButton('repeat_overlay','Switch off to play overlay image only once.','True','FALSE',false)}
          </div>
        )}

        {(selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id == 24) && (
          <div>
            {this.UploadInputField('tossing_model','Fallback Content Model','only_glb',false)}
            {filesType && filesType['tossing_model'] && this.UploadInputFieldExcludeGLBSwitch('use_content_audio','FallBack Audio ( Use Same Audio of Content File )','sound','audio','off','TRUE')}
            {this.SwitchButton('background_ImageVsColor','Switch To Choose Colour as Background','True','FALSE',false)}
            {data['background_ImageVsColor'] === 'FALSE'
              ?
                (this.UploadInputFieldExcludeGLB('background_image','Background Image','ads_background_image',false))
              :
                (this.ColorPicker('background_colour','Choose Color',true))
            }
          </div>
        )}

        {
          (selectedExpType.id === 1 || selectedExpType.id === 2 || (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24)) && 
            (selectedExpType.id === 1 || selectedExpType.id === 2 || (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24)) && (
              <React.Fragment>
                {this.Slider("light_range", "Light Setting Range", 0, 5, 1, true)}
              </React.Fragment>
            )
        }

        {/* for Common Markerbased and Markerless  */}
        {
          (selectedExpType.id === 1 || selectedExpType.id === 2 || (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24)) ? (
            <React.Fragment>
              {selectedExpType.id !== 16 &&  this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,"s")}
              {/* {this.Slider("redirection_time", "Redirection Time", 10, 60, 5, true)} */}

              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('label','text','Label',true)}
              {this.renderInput('website','text','Website Link',true)}
            </React.Fragment>
          ) : null
        }
        
        {
          ((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23) || (selectedExpType.id === 4 && (selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 26 || selectedExpTypeSubcategory.id === 27 || selectedExpTypeSubcategory.id === 28 ) )) && (
            <React.Fragment>
              {selectedExpType.id !== 16 && this.SliderWithSwitch("redirect_to_website","redirection_time", "Redirection Time", 10, 60, 5, true,"s")}
              {this.renderInputGA4('ga_code_exp','text','GA4 Measurement Id',true)}
              {this.renderInput('website',"text", 'Website Link', true)}
            </React.Fragment>
          )
        }

        {
          (selectedExpType.id === 16 && (selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 24 || selectedExpTypeSubcategory.id === 25) )  && (
            <React.Fragment>
              {this.ColorWithLabel('redirection_button_colour','Redirection Button Colour',false)}
            </React.Fragment>
          )
        }
        
        {
          selectedExpType.id === 5 && (
            <React.Fragment >
              {this.MultiLineStringInputField("text_to_display","text","Add Description Here", true,50,100)}
              {this.UploadInputFieldExcludeGLB('global_audio_file','Global Audio','audio',true)}
              {this.state.audio && (this.SwitchButton('audio_option','Audio Setting','on','off',false))}
              <div style={{marginBottom:"16px"}}>
              {this.SwitchButton('screen_gesture','Enable Screen Gesture','True','False',false)}
              </div>
              <div style={{marginBottom:"16px"}}>
              {this.SwitchButton('multisessions','Enable Multisessions','True','False',false)}
              </div>

            </React.Fragment>
          )
        }

        {
          (selectedExpType.id === 1 || selectedExpType.id === 2 ||
            (selectedExpType.id === 3 && (selectedExpTypeSubcategory.id === 7 || selectedExpTypeSubcategory.id === 8)) ||
            (selectedExpType.id === 16 && (selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 24))||
            (selectedExpType.id === 4 && (selectedExpTypeSubcategory.id === 12 || selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 26 || selectedExpTypeSubcategory.id === 27 || selectedExpTypeSubcategory.id === 28 ))
          ) 
            && 
          (
            <div>
              {this.geolocation('Enable Geo Location','enable_geo_location')}
            </div>
          )
        }

        <div id="vossleQrCode" style={{display:'none'}}></div>
      </Box>
    )
  };

  workflow = () => {
    const {data,arExp} = this.state;
    let slugName = data.exp_name;
    return (<CreateWorkFlow changetoFinalState={this.changetoFinalState} arData = {arExp} slug_name={slugName}  handleState={this.handleState} />)
  };
  
  step4 = () => {
    const {selectedExpType,selectedExpTypeSubcategory, data} = this.state;
    const step4Data = {...this.state.step4Data}

    const {enable_placement} = data;
    console.log("my data is",data,enable_placement)
    if(this.state.files.marker_image){
      step4Data['marker_image'] = this.state.files.marker_image;
    }
    // console.log('Final QR Data',step4Data);

    return(
      <Box className="Page-Width-Vossle">
        {/* <Typography variant="h6">Completed...</Typography> */}
        <ArrowBackIcon onClick={()=>this.setState({resetState:true})}  sx={{cursor:'pointer',fontSize:'20px',height:'20px',width:'20px'}}/>
      <Step4 cardData={step4Data} selectedExpType ={selectedExpType} selectedExpTypeSubcategory ={selectedExpTypeSubcategory} placement= {enable_placement} />
      </Box>
    )
  };
  
  stringToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    let from,to,i,l;
    from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    to   = "aaaaeeeeiiiioooouuuunc------";
    for (i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
  // console.log(str);
    str = str.replace(/[^a-z0-9 -]/g, ''); // remove invalid chars
  // console.log(str);
    str = str.replace(/\s+/g, '-'); // collapse whitespace and replace by -
  // console.log(str);
    str = str.replace(/-+/g, '-'); // collapse dashes
  // console.log(str);
    return str;
  };
  
  circularReplacer = () => {
  
    const seen = new WeakSet();    
    return (key, value) => { 
      if (typeof(value) === "object"  && value !== null) {
        if (seen.has(value)) {
            return 'Object';
        }
        seen.add(value);
      }
      return value;
    };
  };

  getQrCode = ()=> {
    const {step4Data,data} = this.state;
    
    const easyqrCodeOption = {
      text:config.webarEndpoint+data.slug_exp_name,
      logo:VossleLogo,
      padding:1,
      height:256,
      width:256,
      dotScale:0.8,
      logoHeight:55,
      logoWidth:100,
      quietZone: 16,
      quietZoneColor: "rgb(255, 255, 255)",
      correctLevel:QRCode.CorrectLevel.H,
      onRenderingEnd:(options, dataURL)=>{
      // console.log("==> ",data.slug_exp_name)
        // console.log("my data url will be decided here please check now",dataURL,options)
        step4Data['url'] = config.webarEndpoint+data.slug_exp_name;
        step4Data['qrcode'] = dataURL; 
        this.setState({qrcode:dataURL,step4Data})      
      // console.log("my qrcode data is updated ================================>",step4Data)
      },
    }
    new QRCode(document.getElementById('vossleQrCode'),easyqrCodeOption);
  }
  
  checkExperienceName = async () => {
  // console.log('called check');
    const {exp_name} = this.state.data;
    let slug_exp_name = await this.stringToSlug(exp_name);
    
    try {
      const data = new FormData();
      data.append("slug_exp_name", slug_exp_name);
      
      const res = await checkExpName(data);
      if(res.data.status) {

          const data = {...this.state.data};
          data['slug_exp_name'] = slug_exp_name;
          let showUrlMessage = `Endpoint : ${config.webarEndpoint}${slug_exp_name}`;
          this.setState({data,isQRCode:true,showUrl:true,showUrlMessage});
          setTimeout(()=>{
            this.getQrCode();
          },200)
      } else {
          this.setState({showUrl:false, showUrlMessage:'Experience name not available.'});
          toast.error("Experience name not available.");
      }
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error("Server Busy");
        } else if (ex.response && ex.response.status === 404) {
          toast.error("Server Down, Under maintenance.");
        } else {
          toast.error("Unexpected Error");
        }
    }
  };
  
  validate = () => {
    // return null;
    const options = { abortEarly: false };
    // const { error } = Joi.validate(this.state.data, this.schema, options);
    // if (!error) return null;

    // const errors = {};
    // for (let item of error.details) errors[item.path[0]] = item.message;
    // console.log(errors);
    // return errors;

    const { data,selectedExpType,selectedExpTypeSubcategory,qrcode,files,ArrayUploadData,SingleArrayUploadFileData } = this.state;
    const errors = {};
    const {use_content_audio} = data;
    if(data.exp_name === '') errors.exp_name = 'Exp Name is required';
   
    if(qrcode === '') {
      errors.global = 'QR Code is required';
      // errors.qrcode = 'QR Code is required';
    }

    if(data.website && data.website !== '') {
    // console.log('Website Link checking',data.website);
      if(this.checkURL(data.website)) errors.website = 'URL is not valid.';
    }

    if(use_content_audio === true)
    {
      if(typeof files['audio'] == 'undefined' ||typeof files['audio'] == '' || typeof files['audio'] == null ){
        errors.audio = 'audio file is required'
      }
    }

    if(selectedExpType.id === 1 || selectedExpType.id === 2 || (selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 24)) {
      if(typeof files.content == 'undefined' || files.content === ''){        
        if(this.state.data['library_assets'] === '' || typeof this.state.data['library_assets'] === 'undefined')
          errors.content = 'Content File is required';
      }
      if(selectedExpType.id === 1 && (typeof files.marker_image === 'undefined' || files.marker_image === '')) errors.marker_image = 'Marker Image File is required';
    }

    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 7) {
      if(typeof files.content == 'undefined' || files.content === ''){ 
        if(this.state.data['library_assets'] === '' || typeof this.state.data['library_assets'] === 'undefined')
        errors.content = 'Target Model is required';
      }
      if(typeof files.tossing_model == 'undefined' || files.tossing_model === '') 
      {
        if(this.state.data['library_assets_tossing'] === '' || typeof this.state.data['library_assets_tossing'] === 'undefined')
          errors.tossing_model = 'Tossing Model is required';
      }
      if(typeof files.tossing_image_modal == 'undefined' || files.tossing_image_modal === '') errors.tossing_image_modal = 'Tossing Model Image is required';
    }

    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 8) {
      if(typeof files.content == 'undefined' || files.content === '') errors.content = 'Main Model is required';
      if(typeof files.tossing_model == 'undefined' || files.tossing_model === '') errors.tossing_model = 'Collider Model is required';
    }

    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 9) {
      if(typeof files.content == 'undefined' || files.content === '') errors.content = 'Target Model is required';
      if(typeof files.tossing_model == 'undefined' || files.tossing_model === '') errors.tossing_model = 'Bullet Model is required';
      if(data['feedbackForm_game']===''){errors.feedbackForm_game = 'Feedback Form is required';}
    }

    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 10) {
      if(typeof data.face_game_type == 'undefined' || data.face_game_type === '') errors.face_game_type = 'Face Game Type is required';
      
      if(ArrayUploadData.length < 11) errors.global = 'All 11 image files are required';
      else {
        ArrayUploadData.map((check,index) => {
          if(typeof data[check.ImageName] == 'undefined' || data[check.ImageName] === '') errors[check.ImageName] = `Image Name ${index+1} are required`;
          if(typeof files[check.FileName] == 'undefined' || files[check.FileName] === '') errors[check.FileName] = `Image File ${index+1} are required`;
        });
      }
    }

    if(selectedExpType.id === 3 && selectedExpTypeSubcategory.id === 11) {
      if(typeof data.game_duration == 'undefined' || data.game_duration === '') errors.game_duration = 'Game Duration is required.';
      
      if(SingleArrayUploadFileData.length < 10) errors.global = 'All 10 hidden objects are required.';
      else {
        SingleArrayUploadFileData.map((check,index) => {
          if(typeof files[check.FileName] == 'undefined' || files[check.FileName] === ''){
            if( typeof data[check.FileName]==='undefined' || data[check.FileName] === ''){
              errors[check.FileName] = `Hidden Object ${index+1} is required.`;
            }
          } 
          
            
        });
      }
    }

    if(selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 12) {
      // if(typeof data.lipstick_colour_0 == 'undefined' || data.lipstick_colour_0 === '') errors.lipstick_colour_0 = 'Lipstick Color is required.';
      // if(typeof data.texture_1 == 'undefined' || data.texture_1 === '') errors.texture_1 = 'Lipstick Texture is required.';
      
    }

    if((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23)||(selectedExpType.id === 4 && (selectedExpTypeSubcategory.id === 13 || selectedExpTypeSubcategory.id === 14 || selectedExpTypeSubcategory.id === 15 || selectedExpTypeSubcategory.id === 26 || selectedExpTypeSubcategory.id === 27 || selectedExpTypeSubcategory.id === 28))) 
    {
      if(((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23)||(selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 14)) && (typeof data.detection_point == 'undefined' || data.detection_point === '')) errors.detection_point = 'Detection Point is required.';
      if(typeof files.content == 'undefined' || files.content === '') errors.content = 'Content is required.';
    }
    
    if(data.enable_geo_location === 'on') 
    {
      // console.log('Please add Latitude, Longitude and Distance');
      if(data.latitude === '') errors.latitude = 'Latitude is required';
      if(data.longitude === '') errors.longitude = 'Longitude is required';
      if(data.distance === '') errors.distance = 'Distance is required';
    }


    if(selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 24){
      if(typeof files['tossing_model'] == 'undefined' || files['tossing_model'] == '' || files['tossing_model'] == null)
      {
        if(typeof this.state.data['library_assets'] === 'undefined')
          errors.tossing_model = 'fallback content model is required';
      }
    }

  // console.log("my selected expType is of validation",selectedExpType.id,"    ",selectedExpTypeSubcategory.id)
    if((selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 23)||(selectedExpType.id === 4 && selectedExpTypeSubcategory.id === 14))
    {
    // console.log("I am in my detection point")
      if(data['detection_point'] == 'head')
      {
        if(typeof data['place_head'] == 'undefined' || data['place_head']=='' && data['place_head']===null){
          errors.place_head = 'detection head point is required';
        }
      }
    }

    // if(data.redirect_to_website == 'TRUE'){
    // // console.log("please add redirection is compusory")
    //   if(data.redirection_time === '' ) errors.redirection_time = 'Redirection time is required'
    // }

    if(data.enable_splash === "TRUE"){
      if(typeof files.splash_screen == 'undefined' || files.splash_screen === '') errors.splash_screen = 'Splash Screen is required';
    }

  

    if(selectedExpType.id === 16 && selectedExpTypeSubcategory.id === 25 ){
      if(typeof files.content == 'undefined' || files.content === '') errors.content = 'Content is required.';
      
     
      if(data.background_ImageVsColor === 'TRUE'){
        if(data.background_colour === '' || typeof data.background_colour ==='undefined' ) 
          errors.background_colour = 'background color is required'
      }
      else{
        if(files.background_image === '' || typeof files.background_image ==='undefined' ) 
          errors.background_image = 'background Image is required'
      }
    }
    if(selectedExpTypeSubcategory.id === 23 || selectedExpTypeSubcategory.id === 24){
      if(data.background_ImageVsColor === 'TRUE'){
        if(data.background_colour === '' || typeof data.background_colour ==='undefined' ) 
          errors.background_colour = 'background color is required'
      }
      else{
        if(files.background_image === '' || typeof files.background_image ==='undefined' ) 
          errors.background_image = 'background Image is required'
      }
    }
    // const regexMobile = /^\d{10}$/;
    // const regexOTP = /^\d{4}$/;

    // if (data.mobileNumber.trim() === "")
    //   errors.mobileNumber = "Mobile Number is required";
    // else if (data.mobileNumber.length > 10)
    //   errors.mobileNumber = "Mobile Number is greater than 10 digit";
    // else if (!data.mobileNumber.match(regexMobile))
    //   errors.mobileNumber = "Mobile Number is not right";

    // if (data.otp.trim() === "") errors.otp = "OTP is required";
    // else if (data.otp.length > 4)
    //   errors.otp = "OTP length is greater than 4";
    // else if (!data.otp.match(regexOTP)) errors.otp = "OTP is not right";

    return Object.keys(errors).length === 0 ? null : errors;
  };
  
  changetoFinalState = (arExp) =>{
    let {currentStep,totalSteps}=this.state.currentStepper;
    currentStep++;
    this.setState({arExp,currentStep:4, currentStepper:{'currentStep':currentStep,'totalSteps':totalSteps}});
  }

  doSubmit = async () => {  
    // console.log('Data saving started');
    this.handleShow();

    document.getElementById("submit_button").disabled = true;
    document.getElementById("submit_button").innerHTML = "Saving Data...";
    // const {selectedExpType,selectedExpTypeSubcategory,data:formobject,splash_screen,content,marker_image,global_audio_file,qrcode,files} = this.state;
    const {selectedExpType,selectedExpTypeSubcategory,data:formobject,qrcode,files,markerBuffer} = this.state;
    const errors = {};

    const data = new FormData();
    data.append("user_id", this.props.user);
    data.append("exp_type", selectedExpType.id);
    
    // if(selectedExpType.id === 1) {
      // let blob_file = new Blob([markerBuffer]);
      // let fileName = `${blob_file.name}.${blob_file.extension}`;
      // const file = new File([markerBuffer.blob], 'targets.mind', {
      //   type: "application/octet-stream",
      // });
      // console.log('Blob File Check',file);
      // data.append("marker_buffer", file, 'targets.mind');
    // }

    if(selectedExpType.id === 3) data.append("sub_game_category", selectedExpTypeSubcategory.exp_type);
    selectedExpTypeSubcategory.exp_type && data.append("sub_category", selectedExpTypeSubcategory.exp_type);
    selectedExpTypeSubcategory.exp_type && data.append("exp_subtype_id", selectedExpTypeSubcategory.id);


    {Object.keys(formobject).map((name) => 
    {
      if(name === 'background_color')
      {
        data.append(name,this.hexToRgbNew(formobject['background_color'])); 
      }
      else
      {
        data.append(name, formobject[name]);
      }
    })}

    // Files
    data.append("qrCodeLogo", qrcode);

    {Object.keys(files).map((key) => {

      if(typeof this.state[key] != 'undefined' ){
        if(key == 'content' && typeof formobject['library_assets'] != 'undefined'){       
        }
        else
        {
          data.append(key, this.state[key]);
        }
      }

    })}

    // for (const value of data.values()) {
    // console.log('Form Values',value);
    // }
    
    
    try {
      const config = {
        onUploadProgress: (progressEvent) => {
          var downloadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // console.log('Download Progress',downloadProgress);
          this.setState({downloadProgress});
        }
      }
      // return;
      this.setState({isButtonDisabled:false})
      const res = await createExperience(data,config);
      if (res.data.status === "success") {
        document.getElementById("submit_button").innerHTML = "Saved :-)";
        
        if(selectedExpType.id !== 5){
          toast.success("AR Experience Created.");
        }
        const arExp = Object.keys(res.data).length !== 0 ? {...res.data.data} : {};
      // console.log("my current stepper is",this.state.currentStepper)
        let currentStep = this.state.currentStepper['currentStep'];
        let totalSteps = this.state.currentStepper['totalSteps'];
        currentStep++;
        // let newStepper = 
      // console.log("checking out workflow workinng here for now")
        if(selectedExpType.id === 5){
          totalSteps = 4;
        }
        this.setState({currentStepper:{'currentStep':currentStep,'totalSteps':totalSteps}})
      // console.log("checking out workflow workinng here for now 2")
        
        selectedExpType.id === 5 ? this.setState({arExp,currentStep:3.5}) : this.setState({arExp,currentStep:4});
      } else if (res.data.status === "failed") {
        document.getElementById("submit_button").innerHTML = "Not Saved";
        document.getElementById("submit_button").disabled = false;
        errors.global = "Data not saved, Please try again" + res.data.message;
        toast.error("Data not saved, Please try again" + res.data.message);
        this.setState({isButtonDisabled:true})
      } else {
        toast.error("Data not saved, Please try again" + res.status);
        errors.global = "Data not saved, Please try again" + res.data.message;
        document.getElementById("submit_button").innerHTML = "Not Saved";
        document.getElementById("submit_button").disabled = false;
        this.setState({isButtonDisabled:true})
      }
    } catch (err) {
      this.setState({isButtonDisabled:true})
      if (err.response && err.response.status === 404) {
        errors.global = "Unexpected Error occured Please try again.";
        toast.error("Unexpected Error occured Please try again.");
      } else {
        errors.global = "Unexpected Error other than 404 Please try again.";
        toast.error("Unexpected Error other than 404 Please try again.");
      }
    }
    this.setState({errors})
    this.handleClose();
  };
  
  handleClose = () => this.setState({setShow:false});
  handleShow = () => this.setState({setShow:true});

  getHelperText = type => {
    if(type === null) return null;
    
    let response = null;
    
    if(type === 'body_track') response = (<Typography sx={{px:2, fontWeight:'regular', fontSize:'16px', textAlign:'justify'}}>Please use GLB or VRM (Virtual Reality Model) files only. To know how to make VRM file click on the link. <a href="https://vrm.dev/en/vrm/how_to_make_vrm/index.html" target="_blank">VRM Documentation</a></Typography>);
    else if(type === 'overlay_image') response = (<Typography sx={{px:2, fontWeight:'regular', fontSize:'16px', textAlign:'justify'}}>Image size should be less than 3MB.</Typography>);
    return response;
  };

  render() {
    const {token_expired,isButtonDisabled,resetState,currentStep,currentStepper,selectedExpType,selectedExpTypeSubcategory,isQRCode,expTypes,theme,data,setShow,downloadProgress,showUrl,showUrlMessage,errors,loaderFlag,step2Data, openAiDialogOpen, openAiSelectedDialogData, markerProgress} = this.state;
    console.log('Final State',this.state);
    // console.log('Final Errors',errors);
    const {handleOpenAIDialogClickOpen,handleOpenAIDialogClose} = this;
    // console.log("my data have ",data.slug_exp_name)
    
    if(resetState) return (<Navigate to = {this.props.location.pathname === '/create' ? '/ar/create':'/create'}  />);
    
    if(token_expired) return (<Navigate to='/logout' />);

    if(loaderFlag) return (<Loader text='Loading Experience Data ...' />);

    const {name,type} = openAiSelectedDialogData;

    console.log("my initial state data is",InitialState)
    

    return (
      <>
        <Helmet>
          <script src="https://assets.vossle.com/scripts/mindar-image.js"></script>
        </Helmet>
        <Box sx={{px:'40px',pt:'20px'}}>
          <ExperiencesStepper  CurrentStepper={currentStepper} sx={{marginTop:"20px"}}/>
          {currentStep===1 && this.step1()}
          {currentStep===2 && 
        
            (
              step2Data.length === 0 ?(
                <Loader text="showcase" />
              )
              :
              (
                <Box className="Page-Width-Vossle">
                  <ArrowBackIcon onClick={()=>this.setState({resetState:true})}  sx={{cursor:'pointer',fontSize:'20px',height:'20px',width:'20px'}}/>
                  <Step2 
                    sx={{p:5,borderRadius:'10px'}}
                    parentId={selectedExpType.id} 
                    data={step2Data}
                    handleState={this.handleState}
                  />
                </Box>
              )
            )
          
          }
          {currentStep===3 && (
            <Box className="Page-Width-Vossle">
              {/* {`Details for step ${currentStep} Selected Experiecnce Type ${selectedExpType.exp_name} Sub Type ${selectedExpTypeSubcategory.exp_name}`} */}
              {errors.qrcode}
              {/* {isQRCode && config.webarEndpoint+data.slug_exp_name} */}
              <ArrowBackIcon onClick={()=>this.setState({resetState:true})}  sx={{cursor:'pointer',fontSize:'20px',height:'20px',width:'20px'}}/>
              {openAiDialogOpen && (type === 'content' || type==='only_png' || type === 'image' || type === 'button' || type === 'arPortal') && 
                this.openAiDialogBox(name,type)
              }
              <Grid sx={{background:'#F1F3F5',borderRadius:'10px'}}>
                <Grid xs={2}></Grid>
                <Grid xs={8}>
                  <ThemeProvider theme={theme}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        my:4,
                        mx:2,
                        py:4,
                      }}
                      onSubmit={this.handleSubmit}
                    >   
                      <Grid xs={12}>{errors.global && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors.global}</Typography>) }</Grid>
                      {this.renderInput("exp_name", "text", "Experience Name", true)}
                      {showUrl && (<Typography paragraph sx={{pl:1, color:'green'}}>{showUrlMessage}</Typography>)}
                      {this.step3()}
                      {isQRCode && (
                        <div id="qrcode-logo" style={{display:'none'}} >
                          <QRCodeCanvas 
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level='H'
                            size={256}
                            value={config.webarEndpoint+data.slug_exp_name}
                            id="qrcode-bar"
                            includeMargin={true}
                            imageSettings={{src:VossleLogo,height: 55,width: 100, excavate:true}}
                          />
                        </div>
                      )}
                      <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
                        <RenderButton 
                          name = 'submit_button'
                          type = "submit"
                          label = {selectedExpType.id === 5 ? 'Next' : (selectedExpType.id === 1 ? (markerProgress <= 99 ? 'Progress : ' + markerProgress : 'Submit') : 'Submit')}
                          isDisabled = {selectedExpType.id === 1 ? (showUrl && isButtonDisabled && markerProgress > 99 ? false : true) : (showUrl && isButtonDisabled ? false : true)}
                          minWidth = {180}
                        />
                        {/* <Button className="button1" id="submit_button" disabled={showUrl ? false : true} type="submit">{selectedExpType.id === 5 ? 'Next' : 'Submit'}</Button> */}
                      </div>
                    </Box>
                  </ThemeProvider>
                </Grid>
                <Grid xs={2}></Grid>
              </Grid>

            </Box>
            )
          }
          {currentStep===3.5 && this.workflow()}
          {currentStep===4 && this.step4()}
        </Box>
      </>
    )
  }

}

export default withRouter(ArCreateExperiences);