import http from "./httpServices";
import config from "./config.json";
const apiEndpoint = config.apiEndpoint;

export function register(user) {
  const data = new FormData();
  data.append("name", user.name);
  data.append("email", user.email);
  data.append("country_code", user.country_code);
  data.append("mobile", user.mobile);
  data.append("password", user.password);
  data.append("password_confirmation", user.password_confirmation);
  data.append("role_id", user.role_id);
  return http.post(apiEndpoint + "register", data);
}

export function forgot(email) {
  // console.log(user.mobile);
  const data = new FormData();
  data.append("email", email);
  return http.post(apiEndpoint + "forgot", data);
}

export function resetPassword(user) {
  return http.post(apiEndpoint + "reset-password", user);
}

export function getSocialLink(){
  return http.get(apiEndpoint+'auth/social')
}

export function socialLogIn(provider, paramUrl){
  return http.get(apiEndpoint+"auth/social/callback/"+ provider + paramUrl)
}

export function resendEmail(user) {
  return http.post(apiEndpoint + "resend-link", user);
}

export function verify(token) {
  return http.get(apiEndpoint + "verify-mail/"+token);
}

export function setPassword(user) {
  return http.post(apiEndpoint + "set-password", user);
}

export function deactivateAccount(user) {
  return http.post(apiEndpoint + "deactivate-account", user);
}

export function markUserNotifications(user) {
  return http.post(apiEndpoint + "mark-notifications", user);
}

export function getNotification(formData) {
  return http.post(apiEndpoint + "get-notifications", formData);
}
