import http from "./httpServices";
import config from "./config.json";
const apiEndpoint = config.apiEndpoint;

export function getPosts() {
  return http.get(apiEndpoint);
}

export function getPost(postId) {
  return http.get(apiEndpoint + "/" + postId);
}

export function getPostsAll(id) {
  return http.get(apiEndpoint + "?id="+id);
}


export function checkExpName(FormData) {
  return http.post(apiEndpoint+'checkExpName', FormData);
}

export function saveARExp(FormData) {
  return http.post(apiEndpoint+'store_ar_exp', FormData);
}

export function createNodes(FormData,config) {
  return http.post(apiEndpoint+'store_nodes', FormData, config);
}

export function deletePost(postId) {
  return http.delete(apiEndpoint + "/" + postId);
}
