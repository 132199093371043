import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


const ExperienceListWithSearch = (props) => {
  const {ExperienceLists,onSelect,value} = props;

  const capitalizeFirst = text => {
    try {
      let capitalFirst = text.charAt(0);
      return capitalFirst.toUpperCase();
    } catch (ex) {
      return text;
    }
  };
  
  const options = ExperienceLists.map((option) => {
    const firstLetter = capitalizeFirst(option.exp_name);
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const setValue = value.hasOwnProperty('exp_name') ? value : {id:0,exp_name:''};

  return (
    <Autocomplete
      id="grouped-demo"
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.exp_name}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label="Search Experience to get insight Analytics." />}
      onChange={(event, newValue) => {
        onSelect(newValue);
      }}
      value={setValue}
    />
  );
}

export default ExperienceListWithSearch;