import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Route, Routes, useNavigate} from 'react-router-dom';

import Home from './Pages/Home';
import Keys from './Pages/Keys';
import Help from './Pages/Help';
import SignUp from './Pages/SignUp';
import Logout from './Pages/Logout';
import Marker from './Pages/Marker';
import Testing from './Pages/Testing';
import Payment from './Pages/Payment';
import NotFound from './Pages/NotFound';
import Category from './Pages/Category';
import Payments from './Pages/Payments';
import Settings from './Pages/Settings';
import OpenAI from './Pages/openai-test';
import Dashboard from './Pages/Dashboard';
import Analytics from './Pages/Analytics';
import LoginForm from './Pages/LoginForm';
import QrCodePage from './Pages/QrCodePage';
import Collection from './Pages/Collection';
import ReSubscribe from './Pages/ReSubscribe';
import Confirmation from './Pages/Confirmation';
import Loader from './Components/Common/Loader';
import ResetPassword from './Pages/ResetPassword';
import ArExperiences from './Pages/ArExperiences';
import PaymentVerify from './Pages/PaymentVerify';
import ForgotPassword from './Pages/ForgotPassword';
import ExperienceForm from './Pages/ExperienceForm';
import auth from './Components/services/authService';
import EditExperiences from './Pages/EditExperiences';
import SocialLoginAuth from './Pages/SocialLoginAuth';
import AuthenticateUser from './Pages/AuthenticateUser';
import CreateExperiences from './Pages/CreateExperiences';
import ArCreateExperiences from './Pages/ArCreateExperiences';
import ProtectedRoutes from './Components/Common/protectedRoutes';

import "./App.css";

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      drawerOpen:true,
    }
  }
  
  async componentDidMount(props) {
    const user = await auth.getCurrentUser();
    if(user !== null && user !== ''){
      this.setState({user});
    }
  }

  render() {
    const {user} = this.state;
    if(this.state.user === 'undefined') return (<Loader text='Checking Auth ...' />);
    
    return (
      <React.Fragment>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<ProtectedRoutes {...this.props} drawerOpen={this.state.drawerOpen}  user={user} />}>
            <Route index element={<Home {...this.props} drawerOpen={this.state.drawerOpen} />} />
            <Route path='dashboard' element={<Dashboard  {...this.props} user={user}  />} />
            <Route path='ar' element={<ArExperiences {...this.props} user={user} />} />
            <Route path='create' element={<CreateExperiences {...this.props} user={user} />} />
            <Route path='ar/create' element={<ArCreateExperiences {...this.props} user={user} />} />
            <Route path='edit/:id' element={<EditExperiences  user={user} />} />
            <Route path='analytics' element={<Analytics {...this.props}  user={user} />} />
            <Route path='payments' element={<Payments  user={user} />} />
            <Route path='payment' element={<Payment  user={user} />} />
            <Route path='re-subscribe' element={<ReSubscribe  user={user} />} />
            <Route path='payment/verify/:id' element={<PaymentVerify  {...this.props} user={user} />} />
            <Route path='keys' element={<Keys {...this.props} user={user} />} />
            <Route path='marker' element={<Marker user={user} />} />
            <Route path="category" element={<Category  {...this.props} user={user}/>}  />
            <Route path='collections/:id' element={<Collection  {...this.props} user={user} />} />
            <Route path='settings' element={<Settings  user={user} />}  />
            <Route path='help' element={<Help user={user} />}  />
            <Route path='form' element={<ExperienceForm user={user} />} />
            <Route path='OpenAI' element={<OpenAI user={user} />} />
            <Route path='ar/qrcode/:name' element={<QrCodePage user={user} />} />
            <Route path='testing'element={<Testing user={user} />} />
            <Route path='logout' element={<Logout />} />
            <Route path='*' element={<NotFound user={user} />} />
          </Route>
          <Route path='/login' element={<LoginForm />} />
          <Route path='/forgot-password' element={<ForgotPassword {...this.props} />}/>
          <Route path='/sign-up' element={<SignUp {...this.props} />} />
          <Route path='/confirmation' element={<Confirmation {...this.props} />} />
          <Route path='/authenticate-user/:id' element={<AuthenticateUser {...this.props} />} />
          <Route path='/reset-password/:id' element={<ResetPassword {...this.props} />} />
          <Route path='/auth/google' element={<SocialLoginAuth provider="google"  {...this.props} />}/>
          <Route path='/auth/facebook' element={<SocialLoginAuth provider="facebook" {...this.props} />}/>
        </Routes>
        {/* <ProtectedRoute path='/' render={props => <Home {...props} user={user} />} /> */}
      </React.Fragment>
    )
  }
};


export function AppWithRouter(props){
  const navigate = useNavigate();
  return (<App {...props} navigate={navigate}></App>)
} 

export default App;