import React from 'react';
import { Typography } from '@mui/material';
import LoaderImage from './../../assets/loader.gif';

const Loader = (props) => {
    return (
        <div className="Page-Width-Vossle">
            <div style={{margin:'auto',textAlign:'center'}}>
            <img src={LoaderImage} alt="loader" />
            <Typography variant="h5">
                {/* {props.text} */}
            </Typography>
            </div>
        </div>
    );
}
 
export default Loader;