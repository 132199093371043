import { Component } from 'react';
import auth from '../Components/services/authService'
class Logout extends Component {
    componentDidMount(){
        auth.logout();
        window.location='/login';
    }
    render() { 
        return null;
    }
}
 
export default Logout;


