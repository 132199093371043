import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { toast } from "react-toastify";
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Info from '@mui/icons-material/Info';
import {Navigate,Link} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import vossleLogo from '../assets/logo.png';
import googleLogo from '../assets/googleLogo.png';
import facebookLogo from '../assets/facebookLogo.png';
import auth from "../Components/services/authService";
import {getSocialLink} from '../Components/services/userService';
import RenderButton from './../Components/InputFields/RenderButton';
import RenderSocialButton from '../Components/InputFields/RenderSocialButton';

class LoginForm extends Component {

    state = {
        data: {email:"",password:""},
        errors:{},
        showPassword:false,
        socialLinks:{
        },
        message:{},
        styles : theme => ({
            button: {
                backgroundColor: "green",
                "&:hover": {
                backgroundColor: "red"
                }
            },
            child: {
                backgroundColor: "blue"
            },
            rippleVisible: {
                opacity: 0.5,
                animation: `$enter 550ms ${theme.transitions.easing.easeInOut}`
            },
            "@keyframes enter": {
                "0%": {
                transform: "scale(0)",
                opacity: 0.1
                },
                "100%": {
                transform: "scale(1)",
                opacity: 0.5
                }
            }
        })
    }

    validateProperty = () => {return;};

    validate = () => {
        return;
    };

    handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
    
        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data, errors });
    };

    async componentDidMount(){
        try{
            const {data:social} = await getSocialLink();
            if(social.status === true)
            {
                let socialLinks = {};
                const {data:links} = social;
                socialLinks.google = links.google;
                socialLinks.facebook = links.facebook;
                this.setState({socialLinks})
            }
            else console.log('facing error in response data');
        }
        catch(ex){
            if (ex.response && ex.response.status === 400) {
                toast.error("Server Busy");
                let message = ex.response.data.errors;
                this.setState({ message });
            } else if (ex.response && ex.response.status === 404) {
                toast.error("Server Down, Under maintenance.");
            } else {
                toast.error("Unexpected Error");
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;
        this.doSubmit();
    };

    doSubmit = async () => {
        document.getElementById("submit_button").disabled = true;
        document.getElementById("submit_button").innerHTML = "Processing...";
    
        try {
            const data = new FormData();
            data.append("email", this.state.data.email);
            data.append("password", this.state.data.password);
            await auth.login(data);
            window.location = '/';
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = { ...this.state.errors };
                const {error} = ex.response.data;
                errors.global = error === 'invalid_credentials' ? 'Invalid Email or Password': error;
                this.setState({ errors });
            }
            document.getElementById("submit_button").disabled = false;
            document.getElementById("submit_button").innerHTML = "Failed, Try Again";
        }
    };
    
    render() {
        if(auth.getCurrentUser()) {
            return <Navigate to="/" />;
        }
        const {errors,showPassword,socialLinks} = this.state;
        return (
            <React.Fragment>
                <Box className="Page-Width-Vossle" sx={{display:'flex',height:'100vh',alignItems:'center',justifyContent:'center'}}>
                    <form className='form-signin' onSubmit={this.handleSubmit} style={{width:'400px'}}>
                        <Typography color='#682cea' sx={{display:'flex',justifyContent:'center',alignItems:'center',fontWeight:"600", fontSize: '35px',fontFamily: `Poppins, sans-serif`}} >
                            <p>Welcome to</p>
                            <span style={{display:'flex',justifyContent:'center',margin:"2px"}}><img  src={vossleLogo} height='42px' alt='vossle-logo'/> </span> 
                        </Typography>  
                        <Typography sx={{mb:'40px',fontSize:'14px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            Faster and easier AR platform
                        </Typography>    
                        
                        <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                            <Grid xs={12} item  sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                { (
                                    <RenderSocialButton
                                        name='login_with_google'
                                        type='button'
                                        label='Login with Google'
                                        isDisabled={socialLinks.google?false:true}
                                        minWidth={340}
                                        logo={googleLogo}
                                        variant='content'
                                        onClick={()=>window.location=socialLinks.google}
                                    />
                                )}
                            </Grid>
                            <Grid xs={12} item sx={{display:'flex',justifyContent:'center',alignItems:'center', mt:2}}>
                                {(
                                    <RenderSocialButton
                                        name='login_with_facebook'
                                        type='button'
                                        label='Login with Facebook'
                                        isDisabled={socialLinks.facebook ? false:true}
                                        minWidth={340}
                                        logo={facebookLogo}
                                        variant='contained'
                                        onClick={()=>window.location=socialLinks.facebook}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        
                        <Divider sx={{maxWidth:'300px',mx:'auto'}} >or </Divider>
                        
                        <Grid xs={12}  autoComplete="off" sx={{minwidth:"340px",display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}} >
                            <FormControl required='true' focused="false" sx={{ m: 1}} variant="outlined"  autoFill="off">
                                <OutlinedInput
                                    sx={{height:"48px !important",width:"340px",mb:1}}
                                    required 
                                    id="email" 
                                    name="email"
                                    placeholder="Email"
                                    autoFill='off'
                                    type="email"
                                    onChange={this.handleChange}
                                />
                            </FormControl>
                    
                            <FormControl  sx={{ m: 1}} variant="outlined"  autoFill="off">
                                <OutlinedInput
                                    required
                                    sx={{height:"48px !important",width:"340px"}}
                                    id="password"
                                    autoFill='off'
                                    placeholder="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={this.handleChange}
                                    name="password" 
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>this.setState({showPassword:(!showPassword)})}
                                        edge="end"
                                        >
                                        {showPassword ? <Visibility sx={{height:'18px'}} /> : <VisibilityOff sx={{height:'18px',color:'#7b7b7b'}} /> }
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                                                                                
                        <Grid xs={12} sx={{color:'red',ml:1,display:'flex',justifyContent:'flex-start',alignItems:'flex-start'}}>{errors.global && (<><Info sx={{display:'inline'}} /><Typography sx={{ml:1,fontWeight:'bold',fontSize:'16px',color:'red',display:'inline'}}>{errors.global}</Typography></>)}</Grid>

                        {/* <Typography variant="subtitle2" sx={{color:"grey"}}>Use 8 or more characters with a mix of letters, numbers and symbols</Typography> */}
                        <Grid xs={12} sx={{minwidth:"340px",display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <RenderButton 
                                name = 'submit_button'
                                type = "submit"
                                label = 'Submit'
                                isDisabled = {false}
                                minWidth = {340}
                            />
                        </Grid>
                        
                        <Grid container spacing={0} sx={{width:"340px",mx:'auto',p:0.4}}>
                            <Grid xs={7} sx={{pt:1,display:'flex',alignItems:'flex-start'}} >
                                <Typography sx={{fontSize:'14px',color:"#454547"}}>Not a member?  
                                    <Link to="/sign-up" style={{color:'#ff821c',marginLeft:"3px",padding:"0px 2px 0px 2px"}}>Signup</Link>
                                </Typography>
                            </Grid>
                            <Grid xs={5}  sx={{mb:'10px',pt:1,display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}}>
                                <Typography sx={{fontSize:"14px"}}>
                                    <Link to="/forgot-password" style={{color:'grey'}}>Forgot Password</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle2" sx={{color:'#B9B9B8',mx:'auto',textAlign:'center',fontSize:'12px',maxWidth:"350px"}}>
                            Creating an account means you're okay with our <a href="https://vossle.com/terms-and-conditions/" target="_blank" rel="noreferrer" style={{color:"#3987FC"}}> Terms of Service, </a><a href="https://vossle.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                        </Typography>
                    </form>
                </Box>
            </React.Fragment>
        );
    }
}
export default LoginForm;