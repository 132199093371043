import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CreateExperienceCard from '../../Components/cards/CreateExperienceCard';
import mouseImage from '../../assets/mouse.png'
import "../../css/CreateExperiences.css"
import TextField from '@mui/material/TextField';

const Step1 = (props) => {
  const {cardData,onStateChange,SearchByName,filterString} = props;
  console.log("my search name is here",filterString);

  let filteredCardData = cardData.filter((data)=>{
    return data.exp_name.toLowerCase().includes(filterString.toLowerCase())
  })

  return (
    // <Box sx={{m:'auto'}}>
      <Grid container>

        {/* <Grid container sx={{mb:4,mt:2,display:'flex',justifyContent:'center',alignItems:'center'}}>
         
          <Grid xs={11} sx={{px:2}}>
            <TextField fullWidth placeholder="Search" id="Search" sx={{borderRadius:'25px', color:'red', mx:'auto'}} onChange={(e)=>SearchByName(e.target.value)}/>
          </Grid>
        </Grid> */}

        {
          filteredCardData.length>0 
          ?(filteredCardData.map((data)=>(                  
              <Grid item xl={4} lg={6} md={6} sm={12}> 
                <CreateExperienceCard data={data} onClick={() => onStateChange(data)} />
              </Grid>
            )))
          :(
              <Grid container>
                <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'24px',fontWeight:'medium'}}>No Category Found</Grid>
                <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'24px',fontWeight:'medium'}}>
                  <img src={mouseImage} alt="no Img found" />
                </Grid>
              </Grid>
          )
        }
      </Grid>
    // </Box>
  )
}
 
export default Step1;