import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import config from './../services/config.json';
const CreateExperienceCard = (props) => {
  const {data} = props;
  const currentStep=1;
  console.log(data.name);
  return (
    <Box sx={{display:'flex',justifyContent:'center'}} onClick={props.onClick}>
      <Card elevation={0} sx={{ background:'#E9F5FF', width: '456px',minHeight: '182px', display:'flex',m:2,alignItems:'center', '&:hover': {boxShadow:6,cursor:'pointer'}}}>
        <Box sx={{position:'absolute',display:'flex-inline',mb:'24px'}}>
          <CardMedia component="img" width='100%' image={config.assetsEndpoint+data.count_color} />
        </Box>
        <Box sx={{fontSize:'18px',display:'flex',justifyContent:'right',pr:4,alignItems:'center',fontWeight:'bold',zIndex:10,height:'100%',width:'100%'}}>
          {data.exp_name}
        </Box>
      </Card>
    </Box>
  );
}
 
export default CreateExperienceCard;