import http from "./httpServices";
import config from "./config.json";
import axios from "axios";
import { getJWT } from "./authService";


export function experienceAnalytics(userFormData){
    return http.post(config.apiEndpoint + 'experience-analytics',userFormData);
}

export function experienceForAnalytics(userFormData){
    return http.post(config.apiEndpoint + 'experience-for-analytics',userFormData);
}

export function analyticsByDate(userFormData){
    return http.post(config.apiEndpoint + 'analytics-by-date',userFormData);
}
  
export function analyticsByExperienceType(userFormData){
    return http.post(config.apiEndpoint + 'analytics-by-exp',userFormData);
}

export function analyticsByGeolocation(userFormData){
    return http.post(config.apiEndpoint + 'analytics-by-geo',userFormData);
}
