import React, { Component } from 'react';
import auth from "../Components/services/authService";
import Box from '@mui/material/Box';
import { resetPassword } from '../Components/services/userService';
import Grid from '@mui/material/Grid';
import { Navigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import { Typography } from '@mui/material';
import {Link} from 'react-router-dom';
import vossleLogo from '../assets/logo.png';
import AllInputFields from '../Components/InputFields/AllInputFields';

import '../css/SignUp.css';
import Info from '@mui/icons-material/Info';
import { isBoolean } from 'lodash';
import withRouter from './../Components/services/withRouter';

class ResetPassword extends AllInputFields {
  constructor(props) {
    super(props)
    // this.qrcode = React.createRef();
    this.state = {
      data:{password:'',password_confirmation:''},
      errors:{},
      response:{},
      message:'',
      redirectreferrer:false,
    }
  }

  componentDidMount() {
    if(this.props.params.id === 'undefined') {
        this.setState({redirectreferrer:true});
    }
  }

  validate = () => {
    console.log('Validation Started');
    const { data } = this.state;
    const errors = {};
    
    if(data.password === '') errors.password = 'Password is required';
    if(data.password_confirmation === '') errors.password_confirmation = 'Password Confirmation is required';
    if(data.password !== data.password_confirmation) errors.global =  'Password and confirm password did not matched.';
    
    return Object.keys(errors).length === 0 ? null : errors;
  };

  doSubmit = async () => {
    document.getElementById("submit_button").disabled = true;
    document.getElementById("submit_button").innerHTML = "Processing...";
    try {
      console.log('processing of reset password');
      const {data} = this.state;
      const formData = new FormData();
      formData.append("password", data.password);
      formData.append("password_confirmation", data.password_confirmation);
      formData.append("login_code", this.props.params.id);
      const {data:res} = await resetPassword(formData);
      console.log(res);
      if (res.status && isBoolean(res.status)) {
        toast.success(res.message);
        const {message} = res;
        this.setState({ message,response:res });
        document.getElementById("submit_button").innerHTML = "Password setted";
        setTimeout(() => {
            this.setState({redirectreferrer:true});
        }, 2000);
      } else {
        toast.error(res.message);
        document.getElementById("submit_button").disabled = false;
      }
    } catch (ex) {
      console.log('Response Ex : ',ex);
      const errors = {};
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data.message);
        errors.global = ex.response.data.message;
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
        errors.global = 'Server Down, Under maintenance.';
      } else {
        toast.error("Unexpected Error");
        errors.global = 'Unexpected error occured, Please try again.'
      }
      this.setState({ errors });
      document.getElementById("submit_button").disabled = false;
      document.getElementById("submit_button").innerHTML = "Failed, Try Again";
    }
  };

  render() {
    const { errors,message,redirectreferrer } = this.state;
    
    if(auth.getCurrentUser()) return <Navigate to="/" />;
    if(redirectreferrer) return <Navigate to="/login" />;
    
    return (
      <Box className="Page-Width-Vossle" sx={{height:'90vh',display:'flex',alignItems:'center'}}>
        <Grid container item spacing={0}>
          <Grid xl={4} lg={4} md={4} sm={12} xs={12} sx={{m:"auto",mt:4,textAlign:'center'}}>
              <span style={{display:'flex',justifyContent:'center',margin:"2px 2px 20px 2px"}}><img  src={vossleLogo} height='36px' alt='vossle-logo'/></span>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                // sx={{
                //   m:4,
                //   borderRadius:'25px',
                //   p:5
                // }}
                onSubmit={this.handleSubmit}
              >
                <Grid xs={12}>{errors.global && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors.global}</Typography>)} {message && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'teal'}}><Info /> {message}</Typography>) }</Grid>
                {this.passwordInputField("password", "password" ,"New Password", true, false)}
                {this.passwordInputField("password_confirmation", "password" ,"Confirm Password", true, false)}
                {/* {this.renderInputWithIcon("password", "password", "New Password", true)} */}
                {/* {this.renderInputWithIcon("password_confirmation", "password", "Confirm Password", true)} */}
                <Grid container>
                  <Grid xs={12} >
                    <Button sx={{p:3,color:"white",fontSize:"medium",border:"3px solid #ff821c",background:'#ff821c',width:"100%",borderRadius:'50px', '&:hover': {color: "black",},}} type="submit" id="submit_button">Update Password</Button>
                  </Grid>
                  <Grid xs={12}>
                    <Typography variant='subtitle1'><Link  to="/login" >Back To Login</Link></Typography>
                  </Grid>
                </Grid>
              </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
};

export default withRouter(ResetPassword);
