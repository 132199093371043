import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Button, Typography } from '@mui/material';
import { isNull } from 'lodash';

const KeyCard = (props) => {
  const {data, user, generateKey} = props;
  const cardStyles = {
    background : "#f00 !important",
    '&:hover' : { background : "#f331 !important",}
  };

  const MouseOnCard = (id) => {
    let cardImg = document.getElementById(id);
    cardImg.style.marginLeft='30px';
    cardImg.style.color='white';

  };

  const MouseOutFromCard = (id) => {
    let cardImg = document.getElementById(id);
    cardImg.style.marginLeft='0px';
    cardImg.style.color='black';
  };

  let key = null;

  return (
    <React.Fragment>
      {
        data.map((data) => (
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Box className="keyCard">
              <Card className="CardForStyling" elevation={0}
                onMouseOver={() => MouseOnCard('KeyImage'+data.id)}
                onMouseOut={() => MouseOutFromCard('KeyImage'+data.id)}
                sx={{
                  // background:'#9090A554',
                  border:'1px solid grey',
                  borderRadius:'10px',
                  width:'212px',height:'265px',m:1,alignItems:'center',
                  '&:hover': {
                    boxShadow:6,
                    backgroundColor:'#682CEA',
                    color:'white'
                  }
                }}
              >
                <div style={{marginTop:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <CardMedia
                      component="img"
                      sx={{width:"155px",height:'160px','&:hover': {
                            // ml:'15px'
                          }}}
                      image={data.image}
                      id = {'KeyImage'+data.id}
                  />
                </div>
                <Box sx={{
                  height:'100px',
                  float:'bottom',
                  width:"100%",
                  textAlign:'center'
                }}
                >
                  <Typography sx={{fontSize:'18px',p:0.4,fontWeight:'550'}}>{data.keyType}</Typography>
                  {
                    (isNull(user[data.id])) 
                    ?(<Button sx={{height:'41px',color:"white",width:"84%",borderRadius:"10px",margin:'auto',background:'#37BE70'}} onClick={() => generateKey(data.id)}>{data.key}</Button>) 
                    :(<Typography sx={{height:'41px',color:"white",width:"84%",borderRadius:"10px",margin:'auto',background:'#37BE70',fontSize:'20px',py:'6px'}}>{user[data.id]}</Typography>)
                  }
                </Box>
              </Card>
            </Box>
          </Grid>
        ))
      }
    </React.Fragment>
  );
}
 
export default KeyCard;

// class Keys extends Component {
  
//   constructor(props){
//     super(props)
//     this.state = {
//       data:props.data,
//       user:props.user,
//       cardStyles : {
//         background: "#f00 !important",
//         '&:hover': {
//           background: "#f331 !important",
//         }
//       },
//     }
//   }

//   MouseOnCard = (id) => {
//     let cardImg = document.getElementById(id);
//     cardImg.style.marginLeft='30px';
//     cardImg.style.color='white';

//   };

//   MouseOutFromCard = (id) => {
//     let cardImg = document.getElementById(id);
//     cardImg.style.marginLeft='0px';
//     cardImg.style.color='black';
//   };

//   render() {
//     const {data,user}=  this.state;
//     console.log('Data',data);
//     console.log('User Data',user);
//     const {generateKey} = this.props;
//     let key = null;
//     if(data.id == 'shopify_key') key = user.shopify_key;
//     if(data.id == 'wordpress_key') key = user.wordpress_key;
//     if(data.id == 'magento_key') key = user.magento_key;
    
//     return (
//           <Box className="keyCard">
//             <Card className="CardForStyling" elevation={0}
//               onMouseOver={() => this.MouseOnCard('KeyImage'+data.id)}
//               onMouseOut={() => this.MouseOutFromCard('KeyImage'+data.id)}
//               sx={{
//                 // background:'#9090A554',
//                 border:'1px solid grey',
//                 borderRadius:'10px',
//                 width:'212px',height:'265px',m:2,alignItems:'center',
//                 '&:hover': {
//                   boxShadow:6,
//                   backgroundColor:'#682CEA',
//                   color:'white'
//                 }
//               }}
//             >
//               <div style={{marginTop:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
//                 <CardMedia
//                     component="img"
//                     sx={{width:"155px",height:'160px','&:hover': {
//                           // ml:'15px'
//                         }}}
//                     image={data.image}
//                     id = {'KeyImage'+data.id}
//                 />
//               </div>
//               <Box sx={{
//                 height:'100px',
//                 float:'bottom',
//                 width:"100%",
//                 textAlign:'center'
//               }}
//               >
//                 <Typography sx={{fontSize:'18px',p:0.5,fontWeight:'550'}}>{data.keyType}</Typography>
//                 {
//                   isNull(key) ? 
//                   (<Button sx={{height:'41px',color:"white",width:"84%",borderRadius:"25px",margin:'auto',background:'#37BE70'}} onClick={() => generateKey(data.id)}>{data.key}</Button>) :
//                   (<Typography sx={{height:'41px',color:"white",width:"84%",borderRadius:"25px",margin:'auto',background:'#37BE70',fontSize:'20px',py:'6px'}}>{key}</Typography>)
//                 }
//               </Box>
//             </Card>
//           </Box>
//     )
//   }
// };
// export default Keys;