import http from "./httpServices";
import config from "./config.json";
const apiEndpoint = config.apiEndpoint;

export function getCollection() {
  return http.get(apiEndpoint+'collections');
}

export function getKeys() {
  return http.get(apiEndpoint+'getpaymentdetails');
}
