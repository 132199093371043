import React from 'react'
import "../../css/ArGLBCard.css";
import config from '../services/config.json';
import { Button,Grid,Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const ArGLBCard = (props) => {
  const { collection, selectedItems} = props;
  
  const cardStyles = {
    visibility: 'hidden',
    '&:hover': {
      visibility: 'visible',
    }
  };

  return (
    <>
      {collection.map((data) => (
        <Grid xs={12} sm={6} md={4} lg={4} xl={3} sx={{mb:2,display:'flex',justifyContent:'center',alignItems:'center'}}>
          <div style={{border:'1px solid lightgrey',borderRadius:'10px',width:"302px",height:'310px'}}>
            <Box className="GLBModal" sx={{height:'251px',display:'flex',alignItems:'center',borderRadius:'10px 10px 0px 0px',background:'#F1F3F5'}}>
                <model-viewer src={config.assetsEndpoint + data.assets} sx={{display:'flex',alignItems:'center',justifyContent:'center'}} width="100%" height='251px' alt="A 3D model of a robot" auto-rotate="" camera-controls="" background-color="#455A64"></model-viewer>
            </Box>
        
            <Box sx={{position:'relative',bottom:'56px',width:'100%'}} >
              <Box sx={{display:'flex',justifyContent:'center'}}>
                <Button  sx={{borderRadius:'25px',textTransform:'capitalize',background:'#FF821C',width:'137px',height:'45px',color:'white',m:1,zIndex:10,opacity:53,'&:hover':{border:'1px solid #FF821C',color:'#FF821C'}}} onClick={() => selectedItems(data) }>Create AR</Button>
              </Box>
              <Box sx={{p:1}}>
                <Typography sx={{fontSize:'18px',fontWeight:'medium'}}>
                  {data.name}
                </Typography>
              </Box>
            </Box>
          </div>
        </Grid>
      ))}
    </>
  );
}
 
export default ArGLBCard;