import React from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const CountdownCircle = (props) => {
    const {timeChange} = props;
    
    const handleChange = () => {
        console.log('Handle Change Called for function');
        // setPage(value);
        timeChange();
        return { shouldRepeat: false, delay: 1.5 };
    };
    return (
        <CountdownCircleTimer
            isPlaying
            duration={59}
            colors={['lightgrey']}
            trailColor="#FF821C"
            size={30}
            strokeWidth={2}
            trailStrokeWidth={2}
            onComplete={() => handleChange()}
        >
            {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>
    );
}
 
export default CountdownCircle;