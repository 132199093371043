import React, { Component } from 'react'

import { Grid } from '@mui/material';
import List from '@mui/material/List';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Loader from './Loader';
import Paginations from './paginations';
import ArGLBCard from '../cards/ArGLBCard';
import config from '../services/config.json'
import { paginate } from '../utils/paginate';

class UploadGlbDialog extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedItem:{},
            selectedCategoryCollection:[],
            fullScreenDialog:false,
            pageSize: 6,
            allCategory:[]
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    ModelViewer = (assetUrl) =>{
      return(
        <model-viewer 
              sx={{maxWidth:"100%",maxHeight:'100%'}}
              src={config.assetsEndpoint+assetUrl} alt="A 3D model" auto-rotate="" camera-controls="" 
              id='first glb'
      ></model-viewer>
      )
    }


  

    showThreeGlb = (data) =>{
      return(
        <React.Fragment>
          <Grid xs={6}  key = {data.category_id} >
            { data.library_collections.length >= 0?
              (
                <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'10px 0px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                  {this.ModelViewer(data.library_collections[0].assets)}
                </Grid>
              ):(
                <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'10px 0px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                  {this.ModelViewer(data.library_collections[0].assets)}
                </Grid>
              )
            }
            {
              data.library_collections.length >= 1?
              (
                <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 0px 0px 10px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                {this.ModelViewer(data.library_collections[1].assets)}
                </Grid>
              ):(
                <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 0px 0px 10px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                {this.ModelViewer(data.library_collections[0].assets)}
                </Grid>
              )
              }
          </Grid>
          {
            <Grid xs={6}  key = {data.category_id} >
            { data.library_collections.length >= 3?
              (
                <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 10px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                  {this.ModelViewer(data.library_collections[2].assets)}
                </Grid>
              ):(
                <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 10px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                  {this.ModelViewer(data.library_collections[0].assets)}
                </Grid>
              )
            }
            {
              data.library_collections.length >= 4?
              (
                <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 0px 10px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                {this.ModelViewer(data.library_collections[3].assets)}
                </Grid>
              ):(
                <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 0px 10px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                {this.ModelViewer(data.library_collections[0].assets)}
                </Grid>
              )
              }
          </Grid>
            // data.library_collections.length > 2 ?
            //   (
            //     <Grid xs={6} sx={{background:'#F1F3F5',borderRadius:'0px 10px 10px 0px',display:'flex',justifyContent:'center',alignItems:'center',borderRight:'1px solid lightgrey',borderTop:'1px solid lightgrey',borderBottom:'1px solid lightgrey'}}>
            //      {this.ModelViewer(data.library_collections[2].assets)}
            //     </Grid>
            //   ):(
            //     <Grid xs={6} sx={{background:'#F1F3F5',borderRadius:'0px 10px 10px 0px',display:'flex',justifyContent:'center',alignItems:'center',borderRight:'1px solid lightgrey',borderTop:'1px solid lightgrey',borderBottom:'1px solid lightgrey'}}>
            //     {this.ModelViewer(data.library_collections[0].assets)}
            //     </Grid>
            //   )
          }
        </React.Fragment>
      )
    }

    
    showCollection = () =>{
      let {isloading,selectedCategoryCollection} = this.props;
      console.log("now selected Collection is",selectedCategoryCollection)
      return(
        <div>
          {
            isloading === true?(
              <Loader text="loading collection..." />
            ):                        
            (<Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <ArGLBCard selectedItems={this.props.selectedItems} collection={selectedCategoryCollection} />
            </Grid>)
          }
        </div>
      )
    }


    showExpTypes = () => {
        const {Transition} = this;

        const {pageSize}= this.state;

        const {allCategory,isloading,handleFullScreenDialogClose,phase,selectedItems,selectedCategoryCollection,currentPage} = this.props;

        console.log("my allCateogyr data is =>>",allCategory,currentPage,pageSize)
        let paginated = []
        if(allCategory.length>0){
          paginated = (phase ===1 ? paginate(allCategory, currentPage, pageSize) : paginate(selectedCategoryCollection, currentPage,pageSize))
        }
    
        console.log("==> all category",allCategory)
        
        return(
            <div>
              <Dialog
              fullScreen
              open={this.props.fullScreenDialog}
              TransitionComponent={Transition}
              >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleFullScreenDialogClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 ,textTransform:'capitalize'}} variant="h6" component="div">
                        Choose From Gallery
                    </Typography>
                
                </Toolbar>
                </AppBar>
                <List>
                  <Grid  id="model" container>
                      {
                        phase === 1 && paginated.length === 0 ?
                        (
                          <Grid xs={12} container sx={{mb:4, padding:'26px',maxHeight:'350px',maxWidth:'450px',borderRadius:'10px'}}>
                            <Typography sx={{ m: 5, flex: 1 ,textTransform:'capitalize'}} variant="h2" component="div">
                              No Category Found
                            </Typography>
                          </Grid>
                        )
                        :
                        (phase === 1 && paginated.map((data) => {
                          return(
                            isloading === true? (
                              <Loader text="loading ..." />
                            ):
                            (
                              <Grid xs={12} sm={6} md={6} lg={4} xl={3} container sx={{mb:4, padding:'26px',maxHeight:'350px',maxWidth:'450px',borderRadius:'10px'}} onClick={()=>{this.props.selectedCategory(data);}} >
                                {this.showThreeGlb(data)}
                                <Grid xs={12}>
                                  <div style={{padding:'4px',marginLeft:'10px'}}>
                                    <div style={{fontSize:'18px',fontWeight:'600'}}>
                                      {data.name}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            )
                          )
                        }))
                      }
                      {
                        phase ===2 && (
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12} container sx={{mb:4, padding:'26px',maxWidth:'450px',borderRadius:'10px'}}>
                            {
                              isloading === true ? 
                              (
                                <div style = {{width:"100%",margin:"auto"}}>
                                  <Loader text="loading collection..." />
                                </div>
                              ):(
                                <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                  <ArGLBCard selectedItems={selectedItems} collection={paginated} />
                                </Grid>
                              )
                            }                   
                          </Grid>
                        )
                      }
                    </Grid>
                    {
                      <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <Paginations
                          itemsCount={phase ===1 ? allCategory.length : selectedCategoryCollection.length}
                          pageSize={pageSize}
                          onPageChange={this.props.handlePageChange}
                          currentPage={currentPage}
                        />
                      </Grid>
                    }
                </List>
              </Dialog>
        </div>
        )
    }

    render() {
        return (
            <div>
                {this.showExpTypes()}
            </div>
        )
    }
}

export default UploadGlbDialog;