import React, { Component } from 'react';

import dayjs from 'dayjs';
import { isBoolean } from 'lodash';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import { Navigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FilterAltSharpIcon from '@mui/icons-material/FilterAltSharp';


import '../css/Dashboard.css';
import thumbnail from '../assets/thumbnail.png';
import CardImage1 from "../assets/CardImage1.png";
import CardImage2 from "../assets/CardImage2.png";
import CardImage3 from "../assets/CardImage3.png";

import Loader from '../Components/Common/Loader';
import VideoCard from '../Components/cards/VideoCard';
import AnalyticsChart from '../Components/chart/AnalyticsChart';
import RenderButton from './../Components/InputFields/RenderButton';
import ARExperienceCard from '../Components/cards/ARExperienceCard';
import WorkingProductivityCard from '../Components/cards/workingProductivityCard';

import {getUserDetails } from '../Components/services/authService';
import {analyticsByDate, analyticsByExperienceType, analyticsByGeolocation} from '../Components/services/analyticsService';
import {getExperinceAllStatus,getExperienceWithFilter, getExperienceWorkingProductivity,deleteArExp} from '../Components/services/arService';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arExperienceFilterType:'created_at',
      analyticsFilterType:'analytics_by_date',
      analyticsExpFilterType:'all',
      loaderFlag:false,
      chartData:[],
      token_expired:false,
      collections:[],
      CurrentRunningVideo:-1,
      status:{
        total:'',
        inactive:'',
        active:'',
        deleted:''
      },
      pageData:{
        name:'',
      },
      WorkingProductivityCardData:[
        {
          title:'Total View Count',
          value:'',
          image:CardImage1,
          subtitle:'',
          background:'#E9F5FF',
        },{
          title:'Total AR Experiences',
          value:'',
          image:CardImage2,
          subtitle:'',
          background:'#F6F3EE',
        },{
          title:'Most Viewed Experience',
          value:'',
          image:CardImage3,
          subtitle:'',
          background:'#F5E3E3',
        }
      ],
      arExperienceData:[],
      responsive2Cards : {
        0: { items: 1.1},
        568: { items: 1.1 },
        1025: { items: 1.1 },
        1050: { items: 1.4 },
      },
      responsive3Cards : {
        0: { items: 1.1},
        568: { items: 1.1 },
        1025: { items: 2.1 },
        1050: { items: 2.4 },
      },
      ExperienceOpen:false,
      ExperienceOption:null,
      selectedFilter: {id:1,name:'All',value:'Active',action:'all'},
      filters:[
          {id:1,name:'All',value:'Active',action:'all'},
          {id:2,name:'Sort By Geography',value:'Recent',action:'status'},

          {id:5,name:'markerless',value:'Recent',action:'exp_type'},
          {id:6,name:'markerbased',value:'Deactive',action:'exp_type'},
          {id:7,name:'tryon',value:'Ascending',action:'exp_type'},
          {id:8,name:'games',value:'games',action:'exp_type'},

          {id:9,name:'Yearly',value:'date',action:'date'},
          {id:10,name:'Monthly',value:'date',action:'date'},
          {id:11,name:'Weekly',value:'date',action:'date'},
          {id:12,name:'Custom Range',value:'date',action:'date'}
      ],
    }
  }

  async componentDidMount(){
    const {arExperienceFilterType} = this.state;
    try {
      let newdate = dayjs(new Date()).format('YYYY-MM-DD');
      const {data:userData} = await getUserDetails();

      if(userData.status && isBoolean(userData.status)) {
        let {data:user} = userData;
        this.setState({user:user})         // set on end
        this.setState({pageData:{name:user.name}})   ///set on end

        const userInfo = new FormData();
        userInfo.append("user_id", user.id);
        userInfo.append('filter_type', arExperienceFilterType);

        const {data:expsByFilter} = await getExperienceWithFilter(userInfo)
        
        const {data:arData} = await getExperinceAllStatus(userInfo);
        if(arData.status && isBoolean(arData.status)) {
          let {data:status} = arData;
          let Status = {
            total:0,
            inactive:0,
            active:0,
            deleted:0
          }
          
          if(status.length !== 0)
          {
            status.forEach(st=>{

              if(st.status === 'Active'){
                Status.active = st.total ;
              }
              else if(st.status==='Deactive'){
                Status.inactive = st.total;
              }
              else if(st.status === 'Delete'){
                Status.deleted= st.total;
              }
            })
          }

          Status.total = Status.active + Status.inactive ;
          const {data:workingprodutivity} = await getExperienceWorkingProductivity(userInfo);         
        
          if(workingprodutivity.status && isBoolean(workingprodutivity.status))
          {
            const data = workingprodutivity.data;
            if(data !== 'not_found')
            {
              let temp_workingProductivity = [...this.state.WorkingProductivityCardData]
              temp_workingProductivity[0].value = data.total_view != null?data.total_view:0;
              temp_workingProductivity[1].value = data.total_ar != null? data.total_ar:0;
              if(data.most_viewed != null){                
                temp_workingProductivity[2].value = data.most_viewed.exp_name;
                temp_workingProductivity[2].subtitle = data.most_viewed.most_view;
              }
              else{
                temp_workingProductivity[2].value = '0';
                temp_workingProductivity[2].subtitle = 'No Experience';
              }

              let start_date = dayjs(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
              userInfo.append("start_date",start_date)
              userInfo.append("end_date",newdate)
              
              // handling chartData by Analytics api
              const {data:chartData} = await analyticsByDate(userInfo);
              if(chartData.status && isBoolean(chartData.status))
              {
                if(expsByFilter.status && isBoolean(expsByFilter.status)) {
                  this.setState({loaderFlag:true,arExperienceData:expsByFilter.data,chartData:chartData.data,WorkingProductivityCardData:temp_workingProductivity,status:Status})
                } else if(expsByFilter.status === 'Token is Expired') {
                  this.setState({text:'User token expired, Redirecting to login page.'});
                  setTimeout(()=>{
                    this.setState({token_expired:true});
                  },400)
                }
              } else if(chartData.status === 'Token is Expired') {
                this.setState({text:'User token expired, Redirecting to login page.'});
                setTimeout(()=>{
                  this.setState({token_expired:true,loaderFlag:false});
                },400)
              }
            }
          } 
          else if(workingprodutivity.status === 'Token is Expired') {
            this.setState({text:'User token expired, Redirecting to login page.'});
            setTimeout(()=>{
              this.setState({token_expired:true});
            },400);
          }
        } else if(userData.status === 'Token is Expired') {
          this.setState({text:'User token expired, Redirecting to login page.'});
          setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
          },400);
        }
      } else if(userData.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400);
      }
    }
    catch(ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }

  makeVideoCardsList = (CardsData) => {
    return CardsData.map((data)=>{
      data = {...data,thumbnail:thumbnail}
      return (<VideoCard CardData={data} 
        // playAndStopVideo={this.playAndStopVideo}
        />);
    })
  }

  ApplyAnalyticsExperienceFilter = (operation) =>{
    this.setState({analyticsExpFilterType:operation,ExperienceOpen:false,ExperienceOption:null})
    setTimeout(()=>{
     this.ApplyAnalyticsFilter('analytics_by_exp');    
    },200)
  }

  ApplyArCardFilter  = async (operation) => {  
    try
    {
      const userInfo = new FormData();
      userInfo.append("user_id",this.state.user.id);
      userInfo.append('filter_type',operation);     
      const {data:expsByFilter} = await getExperienceWithFilter(userInfo);

      if(expsByFilter.status && isBoolean(expsByFilter.status)) {
        if(operation === 'view_count') {
          let {data:viewCountData} = expsByFilter;
          let newViewCountData = Object.values(viewCountData);
          newViewCountData.sort(( a, b ) => {
            let sum1 = 0,sum2 = 0;
            a.analytics.forEach((analy)=>{
              sum1 += analy.sessions;
            })
            b.analytics.forEach((analy)=>{
              sum2 += analy.sessions;
            })
            if(sum1 < sum2){
              return 1;
            }
            else if(sum1 > sum2) {
              return -1;
            }
            return 0;
          });
          this.setState({arExperienceData:newViewCountData,arExperienceFilterType:operation});
        } else this.setState({arExperienceData:expsByFilter.data,arExperienceFilterType:operation});
      }
      else if(expsByFilter.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(() => {
          this.setState({token_expired:true,loaderFlag:false});
        },400);
      }
    }     
    catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }

  ApplyAnalyticsFilter = async (operation ) => {
    try {
      this.setState({analyticsFilterType:operation})
      const analyticsInfo = new FormData();
      analyticsInfo.append("user_id",this.state.user.id);

      let enddate = dayjs(new Date()).format('YYYY-MM-DD');
      let startdate = dayjs(new Date()).subtract(7, 'days').format('YYYY-MM-DD');

      analyticsInfo.append('start_date',startdate)
      analyticsInfo.append('end_date',enddate)

      let chartData = [];
      if(operation === 'analytics_by_date' ) {
        chartData = await analyticsByDate(analyticsInfo)
      } else if(operation === 'analytics_by_exp') {
        if(this.state.analyticsExpFilterType !== 'all') {
          let exp_type_filter = this.state.analyticsExpFilterType;
          if(this.state.analyticsExpFilterType === 'advertisement') {
            exp_type_filter = 'ads';
            analyticsInfo.append('filter_by',exp_type_filter);
          }
        }
        chartData = await analyticsByExperienceType(analyticsInfo);
      } else if(operation === 'analytics_by_geo') {
        chartData = await analyticsByGeolocation(analyticsInfo);
      }

      let {data:chart} = chartData;
      
      if(chart.status === true && isBoolean(chart.status)){   
        this.setState({chartData:chart.data})
      } else if(chart.data === 'data_not_found'){
        this.setState({chartData:[]})
      } else if(chart.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400)
      }
    }     
    catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
      
  }
  
  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  handleArExperienceOperation = async (operation,data) =>{
    if(operation.toLowerCase() === 'edit'){
      this.props.navigate('/edit/'+data.id)
    } else {
      const {arExperienceFilterType} = this.state;
      // delete & get ar experiences
      const DeleteInfoForm = new FormData()
      let {user_id,id} = data;

      DeleteInfoForm.append('user_id',user_id)
      DeleteInfoForm.append('ar_id',id)
      DeleteInfoForm.append('filter_type',arExperienceFilterType);

      const DeletionStatus = await deleteArExp(DeleteInfoForm);
      if(DeletionStatus.status === 200) {
        if(DeletionStatus.data.status) {
          toast.success("Experience Deleted Successfully");
          this.setState({arExperienceData:DeletionStatus.data.data.original.data})
        } else toast.error("Unexpected Error");
      }
    }
  }
  
  getArGlbCardData = (index) =>{
    const {arExperienceData} = this.state;
    return arExperienceData[index];
  }
  
  handleSortByExpOpen = (event) =>{
    this.setState({ExperienceOpen:true,ExperienceOption:event.currentTarget})
  };
  
  handleSortByExp = ()=>{
    this.setState({ExperienceOpen:false,ExperienceOption:null})
  }

  handleSortByExpClose = (selectedFilter) => {
    if(selectedFilter === undefined || typeof selectedFilter === 'string'){
      this.setState({ExperienceOpen:false})
      return false;
    } else {
      this.setState({currentFilter:selectedFilter.name})
      this.setState({selectedFilter,ExperienceOpen:false,ExperienceOption:null})
    }
  };

  render() {
    const {arExperienceFilterType,loaderFlag, pageData,token_expired, arExperienceData,chartData} = this.state;
    if(token_expired) return (<Navigate to='/logout' />);
    if( loaderFlag === false) return (<Loader text="Loading Data ..."/>);

    return (
      <Box className="Page-Width-Vossle">
        <Box sx={{fontFamily:'Noto Sans',p:'10px 52px 0px 52px',mt:"40px",alignItems:'center',justifyContent:'center',textAlign:'center',mb:2}} >
          <p  style={{fontFamily:`Poppins, sans-serif`,fontWeight:'500',margin:"0px 0px 6px 0px",display:'flex',fontSize:'38px',justifyContent:'center',color:'#053630'}} >
            Hello! { pageData && this.capitalizeFirstLetter(pageData.name)} 
          </p>
          <p style={{justifyContent:'center',textAlign:'center',display:'flex',fontSize:'13px',color:'#343434',width:'48%',margin:'0px auto 0px auto'}} >
            Use Vossle to overlay 3D digital content onto physical world - as if they <br/> are actually with you, in your own space
          </p>
        </Box>

        <Grid container spacing={2} sx={{margin:'0px auto 0px auto',display:'flex',justifyContent:'center',alignItems:'center',maxWidth:"300px"}}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <RenderButton 
              name = 'create_ar'
              type = "button"
              label = 'Create AR'
              isDisabled = {false}
              minWidth = {160}
              onClick={()=>{this.props.navigate('/create')}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <a href="/#Inspiration" style={{fontSize:'16px',color:'#1c7ed6',textDecoration:'underline'}}>Inspire Yourself</a>
          </Grid>
        </Grid>

        <Box sx={{mt:10,textAlign:'center',fontSize:"26px",fontFamily:`Poppins, sans-serif`}}>
          Working Productivity
        </Box>
        
        <Box sx={{mt:1}}>
          <Grid container spacing={1}  sx={{mt:1,display:'flex',alignItems:"center !important",justifyContent:'center !important'}}>
            {
              this.state.WorkingProductivityCardData.map(element => {
                return(
                <Grid item xs={12} sm={6} md={4} sx={{display:'flex',justifyContent:'center'}}>
                  <WorkingProductivityCard data={element}/>
                </Grid>
                )
              }) 
            }
          </Grid>
        </Box>
        
        <div style={{display:'flex',justifyContent:'center',marginTop:'70px'}}>
          <Grid container sx={{display:'flex',justifyContent:'center',alignitems:'center'}}>
            {arExperienceData.length !== 0 &&
              <Grid  xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} sx={{p:1}} >
                <Grid xs={12} sx={{mb:1}}>
                  <Typography variant="h5" sx={{mb:3}}> 
                    AR Experiences 
                  </Typography>
                </Grid>
                <Grid container sx={{fontSize:'15px',mb:'10px',mt:'10px'}}>
                  <Grid xs={12} sm = {0.9} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}} >
                    <FilterAltSharpIcon sx={{color:"grey"}}/>
                  </Grid>
                  <Grid xs={6} sm = {3.7} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}  >
                    <Button className={arExperienceFilterType==="created_at"?"selectfilterDesign":"filterDesign"} sx={{width:"110px",borderRadius:"50px",color:(arExperienceFilterType==='created_at'?'white':'#454747')}} onClick={()=>{this.ApplyArCardFilter('created_at')} }>
                      Date
                    </Button>
                  </Grid>
                  <Grid xs={6} sm = {3.7} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                    <Button className={arExperienceFilterType==='exp_name'?"selectfilterDesign":"filterDesign"} sx={{width:"110px",borderRadius:"50px",color:(arExperienceFilterType==='exp_name'?'white':'#454747') }} onClick={()=>{this.ApplyArCardFilter('exp_name')}} >
                      Experience
                    </Button>
                  </Grid>
                  <Grid xs={6} sm={3.7} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                    <Button className={arExperienceFilterType==='view_count'?"selectfilterDesign":"filterDesign"} sx={{width:"110px",borderRadius:"50px",color:(arExperienceFilterType==='view_count'?'white':'#454747')}} onClick={()=>{this.ApplyArCardFilter('view_count')}}>
                      view count
                    </Button>
                  </Grid>
                </Grid> 
                <Grid container xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'80%'}} >
                  {  
                    arExperienceData.map((element,index )=> {
                      return(
                        <Grid xs={12} sm={6} md={12} lg={12} sx={{mb: (index+1===arExperienceData.length ?'0px':'10px') }} >
                          <ARExperienceCard {...this.props}  getArGlbCardData={this.getArGlbCardData} handleArExperienceOperation={this.handleArExperienceOperation} cardData = {element}/>
                        </Grid>
                      )
                    }) 
                  }
                </Grid>
              </Grid>
            }
            {chartData.hasOwnProperty('analytics_data') && 
              <Grid  xs={12} sm={12} md={7.5} lg={7.5} xl={7.5} sx={{p:1}}>
                <Grid xs={12} >
                  <Typography variant="h5" sx={{mb:3}}> 
                    Analytics 
                  </Typography>
                </Grid>

                <Grid container xs={12} sx={{fontSize:'18px'}}>
                  <Grid item xs={12} sm = {1} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}} >
                    <FilterAltSharpIcon sx={{color:"grey"}}/>
                  </Grid>

                  <Grid item xs={6} sm = {2.25} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}  >
                    <Button className={arExperienceFilterType==='analytics_by_date'?"selectfilterDesign":"filterDesign"} sx={{width:"120px",borderRadius:"50px",color:(this.state.analyticsFilterType==='analytics_by_date'?'#682CEA':'#454747')}} onClick={()=>{this.ApplyAnalyticsFilter('analytics_by_date')}} >
                      Date
                    </Button>
                  </Grid>

                  <Grid xs={6} sm = {2.25} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}  >
                    <Button
                      className={arExperienceFilterType==="analytics_by_exp"?"selectfilterDesign":"filterDesign"}
                      id="fade-button"
                      aria-controls={this.state.ExperienceOpen ? 'fade-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={this.state.ExperienceOpen ? 'true' : undefined}
                      onClick={this.handleSortByExpOpen}
                      onClose={this.handleSortByExp}
                      sx={{width:"120px",borderRadius:"50px",color:(this.state.analyticsFilterType==='analytics_by_exp'?'#682CEA':'#454747')}}
                    >
                      <Grid container className="alignCenter" >
                        <Grid xs={10} className="alignCenter" sx={{justifyContent:'center'}} >
                          {this.state.analyticsExpFilterType === 'all'?'Experience': this.state.analyticsExpFilterType}
                        </Grid>
                        <Grid xs={2} sx={{display:'flex',alignItems:'flex-start',justifyContent:'flex-start'}}>
                          {this.state.ExperienceOpen ? <ArrowDropDownIcon />:<ArrowRightIcon />}
                        </Grid>
                      </Grid>
                    </Button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                      'aria-labelledby': 'fade-button',
                      }}
                      anchorEl={this.state.ExperienceOption}
                      open={this.state.ExperienceOpen}
                      onClose={()=>this.handleSortByExpClose(this.state.currentFilter)}
                    >
                      <MenuItem onClick={()=>{this.ApplyAnalyticsExperienceFilter('all')}}> All Experiences</MenuItem>
                      <MenuItem onClick={()=>{this.ApplyAnalyticsExperienceFilter('markerbased')} }>Markerbased</MenuItem>
                      <MenuItem onClick={()=>{this.ApplyAnalyticsExperienceFilter('markerless')} }>Markerless</MenuItem>
                      <MenuItem onClick={()=>{this.ApplyAnalyticsExperienceFilter('games')}}>Games</MenuItem>
                      <MenuItem onClick={()=>{this.ApplyAnalyticsExperienceFilter('tryon')}}>Tryon</MenuItem>
                      <MenuItem onClick={()=>{this.ApplyAnalyticsExperienceFilter('workflow')}}> Workflow</MenuItem>
                      <MenuItem onClick={()=>{this.ApplyAnalyticsExperienceFilter('advertisement')}}> Advertisement </MenuItem>
                    </Menu>
                  </Grid>

                  <Grid xs={6} sm = {2.25} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}  >
                    <Button className={arExperienceFilterType==='analytics_by_geo'?"selectfilterDesign":"filterDesign"} sx={{width:"120px",borderRadius:"50px",color:(this.state.analyticsFilterType==='analytics_by_geo'?'#682CEA':'#454747')}} onClick={()=>{this.ApplyAnalyticsFilter('analytics_by_geo')} }>
                    Geography
                    </Button>
                  </Grid>

                  <Grid sm={4.25}></Grid>
                </Grid>

                <Grid xs={12} sx={{border:'1px solid lightgrey',borderRadius:'10px',mt:'10px',display:'flex',justifyContent:'center',alignItems:'center',minHeight:'80%'}}>
                  <AnalyticsChart chartData={chartData}/>
                </Grid>
              </Grid>
            }
          </Grid>
        </div>       
      </Box>
    )
  }
}

export default Dashboard;