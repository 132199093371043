import React, { Component } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const ExperiencesStepper = (props) =>{

  const CurrentStepper = props.CurrentStepper;
  const steps = Array(CurrentStepper.totalSteps).fill('')
  
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }))

  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 20,
      borderRadius:'50%'
    },
    '& .QontoStepIcon-active-circle':{
      width: 9,
      height: 9,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
      padding:1,
    },
    '& .QontoStepIcon-circle': {
      width: 10,
      height: 10,
      borderRadius: '50%',
      border:'3px solid #784af4',
      backgroundColor: 'white',
    },
  }))

  const QontoStepIcon = (event) => {
    const { active, completed, className } = event;
     
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          active?
          <div className="QontoStepIcon-circle" />
          : <div className="QontoStepIcon-active-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
 
  return (
    <Box sx={{px:5}}>
      <Stack sx={{ width: '100%' }} spacing={4}>
        <Stepper alternativeLabel activeStep={CurrentStepper.currentStep} connector={<QontoConnector />}>
          {steps.map((label) => {
            return(
            <Step key={label} >
              <StepLabel StepIconComponent={QontoStepIcon} >{label}</StepLabel>
            </Step>)
            }
          )}
        </Stepper>
      </Stack>
    </Box>
  );
}


export default ExperiencesStepper;