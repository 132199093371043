import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { toast } from "react-toastify";
import Carousel from 'react-multi-carousel';
import { Navigate, Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


import "../css/Home.css";
import Loader from './../Components/Common/Loader';
import VideoCard from '../Components/cards/VideoCard'
import config from '../Components/services/config.json';
import { getUserDetails } from '../Components/services/authService';
import RenderButton from './../Components/InputFields/RenderButton';
import { getCollection } from './../Components/services/dataRenderService';



class Home extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen:this.props.drawerOpen,
      CurrentRunningVideo:-1,
      pageData:{},
      user:'',
      redirectAR:false,
      // last_login:'',
      responsive4Cards : {
        0: { items: 1},
        568: { items: 1.6 },
        1016: { items: 2.8 },
        1050: { items: 3.45 },
        2000: { items: 4.6},
      },
      responsive4CardsDrawerOpen : {
        0: { items: 1},
        568: { items: 1.4 },
        1016: { items: 2.6 },
        1050: { items: 3.1 },
        2000: { items: 4.2},
      },
      responsive2Cards : {
        0: { items: 1.04 },
        568: { items: 1.8 },
        1016: { items: 1.2 },
        1050: { items: 1.7 },
        2000: { items: 3.04},
      },
      collections:[],
      loaderFlag:false,
      token_expired:false,
      paymentRedirect:false,
    }
  }
  
  async componentDidMount() {
    try {
      const {data} = await getUserDetails();
      if(data.status === true) {
        const {data:user} = data;
        this.setState({ pageData:user,loaderFlag:false});
        
        const { data:res } = await getCollection();
        if(res.status === true) {
          const {data:collections} = res;
          this.setState({ collections, loaderFlag:true });
        } else if(data.status === 'Token is Expired') {
          this.setState({text:'User token expired, Redirecting to login page.'});
          setTimeout(()=>{
            this.setState({token_expired:true,loaderFlag:false});
          },400)
        }
      } else if(data.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400)
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
    }
  }

  handleDragStart =  (e) => {
    e.preventDefault()
  };

  makeVideoCardsList = (CardsData) => {
    return CardsData.map((data)=>{
      data = {...data,thumbnail:config.assetsEndpoint+data.thumbnail}
      return (<VideoCard CardData={data} />);
    })
  };

  // playAndStopVideo = (id)=>{
  //   const video = document.getElementById(id);
  //   const button = document.getElementById(`Button`+id);
  //   const {CurrentRunningVideo} = this.state;
  //   if(CurrentRunningVideo !== -1  && id !== CurrentRunningVideo){
  //     const Video1  = document.getElementById(CurrentRunningVideo)
  //     const Button1 = document.getElementById(`Button`+CurrentRunningVideo)
  //     Button1.style.visibility = 'visible';
  //     Video1.pause();
  //   }

  //   if (video.paused ) {
  //     video.play();
  //     button.style.visibility = 'hidden';
  //   } else {
  //     video.pause();
  //     button.style.visibility = 'visible';
  //   }
  //   this.setState({CurrentRunningVideo:id});
  // };

  getCurrentDate = () =>{

    var currentTime = new Date();
    var currentOffset = currentTime.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30 
    var today = new Date(currentTime.getTime() + (ISTOffset + currentOffset)*60000);
    
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"]; 
    let day = weekday[today.getDay()];
    var dd = today.getDate();
    var mm = month[today.getMonth()];
    return day+", "+dd+" "+mm;
  }

  navigateCreateAR = () => {
    this.setState({redirectAR:true});
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  renderNextButton = ({ isDisabled }) => {
    return <ArrowForwardIosIcon style={{display:'absolute',position:'absolute' ,right:0,width:'100%'}} />
  };

  renderPrevButton = ({ isDisabled }) => {
    return <ArrowBackIosNewIcon style={{ position: "absolute", left: `calc(4% + 1px)`, top: 0,height:'100%' }} />
  };

  setPaymentRedirect = () => {
    this.setState({paymentRedirect:true});
  };
  
  render() {
    const {collections,pageData,loaderFlag,token_expired,redirectAR,paymentRedirect} = this.state;
    
    if(token_expired) return (<Navigate to='/logout' />);
    if(paymentRedirect) return (<Navigate to='/payments' />);
    
    if(!loaderFlag) return (<Loader text='Loading Home Data ...' />);
    
    const responsiveCarousal = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 2200 },
        items: 4.2
      },
      LargeDesktop:{
        breakpoint: { max: 2200, min: 1800 },
        items: 3.98
      },
      desktop: {
        breakpoint: { max: 1800, min: 1600 },
        items: 4.0
      },
      laptop: {
        breakpoint: { max: 1600, min: 990 },
        items: 3.2
      },
      tablet: {
        breakpoint: { max: 990, min: 764 },
        items: 2.5
      },
      bigMobile: {
        breakpoint: { max: 764, min: 484 },
        items: 1.6
      },
      mobile: {
        breakpoint: { max: 484, min: 0 },
        items: 1
      }
    };

  
    if(redirectAR) return (<Navigate to='/create' />);

    const VideoCardData = collections.filter((collection) => collection.category === 'Tutorials');
    const InspirationsData = collections.filter((collection) => collection.category === 'Inspiration');
    const BlogsData = collections.filter((collection) => collection.category === 'Blogs');
    
    let index = 0;
    let j = collections.length+100;
    
    while(VideoCardData.length < 6){
      let data = {...VideoCardData[index]}
      data.id = j;
      VideoCardData.push(data)
        index++;
      j++;
    }

    const VideoTutorialItems = this.makeVideoCardsList(VideoCardData);
    
    index = 0;
    while(InspirationsData.length < 6){
      let data = {...InspirationsData[index]}
      data.id = j;
      InspirationsData.push(data)
        index++;
      j++;
    }

    const Inspirations = this.makeVideoCardsList(InspirationsData);
    

    index = 0;
    while(BlogsData.length < 6){
      let data = {...BlogsData[index]}
      data.id = j;
      BlogsData.push(data)
        index++;
      j++;
    }
    const Blogs = this.makeVideoCardsList(BlogsData);
    

    return (
      <Box className="Page-Width-Vossle">
        <div id="overlay" style={{display: pageData.views === 0 ? 'block' : 'none'}}>
          <div id="overlay_text">
            <Typography variant='h6'>
              Dashboard deactivated. Please upgrade the account to use further.
            </Typography>
            {
              (pageData.views === 0 && pageData.payment_status === 'Success') ? (
                <Link to='re-subscribe'>
                  <RenderButton 
                    name = 'payment'
                    type = "button"
                    label = 'Subscribe'
                    isDisabled = {false}
                    minWidth = {160}
                  />
                </Link>
              ) : (
                <Link to='payment'>
                  <RenderButton 
                    name = 'payment'
                    type = "button"
                    label = 'Subscribe'
                    isDisabled = {false}
                    minWidth = {160}
                  />
                </Link>
              )
            }
            <Link to='payments'>
              <Button>Payments History</Button>
            </Link>
          </div>
        </div>
        <Box sx={{fontFamily:'Noto Sans',p:'10px 52px 0px 52px',mt:"40px",alignItems:'center',justifyContent:'center',textAlign:'center',mb:2}} >
          <p style={{display:'flex',justifyContent:'center',color:'#585858',fontSize:'13px'}}>
            {this.getCurrentDate()}
          </p>
          <p  style={{fontFamily:`Poppins, sans-serif`,fontWeight:'500',margin:"0px 0px 6px 0px",display:'flex',fontSize:'38px',justifyContent:'center',color:'#053630'}} >
            {/* Welcome, { pageData && this.capitalizeFirstLetter(pageData.name)} */}
            Welcome { pageData && this.capitalizeFirstLetter(pageData.name)} 
          </p>
          <p style={{justifyContent:'center',textAlign:'center',display:'flex',fontSize:'13px',color:'#343434',width:'48%',margin:'0px auto 0px auto'}} >
            Use Vossle to overlay 3D digital content onto physical world - as <br/> if they  are actually with you, in your own space
          </p>
        </Box>

        <Grid container spacing={2} sx={{margin:'0px auto 0px auto',display:'flex',justifyContent:'center',alignItems:'center',maxWidth:"300px"}}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <RenderButton 
              name = 'create_ar'
              type = "button"
              label = 'Create AR'
              isDisabled = {false}
              minWidth = {160}
              onClick={()=>{this.props.navigate('/create')}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <a style={{fontSize:'16px',color:'#1c7ed6',textDecoration:'underline'}}  href='#Inspiration'>Inspire Yourself</a>
          </Grid>
        </Grid>

        <Grid container sx={{mt:'40px',py:3,mx:'auto'}}>
          <Grid xs={12}>
            <Typography sx={{fontSize:'26px',mx:'auto'}}>
              Video Tutorials
            </Typography>
          </Grid>
        </Grid>
        
        <Grid sx={{mt:2,mx:'auto'}}>
          <Carousel
            responsive={responsiveCarousal}
            ssr={true}
            keyBoardControl={true}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {VideoTutorialItems.map(videocard=>(<div >{videocard}</div>))
            }
          </Carousel>
        </Grid>

        <Typography sx={{fontSize:'26px',marginLeft:'10px',marginTop:"32px",mx:'auto'}} id="Inspiration">
          Inspirations
        </Typography>
      
        <Box sx={{mt:2}} spacing={1}>
          <Carousel
            responsive={responsiveCarousal}
            ssr={true}
            keyBoardControl={true}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {Inspirations.map(inspire=>{
              return(<div>{inspire}</div>)
            })}
          </Carousel>
        </Box>

        <Typography sx={{fontSize:'26px',marginLeft:'10px',marginTop:"32px",mx:'auto'}}>
            Blogs
        </Typography>
    
        <Box sx={{mt:2,mx:'auto'}}>
          <Carousel
            responsive={responsiveCarousal}
            ssr={true} 
            keyBoardControl={true}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {Blogs.map(blog=>{
              return(<div>{blog}</div>)
            })}
    </Carousel>
        </Box>
      </Box>
    )
  }
}

export default Home;