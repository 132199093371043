import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import { Typography,Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { toast } from "react-toastify";
import config from '../Components/services/config.json';
import {getCategories} from '../../src/Components/services/collectionService';
import withRouter from '../Components/services/withRouter';
// import { useHistory } from 'react-router-dom';
import { paginate } from './../Components/utils/paginate';
import { Navigate } from 'react-router-dom';
import Loader from './../Components/Common/Loader';
import Paginations from './../Components/Common/paginations';
import mouseImage from './../assets/mouse.png';
import { isBoolean } from 'lodash';

export default class Category extends Component {

  constructor(props){
    super(props)
    this.state = {
      itemData : [],
      currentData:[],
      pageSize: 6,
      filterByName:"",
      currentPage: 1,
      selectedFilter: {id:1,name:'All',value:'Active',action:'status'},
      token_expired:false,
    }
  }

  handleChange = (event) => {
    this.setState({currentPage: 1,filterByName:event.target.value})
  }

  ModelViewer = (assetUrl) =>{
    return(
      <model-viewer 
            // sx={{width:'20px',height:'20px'}}
            sx={{maxWidth:"100%",maxHeight:'100%'}}
            src={config.assetsEndpoint+assetUrl} alt="A 3D model" auto-rotate="" camera-controls="" 
            id='first glb'
            // background-color="#455A64"
    ></model-viewer>
    )
  }

  showThreeGlb = (data) =>{
    return(
      <React.Fragment>
        <Grid xs={6}  key = {data.category_id}>
          { data.library_collections.length >= 0?
            (
              <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'10px 0px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                {this.ModelViewer(data.library_collections[0].assets)}
              </Grid>
            ):(
              <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'10px 0px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                {this.ModelViewer(data.library_collections[0].assets)}
              </Grid>
            )
          }
          {
            data.library_collections.length >= 1?
            (
              <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 0px 0px 10px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
              {this.ModelViewer(data.library_collections[1].assets)}
              </Grid>
            ):(
              <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 0px 0px 10px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
              {this.ModelViewer(data.library_collections[0].assets)}
              </Grid>
            )
            }
        </Grid>
        {
          <Grid xs={6}  key = {data.category_id}>
          { data.library_collections.length >= 3?
            (
              <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 10px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                {this.ModelViewer(data.library_collections[2].assets)}
              </Grid>
            ):(
              <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 10px 0px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
                {this.ModelViewer(data.library_collections[0].assets)}
              </Grid>
            )
          }
          {
            data.library_collections.length >= 4?
            (
              <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 0px 10px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
              {this.ModelViewer(data.library_collections[3].assets)}
              </Grid>
            ):(
              <Grid xs={12} sx={{background:'#F1F3F5',borderRadius:'0px 0px 10px 0px',display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid lightgrey'}}>
              {this.ModelViewer(data.library_collections[0].assets)}
              </Grid>
            )
            }
        </Grid>
        }
      </React.Fragment>
    )
  }

  selectedCategory = (category) =>{
    console.log(category,this.props)
    console.log("my selected category to send is",category)
    this.props.navigate('/collections/'+category.id,{
    state:{selectedCategory:category}
    })
  }

  async componentDidMount(){
    try 
    {
      const {data:allCategory} = await getCategories();
      if(allCategory.status && isBoolean(allCategory.status)) {
        this.setState({itemData:allCategory.data,currentData:allCategory.data})
      }
      else if(allCategory.status === 'Token is Expired') {
        this.setState({text:'User token expired, Redirecting to login page.'});
        setTimeout(()=>{
          this.setState({token_expired:true,loaderFlag:false});
        },400)
      }
    }
    catch(ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Server Busy");
      } else if (ex.response && ex.response.status === 404) {
        toast.error("Server Down, Under maintenance.");
      } else {
        toast.error("Unexpected Error");
      }
      console.log('please check the error',ex) 
    }
  };

  handlePageChange = (value) => {
    console.log('Page Change',value);
    this.setState({ currentPage: value });
  };

  stringCapitalLetter = (name)=>{
    if(name.length==0)
      return ''
    return name[0].toUpperCase() + name.substring(1,name.length);
  }

  render() {
    const { length: count } = this.state.currentData;
    const {
      pageSize,
      currentPage,
      selectedFilter,
      itemData,
      token_expired,
      currentData:allData,
      filterByName
    } = this.state;
    
    

    if (count === 0) return (<Loader text='Loading Experiences Data ...' />);
    if(token_expired) return (<Navigate to='/logout' />);
    console.log("my allData",allData)
    const filtered = selectedFilter && selectedFilter.id ? allData.filter((fil)=>{ return selectedFilter.value === fil.status && fil.name.toLowerCase().includes(filterByName.toLowerCase())  }) : allData;
    const paginated = paginate(filtered, currentPage, pageSize);
    
    
    const {selectedCategory,handleChange} = this;
    
    return (
        <Box className="Page-Width-Vossle">
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',textAlign:'center',marginBottom:'16px'}}>
            <p  style={{fontSize:'35px',justifyContent:'center',color:'#053630',fontFamily:`Poppins, sans-serif`}}>
              3D Collections
            </p>
            <p style={{fontSize:'14px',marginBottom:'16px',color:'#343434',fontFamily:'Noto Sans'}}>
              Step into endless possibilities
            </p>
            <Paper
              component="form"
              sx={{ boxShadow:'none', p: '2px 4px', display: 'flex', alignItems: 'center', width: 500,mb:'20px',background:'#F1F3F5' ,border:'1px solid lightgrey',borderRadius:'10px'}}
            >
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1}}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange = {handleChange}
              />
             
            </Paper>

          </div>   
          
          <Grid container >
            {/* { */}
            <Grid xs={11.5} sx={{mx:'auto'}}>
              
                Categories
              {/* <p style={{fontFamily:'Noto Sans',fontSize:'13px',color:'grey'}}>
                {count * 3} GLB's of {count} Category
              </p> */}
            </Grid>
          </Grid>
          {/* <div style={{padding:'24px 24px 0px 24px'}}> 
            <Typography sx={{fontSize:'24px',fontWeight:'500'}}>
              
            </Typography>
            <Typography sx={{fontSize:'14px',color:'lightgrey'}}>
            
            </Typography>
          </div> */}
          <Grid  id="model" container>
            {
              paginated.length>0
              ?
              (paginated.map((data)=>{
                return(
                  <Grid xs={12} sm={6} md={6} lg={4} xl={3} container sx={{mb:4, padding:'26px',maxHeight:'350px',borderRadius:'10px'}} onDoubleClick={()=>selectedCategory(data)} >
                   
                    {this.showThreeGlb(data)}

                    <Grid xs={12}>
                      <div style={{padding:'4px',marginLeft:'10px'}}>
                        <div style={{fontSize:'18px',fontWeight:'600'}}>
                         {data.name}
                        </div>
                       
                      </div>

                    </Grid>
                  </Grid>
                )
                })
              ):(
                <Grid container>
                  <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'24px',fontWeight:'medium'}}>No Glb Found</Grid>
                  <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'24px',fontWeight:'medium'}}>
                    <img src={mouseImage} alt="no glb found"/>

                  </Grid>
                
                </Grid>
              )
            }
            <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Paginations
                itemsCount={filtered.length}
                pageSize={pageSize}
                onPageChange={this.handlePageChange}
                currentPage={currentPage}
              />
            </Grid>
          </Grid>
        </Box>  
    )
  }
}
