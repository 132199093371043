import http from "./httpServices";
import config from "./config.json";
import axios from "axios";
import { getJWT } from "./authService";

const apiEndpoint = config.apiEndpoint + "ar";
const apiEndPointUpdate = config.apiEndpoint + 'update';


export function getExperiences() {
  return http.get(apiEndpoint);
}

export function getNode(slug){
  return http.get(config.apiEndpoint +"get_experience/"+ slug);
}

export function getExperienceType() {
  return http.get(config.apiEndpoint + "experience-type");
}

export function getExperienceSubCategory(id) {
  return http.get(config.apiEndpoint + "experience-type/"+id);
}

export function getExperinceAllStatus(userFormData){
  return  http.post(config.apiEndpoint + 'ar-status', userFormData);
} 

export function getExperienceWorkingProductivity(userFormData){
  return http.post(config.apiEndpoint +'working-productivity',userFormData )
}

export function getExperienceWithFilter(userFormData){
  return http.post(config.apiEndpoint + 'get-experience',userFormData)
}

export function deleteArExp(userFormData){
  return http.post(config.apiEndpoint + 'delete-ar',userFormData)
}

export function getExperience(postId) {
  return http.get(apiEndpoint + "/" + postId);
}

export function getExperiencesAll(id) {
  return http.get(apiEndpoint + "/"+id);
}

export function createExperience(FormData,config) {
  return http.post(apiEndpoint, FormData, config);
}

export function editExperience(FormData,config) {
  return http.post(apiEndPointUpdate, FormData, config);
}

export function deleteExperience(expId) {
  return http.delete(apiEndpoint + "/" + expId);
}

export function deleteARExperience(formdata) {
  return http.post(config.apiEndpoint + 'delete-ar-exp',formdata);
}

export function saveARExp(FormData) {
  return http.post(apiEndpoint+'store_ar_exp', FormData);
}

export function createNodes(FormData,config) {
  return http.post(apiEndpoint+'store_nodes', FormData, config);
}

export function checkExpName(FormData) {
  return http.post(config.apiEndpoint+'checkExpName', FormData);
}

export function getLibraryBasedExpCategory(FormData) {
  return http.post(config.apiEndpoint+'library-id-based-exp-category', FormData);
}

export function getLibraryBasedSubExpCategory(FormData) {
  return http.post(config.apiEndpoint+'library-id-based-sub-category', FormData);
}

