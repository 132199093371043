import React, { Component } from 'react';
import Image from '../assets/mouse.png';
import {  Grid } from '@mui/material';

class NotFound extends Component {
    
    constructor(props) {
        super(props)
    }
    
    render() {
        return (
            <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={Image} alt=""/></Grid>
                <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',mt:3,color:'red'}}>{this.props.text}</Grid>
            </Grid>
        );
    }
}
 
export default NotFound;