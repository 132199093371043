import React from 'react';
import { useParams ,useLocation } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
  const params = useParams();
  const location = useLocation();
  const outletContext = useOutletContext();
  return (
    <WrappedComponent
      {...props}
      params={params}
      location = {location}
      outletContext = {outletContext}
    />
  );
};
 
export default withRouter;