import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Typography, capitalize } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import dayjs from 'dayjs';
import { toast } from "react-toastify";
import { isBoolean, isNull } from 'lodash';
import { Navigate } from 'react-router-dom';

import "../css/Analytics.css";
import NotFound from '../Components/NotFound';
import Loader from './../Components/Common/Loader';
import TextButton from './../Components/InputFields/TextButton';
import AnalyticsChart from '../Components/chart/AnalyticsChart';
import DateRangeCalendars from '../Components/DateRangeCalendars';
import ExperienceListWithSearch from './../Components/ExperienceListWithSearch';
import AdvertisementSepration from './../Components/chart/AdvertisementSepration';
import { experienceForAnalytics, experienceAnalytics } from './../Components/services/analyticsService';
import {analyticsByDate, analyticsByExperienceType, analyticsByGeolocation} from '../Components/services/analyticsService'

class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visitSeprationDrawerOpen:false,
            visitSeprationDrawerOption:null,
            visitSeprationDrawerDuration:'Monthly',
            VisitSeprationFilter: [
                {id:1,name:'Gender',value:'gender',action:'gender'},
                {id:2,name:'Age',value:'age',action:'age'},
                {id:3,name:'Devices',value:'devices',action:'devices'}
            ],
            selectedVisitSeprationFilter:{id:1,name:'Gender',value:'gender',action:'gender'},
            redirectToHome:false,
            ExperienceOption:null,
            ExperienceOpen:false,
            dialogOpen:false,
            SortByDateOption:null,
            SortByDateOpen:false,
            start_date:'',
            end_date:'',
            SortByGeographyOption:null,
            SortByGeographyOpen:false,
            panelData:[],
            chartData:[],
            tableData:[],
            data:[],
            user:this.props.user,
            loaderFlag:true,
            token_expired:false,
            currentFilter:'All',
            text:'Getting analytics ready, Please wait ...',
            error:{},
            dates:{},
            selectedFilter: {id:1,name:'All',value:'all',action:'all'},
            filters:[
                {id:1,name:'All',value:'all',action:'all'},
                {id:2,name:'Geography',value:'geography',action:'geography'},
                {id:5,name:'markerless',value:'markerless',action:'exp_type'},
                {id:6,name:'markerbased',value:'markerbased',action:'exp_type'},
                {id:7,name:'tryon',value:'tryon',action:'exp_type'},
                {id:8,name:'games',value:'games',action:'exp_type'},
                {id:9,name:'Yearly',value:'Yearly',action:'date'},
                {id:10,name:'Monthly',value:'Monthly',action:'date'},
                {id:11,name:'Weekly',value:'Weekly',action:'date'},
                {id:12,name:'Custom Range',value:'Custom Range',action:'date'},
                {id:13,name:'workflow',value:'Workflow',action:'exp_type'},
                {id:14,name:'ads',value:'advertisement',action:'exp_type'},
                {id:15,name:'Experience',value:'experience',action:'experience'}
            ],
            dateSelected:{},
            experiences:[],
            selectedExperience:{},
            selectedExperienceType:{}
        }
    }

    async componentDidMount() {
        const error = {};
        try {
            if(typeof this.props.user === 'undefined'){
                this.setState({redirectToHome:true});
            } else {
                const data = new FormData();
                data.append("user_id",this.props.user);
                
                const {data:res} = await experienceForAnalytics(data);
                if(res.status && isBoolean(res.status)) {
                    const {data:experiences} = res;
                    this.setState({ experiences,loaderFlag:false});
                } else if(res.status === 'Token is Expired') {
                    this.setState({text:'User token expired, Redirecting to login page.'});
                    setTimeout(()=>{
                        this.setState({token_expired:true,loaderFlag:false});
                    },400)
                } else this.setState({ text:'Experience not found.' });
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error(ex.message);
                error.global = ex.response.data.message ?? ex.message;
            } else if (ex.response && ex.response.status === 404) {
                toast.error(ex.message);
                error.global = ex.response.data.message ?? ex.message;
            } else {
                toast.error("Unexpected Error");
            }
        }
        this.setState({error});
    }

    setVisitSeprationFilter = (newFilter) => {
        this.setState({selectedVisitSeprationFilter:newFilter})
    };

    getDate = () => {
        let dateObj = new Date();
        const month = dateObj.getUTCMonth() + 1; //months from 1-12
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        return year + "-" + month + "-" + day;
    };    

    setVisitSeprationOpen = (event) => {
        this.setState({visitSeprationDrawerOption:event.currentTarget,visitSeprationDrawerOpen:true})
    };

    setVisitSeprationClose = (newValue) =>{
        this.setState({visitSeprationDrawerDuration:newValue,visitSeprationDrawerOption:null,visitSeprationDrawerOpen:false})
    };

    selectedDates = async (bothDates) =>{
        this.setState({SortByDateOpen:false,currentFilter:'Custom Range',start_date:bothDates.firstDate,end_date:bothDates.secondDate,selectedFilter:{id:12,name:'Custom Range',value:'Custom Range',action:'date'}});
        setTimeout(()=>{this.applyAnalyticsFilter()},200)    
    };
    
    onSelectDate = (dates) => {
        this.setState({loaderFlag:true,dateSelected:dates,currentFilter:'Custom Range',start_date:dates.firstDate,end_date:dates.secondDate,selectedFilter:{id:12,name:'Custom Range',value:'Custom Range',action:'date'}});
        setTimeout(()=>{this.applyAnalyticsFilter()},200);
    };

    showCalendar = () => {
        this.setState({SortByDateOpen:false,dateSelected:{}});
    };

    onSelectExperience = async (experience) => {
        if(isNull(experience) || experience === '') return true;
        let {start_date,end_date,user,error} = this.state;
        const selectedFilter = {id:15,name:'On Experience',value:'experience',action:'experience'};
        
        if(start_date === '') start_date = dayjs(new Date()).subtract(30, 'days').format('YYYY-MM-DD');
        if(end_date === '') end_date = dayjs(new Date()).format('YYYY-MM-DD');
        
        let formData = new FormData();
        formData.append("user_id",user);
        formData.append('start_date',start_date);
        formData.append('end_date',end_date);
        formData.append('exp_id',experience.id);
        
        try {
            const {data:res} = await experienceAnalytics(formData);
            if(res.status && isBoolean(res.status)) {
                let {analytics_data:chartData} = res.data;
                let {exp_type:selectedExperienceType} = res.data;
                
                if(chartData.length === 0) chartData = [];

                if(chartData.length > 0 && selectedExperienceType.exp_type === 'ads') {
                    let {ads_cam_permission_count:camera_permission} = res.data;
                    const all_camera_permission = camera_permission.camera_yes_count + camera_permission.camera_no_count;
                    const all_view_count = chartData.length === 0 ? 0 : chartData.map(data => data.view_count).reduce(this.sumAll);
                    
                    const difference = all_camera_permission - all_view_count;
                    if(difference > 0) {
                        let tableData = [...chartData,
                            {
                                "view_count": difference,
                                "avg_time": "0",
                                "exp_type": "ads",
                                "analytics_date": "Not-Set"
                            }
                        ];
                        this.setState({ chartData, tableData, data:res.data, selectedExperience:experience, selectedExperienceType});
                    } else {
                        this.setState({ chartData, tableData:chartData, data:res.data, selectedExperience:experience, selectedExperienceType });
                    }
                }
                else {
                    this.setState({ chartData, tableData:chartData, data:res.data, selectedExperience:experience, selectedExperienceType });
                }
            }
            else console.log("Facing Error in loading the data of analytics usign Experience Name.");
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error(ex.message);
                error.global = ex.response.data.message ?? ex.message;
            } else if (ex.response && ex.response.status === 404) {
                toast.error(ex.message);
                error.global = ex.response.data.message ?? ex.message;
            } else {
                toast.error("Unexpected Error");
            }
        }
        this.setState({error});
    };

    applyAnalyticsFilter = async () => {
        let {selectedFilter,end_date,start_date} = this.state;
        
        if(start_date === '') start_date = dayjs(new Date()).subtract(30, 'days').format('YYYY-MM-DD');
        if(end_date === '') end_date = dayjs(new Date()).format('YYYY-MM-DD');
        
        let analyticsInfo = new FormData();
        analyticsInfo.append("user_id",this.state.user);
        analyticsInfo.append('end_date',end_date);

        if(selectedFilter.action === 'exp_type') {
            analyticsInfo.append('start_date',start_date);
            analyticsInfo.append('filter_by',selectedFilter.name);
            
            const {data:res} = await analyticsByExperienceType(analyticsInfo);
            if(res.status && isBoolean(res.status)) {
                let {analytics_data:chartData} = res.data;
                if(chartData.length === 0) chartData = [];
                
                let {experiences} = res.data;
                let {exp_type:selectedExperienceType} = res.data;
                if(experiences.length === 0) experiences = [];
                
                if(selectedFilter.value === 'advertisement' && chartData.length > 0) {
                    let {ads_cam_permission_count:camera_permission} = res.data;
                    const all_camera_permission = camera_permission.camera_yes_count + camera_permission.camera_no_count;
                    const all_view_count = chartData.length === 0 ? 0 : chartData.map(data => data.view_count).reduce(this.sumAll);
                    
                    const difference = all_camera_permission - all_view_count;
                    if(difference > 0) {
                        let tableData = [...chartData,
                            {
                                "view_count": difference,
                                "avg_time": "0",
                                "exp_type": "ads",
                                "analytics_date": "Not-Set"
                            }
                        ];
                        this.setState({ chartData, tableData, experiences, data:res.data, selectedExperienceType});
                    } else {
                        this.setState({ chartData, tableData:chartData, experiences, data:res.data, selectedExperienceType});
                    }
                } else {
                    this.setState({ chartData, tableData:chartData, experiences, data:res.data, selectedExperienceType});
                }
            }
            else console.log("Facing error in loading the data of analytics by experience name.");
        } else if(selectedFilter.action === 'date' || selectedFilter.action === 'all') {
            if(selectedFilter.name === 'Yearly') {
                let firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
                firstDayOfYear = firstDayOfYear.toISOString().split('T')[0];
                start_date = firstDayOfYear;
                
                analyticsInfo.append('start_date',start_date);
            } else if(selectedFilter.name === 'Monthly') {
                let firstDayOfYear = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                firstDayOfYear = firstDayOfYear.toISOString().split('T')[0];
                start_date = firstDayOfYear;
                analyticsInfo.append('start_date',firstDayOfYear);
            } else if(selectedFilter.name === 'Weekly') {
                var oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
                oneWeekAgo = oneWeekAgo.toISOString().split('T')[0]
                
                start_date = oneWeekAgo;
                analyticsInfo.append('start_date',oneWeekAgo);
            } else analyticsInfo.append('start_date',start_date);

            const {data:res} = await analyticsByDate(analyticsInfo);
            if(res.status && isBoolean(res.status)) {
                let {analytics_data:chartData} = res.data;
                
                let {experiences} = res.data;
                if(experiences.length === 0) experiences = [];
                if(chartData.length === 0) chartData = [];

                this.setState({ chartData, tableData:chartData, start_date, end_date, data:res.data, experiences, selectedExperienceType:{}});
            } else console.log("Facing error in loading the data of analytics by experience name.");
        } else if(selectedFilter.action === 'geography') {
            analyticsInfo.append('start_date',start_date);
            
            const {data:res} = await analyticsByGeolocation(analyticsInfo);
            
            if(res.status && isBoolean(res.status)) {
                let {analytics_data:chartData} = res.data;
                
                let {experiences} = res.data;
                if(experiences.length === 0) experiences = [];
                if(chartData.length === 0) chartData = [];
                
                this.setState({ chartData, tableData:chartData, data:res.data, experiences, selectedExperienceType:{}});
            } else console.log("Facing error in loading the data of analytics by experience name.");
        }
        this.setState({loaderFlag:false, selectedExperience:{}});
    };

    handleExperienceOpen = (event) => {
        this.setState({ExperienceOpen:true,ExperienceOption:event.currentTarget})
    };
    
    handleExperienceClose = (selectedFilter) => {
        this.setState({ExperienceOpen:false,ExperienceOption:null,selectedFilter,currentFilter:selectedFilter.value})
        
        setTimeout(()=>{this.applyAnalyticsFilter()},200)
    };

    applyAnalyticsExperienceFilter = (operation) =>{
        this.setState({analyticsExpFilterType:operation})
    };

    handleSortByDateOpen = (event) => {
        this.setState({SortByDateOpen:true})
        this.setState({SortByDateOption:event.currentTarget})
    };

    handleSortByDateClose = (selectedFilter) => {
        this.setState({SortByDateOpen:false,SortByDateOption:null,selectedFilter,currentFilter:selectedFilter.value})
        setTimeout(()=>{this.applyAnalyticsFilter()},200)
    };
    
    handleSortByGeographyOpen = (event) => {
        this.setState({SortByGeographyOpen:true})
        this.setState({SortByGeographyOption:event.currentTarget})
    };
    
    handleSortByGeographyClose = (selectedFilter) => {
        this.setState({selectedFilter,currentFilter:selectedFilter.value,SortByDateOpen:false,ExperienceOpen:false,ExperienceOption:null});
        setTimeout(()=>{this.applyAnalyticsFilter()},200);
    };

    stringCapitalLetter = (name)=>{
        let c = name.split(' ');
        
        let d = c.map((val) => {
            return val[0].toUpperCase() + val.substring(1,val.length);
        });
        return d.join(' ');
    }

    sumAll = (accumulator,currentValue) =>{
        let x = 0;
        let y = 0; 
        if(accumulator != null ) x = parseFloat(accumulator)

        if(currentValue != null) y = parseFloat(currentValue);

        return x+y;
    }

    getPanel = () => {
        const {tableData:datas,chartData} = this.state;
        
        let view_count =   datas.length === 0 ? 0 : datas.map(data => data.view_count).reduce(this.sumAll);
        let avg_session_duration = datas.length === 0 ? 0 : datas.map(data => data.avg_time).reduce(this.sumAll);
        let avg_view_count =   chartData.length === 0 ? 0 : chartData.map(data => data.view_count).reduce(this.sumAll);
        let share_count = datas.length === 0 ? 0 : datas.map(data => data.share_count).reduce(this.sumAll);
        
        if(view_count !== 0 && avg_session_duration !== 0){
            avg_session_duration = Math.ceil(avg_session_duration/avg_view_count);
        }
        else{
            avg_session_duration = 0;
        }

        let panel = [
            {
                name:'View Count',
                count:view_count ?? 0,
                color:'#FF7709',
            },
            {
                name:'Avg. Session Duration',
                count:avg_session_duration ?? 0,
                color:'#682CEA',
            },
            {
                name:'Share Count',
                count: !isNaN(share_count) ? share_count : 0,
                color:'#37BE70',
            }
        ];
        
        return(
            panel.map((counts)=>{
                return(
                <Grid xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center',fontFamily:'Noto Sans'}}>
                    <Box sx={{display:'flex',alignItems:'center'}}>
                        <Box sx={{width:'76px',height:'76px',background:counts.color,borderRadius:'50px',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}></Box>
                        <Box sx={{ml:2,height:'76px',display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'column'}}>
                            <Typography sx={{fontSize:'16px'}}>{counts.name}</Typography>
                            
                            <Typography sx={{fontSize:'32px',fontWeight:'medium',color:'#682CEA'}}>{counts.count}</Typography>
                        </Box>
                    </Box>

                </Grid>)
            })
        )
    };

    render() {
        const {dateSelected,visitSeprationDrawerDuration,visitSeprationDrawerOption,visitSeprationDrawerOpen,selectedFilter,redirectToHome,chartData,tableData,ExperienceOption,currentFilter,ExperienceOpen,SortByDateOpen,SortByDateOption,loaderFlag,token_expired,text,start_date,end_date,experiences,selectedExperience,selectedExperienceType} = this.state;
        const {ads_cam_permission_count:AdvertisementData} = this.state.data;
        
        if(token_expired) return (<Navigate to='/logout' />);
        if(redirectToHome) return (<Navigate to="/" />);
        if(loaderFlag) return (<Loader text={text} />);

        return (
            <Box className="Page-Width-Vossle" >
                <Box sx={{textAlign:'center',color:'black'}}>
                    <p style={{textAlign:'center',fontSize:'30px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',marginTop:'16px',color:'#053630'}}>
                        Analytics
                    </p>
                    <p style={{textAlign:'center',marginBottom:'20px',fontFamily:'Noto Sans',fontSize:'13px',color:'#343434'}}>
                        Step into endless possibilities
                    </p>
                </Box>
                { !dateSelected.hasOwnProperty('firstDate') ?
                    (
                        <React.Fragment>
                            <Grid container sx={{display:'flex',justifyContent:'center'}}>
                                <DateRangeCalendars onSelect={this.onSelectDate} />
                            </Grid>
                        </React.Fragment>
                    ) : 
                    (
                        <>
                            <React.Fragment>
                                <Grid container sx={{display:'flex',justifyContent:'center'}} spacing={1}>
                                    {/* Sort By Date */}
                                    <Grid item xs={2} >
                                        <Button
                                            className={selectedFilter.action === 'date'?"selectfilterDesign":"filterDesign"}
                                            id="fade-button"
                                            aria-controls={SortByDateOpen ? 'fade-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={SortByDateOpen ? 'true' : undefined}
                                            onClick={this.handleSortByDateOpen}
                                            // sx={{height:'30px',border:'0.1px solid #682CEA',color:(selectedFilter.action === 'date'?'white':'#682CEA') ,background:(selectedFilter.action === 'date'?'#682CEA':'#F1F3F5'),borderRadius:'6px'}}
                                            sx={{width:'100%',borderRadius:"50px",color:(selectedFilter.action === 'date'?'white':'#454747')}}
                                            endIcon={this.SortByDateOpen ? <ArrowDropDownIcon />:<ArrowRightIcon />}
                                        >
                                            Date
                                        </Button>
                                        <Menu
                                            id="fade-menu"
                                            MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                            }}

                                            anchorEl={SortByDateOption}
                                            open={SortByDateOpen}
                                            onClose={()=>this.handleSortByDateClose(selectedFilter)}
                                            TransitionComponent={Fade}
                                            
                                        >
                                            <MenuItem onClick={()=>this.handleSortByDateClose({id:9,name:'Yearly',value:'Yearly',action:'date'})}>Current Year</MenuItem>
                                            <MenuItem onClick={()=>this.handleSortByDateClose({id:10,name:'Monthly',value:'Monthly',action:'date'})} >Currnet Month</MenuItem>
                                            <MenuItem onClick={()=>this.handleSortByDateClose({id:11,name:'Weekly',value:'Weekly',action:'date'})}>Current Week</MenuItem>
                                            {/* <MenuItem onClick={()=>handleDialogOpen()} >Custom Range</MenuItem> */}
                                            {/* <MenuItem ><DatePicker selectedDates={this.selectedDates}/></MenuItem> */}
                                            <MenuItem onClick={this.showCalendar}>Custom Range</MenuItem>
                                        </Menu>
                                    </Grid>
                                    
                                    {/* Sort By Experience Type */}
                                    <Grid item xs={3} >
                                        <Button
                                    
                                            id="fade-button"
                                            aria-controls={this.ExperienceOpen ? 'fade-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={this.ExperienceOpen ? 'true' : undefined}
                                            onClick={this.handleExperienceOpen}
                                            className={(selectedFilter.action === 'exp_type' || selectedFilter.action === 'all') ? "selectfilterDesign" : "filterDesign"}
                                            // sx={{height:'30px',border:'0.1px solid #682CEA',color:(selectedFilter.action === 'exp_type'?'white':'#682CEA') ,background:(selectedFilter.action === 'exp_type'?'#682CEA':'#F1F3F5'),borderRadius:'6px'}} 
                                            sx={{width:"100%",borderRadius:"50px",color:((selectedFilter.action === 'exp_type' || selectedFilter.action === 'all') ? 'white' : '#454747')}}
                                            endIcon={this.ExperienceOpen ? <ArrowDropDownIcon />:<ArrowRightIcon />}
                                        >
                                            {selectedFilter.action === 'exp_type' ? capitalize(selectedFilter.value) : 'All Experience Types'}
                                        </Button>
                                        <Menu
                                            id="fade-menu"
                                            MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                            }}
                                            anchorEl={ExperienceOption}
                                            open={ExperienceOpen}
                                            onClose={()=>this.handleExperienceClose(selectedFilter)}
                                            TransitionComponent={Fade}
                                        >
                                            <MenuItem onClick={()=>this.handleSortByGeographyClose({id:1,name:'All',value:'all',action:'all'})}>All Experience Types</MenuItem>
                                            <MenuItem onClick={()=>this.handleExperienceClose({id:5,name:'markerless',value:'markerless',action:'exp_type'})}>Markerless</MenuItem>
                                            <MenuItem onClick={()=>this.handleExperienceClose({id:6,name:'markerbased',value:'markerbased',action:'exp_type'})}>Markerbased</MenuItem>
                                            <MenuItem onClick={()=>this.handleExperienceClose({id:7,name:'tryon',value:'tryon',action:'exp_type'})}>Tryon</MenuItem>
                                            <MenuItem onClick={()=>this.handleExperienceClose({id:8,name:'games',value:'games',action:'exp_type'})}>Games</MenuItem>
                                            <MenuItem onClick={()=>this.handleExperienceClose({id:13,name:'workflow',value:'Workflow',action:'exp_type'})}>Workflow</MenuItem>
                                            <MenuItem onClick={()=>this.handleExperienceClose({id:14,name:'ads',value:'advertisement',action:'exp_type'})}>Advertisement</MenuItem>
                                        </Menu>
                                    </Grid>
                                    
                                    {/* sort by geography*/}
                                    <Grid item xs={2}>
                                        <Button
                                            id="fade-button"
                                            sx={{width:'100%',borderRadius:"50px",color:(selectedFilter.action === 'geography'?'white':'#454747')}}
                                            // aria-controls={SortByGeographyOpen ? 'fade-menu' : undefined}
                                            // aria-haspopup="true"
                                            // aria-expanded={SortByGeographyOpen ? 'true' : undefined}
                                            onClick={()=>this.handleSortByGeographyClose({id:2,name:'Geography',value:'geography',action:'geography'})}
                                            // sx={{height:'30px',border:'0.1px solid #682CEA',color:(selectedFilter.action==='geography'?'white':'#682CEA') ,background:(selectedFilter.action==='geography'?'#682CEA':'#F1F3F5'),borderRadius:'6px'}} 
                                            className={selectedFilter.action === 'geography'?"selectfilterDesign":"filterDesign"}
                                            // endIcon={SortByGeographyOpen ? <ArrowDropDownIcon />:<ArrowRightIcon />}
                                        >
                                            Geography
                                        </Button>
                                    </Grid>

                                    <Grid item xs={2} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                                        <TextButton
                                            name = 'clear_filter'
                                            type = "button"
                                            label = 'Clear Filter'
                                            isDisabled = {false}
                                            minWidth = {100}
                                            onClick={()=>this.handleSortByGeographyClose({id:1,name:'All',value:'all',action:'all'})}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{mt:'20px',mb:1}}>
                                    <Grid xs={10} sx={{ margin:'auto'}}>
                                        <Typography variant='h5' sx={{margin:'auto'}}>
                                            {/* {this.stringCapitalLetter(currentFilter)} */}
                                            Reports (from {start_date} to {end_date})
                                        </Typography>
                                    </Grid>

                                    <Grid xs={10} sx={{ margin:'auto', my:3}}>
                                        <ExperienceListWithSearch value={selectedExperience} onSelect={this.onSelectExperience} ExperienceLists={experiences} />
                                    </Grid>
                                </Grid>

                                {chartData.length === 0 && tableData.length === 0 
                                    ?
                                        <Grid xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                            <NotFound text="Analytics Not Found"/> 
                                        </Grid>
                                    : (
                                        <React.Fragment >
                                            <Grid container sx={{maxWidth:'1296px'}}>
                                                <Grid xs={10} style={{padding:'16px',borderRadius:'10px',margin:'auto',border:'1px solid lightgrey'}}>
                                                    {(selectedFilter.name === 'ads' || (selectedExperienceType.exp_type && selectedExperienceType.exp_type === 'ads')) && (<Typography variant='h5' sx={{textAlign: 'center', color: 'teal'}}>* Not-Set data is not displayed on chart.</Typography>)}
                                                    <AnalyticsChart chartData={chartData}/>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{p:2,my:2}}>
                                                {this.getPanel()}
                                            </Grid>
                                            <Grid container>
                                                <Grid xs={10} sx={{m:'auto',border:'1px solid lightgrey',textAlign:'center',margin:'auto',p:4,borderRadius:'10px'}}>
                                                    <TableContainer sx={{p:2}}>
                                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                            <TableHead>
                                                                <TableRow >
                                                                    {selectedFilter.value === 'geography' && (
                                                                        <TableCell align="center" sx={{fontSize:'16px',fontWeight:'bold'}}>Geography</TableCell>
                                                                    )}
                                                                    <TableCell align="center" sx={{fontSize:'16px',fontWeight:'bold'}}>Date</TableCell>
                                                                    <TableCell align="center" sx={{fontSize:'16px',fontWeight:'bold'}}>View Count</TableCell>
                                                                    <TableCell align="center" sx={{fontSize:'16px',fontWeight:'bold'}}>Session Duration</TableCell>
                                                                    <TableCell align="center" sx={{fontSize:'16px',fontWeight:'bold'}}>Share Count</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody sx={{}}>
                                                            {tableData.map((row,index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } ,height:'50px'}}
                                                                >
                                                                    {selectedFilter.value === 'geography' && (
                                                                        <TableCell align="center" sx={{fontSize:'16px'}}>{row.country && row.country}</TableCell>
                                                                    )}
                                                                    <TableCell align="center" sx={{fontSize:'16px'}}>{row.analytics_date != null?row.analytics_date:'-'}</TableCell>
                                                                    <TableCell align="center" sx={{fontSize:'16px'}}>{row.view_count != null ? row.view_count:0}</TableCell>
                                                                    <TableCell align="center" sx={{fontSize:'16px'}}>{row.analytics_date === 'Not-Set' ? '-' : (Number(row.avg_time) != null && Number(row.view_count)!=null && Number(row.view_count) !== 0 && Number(row.avg_time) !== 0 ? Math.ceil(Number(row.avg_time)/Number(row.view_count)) : 0)}</TableCell>
                                                                    <TableCell align="center" sx={{fontSize:'16px'}}>{ (row.ShareCount ?? 0) != null ?(row.ShareCount ?? 0):0 }</TableCell>
                                                                </TableRow>
                                                            ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        

                                            {(selectedFilter.name === 'ads' || (selectedExperienceType.exp_type && selectedExperienceType.exp_type === 'ads')) && (
                                                <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center',p:1}}>
                                                    <Grid xs={10} style={{fontSize:'20px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',paddingTop:'16px',color:'#053630',margin:'auto'}}>
                                                        Advertisement Separation
                                                    </Grid>
                                                    <Grid xs={10} sx={{height:"460px",border:'1px solid grey',borderRadius:'10px',margin:1}}>
                                                        <div style={{height:'320px'}}>
                                                            <AdvertisementSepration 
                                                                selectedFilter={selectedFilter}
                                                                data={AdvertisementData}
                                                                setFilter={this.setVisitSeprationFilter}
                                                                Duration={visitSeprationDrawerDuration}
                                                                handleDropDownOpen={this.setVisitSeprationOpen}
                                                                handleDropDownClose={this.setVisitSeprationClose}
                                                                DropDownOption={visitSeprationDrawerOption}
                                                                DropDownOpen={visitSeprationDrawerOpen}
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )
                                            }

                                            {/* <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center',p:1}}>
                                                <Grid xs={10} style={{fontSize:'20px',fontFamily:`Poppins, sans-serif`,fontWeight:'500',paddingTop:'16px',color:'#053630',margin:'auto'}}>
                                                    Visit Sepration 
                                                </Grid>
                                                <Grid xs={10} sx={{height:"460px",border:'1px solid grey',borderRadius:'10px',margin:1}}>
                                                    <div style={{height:'320px'}}>
                                                        <VisitSepration selectedFilter={selectedVisitSeprationFilter} setFilter={this.setVisitSeprationFilter}  handleDropDownClose = {this.setVisitSeprationClose} handleDropDownOpen={this.setVisitSeprationOpen} Duration={visitSeprationDrawerDuration} DropDownOption = {visitSeprationDrawerOption} DropDownOpen={visitSeprationDrawerOpen}/>
                                                    </div>
                                                </Grid>
                                                <Grid xs={5} sx={{height:"460px",border:'1px solid grey',borderRadius:'10px',margin:1}}>
                                                    <WebsiteVisit/>
                                                </Grid>}
                                            </Grid> */}
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                        </>
                    )
                }
            </Box>
        )
    }
}

export default Analytics;