import React from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    // removeElements,
    Controls,
    Background,
    updateEdge,
    getBezierPath,
    // getEdgeCenter,
    getMarkerEnd,
    ControlButton,
    
    applyEdgeChanges,
    applyNodeChanges
} from 'reactflow';

import Info from '@mui/icons-material/Info';
import { toast } from "react-toastify";
import { Navigate } from 'react-router-dom';
import { getCategories, getCollectionById} from '../services/collectionService'
import initialElements from './initial-elements';

import './dnd.css';
import './index.css';
import './save.css';
import {saveARExp, createNodes} from '../services/workFlowService';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LeftDrawer from '../LeftDrawer';
import StepperModule from '../StepperModule';
import { isBoolean } from 'lodash';
// For Switch
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Close';
import AllInputFields from '../InputFields/AllInputFields.jsx';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { Redirect } from 'react-router';
import UploadGlbDialog from '../Common/UploadGlbDialog';
import Form from "../Common/form";
import config from '../services/config.json';
import ButtonEdge from './ButtonEdge';

// default styling
import 'reactflow/dist/style.css';

// or if you just want basic styles
import 'reactflow/dist/base.css';

class CreateWorkFlow extends AllInputFields {
    
    constructor(props) {
        super(props);
        this.reactFlowWrapper = React.createRef();    
        this.state = {
            openAiSelectedDialogData:{
                name:'',
                type:'',
            },
            openAiDialogOpen:false,
            elements: initialElements,
            edges:[],
            submitForms:[],
            isChildLoading:false,
            formDialougOpen:false,
            setElements:initialElements,
            reactFlowInstance:null, 
            fullScreenDialog:false,
            allCategory:[],
            selectedCollection:[],
            phase:1,
            token_expired:false,
            openDialog:false,
            arExp:this.props.arExp,
            setReactFlowInstance:null,
            UploadGlbCurrentPage:1,
            data: {
                ar_id:1,
                type:'splash_screen',
                action:'',
                node_id:''
            },
            files:{},
            filesType:{},
            content:'',
            audio:'',
            // btn_bg_image:'',
            errors: {},
            forForm:{
                ss:false,
                c:true,
                pi:false,
                cf:false,
                new:false,
            },
            // arExp: this.props.location.state.arExp,
            arExp: this.props.arData,
            handleState:this.props.handleState,
            redirectToReferrer: false,
            downloadProgress:0,
            id:0,
            dataLeftDrawer : [
                // { icon: <Info />, label: 'Splash Screen', class: 'dndnode input', dragid: 'input ss' },
                { icon: <ViewInArIcon />, label: 'Content', class: 'dndnode', dragid: 'default c', bordercolor: '#d95a3d' },
                { icon: <AddPhotoAlternateOutlinedIcon />, label: 'Panel Image', class: 'dndnode', dragid: 'default pi', bordercolor: '#cc4a69' },
                { icon: <ContactPageOutlinedIcon />, label: 'Contact Form', class: 'dndnode', dragid: 'default cf', bordercolor: '#50a7aa' },
            ],
        };
    }

    handleFormDialougOpen = () => {
        this.setState({errors:{},formDialougOpen:true})
    };
    
    handleFormDialougClose = () => {
    
        this.setState({formDialougOpen:false,errors:{},data:{},files:{},filesType:{}})
    };

    getId = () => {
        let temp_id = this.state.id;
        this.setState({id:++temp_id});
        return `dndnode_${temp_id}`;
    }

    getType = (str,type) => {
        const myArr = str.split(" ");
        if(type==='type') return myArr[0];
        else return myArr[1];
    };

    setFormType = (formType) =>{
        let forForm = {
            ss:false,
            c:false,
            pi:false,
            cf:false,
            new:false,
        };
        forForm[formType] = true;
        console.log("form is opening from here")
        this.setState({forForm,formDialougOpen:true});
    }

    getLabel = (l_str, id=null) => {
        if(l_str === 'c') return 'Content';
        if(l_str === 'pi') return 'Panel Image';
        if(l_str === 'cf') return 'Contact Form';
        if(l_str === 'new') return 'New Node';
    };
    
    onConnect = (params) => {
        console.log(params);
        let params_temp = {...params};
        params_temp['type'] = 'buttonedge';
        this.setState({edges: addEdge(params_temp,this.state.edges)});
        console.log(this.state.edges);
    };

    onEdgesChange = (oldEdge, newConnection) => {
        console.log('Edge Changes : ',oldEdge);
        console.log(newConnection); return;
        this.setState({elements: applyEdgeChanges(oldEdge, newConnection, this.state.elements)});
        // this.setState({elements: updateEdge(oldEdge, newConnection, this.state.elements)});
        // setElements((els) => updateEdge(oldEdge, newConnection, els));
    };

    onNodesChange = (changes) => {
        console.log('Nodes Changes : ',changes);
        this.setState({elements: applyNodeChanges(changes, this.state.elements)});
        // this.setState({elements: updateEdge(oldEdge, newConnection, this.state.elements)});
        // setElements((els) => updateEdge(oldEdge, newConnection, els));
    };

    onElementsRemove = (elementsToRemove) => {
        console.log('Elements Remove', elementsToRemove);
        // this.setState({elements: removeElements(elementsToRemove, this.state.elements)});
        // this.state.setElements((els) => removeElements(elementsToRemove, els))
    };

    onLoad = (reactFlowInstance) => this.setState({reactFlowInstance});
    
    onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };
    
    onDragDrop = (event) => {
        event.preventDefault();

        const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();
        const f_type = event.dataTransfer.getData('application/reactflow');
        const type = this.getType(f_type,'type');
        const s_label = this.getType(f_type,'label');
        console.log(s_label);
        const position = this.state.reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        const id = this.getId();
        const newNode = {
            id: id,
            type,
            sourcePosition: 'right',
            targetPosition: 'left',
            position,
            data: { label: `${this.getLabel(s_label,id)}`, temp_id: s_label },
        };
        this.setState({elements: [...this.state.elements,newNode]});
        console.log('onDragDrop');
    };

    handlePageChange = (value) => {
        console.log('Page Change',value);
        this.setState({ UploadGlbCurrentPage: value });
    };

    selectedCategory  = async (data) =>{
  
        try
        {
          this.setState({phase:2,isChildLoading:true,selectedItem:data,UploadGlbCurrentPage:1});
          let {data:selectedCollection} = await getCollectionById(data.id);
          if(selectedCollection.status && isBoolean(selectedCollection.status) )
          {
            console.log("my selected Collection data is",selectedCollection)
            this.setState({selectedCollection:selectedCollection.data})
            setTimeout(()=>{
              this.setState({isChildLoading:false})
            },200)
          }
          else if(selectedCollection.status === 'Token is Expired') {
            this.setState({text:'User token expired, Redirecting to login page.'});
            setTimeout(()=>{
              this.setState({token_expired:true,loaderFlag:false});
            },400)
          }
        }
        catch (ex) {
          if (ex.response && ex.response.status === 400) {
            toast.error("Server Busy");
          } else if (ex.response && ex.response.status === 404) {
            toast.error("Server Down, Under maintenance.");
          } else {
            toast.error("Unexpected Error");
          }
        }
    }

    selectedItems = (data,type) => {
    
        const files = {...this.state.files}
        const filesType = {...this.state.filesType}
        const fileAddress = config.assetsEndpoint+data.assets;
        let newData = {...this.state.data};
    
        console.log("my selected glb from gallery is ",data,type,this.state.selectFileFromGallereyType)
        type = this.state.selectFileFromGalleryType;
        let curfile = this.state.selectFileFromGallery;
        console.log('my selected glb from gallery is ',curfile," ",type)
        this.setState({[this.state.selectFileFromGallery]:fileAddress})
        const stateData = {...this.state.data};
        if(type === 'content' || curfile === 'content')
        {
          files[this.state.selectFileFromGallery] = fileAddress;
          newData['library_assets'] = data.id;
          this.setState({data:{...stateData,library_assets:data.id,files}})
        }
        else if(type === 'only_glb'){
          console.log("step2")
          files[this.state.selectFileFromGallery] = fileAddress;
          newData['library_assets_tossing'] = data.id;
          delete files[this.state.selecteFileFromGallery];
        }
        else{
    
          console.log("data in stored single Array",this.state.selectFileFromGallery)
          let k = files;
          
          newData[this.state.selectFileFromGallery] = data.id;
          console.log("step4")
          if(! (files[this.state.selectFileFromGallery] === 'undefined')){
            delete files[this.state.selectFileFromGallery];
          }
          console.log("my new data is",newData)
          console.log("step5")
        }
        console.log("step6")
        filesType[this.state.selectFileFromGallery] = 'glb';
        console.log("step7")
        this.setState({fullScreenDialog:false,filesType,files,phase:1,data:newData,[this.state.selectFileFromGallery]:fileAddress})
    }

    OpenDialogBox = (name) =>{
    
        const fullScreenDialog = this.state.fullScreenDialog;
        const handleFullScreenDialogClose = this.handleFullScreenDialogClose;
        return(
          <div>
            <UploadGlbDialog handlePageChange={this.handlePageChange} currentPage = {this.state.UploadGlbCurrentPage} isloading={this.state.isChildLoading} id={name} fieldname={name} selectedItems={this.selectedItems} selectedCategory={this.selectedCategory} phase={this.state.phase} fullScreenDialog={this.state.fullScreenDialog} handleFullScreenDialogClose={this.handleFullScreenDialogClose} allCategory = {this.state.allCategory} selectedItem={this.state.selectedItem} selectedCategoryCollection={this.state.selectedCollection}/>
          </div>
        )
    }

    handleFullScreenDialogClose = () => {
    console.log("changing dialog to stop",this.state.fullScreenDialog)
    this.setState({fullScreenDialog:false,phase:1})
    }
      
    handleFullScreenDialogOpen = async (name,type) =>{

        try{
            const {data:allCategory} = await getCategories();
            if(allCategory.status && isBoolean(allCategory.status))
            {
                console.log("my total catetgories are", allCategory);
                console.log("create experience page")
                this.setState({fullScreenDialog:true,UploadGlbCurrentPage:1,selectFileFromGallery:name,selectFileFromGalleryType:type,allCategory:allCategory.data, loaderFlag:false})  
            }
            else if(allCategory.status === 'Token is Expired') {
                this.setState({text:'User token expired, Redirecting to login page.'});
                setTimeout(()=>{
                    this.setState({token_expired:true,loaderFlag:true});
                },400)
            }    
        }
        catch(ex) {
            if (ex.response && ex.response.status === 400) {
            toast.error("Server Busy");
            } else if (ex.response && ex.response.status === 404) {
            toast.error("Server Down, Under maintenance.");
            } else {
            toast.error("Unexpected Error");
            }
        }
        
    }

    getForm = (text, type=null) => {

        const {data,files,filesType,errors} = this.state;

        return (
            <div style={{width:"500px",padding:'10px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            {
                type === 'new'
                && 
                (
                    <div style={{width:"100%"}}>
                        {this.DropDownInputField('node_type','Node Type',[{name:'ss',label:'Splash Image'},{name:'c',label:'Content'},{name:'cf',label:'Contact Form'},{name:'pi',label:'Panel Image'}],"",true)}
                    </div>
                )
            }
            {
                (type === 'ss' || (type === 'new' && data['node_type'] === 'ss'))
                && 
                (
                    <div style={{width:"100%"}}>
                        {this.UploadInputFieldExcludeGLB('content','Splash Image','image',true,20480)}
                    </div>
                )
            }
            {
                (type === 'c' || (type === 'new' && data['node_type'] === 'c')) &&
                (
                    <div style={{width:"100%"}}>
                        {this.UploadInputField('content','Content File','content',false)}
                        { filesType && filesType['content'] && filesType['content']!=='mp4' && this.UploadInputFieldExcludeGLB('audio','Audio File','audio',true)}
                        {this.UploadInputFieldExcludeGLB('btn_bg_image','Button Image','image',true,20480)}
                        {this.DropDownInputField('action','Choose Action',[{name:'tap',label:'Tap'},{name:'shake',label:'Shake'},{name:'swipe',label:'Swipe'}],"",true)}                 
                        {this.DropDownInputField('camera_anchored','Camera Anchored',[{name:'no',label:'No'},{name:'yes',label:'Yes'}],"",true)}
                        {this.geolocation('Enable Geo Location','enable_geo_location')}
                    </div>
                )
            }
            {
                ( type === 'pi' || (type === 'new' && data['node_type'] === 'pi') )
                    &&
                (
                    <div style={{width:"100%"}}>
                        {this.UploadInputFieldExcludeGLB('content','Panel Image','image',true,20480)}
                        {this.DropDownInputField('action','Choose Action',[{name:'tap',label:'Tap'},{name:'shake',label:'Shake'},{name:'swipe',label:'Swipe'}],"",true)}
                        {this.geolocation('Enable Geo Location','enable_geo_location')}
                    </div>
                )
            }
            {
                (type === 'cf' ||  (type === 'new' && data['node_type'] === 'cf')) && 
                (
                    <div style={{width:"100%"}}>
                        {this.UploadInputFieldExcludeGLB('content','Background Image','image',true,20480)}
                        {this.DropDownInputField('action','Choose Action',[{name:'tap',label:'Tap'},{name:'shake',label:'Shake'},{name:'swipe',label:'Swipe'}],"",true)}
                        
                        {this.SwitchButton('get_name','Get Name',true,false,false)}
                        {this.SwitchButton('get_mobile','Get Mobile',true,false,false)}
                        {this.SwitchButton('get_feedback','Get Feedback',true,false,false)}
                        {/* get_name
                        get_mobile
                        get_feedback */}
                        {this.geolocation('Enable Geo Location','enable_geo_location')}
                    </div>
                )
            }                    
            </div>
        )
    };

    onElementClick = (event, element) => {
        console.log(element);
        const forForm = {...this.state.forForm};
        for (const [key, value] of Object.entries(forForm)) 
        {
            // console.log(`${key}: ${value}`);
            if(key === element.data.temp_id) forForm[key] = true;
            else forForm[key] = false;
        }

        const data = {...this.state.data};
        if(element.data.temp_id === 'ss') data.type = 'splash_screen';
        else if(element.data.temp_id === 'pi') data.type = 'panel_image';
        else if(element.data.temp_id === 'cf') data.type = 'feedback';
        else if(element.data.temp_id !== 'new') data.type = 'content';

        data.node_id = element.id;

        this.setState({ forForm, data, formDialougOpen:true , errors:{} });
    };

    doSubmit = async () => {

        const {data:formData,forForm,content,audio,btn_bg_image,elements,arExp,edges,submitForms,files} = this.state;
        
        console.log("my state data is before doSubmit",this.state)

        console.log('called do submit');
        document.getElementById("submit_button").disabled = true;
        document.getElementById("submit_button").innerHTML = "Saving Data...";
        
        let node_id = formData.node_id;
        console.log(node_id);
        
        let connected = [];
        for(const ele of edges) {
            if(ele['source'] === node_id) connected.push(parseInt(ele.target.replace('dndnode_','')));
        }
        console.log('Checking',connected);
        

        const data = new FormData();
        data.append("ar_id", arExp.ar_id);
        data.append("type", formData.type);

        if(typeof formData.library_assets === 'undefined' || formData.library_assets === undefined || formData.library_assets === '' ) {
            console.log("my form",formData)
            data.append("content", content);
        }
        else if( !(typeof formData.library_assets === 'undefined' || formData.library_assets === undefined || formData.library_assets === '' ) ) {
            console.log("library asset is innserted in formData")
            data.append('library_assets',formData.library_assets)
        }

        {audio && data.append("audio", audio)}
        {btn_bg_image && data.append("btn_bg_image", btn_bg_image)}
        
        data.append("action", formData.action);
        data.append("index", node_id.replace('dndnode_',''));
        data.append("connected_to", JSON.stringify(connected));
        data.append("node_position", JSON.stringify(elements));
        data.append("edge_position", JSON.stringify(edges));
        data.append("node_id", node_id);
        
        // Display the values
        for (var value of data.values()) {
            console.log(value);
        }
        
        console.log("==================================>",data)

        try {
            const config = {
                onUploadProgress: (progressEvent) => {
                    var downloadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    // console.log(downloadProgress);
                    this.setState({downloadProgress});
                }
            }

            const res = await createNodes(data,config);
            if (res.data.status === "success") {
                console.log("hello rj success")
                document.getElementById("submit_button").innerHTML = "Saved :-)";

                elements.map((ele)=>{
                    if(ele.id === node_id){
                        ele.style = { border: '1px solid green',borderLeft:'5px solid green', padding: 10 };
                    }
                })
                
                submitForms.push({
                    node_id:{
                        data:formData,
                        file:files
                    }
                })
                console.log("===============>",submitForms)
                
                // style: { border: '1px solid #777', padding: 10 },
                console.log("my node id is",node_id,elements)
                toast.success("Data Saved...");
                this.setState({content:'',filesType:{},files:{},formDialougOpen:false,elements,data:{},submitForms});
                
                document.getElementById("submit_button").disabled = false;
                // setTimeout(() => {
                //     this.setState({ redirectToReferrer: true });
                // }, 2000);
            } else if (res.data.status === "failed") {
                document.getElementById("submit_button").innerHTML = "Not Saved";
                document.getElementById("submit_button").disabled = false;
                toast.error("Data not saved, Please try again" + res.data.message);
            } else {
                toast.error("Data not saved, Please try again" + res.status);
                document.getElementById("submit_button").innerHTML = "Not Saved";
                document.getElementById("submit_button").disabled = false;
            }
        } catch (err) {
            if (err.response && err.response.status === 404) {
                toast.error("Unexpected Error occured Please try again.");
            } else {
                console.log("my error is",err)
                toast.error("Unexpected Error other than 404 Please try again.");
            }
            document.getElementById("submit_button").innerHTML = "Back with Error : "+err.response.status;
            document.getElementById("submit_button").disabled = false;
        }
    };

    CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd, data, arrowHeadType}) => {
        console.log(id);

        const foreignObjectSize = 25;

        const [edgePath, labelX, labelY] = getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        });

        return (
            <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={labelX - foreignObjectSize / 2}
                y={labelY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <div class="edge_button_body">
                    <button
                        className="edgebutton"
                        onClick={(event) => this.onEdgeClick(event, id)}
                    >
                        ×
                    </button>
                </div>
            </foreignObject>
            </>
        );
    };

    validate = () =>{
        const {forForm,data,files,filesType} = this.state;
        let errors = {};

        
        if(forForm.c || forForm.pi || forForm.cf || data['node_type'] == 'pi' || data['node_type'] == 'cf' || data['node_type'] == 'c')
        {
            console.log("myy data is",data)
            if(data.enable_geo_location === 'on') {
                if(data.latitude === '' || typeof data.latitude === 'undefined' || data.latitude === undefined) errors.global = 'Latitude is required';
                if(data.longitude === '' || typeof data.longitude === 'undefined' || data.longitude === undefined) errors.global = 'Longitude is required';
                if(data.distance === '' || typeof data.distance === 'undefined' || data.distance === undefined) errors.global = 'Distance is required';
            }
            if(typeof data['action'] =='undefined' ||  data['action'] =='' ){
                errors.global = 'please Choose Action';
                return errors;
            }
        }
        
        if(forForm.c || data['node_type'] == 'c'){

            if(files['content'] == '' || typeof files['content'] == 'undefined' || files['content'] == undefined ){
                errors.global = 'please upload content file';
                return errors;
            }
            // if((filesType['content'] !== 'undefined' && filesType['content']!=='mp4') && (files['audio'] == '' || typeof files['audio'] == 'content' || files['audio'] == undefined) ){
            //     errors.global = 'please upload audio file';
            //     return errors;
            // }
            
            // if(files['btn_bg_image'] == '' || typeof files['btn_bg_image'] == 'undefined' || files['btn_bg_image'] == undefined ){
            //     errors.global = 'please upload Btn Image file';
            //     return errors;
            // }

            if(typeof data['camera_anchored'] =='undefined'){
                errors.global = 'please Choose Camera Anchored';
                return errors;
            }

        }
        else if(forForm.cf || data['node_type'] == 'cf'){

            if(files['content'] == '' || typeof files['content'] == 'undefined' || files['content'] == undefined ){
                errors.global = 'please upload background image';
                return errors;
            }
        }
        else if(forForm.pi || data['node_type'] == 'pi'){
            console.log("let's check panel image")
            if(files['content'] == '' || typeof files['content'] == 'undefined' || files['content'] == undefined ){
                errors.global = 'please upload Panel Image file';
                return errors;
            }

        }
        else if(forForm.ss || data['node_type'] === 'ss'){
            if(files['content'] === '' || typeof files['content'] == 'undefined' || files['content'] === undefined ){
                errors.global = 'please upload splash image';
                return errors;
            }
        }        
        else if(forForm.new )
        {
            console.log("my selected node type is",data['node_type'])
            if(data['node_type'] == '' || data['node_type'] === undefined || typeof data['node_type'] === 'undefined'){
                errors.global = 'please Choose Node Type';
                return errors;
            }
        }
        return errors;
    }

    onEdgeClick = (evt, id) => {
        evt.stopPropagation();
        let edges = this.state.edges;
        edges = edges.filter((e) => e.id !== id);
        // edges = edges.map((e) => { if(e.id !== id) { return e; } });
        this.setState({edges});
        console.log(edges);
    };

    SubmitWorkflow = () =>{
        console.log("my state data is",this.state)
    }

    handleFinalSubmit = () =>{
        const {submitForms,errors} = this.state;
        if(Object.keys(submitForms).length !== 0){
            
            this.props.changetoFinalState(this.state.arExp)
        }
        else{
            errors.global = 'filling atleast one form is mendatory';
            this.setState({errors});
        }
    }

    render() {
        const {forForm,data,arExp, dataLeftDrawer,formDialougOpen,errors,token_expired,openAiSelectedDialogData, openAiDialogOpen} = this.state;
        const nodes = true;
        const activeStep = 1;
        const edgeTypes = {buttonedge: this.CustomEdge,};
        const {name,type} = openAiSelectedDialogData;


        console.log("final state",this.state)

        console.log('Elements : ',this.state.elements);
    
        if(token_expired) return (<Navigate to='/logout' />);

        return (
            <React.Fragment>
                <Box container className="workflowPageDesign" sx={{ overflow:'hidden', display: 'flex',pl:0.6}}>
                    
                    <Grid xs={1} container>
                        <LeftDrawer
                            data= {dataLeftDrawer}
                            nodes= {nodes}
                        />
                    </Grid>
                    {        
                        openAiDialogOpen && (type === 'content' || type==='only_png' || type === 'image' || type === 'button' || type === 'arPortal')
                                && 
                        this.openAiDialogBox(name,type)
                        }
                    <Grid xs={11} sx={{width:"100%"}}>
                        <Grid xs={12} container >
                            <Grid xs={12}>
                                <Box component="main" sx={{ flexGrow: 1}}>
                                    <div className="dndflow" >
                                        
                                        <ReactFlowProvider>
                                            <div className="reactflow-wrapper" ref={this.reactFlowWrapper} >
                                                <ReactFlow
                                                    
                                                    nodes={this.state.elements}
                                                    edges={this.state.edges}
                                                    onNodesChange={this.onNodesChange}
                                                    onEdgesChange={this.onEdgesChange}
                                                    onNodeClick={this.onElementClick}
                                                    onConnect={this.onConnect}

                                                    onDrop={this.onDragDrop}
                                                    onInit={this.onLoad}
                                                    onDragOver={this.onDragOver}
                                                    onElementsRemove={this.onElementsRemove}
                                                    // onClickConnectEnd={this.handleFormDialougOpen}
                                                    // onConnectEnd={this.handleFormDialougOpen}

                                                    // onElementClick={this.onElementClick}
                                                    // snapToGrid={true}
                                                    // snapGrid={[15, 15]}
                                                    edgeTypes={edgeTypes}
                                                    key="edge-with-button"
                                                    deleteKeyCode={46}
                                                    fitView
                                                >
                                                    <Controls />
                                                    <Background color="#aaa" gap={16} />
                                                </ReactFlow>
                                            </div>
                                        </ReactFlowProvider>
                                    </div>
                                </Box>
                                <Dialog open={formDialougOpen} // onClose={this.handleFormDialougClose}
                                >
                                    {(<div className="arData_pane" style={{padding:'10px',color:'grey'}}>
                                        Experience Name : {this.props.slug_name}
                                        <CancelIcon onClick={this.handleFormDialougClose} style={{cursor:'pointer',borderRadius:'50px',height:'15px',width:'15px',float:'right',border:'1px solid grey'}} />
                                    
                                    </div>) }
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        sx={{ m:'auto' }}
                                    >
                                        {forForm.ss && this.getForm('Splash Screen','ss')}
                                        {forForm.c && this.getForm('Content', 'c')}
                                        {forForm.pi && this.getForm('Panel Image','pi')}
                                        {forForm.cf && this.getForm('Contact From','cf')}
                                        {forForm.new && this.getForm('New Node','new')}
                                        <Grid xs={12}>{errors.global && (<Typography sx={{p:1,pl:4,fontWeight:'bold',fontSize:'16px',color:'red'}}><Info /> {errors.global} </Typography>) }</Grid>
                                        <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center',mb:1.5}}>
                                            <Button onClick={this.handleSubmit} className="button1" id='submit_button' type="submit" variant="contained" size="small" sx={{height:"30px !important"}}>Submit</Button>
                                        </Grid>
                                    </Box>
                                </Dialog>
                            </Grid>
                        </Grid>  
                
                        <Grid xs={12} container style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <Grid xs={12} style={{display:'flex',color:'red',alignItems:'center',justifyContent:'center',height:"18px"}}>
                                {errors && <Typography>{errors.global}</Typography>}
                            </Grid>
                            <Button className="button1" variant="contained" size="small" sx={{height:"30px !important",textTransform:'capitalize'}} onClick={this.handleFinalSubmit}>Create Experience</Button>
                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }

}
 
export default CreateWorkFlow;