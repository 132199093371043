import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import "../../css/VideoCard.css";
import arCardImage from '../../assets/arCard.png'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import config from '../services/config.json';
import {deleteArExp} from '../services/arService';


export default class ARExperienceCard extends Component {

  constructor(props){
      super(props)
      this.state = {
          // dataIndex:props.cardDataIndex,
          data:props.cardData,
          handleArExperienceOperation:props.handleArExperienceOperation,
          ARExperienceMenu : null,
          ARExperienceOptions : [
            'Edit',
            'Delete'],
          // open:Boolean(this.ARExperienceMenu),
          open:false,
          playAndStopVideo:props.playAndStopVideo,
          BorderLinearProgress : styled(LinearProgress)(({ theme }) => ({
            height: 5,
            borderRadius: 5,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 5,
              backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
            },
          })),
      }
  }

  getSubcategoryIdBySelectionPoint = (detectionpoint) => {

    if(detectionpoint === 'toss'){
      return "Toss";
    }
    else if(detectionpoint ==="dodge_collect"){
      return "Collect";
    }
    else if(detectionpoint === "shooter"){
      return "Shooter";
    }
    else if(detectionpoint === 'face_game'){
      return "Face Game";
    }
    else if(detectionpoint === "treasure_hunt"){
      return "Treasure Hunt";
    }
    else if(detectionpoint === "lips" || detectionpoint === "lipstick_tryon"){
      return "Lipstick Try-on";
    }
    else if(detectionpoint === "wrist" || detectionpoint === "watch_tryon"){
      return 'Wrist Detection';
    }
    else if(detectionpoint === "eye" || detectionpoint === 'ear' || detectionpoint === 'neck' || detectionpoint === 'head'){
      return "Face Detection";
    }    
    else if(detectionpoint === "finger" || detectionpoint === "ring_tryon"){
      return "Ring Tryon";
    }
    return "";
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.cardData });  
  }

  componentDidMount() {
    // let {dataIndex} = this.state;
    // let data = this.props.getArGlbCardData(dataIndex)
    // console.log("data check from arExperience Card Page",this.state.data)
    // this.setState({data,isLoading:false});

  };

  handleDelete = (option,data) =>{
    this.state.handleArExperienceOperation(option,data)
    this.CloseARExperienceMenu();
  }

  OpenARExperienceMenu = (event) => {
    this.setState({ARExperienceMenu:event.currentTarget,open:true});
  };

  

  

  CloseARExperienceMenu = () => {
    this.setState({ARExperienceMenu:null,open:false});
  }

  render() {
    const BorderLinearProgress = this.state.BorderLinearProgress
    const progress = 100; // in ragen from 1 to 100
    const ITEM_HEIGHT= 38;
    const {data} = this.state;
    const {analytics} = data;
    let viewCount = 0;

    if(analytics.length>0)
    {
      analytics.forEach((an) => {
        viewCount += an.sessions ;
      })
    }

    console.log('data to preview in arExperience CArd is ',data);

    return (
    <Card sx={{ borderRadius:'10px',border:'1px solid lightgrey',boxShadow: '0px 0px 0px 0px #888888'}}>
      <Grid container >
            <Grid xs={4} sm={4} md={4} lg={4} xl={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                
                <CardMedia
                  component="img"
                  style={{width:'70%',height:'auto',padding:'15px 10px 15px 0px'}}
                  image= { arCardImage }
                  alt="Live from space album cover"
                />
            </Grid>
            <Grid xs={8} sm={8} md={8} lg={8} xl={8} sx={{mt:1}}>
              {/* <Box sx={{ display: 'flex',minWidth:"100%", flexDirection: 'column'}}> */}
                {/* <CardContent> */}
                  <Grid container sx={{p:0,m:0}}>
                    
                      <Grid xs={10}>
                         {
                          data.exp_name &&
                          <Typography  sx={{height:'100%',display:'flex',alignItems:'center',fontSize:'18px',fontWeight:'bold'}}>
                          {data.exp_name.length>15 ? data.exp_name.slice(0,12)+"...":data.exp_name}
                          </Typography>
                          }
                      </Grid>
                      <Grid xs={2} sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}}>
                        <IconButton 
                          sx={{float:'left'}} 
                          id="long-button"
                          onClick={this.OpenARExperienceMenu}
                          aria-controls={this.state.open ? 'long-menu' : undefined}
                          aria-expanded={this.state.open ? 'true' : undefined}
                          aria-haspopup="true"
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={this.state.ARExperienceMenu}
                          open={this.state.open}
                          onClose={ this.CloseARExperienceMenu}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '10ch'
                            },
                          }}
                        >
                          {this.state.ARExperienceOptions.map((option) => (
                           
                           (
                            (!(data.exp_type.exp_type.toLowerCase() ==='workflow'  && option === 'Edit')) &&
                              <MenuItem key={option} onClick={()=>this.handleDelete(option,data)} >
                              {/* // this.state.handleArExperienceOperation(option,data)}> */}
                                {option}
                              </MenuItem>
                           )
                          ))}
                        </Menu>
                      </Grid>
                  </Grid>
                  <Grid container>
                      <Grid xs={8} >
                        <Typography  color="text.secondary" component="div" sx={{fontSize:'13px'}}>
                          {data.exp_type.exp_type.length>18?data.exp_type.exp_type.substring(0,15)+'...':data.exp_type.exp_type} 
                          { (data.exp_type_id !==1 && data.exp_type_id !==2)  && (' | '+ this.getSubcategoryIdBySelectionPoint(data.detection_point) ) }
                        </Typography>
                        <Typography variant="subtitle2" sx={{mt:-0.2}}>
                          <span style={{color:'grey',fontSize:'10px',marginLeft:'2px'}}>
                            {data.created_at.split('T')[0].replaceAll('-','/')}
                          </span>
                        </Typography>
                        {/* <div style={{width:"80%",mt:1}}>
                          <BorderLinearProgress  variant="determinate" value={progress} />
                        </div> */}
                      </Grid>
                      <Grid xs={4} sx={{mt:1,mb:'10px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <p style={{color:"#682CEA",fontSize:'26px',fontWeight:'bold',p:0,m:0,width:"100%",textAlign:'center'}}>
                          {viewCount}
                        </p>
                        <p style={{fontSize:'10px',fontWeight:'560'}}>
                        Total views
                        </p>
                      </Grid>
                  </Grid>
                  
                {/* </CardContent> */}
              {/* </Box> */}
            </Grid>
            
      </Grid>
    </Card>
    )
  }
}
