import React from 'react';
import {Grid,Typography,Divider} from '@mui/material';
import config from '../services/config.json';
import mouseImage from './../../assets/mouse.png';
import MoreMenu from './../Common/moreMenu';
import { Navigate } from 'react-router-dom';
import Slide from '@mui/material/Slide';

const TableDataCard = (props) => {

    const { data:arExperiences,handleOperation ,setSlug, setNewSlug} = props;
    
   
    const showExtensions = (assets) =>{
        let allextensions = [];
        assets.forEach((asset)=>{
            let partition = asset.assets.split('.');
            if(partition.length >1){ 
                let ext = asset.assets.split('.').pop();
                ext = ext[0].toUpperCase() + ext.substring(1,).toLowerCase();
                allextensions.push( ext)
            }
        })    
        allextensions = [...new Set(allextensions)]
        return allextensions.length>3 ? allextensions.splice(0,3).join(', ')+', ...' : (allextensions.length === 0 ? '-' : allextensions.join(', '))    
    }
    

    const filterImage = (assets) =>{
        console.log("my asset type should be",assets)
        let value = assets.filter( (asset)=>{
            return (asset.type === 'splash_screen')
        })
        console.log("my value is ",value);
        return (value.length ===0 ? 'https://vossle-v2.s3.ap-south-1.amazonaws.com/v2-assets/splash_common.png':(config.assetsEndpoint+value[0].assets))
    }

    const getViewCount = (analytics) =>{

        let sum = 0;

        analytics.forEach((val)=>{
            sum += val.sessions;
        })

        return sum;
    }

    if(typeof setSlug !== 'undefined'  && setSlug !== '') {
        let newPageUrl  = "qrcode/"+setSlug;
        return (<Navigate to={newPageUrl}/>)
    }


    return  (
        <React.Fragment>
            {arExperiences.length === 0 ? (
                <>
                    <Grid xs={12} sx={{fontSize:"26px",fontWeight:"medium",display:'flex',justifyContent:'center',alignItems:'center'}}>
                        Oops, No Experience Found ..
                    </Grid>
                    <Grid xs={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <img src={mouseImage} alt="AR not found"/>
                    </Grid>
                </>
            ) : (
                <div style={{width:"100%"}}>

                    <Grid sx={{m:'auto',p:'auto',width:"100%"}}>

                       
                        <Grid md={1}></Grid>
                        <Grid container md={10} sx={{fontWeight:'500',mt:1,p:2,mx:'auto'}}>
                            <Grid container xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'flex-start'}}>
                                <Grid xs={12} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                                    Experience Name
                                </Grid>
                            </Grid>
                            <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>Content File</Grid>
                            <Grid xs={3} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>Status</Grid>
                            <Grid xs={2} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>Total View Count</Grid>
                            <Grid xs={1} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}></Grid>
                        </Grid>
                        <Grid md={1}></Grid>                         
                        {arExperiences.map((arExperience,index)=> (
                            <div>
                                <Grid md={1}></Grid>
                                <Grid container xs={12} md={10}  sx={{border:"1px solid lightgrey",borderRadius:'10px',mx:'auto',py:1,mb:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <Grid container xs={3}>
                                        <Grid xs={4} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <img src={filterImage(arExperience.assets)} alt="card-img"  height="50px" width="50px" style={{borderRadius:"10px"}}/>
                                        </Grid>
                                        <Grid xs={8}  >
                                            <div style={{height:'100%',marginLeft:'10px',flexDirection:'column',display:'flex',justifyContent:'center',alignItems:'flex-start'}}>
                                                <Typography sx={{fontSize:'16px',fontWeight:'medium',cursor:'pointer'}} component="div" onClick={()=>setNewSlug(arExperience.slug_exp_name)}>
                                                    {arExperience.exp_name.length>10?(arExperience.exp_name.substring(0,9)+"..."):arExperience.exp_name}
                                                </Typography>
                                                <Divider/>
                                                <Typography sx={{fontSize:'14px',color:'grey',fontWeight:'400'}} >
                                                    {arExperience.exp_type.exp_name === "Face-Detection"?"Tryon":arExperience.exp_type.exp_name}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                
                                    <Grid xs={3}>
                                        <Typography sx={{fontSize:'16px',fontWeight:'440',display:'flex',alignItems:'center',justifyContent:'center',height:'100%'}} component="div">
                                            {showExtensions(arExperience.assets)}
                                        </Typography>
                                    </Grid>
                
                                    <Grid xs={3} sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <Typography component="div" sx={{display:'flex',justifyContent:'center',fontSize:'13px',color:'grey'}}>
                                            {/* {progress}% */}
                                        <span style={{color:'grey',margin:'4px'}}>{arExperience.created_at.split('T')[0]}</span>
                                        </Typography>
                                        {/* <BorderLinearProgress sx={{mb:1}}  variant="determinate" value={progress} /> */}
                                        {
                                            <Typography sx={{display:'flex',justifyContent:'center',alignItems:'center',color:'grey',fontSize:'14px'}}>
                                            status: <span style={{marginLeft:"2px",color:(arExperience.status==='Active'?'green':'red'),background:(arExperience.status==='Active'?'#E2FBED':'#FEE8EA'),padding:"2px 8px 2px 8px",borderRadius:'2px'}}>{arExperience.status}</span>
                                        </Typography>
                                        }

                                    </Grid>
                                    
                                    <Grid xs={2} sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                                        <Typography sx={{fontWeight:'medium',fontSize:'16px'}} component="div">
                                            {getViewCount(arExperience.analytics)}
                                        </Typography>
                                        <Typography sx={{fontSize:'14px',color:'grey'}} component="div">
                                            Total Views
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid xs={1} >
                                        {
                                            <MoreMenu
                                                onEdit={() => handleOperation('Edit',arExperience)}
                                                onDelete={() => handleOperation('Delete',arExperience)}
                                                arExperience={arExperience}
                                            
                                            />
                                        }
                                    </Grid>
                                </Grid> 
                                <Grid md={1}></Grid>
                            </div>
                        ))}

                    </Grid>

                </div>
            )}
        </React.Fragment>
    );
}

export default TableDataCard;