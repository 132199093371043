import React,{useState} from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton,Menu,MenuItem} from '@mui/material';

const MoreMenu = (props) => {
    console.log('More Menu Props',props);
    const {onEdit,onDelete,arExperience} = props;

    const [editMenuAnchor,setEditMenuAnchor] = useState(null);
    const [editMenuOpen,setEditMenuOpen] = useState(false);
    
    // const editMenuAnchor = null;
    // const editMenuOpen = false;
    const ITEM_HEIGHT = 48;

    const handleOpenEditMenu = (event) => {
        setEditMenuAnchor(event.currentTarget)
        setEditMenuOpen(true)
    };

    const handleCloseEditMenu = () => {
        setEditMenuAnchor(null)
        setEditMenuOpen(false)
    };

    const closeMenu = ()=>{
        onDelete();
        setEditMenuOpen(false);
    }

    return (
        <>
            <IconButton
                sx={{float:'right'}}
                aria-label="more"
                aria-controls={editMenuOpen ? 'long-menu' : undefined}
                aria-expanded={editMenuOpen ? 'true' : undefined}
                onClick={(event)=>{handleOpenEditMenu(event)}}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={arExperience.id}
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={editMenuAnchor}
                open={editMenuOpen}
                onClose={handleCloseEditMenu}
                PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '10ch',
                    boxShadow:'0px',
                },
                }}
            >
                {
                    arExperience.exp_type.exp_name.toLowerCase() !=='workflow'
                        && 
                    <MenuItem key='edit' onClick={onEdit}>
                        Edit
                    </MenuItem>
                }
                <MenuItem key='delete' onClick={closeMenu}>
                    Delete
                </MenuItem>
            </Menu>
        </>
    );
}
 
export default MoreMenu;