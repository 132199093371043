import React, { Component } from "react";
import Input from "./input";
import File from "./file";
import TextArea from "./textarea";
import SelectBox from './selectbox';
import './common.css'
class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    // const options = { abortEarly: false };
    // const { error } = Joi.validate(this.state.data, this.schema, options);
    // if (!error) return null;

    // const errors = {};
    // for (let item of error.details) errors[item.path[0]] = item.message;
    // console.log(errors);
    // return errors;
    return null;
    // const { data } = this.state;

    // const regexMobile = /^\d{10}$/;
    // const regexOTP = /^\d{4}$/;

    // if (data.mobileNumber.trim() === "")
    //   errors.mobileNumber = "Mobile Number is required";
    // else if (data.mobileNumber.length > 10)
    //   errors.mobileNumber = "Mobile Number is greater than 10 digit";
    // else if (!data.mobileNumber.match(regexMobile))
    //   errors.mobileNumber = "Mobile Number is not right";

    // if (data.otp.trim() === "") errors.otp = "OTP is required";
    // else if (data.otp.length > 4)
    //   errors.otp = "OTP length is greater than 4";
    // else if (!data.otp.match(regexOTP)) errors.otp = "OTP is not right";

    // return Object.keys(errors).length === 0 ? null : errors;
  };

  validateProperty = ({ name, value }) => {
    // const obj = { [name]: value };
    // const schema = { [name]: this.schema[name] };
    // const { error } = Joi.validate(obj, schema);
    // return error ? error.details[0].message : null;

    return null;

    // if (name === "mobileNumber") {
    //   const regexMobile = /^\d{10}$/;

    //   if (value.trim() === "") return "Mobile Number is required";
    //   else if (value.length > 10)
    //     return "Mobile Number is greater than 10 digit";
    //   else if (!value.match(regexMobile)) return "Mobile Number is not right";
    // }

    // if (name === "otp") {
    //   const regexOTP = /^\d{4}$/;
    //   if (value.trim() === "") return "OTP is required";
    //   else if (value.length > 4) return "OTP length is greater than 4";
    //   else if (!value.match(regexOTP)) return "OTP is not right";
    // }
  };

  validateFileProperty = ({ name, value }) => {
    // console.log(name, value);
    // Allowing file type
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.bmp|\.gif)$/i;
    // let allowedExtensionsVideos = /(\.mp4)$/i;

    if (!allowedExtensions.exec(value)) {
      // alert("Invalid file type");

      // if (!allowedExtensionsVideos.exec(value)) return "Invalid File Type";
      // else return null;

      return null;
    } else {
      // alert("valid file type");
      return null;
    }

    // const obj = { [name]: value };
    // const schema = { [name]: this.schema[name] };
    // const { error } = Joi.validate(obj, schema);
    // return error ? error.details[0].message : null;

    // if (name === "mobileNumber") {
    //   const regexMobile = /^\d{10}$/;

    //   if (value.trim() === "") return "Mobile Number is required";
    //   else if (value.length > 10)
    //     return "Mobile Number is greater than 10 digit";
    //   else if (!value.match(regexMobile)) return "Mobile Number is not right";
    // }

    // if (name === "otp") {
    //   const regexOTP = /^\d{4}$/;
    //   if (value.trim() === "") return "OTP is required";
    //   else if (value.length > 4) return "OTP length is greater than 4";
    //   else if (!value.match(regexOTP)) return "OTP is not right";
    // }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
    // console.log(this.state);
  };

  handleChangeCustom = ({ currentTarget: input }) => {
    this.setState({ [input.name]: input.value });
  };

  handleChangeFile = (event) => {
    const { currentTarget: input } = event;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateFileProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else {
      delete errors[input.name];

    //   let str = document.getElementById(input.name);
  
    //   let element, name, arr;
    //   element = str.nextSibling;
    //   console.log(
    //     (element.innerHTML = str.value.replace(/C:\\fakepath\\/i, ""))
    //   );

    //   name = "selected";
    //   arr = element.className.split(" ");
    //   if (arr.indexOf(name) === -1) {
    //     element.className += " " + name;
    //   }
    }
    this.setState({ [event.target.id]: event.target.files[0], errors });
    console.log(this.state.content);
    
  };

  renderButton(label, id = "") {
    return (
      <button
        disabled={this.validate()}
        className="form-control primary-btn mt-2"
        id={id}
        type="submit"
      >
        {label}
      </button>
    );
  }

  renderInput(name, label, isFocus, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        label={label}
        isFocus={isFocus}
        error={errors[name]}
        type={type}
        value={data[name]}
        onChange={this.handleChange}
      />
    );
  }
  
  renderSelectBox(name, label, properties,isRequired) {
    const { errors } = this.state;
    return (
      <SelectBox
        name={name}
        label={label}
        values={properties}
        error={errors[name]}
        required={isRequired}
        onChange={this.handleChange}
      />
    );
  }
  
  renderLabel(labelfor,text) {
    return (
      <label for={labelfor}>{text}</label>
    );
  }

  renderCustomInput(name, type = "text", label, isFocus) {
    const { errors } = this.state;
    return (
      <Input
        name={name}
        label={label}
        isFocus={isFocus}
        error={errors[name]}
        type={type}
        value={this.state[name]}
        onChange={this.handleChangeCustom}
      />
    );
  }

  renderTextArea(name, label) {
    const { errors } = this.state;
    return (
      <TextArea
        name={name}
        label={label}
        error={errors[name]}
        value={this.state[name]}
        onChange={this.handleChangeCustom}
      />
    );
  }

  renderFile(name, label, isFocus, isRequired) {
    const { data, errors } = this.state;
    return (
      <File
        name={name}
        label={label}
        isFocus={isFocus}
        error={errors[name]}
        value={data[name]}
        onChange={this.handleChangeFile}
        required={isRequired}
      />
    );
  }

  renderRememberMe(check) {
    if (!check) return null;
    return (
      <div className="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me" /> Remember me
        </label>
      </div>
    );
  }
}

export default Form;